<template>      
    <div id="logo-bar">
        <!-- <a href="https://rankedin.ladesk.com/491472-Accept-Online-Payment---Tournament-Fee" target="_blank" style="border: none">
            <img class="logo-img" src="https://cdn.rankedin.com/images/static/RI_card_icon_1.svg" style="max-height:25px;padding-right:10px;height: 18px;margin-top: -4px;" />
            <span class="logo-text"> {{translations["stripe_AdText"]}}</span>
            <img class="logo-img" src="https://cdn.rankedin.com/images/static/StripeLogo.svg" style="max-height: 25px;padding-left: 5px;height: 18px;margin-top: -4px;" />
        </a> -->

		<a href="https://sportcam.app/" target="_blank" style="border: none">
			<img
				class="logo-img"
				src="https://cdn.rankedin.com/images/static/sportcam_logo.svg"
				style="max-height: 25px; padding-right: 6px; height: 18px; margin-top: -4px"
			/>

		</a>
		<span class="logo-text" style="line-height: 20px; font-size: 10px; color: #fff; white-space: nowrap">
			{{ translations['logoTopBar_SportCam_Header'] }}
		</span>
		<a href="https://sportcam.app/" target="_blank">
			<span
				class="logo-text"
				style="line-height: 20px; font-size: 12px; text-decoration: underline; font-weight: bold"
			>
				{{ translations['logoTopBar_SportCam_Link'] }}</span
			>
		</a>
		<!-- <span class="logo-text"> {{translations["stripe_AdText"]}}</span>
		<img class="logo-img" src="https://cdn.rankedin.com/images/static/StripeLogo.svg" style="max-height: 25px;padding-left: 5px;height: 18px;margin-top: -4px;" /> -->


        <!--<template v-if="data.Logo !== null">
            <a v-bind:href="data.Logo.Url"><img class="logo-img" v-bind:src="data.Logo.Path" /></a>
            <span class="logo-text"> {{translations['logoTopBar_PlatinumPartner']}} {{data.Logo.SportName.toLowerCase()}} {{translations['logoTopBar_In']}} {{data.Logo.CountryName}}</span>
        </template>
        <a v-else class="btn btn-primary btn-sm nologo" v-bind:href="'/' + layout.language + '/logo/partnership?country=' + data.CountryId + '&sport=' + data.SportId">{{translations['logoTopBar_AddLogo']}}</a> <span> {{data.SportName.toLowerCase()}} {{translations['logoTopBar_In']}} {{data.CountryName}}</span>-->
    </div>        
</template>

<script>
    import eventBus from "@/app/eventBus";
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        data() {
            return {
                isLoaded: false,
                sponsorCountryId: 'get from layout page store',
                sponsorSportId: 'get from layout page store',
                data: {
                    Logo: {
                        SportName:''
                    }
                }                
            }
        },
        mounted() {       
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
        },
        methods: {
            loadLogos: function () {
                const _this = this;            
                this.$http.get('/logo/getlogotopbarjson', {
                    params: {
                        country: _this.sponsorCountryId,
                        sport: _this.sponsorSportId
                    }}).then(function (res) {
                        _this.data = res.data;
                        _this.isLoaded = true;
                })
            }
        }
    }
</script>

<style scoped>
   
    @media(min-width:768px) {
        #logo-bar {
            max-height: 45px;
        }
    }

    #logo-bar {
        background-color: #0b4e6e;
        color: white;
        display: inline-block;
        padding: 5px 15px;
    }

        #logo-bar > a:hover {
            color: white;
            text-decoration: none;
        }

        #logo-bar span {
            font-size: 11px;
            font-family: 'Montserrat', sans-serif;
        }



        #logo-bar > a {
            color: white;
            text-decoration: none;
            padding: 2px 5px;
            margin: 5px 10px 5px 0;
        }

    .logo-img {
        height: 35px;
    }

    @media(max-width: 767px) {
        #logo-bar {
            max-height: 35px;
        }

            #logo-bar a {
                padding: 0;
                margin: 0 10px 0 -10px;
                float: left;
            }

        .logo-text {
            margin-left: 15px;
            display: block;
        }

        .dropdown-menu-search-results {
            margin-top: -20px;
        }
    }


    #logo-bar > a.nologo {
        color: white;
        text-decoration: none;
        padding: 2px 5px;
        margin: 5px 10px 5px 0;
        border: 2px solid white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 900;
        font-size: 12px;
        background-color: transparent;
    }

</style>