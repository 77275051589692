<template>
    <div>
        <div v-if="hasBillingAddress" class="choose-billing-address">
            <span>
                <b> {{ translations['monetization_ChooseBillingAddress'] }}</b>
            </span>
            <div class="m-2 d-flex justify-content-center" >
                <BaseCard type="primary"
                          :shadow="false"
                          class="address-card">
                    <template #body>
                        <div class="m-3">
                            <p class="m-0">{{billingAddressModel.name}}</p>
                            <p class="m-0">{{billingAddressModel.address}}</p>
                            <p class="m-0">{{billingAddressModel.address2}}</p>
                            <p class="m-0">{{billingAddressModel.postalCode}} {{billingAddressModel.city}}</p>
                            <p class="m-0">{{billingAddressModel.country}}</p>

                            <div class="d-flex justify-content-center mt-4">
                                <BaseButton type="success" size="m" @click.native="continueToCheckout(false)">
                                    <template>
                                        {{ translations['monetization_ChooseBillingAddress_Useit'] }}
                                    </template>
                                </BaseButton>
                            </div>
                        </div>
                    </template>                  
                </BaseCard>
            </div>
            <span>
                <b>     {{ translations['monetization_ChooseBillingAddress_Or'] }}</b>
            </span>
            <div class="m-2 d-flex justify-content-center">
                <BaseButton type="primary" size="m" @click.native="clearBillingAddressAndCheckout">
                    <template>
                        {{ translations['monetization_ChooseBillingAddress_Createnew'] }}
                    </template>
                </BaseButton>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapGetters } from 'vuex';

    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard';

    export default {
        name: 'ChooseBillingAddress',
        components: {
            BaseButton,
            BaseCard
        },
        data() {
            return {                
                isLoading: false,
                billingAddressModel: null
            };
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['selectedPremiumTournamentPlan']),

            hasBillingAddress() {
                return this.billingAddressModel !== null && this.billingAddressModel !== '';
            }
        },
        methods: {                     

            getBillingAddress() {
                this.isLoading = true;
                return this.$http.get(`${this.nomenclatures.ApiDomain}/Monetization/GetPlayerBillingAddressAsync`)
                    .then((response) => {
                        this.billingAddressModel = response.data;
                        this.isLoading = false;
                    });
            },
            clearBillingAddressAndCheckout() {
                this.continueToCheckout(true);
            },
            continueToCheckout(changeBillingAddress) {
                if (this.selectedPremiumTournamentPlan == null) return;

                this.$http.post(`${this.nomenclatures.ApiDomain}/Monetization/BuyOrUpgradeTournamentPlanAsync`, {
                    tournamentId: this.selectedPremiumTournamentPlan.tournamentId,
                    priceId: this.selectedPremiumTournamentPlan.priceId,
                    currency: this.selectedPremiumTournamentPlan.currency,
                    startTierUnits: this.selectedPremiumTournamentPlan.startTierUnits,
                    endTierUnits: this.selectedPremiumTournamentPlan.endTierUnits,
                    changeBillingAddress: changeBillingAddress,
                    language: this.layout.language
                })
                .then((response) => window.location.href = response.data);
          
            }
        },
        beforeMount() {

            this.getBillingAddress().then(() => {
                if (!this.hasBillingAddress) this.continueToCheckout();
            });         
        },
    }
</script>