//import axios from 'axios';

export default {
    namespaced: true,
    state: {
        isAuthorized: false,
        isMenuOpen: false,

        //playerName: '',
        playerFirstName: '',
        playerLastName: '',
        rankedinId: '',
        rankingId: null,
        homeClubId: null,
        isSuperAdmin: false,

        playerImage: null,
        urlName: ''

    },
    mutations: {
        toggleMenu(state, payload) {
            state.isMenuOpen = payload;
        },
        updateInfo(state, payload) {
            //state.playerName = payload.FirstName + ' ' + payload.LastName;

            state.playerFirstName = payload.FirstName;
            state.playerLastName = payload.LastName;

            state.rankedinId = payload.RankedinId;
            state.isSuperAdmin = payload.IsSuperAdmin;
            state.isAuthorized = payload.IsAuthenticated;
            state.rankingId = payload.RankingId;
            state.homeClubId = payload.HomeClubId;
            state.urlName = payload.UrlName;
            if (payload.PlayerImageUrl != null) state.playerImage = payload.PlayerImageUrl + '?' + new Date().getUTCMilliseconds();
            if (payload.PlayerImageUrl == null) state.playerImage = null;

            //state.playerImage = null;
        },
        clearState(state) {
            state.isAuthorized = false;
            state.isMenuOpen = false;
            state.playerName = '';
            state.rankedinId = '';
            state.rankingId = null;
            state.homeClubId = null;


            state.isSuperAdmin = false;
            state.playerImage = null;
            state.urlName = '';

        },
        updateImage(state, payload) {
            console.log('topnav: image upadated');
            state.playerImage = payload + '?' + new Date().getUTCMilliseconds();
        }
    },
    actions: {
        ['global_UpdateTopNavInfo']: {
            root: true,
            handler(namespacedContext, payload) {
                namespacedContext.commit('updateInfo', payload);
            }
        }
    },
    getters: {
        isMenuOpen(state) {
            return state.isMenuOpen;
        },
        isAuthorized(state) {
            return state.isAuthorized;
        },
        playerName(state) {
            return state.playerFirstName + ' ' + state.playerLastName;
        },
        playerImage(state) {
            return state.playerImage;
        },
        playerRankingId(state) {
            return state.rankingId;
        },
        playerHomeClubId(state) {
            return state.homeClubId;
        },
        isSuperAdmin(state) {
            return state.isSuperAdmin;
        },
        rankedinId(state) {
            return state.rankedinId;
        },
        playerUrlName(state) {
            return state.urlName;
        },
        playerInitials(state) {
            let nameLetter = '';
            let surnLetter = '';
            if (state.playerFirstName?.length > 0) nameLetter = state.playerFirstName[0];
            if (state.playerLastName?.length > 0) surnLetter = state.playerLastName[0];

            return nameLetter + surnLetter;
        }
    },
};
