<template>
    <div class="upgrade-to-pro-button" v-if="!isProPlayer">
        <div class="rin-upgrade-to-pro-overlay" v-if="isOverlayed">
            <slot name="top"></slot>
            <BaseButton type="warn" :size="size" @click.native="toggleModal">
                <span class="rin-upgrade-to-pro-button-text">
                    {{translations['monetization_UpgradeTo']}}
                </span>
                <BaseTypeLabel type="pro-invert" />
            </BaseButton>
            <slot name="bottom"></slot>
        </div>
        <BaseButton v-if="!isOverlayed" type="warn" :size="size" @click.native="toggleModal">
            <span class="rin-upgrade-to-pro-button-text">
                {{translations['monetization_UpgradeTo']}}
            </span>
            <BaseTypeLabel type="pro-invert" />
        </BaseButton>

        <portal to="main-base-modal" v-if="showProPurchaseModal">
            <BaseModalWrapper size="l" :show="showProPurchaseModal" @close="showProPurchaseModal = false">
                <template #body>
                    <ModalProPlayers />
                </template>
            </BaseModalWrapper>
        </portal>
        <portal to="main-base-modal" v-if="showTrialModal">
            <BaseModalWrapper size="m" :show="showTrialModal" @close="closeTrialModal" class="rin-modal-no-padding">
                <template #body>
                    <TrialModal @trialStarted="trialStarted" @close="closeTrialModal" />
                </template>
            </BaseModalWrapper>
        </portal>
    </div>
</template>

<script>
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import ModalProPlayers from '@/components/rin_new_design/components/monetization/modals/ModalProPlayers';
    import TrialModal from '@/components/rin_new_design/components/monetization/modals/TrialModal';
    import { mapGetters } from 'vuex';

    export default {
        name: 'UpgradeToProButton',
        components: {
            BaseButton,
            BaseModalWrapper,
            BaseTypeLabel,
            ModalProPlayers,
            TrialModal
        },
        props: {
            size: {
                type: String,
                validator: (v) => ['s', 'm', 'l'].indexOf(v) > -1,
                default: 's',
            },
            isOverlayed: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                showProPurchaseModal: false,
                showTrialModal: false,
                isTrialStarted: false,
            };
        },
        computed: {
            ...mapGetters('monetization', ['isProPlayer']),
            ...mapGetters(['layout', 'translations'])
        },
        methods: {
            toggleModal() {
                let hasUsedFreeTrial = this.layout.player.hasUsedFreeTrial;

                if (!this.layout.player.isAuthenticated || hasUsedFreeTrial) {
                    this.showProPurchaseModal = true;
                } else {
                    this.showTrialModal = true;
                }
            },
            trialStarted() {
                this.isTrialStarted = true;
            },
            closeTrialModal() {
                if (this.isTrialStarted) {
                    window.location.reload();
                }
                else {
                    this.showTrialModal = false;
                }
            }
        }
    };
</script>

<style></style>
