<template>
    <BaseCard type="secondary" :border="true" class="rin-matches-card">
        <template #title>
            <div class="match-title-container">
                <div class="matches-header-left-side">
                    <BaseLabel :type="getEventTypeAsString(match.Info.EventType)">
                    </BaseLabel>
                    <div class="rin-matches-name-wrapper">
                        <span v-bind:class="match.Info.SportIcon" class="icon-sport icon-custom"></span>&nbsp;
                        <router-link v-if="match.Info.EventType !== nomenclatures.FrontEndEventTypeEnum.PrivateMatch"
                                     class="rin-matches-title"
                                     v-bind:class="{ 'underlined' : isEvent(match)}"
                                     :to="match.Info.EventUrl">
                            {{match.Info.EventName}}
                        </router-link>
                        <span v-else
                              class="rin-matches-title"
                              v-bind:class="{ 'underlined' : isEvent(match)}">
                            {{match.Info.EventName}}
                        </span>
                        <StarIndicator size="m" v-if="!isTournamentKillSwitchActive && match.Info.IsPremium"></StarIndicator>
                    </div>
                </div>
            </div>
        </template>
        <template #header>
            <div class="match-header-content" v-if="match.Info.Date !== null && match.Info.EventType !== nomenclatures.FrontEndEventTypeEnum.PrivateMatch">
                <div class="header-item">
                    <BaseIcon name="calendar" />
                    <div class="rin-matches-max-width">
                        {{getDate(match.Info.Date)}}
                    </div>
                </div>
                <div class="header-item">
                    <BaseIcon name="time" />
                    <div class="rin-matches-max-width">
                        {{getHours(match.Info.Date)}}
                    </div>
                </div>
            </div>
            <div class="match-header-content" v-if="match.Info.Location">
                <div class="header-item">
                    <BaseIcon name="pin2" />
                    <div class="rin-matches-max-width">
                        {{match.Info.Location}}
                    </div>
                </div>
                <div v-if="match.Info.Court !== null" class="header-item">
                    <div class="rin-matches-max-width">
                        {{match.Info.Court}}
                    </div>
                </div>
            </div>
            <div class="match-header-content" v-if="match.Info.Ranking !== null">
                <div class="header-item">
                    <BaseIcon name="cup-2" />
                    <div class="rin-matches-max-width">
                        {{match.Info.Ranking}}
                    </div>
                </div>
            </div>
            <hr class="upper-row" />
        </template>
        <template #body>
            <div class="rin-matches-card-body-container">
                <div class="rin-matches-card-participant-names-container">
                    <MatchCardPlayerName :player="match.Info.Challenger" />
                    <MatchCardPlayerName v-if="match.Info.Challenger1 !== null" :player="match.Info.Challenger1" />

                    <div v-if="match.Info.Challenged1 !== null" class="space"></div>

                    <MatchCardPlayerName :player="match.Info.Challenged" />
                    <MatchCardPlayerName v-if="match.Info.Challenged1 !== null" :player="match.Info.Challenged1" />
                </div>
                <template v-if="match.Score && match.Score.Score && match.Score.Score.length > 0">
                    <div v-for="sc in match.Score.Score" :key="sc.$id" class="rin-matches-score-container" v-bind:class="{'doubles-match' : match.Info.Challenger1 !== null }">
                        <div class="rin-matches-scorebox">
                            <span>{{sc.Score1}}</span>
                            <span>{{sc.Score2}}</span>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <template v-if="!isWalkover(match)">
                        <div class="rin-matches-card-participant-score-container">
                            <div class="rin-matches-card-participant-scorebox">
                                <span class="invisible">0</span>
                                <span class="invisible">0</span>
                            </div>
                        </div>
                    </template>
                    <template v-if="isWalkover(match)">
                        <div class="rin-matches-card-participant-score-container">
                            <div class="in-matches-card-participant-scorebox">
                                <span v-if="match.Info.Participant1Status === nomenclatures.ParticipantStatuses.Walkover">{{translations["enterResult_WO"]}}</span>
                                <br v-if="match.Info.Participant1Status === nomenclatures.ParticipantStatuses.Walkover || match.Info.Participant2Status === nomenclatures.ParticipantStatuses.Walkover" />
                                <span v-if="match.Info.Participant2Status === nomenclatures.ParticipantStatuses.Walkover">{{translations["enterResult_WO"]}}</span>
                            </div>
                        </div>
                    </template>
                </template>
                <div v-if="match.Message.ShowMessageButton && isOwnProfile" class="rin-matches-card-message"><router-link v-bind:to="match.Message.ButtonUrl"><span class="icon-chat icon-custom float-right"></span></router-link></div>
            </div>
        </template>
        <template #footer v-if="isOwnProfile">
            <hr class="lower-row" />
            <div class="matches-buttons-container">
                <div class="left-buttons" v-bind:class="{'has-action-buttons': hasActionButton }">
                    <a v-if="match.Buttons.AcceptDate.IsActive" :href="`/challenges/acceptclubleaguechallenge/${match.ChallengeId}`" class="rin-btn-size-s rin-btn-type-success">
                        {{translations["profileMatchButtonsModel_T1"]}}
                    </a>
                    <BaseButton v-if="match.Buttons.ConfirmResult.IsActive" type="success" size="s" @click.native="confirmResult(match.ChallengeId)">
                        {{translations["button_ConfirmResults"]}}
                    </BaseButton>
                    <label v-if="match.Buttons.AwaitingDateConfirmation.IsActive" class="rin-label">{{translations["profileMatchButtonsModel_T2"]}}</label>
                    <label v-if="match.Buttons.AwaitingResultConfirmation.IsActive" class="rin-label">{{translations["matchesPartial_UnconfirmedResultButton"].toLowerCase()}}</label>
                </div>
                <div class="right-buttons">
                    <BaseButton v-if="match.Buttons.EnterResult.IsActive" type="tertiary" size="s" @click.native="enteringResultsLocal(match)">
                        {{translations["matchesPartial_EditResuts"]}}
                    </BaseButton>
                    <BaseButton v-if="match.Buttons.EditResult.IsActive"
                                @click.native="enteringResultsLocal(match)"
                                type="tertiary"
                                size="s">
                        {{translations["button_EditResults"]}}
                    </BaseButton>
                    <BaseButton v-if="match.Buttons.DeletePrivateChallenge && match.Buttons.DeletePrivateChallenge.IsActive"
                                @click.native="deletePrivateChallenge(match)"
                                type="tertiary"
                                size="s">
                        {{translations["button_Delete"]}}
                    </BaseButton>
                    <router-link class="rin-btn-size-s rin-btn-type-tertiary" :to="`/match/edit/${match.ChallengeId}`" v-if="match.Buttons.SetDate.IsActive">{{translations["shared_SetDate"]}}</router-link>
                    <router-link class="rin-btn-size-s rin-btn-type-tertiary" :to="`/match/edit/${match.ChallengeId}`" v-if="match.Buttons.EditDate.IsActive">{{translations["shared_EditDate"]}}</router-link>
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import rinbootbox from '@/app/rin-bootbox';
    import spaEventBus from '@/app/eventBus';

    import BaseLabel from '@/components/rin_new_design/components/base/BaseTypeLabel'
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard'
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton'
    import StarIndicator from '@/components/rin_new_design/components/monetization/labels/StarIndicator';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import MatchCardPlayerName from '@/components/rin_new_design/components/profile/MatchCardPlayerName';

    export default {
        name: 'MatchCard',
        components: {
            BaseLabel,
            BaseCard,
            BaseButton,
            StarIndicator,
            BaseIcon,
            MatchCardPlayerName
        },
        props: {
            match: {
                type: Object,
                required: true
            },
            showActionButtons: {
                type: Boolean,
                required: true,
            }
        },
        data() {
            return {
            };
        },
        mounted() {
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
            ...mapGetters('monetization', ['isProPlayer', 'isTournamentKillSwitchActive']),

            isOwnProfile() {
                return this.showActionButtons;
            },
            hasActionButton() {
                return this.match.Buttons.AcceptDate.IsActive ||
                    this.match.Buttons.ConfirmResult.IsActive ||
                    this.match.Buttons.AwaitingDateConfirmation.IsActive ||
                    this.match.Buttons.AwaitingResultConfirmation.IsActive;
            }
        },
        methods: {
            getEventTypeAsString(type) {
                switch (type) {
                    case this.nomenclatures.EventTypes.Tournament:
                        return 'tournament';
                    case this.nomenclatures.EventTypes.ClubLeague:
                        return 'clubleague';
                    case this.nomenclatures.EventTypes.TeamLeague:
                        return 'teamleague';
                    case this.nomenclatures.FrontEndEventTypeEnum.PrivateMatch:
                        return 'privatematch';
                    default:
                        return '';
                }
            },

            isEvent(entry) {
                if (entry.Info.EventType === this.nomenclatures.EventTypes.Tournament ||
                    entry.Info.EventType === this.nomenclatures.EventTypes.TeamLeague ||
                    entry.Info.EventType === this.nomenclatures.EventTypes.ClubLeague)
                    return true;

                return false;
            },

            getHours(date) {
                return date.split(' ')[1];
            },

            getDate(date) {
                return date.split(' ')[0];
            },

            isWalkover(match) {
                return match.Info.Participant1Status === this.nomenclatures.ParticipantStatuses.Walkover ||
                    match.Info.Participant2Status === this.nomenclatures.ParticipantStatuses.Walkover;
            },

            confirmResult(challengeId) {
                spaEventBus.$emit('rin-profile-matches-confirm-result', { challengeId });
            },

            enteringResultsLocal(match) {
                if (match.Buttons.EnterResult.AlertMessageKey) {
                    rinbootbox.alert(this.translations["playerProfile_ActionNotAvailable"], this.translations[match.Buttons.EnterResult.AlertMessageKey]);
                } else {
                    spaEventBus.$emit('enteringResults_enteringResults', {
                        challengeId: match.ChallengeId,
                        isFromSpa: true
                    });
                }
            },

            deletePrivateChallenge(match) {
                const _this = this;
                
                rinbootbox.confirm({
                    title: this.translations["matches_DeleteResultConfirmationTitle"],
                    body: this.translations["matches_DeleteResultConfirmationText"],
                    successButtonCallback: () => {
                        _this.$http.get(`${_this.nomenclatures.ApiDomain}/privatematch/DeletePrivateMatchAsync?challengeId=${match.ChallengeId}`)
                            .then(() => {
                                spaEventBus.$emit('rin-profile-matches-reload-all-matches');
                            })
                            .catch(() => {
                                rinbootbox.alert(
                                    this.translations["error_Text"],
                                    this.translations["error_ProblemWithRequest"]);
                            });
                    },
                    cancelButtonCallback: () => { },
                    successButtonText: this.translations['button_Delete']
                });
            },
        }
    }
</script>