export default {
    state: {
        idsForCompare: [],
        ratingId: 0
    },
    mutations: {
        addId(state, payload) {
            const index = state.idsForCompare.findIndex(x => x == payload);
            if (index < 0)
                state.idsForCompare.push(payload);
        },
        clearIDsForCompare(state) {
            state.idsForCompare = [];
        },
        removeId(state, payload) {
            const index = state.idsForCompare.findIndex(x => x == payload);
            if (index >= 0)
                state.idsForCompare.splice(index, 1);
        },
        setRatingId(state, payload) {
            state.ratingId = payload;
        }
    },
    actions: {
        addPlayerForRatingCompare: ({ commit }, payload) => {
            commit("addId", payload);
        },
        removePlayerForRatingCompare: ({ commit }, payload) => {
            commit("removeId", payload);
        },
        setRatingForCompare: ({ commit }, payload) => {
            commit("setRatingId", payload);
        },
        clearIdsForCompare: ({ commit }) => {
            commit("clearIDsForCompare");
        },
    },
}