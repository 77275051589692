<template>
    <BaseModalWrapper size="l" :show="showAd" @close="showAd = false">
        <template #body>
            <div class="d-flex flex-row align-items-center private-match-campaign-container">
                <div class="p-4">
                    <img class="img-fluid" src="https://cdn.rankedin.com/images/static/campaign/privatematch-campaign-iphone.png" alt="private match image" />
                </div>
                <div class="d-flex flex-column campaign-text">
                    <div class="h1 p-3">{{translations['campaign_PrivateMatch_Line1']}}</div>
                    <div class="p-3">
                        {{translations['campaign_PrivateMatch_Line2']}}
                    </div>
                    <div class="font-weight-bold p-3"><span>{{translations['campaign_PrivateMatch_Line3']}}</span></div>
                    <div class="p-3 d-flex">
                        <BaseButton type="success" size="m" @click.native="createPrivateMatch">
                            {{translations['campaign_PrivateMatch_ButtonText']}}
                        </BaseButton>
                    </div>
                </div>

            </div>
        </template>
    </BaseModalWrapper>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';

    const privateMatchSettings = {
        name: 'PrivateMatchCampaign',
        async checkAudienceAsync(params) {
            if (!params.store.getters.layout.isEmbed && params.store.getters.layout.player.isAuthenticated) return Promise.resolve(true);

            return Promise.resolve(false);
        }
    };
    export { privateMatchSettings };

    export default {
        name: 'PrivateMatchCampaign',
        props: {},
        data() {
            return {
                showAd: true
            }
        },
        components: {
            BaseModalWrapper,
            BaseButton
        },
        mounted() {

        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
           
        },
        methods: {
            createPrivateMatch() {
                if (this.$route.name !== 'private-match-create') {
                    this.showAd = false;
					this.$router.push({ name: 'private-match-create', params: { language: this.layout.language } }).catch(() => { });
				}
            }

        }

    }
</script>