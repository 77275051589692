<template>
    <div class="rin-search-result-group">
        <h2 class="rin-search-result-group-title">
            <span>{{ title }}</span>
        </h2>
        <Card v-for="result in events" :key="result.Url" :type="$windowSize.isMobile ? 'secondary': 'primary'">
            <template v-slot:header>
                <div>
                    <Label :type="result.SearchResultType" />
                </div>
            </template>
            <template v-slot:body>
                <div class="rin-search-result-group-body">
                    <Flag :country="result.CountryFlag" />
                    <h4>
                        <router-link :to="result.Url" @click.native="closeSearch">
                            {{ result.Name }}
                        </router-link>
                        <StarIndicator v-if="!isTournamentKillSwitchActive && result.IsPremium" size="s"></StarIndicator>
                    </h4>
                </div>
            </template>
            <template v-slot:footer>
                <div class="rin-search-result-group-description">
                    <slot name="description" :item="result"></slot>
                    <!-- Start Date: {{ result.start }}</div> -->
                </div>
            </template>
        </Card>
        <div class="rin-search-show-more-wrapper">
            <Button size="s" type="tertiary" @click.native="showMore">
                <BaseSpinner v-if="isloading" size="s" />
                {{translations['shared_ShowMore']}}
            </Button>
        </div>
    </div>
</template>
<script>
    import Card from '@/components/rin_new_design/components/base/BaseCard';
    import Button from '@/components/rin_new_design/components/base/BaseButton';
    import Flag from '@/components/rin_new_design/components/base/BaseFlag';
    import Label from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner';
    import StarIndicator from '@/components/rin_new_design/components/monetization/labels/StarIndicator';

	import { mapGetters } from 'vuex';

    export default {
        name: 'SearchResultsGroup',
        props: {
            events: {
                type: Array,
                required: true,
            },
            title: {
                type: String,
                required: true,
            },
            type: {
                type: String,
                required: true,
            },
        },
        components: {
            Card,
            Button,
            Flag,
            Label,
            BaseSpinner,
            StarIndicator
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
			...mapGetters('monetization', ['isTournamentKillSwitchActive']),
            isloading() {
                return this.$store.getters['search/isloading'][this.type.toLowerCase()];
            }
        },
        methods: {
            closeSearch() {
                this.$store.commit('search/toggle', false);
            },
            showMore() {
                this.$store.dispatch('search/loadMore', {
                    type: this.type,
                    skip: this.events.length,
                    take: 5,
                    language: 'en',
                    playerid: 0,
                });
            },

        },
    };
</script>
