import _ from 'lodash';
import axios from 'axios';

export const GET_LAYOUT_MODEL_ASYNC = 'getLayoutModel';
export const GET_FEATURE_META_ASYNC = 'getFeatureMetaModel';

export default {
    state: {
        domain: '',
        path: '',
        language: '',
        player: {
            isLoaded: false,            
            sport: 0,
            country: 0,
            currentPlayerId: 0,
            isAuthenticated: false,
            rankingId: 0,
            rankedinId: '',
            firstName: '',
            lastName: '',
            isEventOrganizer: false,
            isSuperAdmin: false,
            unreadMessages: 0,
            unreadNotifications: 0,
            homeClubId: null
        },
        meta: {
            title: '',
            description: '',
            socialMediaImage: 'https://cdn.rankedin.com/images/static/rin-blue-logo.png',
            socialMediaTitle: '',
            socialMediaUrl: 'https://rankedin.com',
            socialMediaDescription: '',
        },
        hreflangsAndCanonicalLinks: [],
        search: {
            searchTerm: "",
        },
        topNotification: {
            isShown: false,
            message: ''
        },
        showHeader: true,
        showFooter: true,
        isEmbed: false,
        embedColor: null,
        page: {
            name: null,
            sport: 0,
            country: 0
        },
        isOldStoreApp: false
    },
    mutations: {
        updateLayoutDomain(state, payload) {
            state.domain = payload;
        },
        updateLayoutPath(state, payload) {
            state.path = payload;
        },
        updateIsEmbed(state, payload) {
            state.isEmbed = state.isEmbed || payload.path.includes('/promote/') || payload.path.includes('/embedevents/') || payload.name == 'print-tournament-draw';
            if (state.isEmbed && payload.path.includes('/promote/')) {
                state.embedColor = payload.path.split('/promote/')[1].split('/')[0];                
                document.body.classList.remove(document.body.classList[0]);
                document.body.classList.add('theme-' + state.embedColor)
            }

            if (payload.query['theme'] !== undefined) {
                document.body.classList.remove(document.body.classList[0]);
                document.body.classList.add('theme-' + payload.query['theme']);
            }
        },
        updateLayoutLanguage(state, payload) {
            state.language = payload;
        },
        clearUnreadNotificationsCount(state) {
            state.player.unreadNotifications = 0;
        },
        clearUnreadMessagesCount(state) {
            state.player.unreadMessages = 0;
        },
        setHeader(state, payload) {
            state.showHeader = payload;
        },
        setFooter(state, payload) {
            state.showFooter = payload;
        },
        updatehreflangsAndCanonicalLinks(state, payload) {
            let languages = payload.LanguagesReversed;
            state.hreflangsAndCanonicalLinks = [{ rel: 'canonical', href: `${state.domain}/en${state.path}` }];
            _.each(languages, function (lang) {
                state.hreflangsAndCanonicalLinks.push({
                    rel: 'alternate',
                    hreflang: lang,
                    href: `${state.domain}/${lang}${state.path}`,
                });
            });
        },

        updateSearchInputVisibility(state, payload) {
            state.search.visibilityCss = payload;
        },
        updateGobalSearchTerm(state, payload) {
            state.search.searchTerm = payload;
        },
        layout_updatePlayerLayout(state, payload) {
            state.player.isAuthenticated = payload.IsAuthenticated;
            state.player.currentPlayerId = payload.CurrentPlayerId;
            state.player.rankingId = payload.RankingId;
            state.player.sport = payload.SportId;
            state.player.country = payload.CountryId;            
            state.player.rankedinId = payload.RankedinId;
            state.player.firstName = payload.FirstName;
            state.player.lastName = payload.LastName;
            state.player.hasUsedFreeTrial = payload.HasUsedFreeTrial
            state.player.unreadMessages = payload.UnreadMessages;
            state.player.unreadNotifications = payload.UnreadNotifications;
            state.player.IsProPlayer = payload.IsProPlayer;
            state.player.isEventOrganizer = payload.IsEventOrganizer;
            state.player.isSuperAdmin = payload.IsSuperAdmin;
            state.player.isLoaded = true;
            state.player.homeClubId = payload.HomeClubId;
        },
        updateTitle(state, payload) {
            state.meta.title = payload;
        },
        updateMetaDescription(state, payload) {
            state.meta.description = payload;
        },
        updateSocialMedia(state, payload) {
            if (typeof (payload.image) !== 'undefined') state.meta.socialMediaImage = payload.image;
            if (typeof (payload.title) !== 'undefined') state.meta.socialMediaTitle = payload.title;
            if (typeof (payload.url) !== 'undefined') state.meta.socialMediaUrl = payload.url;
            if (typeof (payload.description) !== 'undefined') state.meta.socialMediaDescription = payload.description;
        },

        layout_updateTopNotification(state, payload) {
            state.topNotification.isShown = payload.isShown;
            state.topNotification.message = payload.message;
        },
        layout_updatePage(state, payload) {
            state.page.id = payload.id;
            state.page.sport = payload.sport;
            state.page.country = payload.country;
            state.page.name = payload.name;
        }
    },
    actions: {
        async [GET_LAYOUT_MODEL_ASYNC]({ state, commit, dispatch, rootState }) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            return axios.get(`${apiDomain}/player/getlayoutinfoasync`)
                .then(response => {
                    commit("layout_updatePlayerLayout", response.data);
                    dispatch('global_UpdateTopNavInfo', response.data, { root: true });
                    dispatch('monetization/updateIsProPlayer', response.data.IsProPlayer);
                    dispatch('monetization/updateIsJoinedInPremiumEvent', response.data.IsJoinedInPremiumEvent);
                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
        hideHeaderAndFooter: ({ commit }) => {
            commit("setHeader", false);
            commit("setFooter", false);
        },
        showHeaderAndFooter: ({ commit }) => {
            commit("setHeader", true);
            commit("setFooter", true);
        },
        hideFooterOnly: ({ commit }) => {
            commit("setFooter", false);
        },
        updateLayoutDomain: ({ commit }, payload) => {
            commit("updateLayoutDomain", payload);
        },
        updateLayoutPath: ({ commit }, payload) => {
            let noLanguagePath = payload.path.replace(`/${payload.language}/`, "/");
            commit("updateLayoutPath", noLanguagePath);
            let language = payload.language;
            if (typeof (language) == 'undefined') language = 'en';
            commit("updateLayoutLanguage", language);
            commit("updatehreflangsAndCanonicalLinks", payload.nomenclatures);
        },
        updateIsEmbed: ({ commit }, payload) => {
            commit("updateIsEmbed", payload);
        },

        //search
        updateGobalSearchTerm: ({ commit }, payload) => {
            commit('updateGobalSearchTerm', payload);
        },
        updateSearchInputVisibility: ({ commit }, payload) => {
            commit('updateSearchInputVisibility', payload);
        },

        //player
        layout_updatePlayerLayout: ({ commit }, payload) => {
            commit('layout_updatePlayerLayout', payload);
        },

        //topNotification
        layout_updateTopNotification: ({ commit }, payload) => {
            commit('layout_updateTopNotification', payload);
        },

        //current page meta
        async [GET_FEATURE_META_ASYNC]({ commit, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            return axios.get(`${apiDomain}/metadata/GetFeatureMetadataAsync`,
                {
                    params: payload
                })
                .then(response => {
                    commit("layout_updatePage", response.data);
                    commit("updateTitle", response.data.featureTitle);
                    commit("updateMetaDescription", response.data.featureDescription);
                    commit("updateSocialMedia", {
                        image: response.data.featureImage,
                        url: response.data.featureUrl,
                        description: response.data.featureDescription,
                        title: response.data.featureTitle
                    });                    

                })
                .catch(error => {
                    return Promise.reject(error);
                });
        },
        layout_updatePage: ({ commit }, payload) => {
            commit('layout_updatePage', payload);
        },
    },
    getters: {
        layout: (state) => state,

        //search
        getGlobalSearchTerm(state) {
            return state.search.searchTerm;
        },
    },
};
