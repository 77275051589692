export default {
	namespaced: true,
	state: {
		selectedFilters: {},
	},
	mutations: {
		updateSelectedFilters(state, payload) {
			state.selectedFilters = payload;
		},
	},
	actions: {},
	getters: {
		getSelectedFilters(state) {
			return state.selectedFilters;
		},
	},
};
