<template>
    <div class="rin-profile-image">
        <img v-if="hasPlayerImage" :src="playerImage" alt="logo" height="25" />
        <div class="img-placeholder" v-if="!hasPlayerImage">{{ playerInitials }}</div>
    </div>
</template>
<script>
    export default {
        name: 'ProfileImage',
        props: {
            useTopnav: {
                type: Boolean,
                default: true
            },
            imageThumbUrl: {
                type: String,
                default: null
            },
            firstName: {
                type: String,
            },
            lastName: {
                type: String
            }
        },
        computed: {
            playerInitials() {
                if (this.useTopnav)
                    return this.$store.getters['topnav/playerInitials'];
                else {

                    var firstName = this.firstName;
                    var lastName = this.lastName;
                    var firstInitial = '';
                    var secondInitial = '';

                    if (firstName.length > 0) firstInitial = firstName[0];
                    if (lastName.length > 0) secondInitial = lastName[0];

                    return firstInitial + secondInitial;
                }
            },
            playerImage() {
                if (this.useTopnav)
                    return this.$store.getters['topnav/playerImage'];
                else {
                    return this.imageThumbUrl;
                }
            },
            hasPlayerImage() {
                if (this.useTopnav) {
                    var opa = this.$store.getters['topnav/playerImage'] != null;
                    return opa;
                }
                else {
                    return this.imageThumbUrl != null;
                }
            }
        }
    };
</script>
