<template>
    <div v-if="!isLoading">
        <slot name="title"></slot>
        <div class="bookmarks-container" v-bind:class="{'flash' : isProcessingBookmark || isLoadingMore}">
            <template v-if="isProPlayer && hasBookmarks">
                <template v-for="bookmark in bookmarks" >
                    <Card :type="'secondary'" v-bind:key="bookmark.id">
                        <template v-slot:header>
                            <div class="bookmark-title-container">
                                <div class="bookmarks-header-left-side">
                                    <Label :type="nomenclatures.BookmarkTypes[bookmark.bookmarkType].toLowerCase()"></Label>
                                    <br />
                                    <b>
                                        <router-link class="event-name-link" v-bind:to="getBookmarkURL(bookmark)">{{bookmark.name}}</router-link>
                                    </b>
                                </div>
                                <div class="bookmarks-header-right-side">
                                    <Popover>
                                        <template v-slot:trigger>
                                            <span class="opt">
                                                <a href="javascript:;">
                                                    <span class="icon icon-dots"></span>
                                                </a>
                                            </span>
                                        </template>
                                        <template v-slot:tooltip>
                                            <div class="tooltip-buttons-wrapper">
                                                <Button @click.native="moveBookmark(bookmark.id, true)" :type="'tertiary'" :size="'s'">{{translations['bookmarks_MoveUp']}}</Button>
                                                <Button @click.native="moveBookmark(bookmark.id, false)" :type="'tertiary'" :size="'s'">{{translations['bookmarks_MoveDown']}}</Button>
                                                <Button @click.native="removeBookmark(bookmark.id)" :type="'tertiary'" :size="'s'">{{translations['shared_Remove']}}</Button>
                                            </div>
                                        </template>
                                    </Popover>
                                </div>
                            </div>
                        </template>
                        <template v-slot:body>
                            <template v-if="bookmark.bookmarkType == nomenclatures.BookmarkTypes['Tournament'] ||
                                            bookmark.bookmarkType == nomenclatures.BookmarkTypes['ClubLeague'] ||
                                            bookmark.bookmarkType == nomenclatures.BookmarkTypes['TeamLeague'] ||
                                            bookmark.bookmarkType == nomenclatures.BookmarkTypes['Team']">
                                <span v-if="bookmark.country !== 'Unknown'">
                                    <span v-if="bookmark.countryCss" class="bookmark-flag" v-bind:class="bookmark.countryCss.toLowerCase()"></span>
                                    <span class="bookmark-city" v-if="bookmark.city">{{bookmark.city}},</span>
                                    <span class="bookmark-country">&nbsp;{{bookmark.country}}</span>
                                    <br />
                                </span>
                                <span v-if="bookmark.bookmarkType == nomenclatures.BookmarkTypes.Team" class="">
                                    <b>League: {{bookmark.teamEventName}}</b>
                                    <br />
                                </span>
                                <span v-if="bookmark.state>0">
                                    <span class="bookmark-status">
                                        <b>{{translations['playerProfile_Status']}}:</b> {{translations['shared_Events_' + nomenclatures.EventStatesReversed[bookmark.state]]}}
                                    </span>
                                    <br />
                                </span>

                                <span v-if="bookmark.ranking && bookmark.bookmarkType != nomenclatures.BookmarkTypes.Team">
                                    <span class="bookmark-ranking">
                                        <b>{{translations['rankingsPartial_Ranking']}}:</b> {{bookmark.ranking}}
                                    </span>
                                    <br />
                                </span>
                                <span v-if="bookmark.bookmarkType != nomenclatures.BookmarkTypes.Team && isDateReal(bookmark.startDate)" class="">
                                    <span>
                                        <span class="bookmark-date-from">
                                            <b>{{translations['calendarIndex_DateFilterFrom']}}:</b>
                                        </span>
                                        <span class="bookmark-date">
                                            {{momentFunc(bookmark.startDate)}}
                                            {{translations['calendarIndex_DateFilterTo'].toLowerCase()}}
                                            {{momentFunc(bookmark.endDate)}}
                                        </span>
                                    </span>
                                    <br />
                                </span>
                            </template>
                            <template v-if="bookmark.bookmarkType == nomenclatures.BookmarkTypes.Organization">
                                <span>
                                    <span v-if="bookmark.countryCss" class="bookmark-flag" v-bind:class="bookmark.countryCss.toLowerCase()"></span>
                                    <span class="bookmark-city" v-if="bookmark.city">{{bookmark.city}},</span>
                                    <span class="bookmark-country">&nbsp;{{bookmark.country}}</span>
                                </span>
                                <br />
                            </template>
                            <template v-if="bookmark.bookmarkType == nomenclatures.BookmarkTypes.PlayerProfile">
                                <span v-if="bookmark.clubName">
                                    <span>
                                        <b>Club:</b>
                                    </span>
                                    <span class="bookmark-club">{{bookmark.clubName}}</span>
                                </span>
                            </template>
                        </template>
                    </Card>
                </template>

            </template>
            <template v-else>
                <Card class="watermark" v-for="num in [0, 1]" :key="num" :type="'secondary'">
                    <template v-slot:header>
                        <div class="bookmark-title-container">
                            <div class="bookmarks-header-left-side">
                                <Label :type="'tournament'"></Label>
                                <br />
                                <b>
                                    <a class="event-name-link">cool naym</a>
                                </b>
                            </div>
                            <div class="bookmarks-header-right-side">
                                <Popover>
                                    <template v-slot:trigger>
                                        <span class="opt">
                                            <a href="#">
                                                <span class="icon icon-dots"></span>
                                            </a>
                                        </span>
                                    </template>
                                </Popover>
                            </div>
                        </div>
                    </template>
                    <template v-slot:body>
                        <template>
                            <span>
                                <span>
                                    <span class="bookmark-flag"></span>
                                    <span class="bookmark-city">cool city,</span>
                                    <span class="bookmark-country">cool country</span>
                                </span>
                                <br />
                            </span>
                            <span class="">
                                <b>League: cool</b>
                                <br />
                            </span>

                            <span>
                                <span class="bookmark-status">
                                    <b>cool status:</b> status cool
                                </span>
                                <br />
                            </span>
                            <span>
                                <span class="bookmark-ranking">
                                    <b>cool ranking:</b> some cool ranking
                                </span>
                                <br />
                            </span>
                            <span class="">
                                <span>
                                    <span class="bookmark-date-from">
                                        <b>cool date watermark:</b>
                                    </span>
                                    <span class="bookmark-date">
                                        cool date to other cool date
                                    </span>
                                </span>
                                <br />
                            </span>
                        </template>
                        <template>
                            <span>
                                <span>
                                    <span class="bookmark-flag"></span>
                                    <span class="bookmark-city">cool city,</span>
                                    <span class="bookmark-country">&nbsp;cool country</span>
                                </span>
                                <br />
                            </span>
                        </template>
                        <template>
                            <span class="">
                                <span>
                                    <b>Club:</b>
                                </span>
                                <span class="bookmark-club">cool name</span>
                            </span>
                        </template>
                    </template>
                </Card>

                <div class="watermark-square" v-if="!isProPlayer">
					Upgrade to our PRO plan to unlock this feature
					"Followed PRO" tab will be only visible to you, not to any other visitor
					<UpgradeToProButton :is-overlayed="false"/>
					
                </div>
                <div class="watermark-square" v-if="!hasBookmarks && isProPlayer">
                    <template>
                        <div class="followBig">{{translations['bookmarks_AddBookmarks']}}</div>
                        <div class="followWrapper">
                            {{translations['Bookmarks_JustClick']}}
                            <Button type="secondary" :size="'s'">{{translations['bookmarks_Follow']}}</Button>
                            {{translations['Bookmarks_JustClick2']}}
                        </div>
                    </template>
                    <div class="followSmall"><span>{{translations['bookmarks_FollowedPRO']}}</span></div>
                </div>

            </template>
            <br />
        </div>
        <div v-if="isProPlayer && hasBookmarks" class="show-more-wrapper">
            <Button type="tertiary" @click.native="loadMoreBookmarks(bookmarks.length)" :size="'s'">
                <span v-if="isLoadingMore"><Spinner :size="'s'" /></span>
                <span v-if="!isLoadingMore">{{translations['shared_ShowMore']}}</span>
            </Button>
        </div>
    </div>
    <Spinner v-else :size="'m'" />
</template>
<script>
    import { mapGetters } from 'vuex';
    import { MOMENT_DATETIME_FORMAT } from '@/app/constants';
    import spaEventBus from '@/app/eventBus';
    import moment from 'moment';
    import Card from '@/components/rin_new_design/components/base/BaseCard.vue';
    import Popover from '@/components/rin_new_design/components/base/BasePopover.vue';
    import Spinner from '@/components/rin_new_design/components/base/BaseSpinner.vue';
    import Label from '@/components/rin_new_design/components/base/BaseTypeLabel.vue';
    import Button from '@/components/rin_new_design/components/base/BaseButton.vue';
	import UpgradeToProButton from '@/components/rin_new_design/components/monetization/buttons/UpgradeToProButton';

    export default {
        name: 'profileBookmarks',
        components: {
            Card,
            Popover,
            Spinner,
            Label,
            Button,
			UpgradeToProButton
        },
        data() {
            return {
                isLoading: false,
                isLoadingMore: false,
                hasBookmarks: false,
                isProcessingBookmark: false,
                bookmarks: [],
                skip: 0,
                take: 10
            }
        },
        mounted() {
            spaEventBus.$on('reloadBookmarks', () => { this.checkIfHasBookmarks(); });
            this.checkIfHasBookmarks();
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
			...mapGetters('monetization', ['isProPlayer']),
            playerId: function () {
                return this.layout.player.currentPlayerId;
            }
        },
        methods: {
            checkIfHasBookmarks() {
                if (!this.hasBookmarks) {
                    this.isLoading = true;
                    this.$http.get(`${this.nomenclatures.ApiDomain}/bookmark/HasBookmarksAsync`, { params: { playerId: this.playerId, skip: 0, take: 10 } })
                        .then((res) => {
                            this.isLoading = false;
                            if (res.data === true) {
                                this.hasBookmarks = true;
                                this.loadBookmarks();
                            }
                        });
                }
            },
            loadBookmarks(isRefresh = false) {

                if (this.isProPlayer && !this.isLoading) {
                    if (!isRefresh) {
                        this.isLoading = true;
                    } else {
                        this.skip = 0;
                        this.take = this.bookmarks.length;
                    }
                    this.$http.get(`${this.nomenclatures.ApiDomain}/bookmark/GetBookmarksAsync`, { params: { playerId: this.playerId, skip: this.skip, take: this.take } })
                        .then((res) => {
                            this.bookmarks = res.data;
                            this.isLoading = false;
                            this.isProcessingBookmark = false;
                        })
                        .finally(() => {
                            this.isLoading = false;
                            this.isProcessingBookmark = false;
                        })
                }
            },
            loadMoreBookmarks(skip = 0) {
                if (this.isProPlayer && !this.isLoading) {
                    this.skip = skip;
                    this.isLoadingMore = true;
                    this.$http.get(`${this.nomenclatures.ApiDomain}/bookmark/GetBookmarksAsync`, { params: { playerId: this.playerId, skip: skip, take: 10 } })
                        .then((res) => {
                            this.bookmarks = this.bookmarks.concat(res.data);
                            this.isLoadingMore = false;
                        })
                }
            },
            removeBookmark(id) {
                this.isProcessingBookmark = true;
                this.$http.post(`${this.nomenclatures.ApiDomain}/bookmark/deletebookmarkbyidasync`, { bookmarkId: id })
                    .then(() => {
                        this.bookmarks = this.bookmarks.filter(x => x.id !== id);
                        this.isProcessingBookmark = false;
                    })
            },
            moveBookmark(id, directionUp) {
                this.isProcessingBookmark = true;
                this.$http.post(`${this.nomenclatures.ApiDomain}/bookmark/changebookmarkorderasync`, { bookmarkId: id, isup: directionUp })
                    .then(() => {
                        this.moveBookmarkLocalArray(id, directionUp)
                        this.loadBookmarks(true);
                        this.isProcessingBookmark = false;
                    })
            },
            moveBookmarkLocalArray(id, directionUp) {
                const bookmarkToMove = this.bookmarks.find(x => x.id === id);
                let from = this.bookmarks.indexOf(bookmarkToMove);
                let to = directionUp ? from - 1 : from + 1
                if (from >= 0 && to >= 0) {
                    let bookmark = this.bookmarks.splice(from, 1)[0];
                    this.bookmarks.splice(to, 0, bookmark);
                }
            },
        

            // utils
            momentFunc(date) {
                return moment(date).format(MOMENT_DATETIME_FORMAT);
            },
            getBookmarkURL(bookmark) {
                switch (bookmark.bookmarkType) {
                    case parseInt(this.nomenclatures.BookmarkTypes.PlayerProfile):
                        return `/${this.layout.language}/player/${bookmark.rankedinID}`;
                    case parseInt(this.nomenclatures.BookmarkTypes.Organization):
                        return `/${this.layout.language}/organisation/${bookmark.eventId}`;
                    case parseInt(this.nomenclatures.BookmarkTypes.Ranking):
                        return `/${this.layout.language}/ranking/${bookmark.eventId}`;
                    case parseInt(this.nomenclatures.BookmarkTypes.ClubLeague):
                        return `/${this.layout.language}/clubleague/${bookmark.eventId}`;
                    case parseInt(this.nomenclatures.BookmarkTypes.Tournament):
                        return `/${this.layout.language}/tournament/${bookmark.eventId}`;
                    case parseInt(this.nomenclatures.BookmarkTypes.TeamLeague):
                        return `/${this.layout.language}/teamleague/${bookmark.eventId}`;
                    case parseInt(this.nomenclatures.BookmarkTypes.Team):
                        if (bookmark.eventType === this.nomenclatures.EventTypes.Tournament) {
                            return `/${this.layout.language}/team/${bookmark.teamRankedinId}`;
                        }
                        if (bookmark.eventType === this.nomenclatures.EventTypes.TeamLeague) {
                            return `/${this.layout.language}/team/homePage/${bookmark.eventId}`;
                        }
                        break;
                }
            },            
            isDateReal(date) {
                return moment(date).isAfter('1931-03-24T01:14:00Z');
            }
        },

    }
</script>
