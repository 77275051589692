export default {
	state: {
		isOwnProfile: false,
		isDonated: false,
		rating: {},
		playerIdForCurrentProfile: 0,
		currentRating: 0,
		opponentsTab: {
			excludedPlayersFromSearch: [],
		},
	},
	mutations: {
		updateExcludedPlayersFromSearch(state, payload) {
			state.opponentsTab.excludedPlayersFromSearch = payload;
		},
		updateIsOwnProfile(state, payload) {
			state.isOwnProfile = payload;
		},
		updateIsDonated(state, payload) {
			state.isDonated = payload;
		},
		updateRating(state, payload) {
			state.rating = payload;
		},
		updateplayerIdForCurrentProfile(state, payload) {
			state.playerIdForCurrentProfile = payload;
		},
		updateCurrentRating(state, payload) {
			state.currentRating = payload;
		},
	},
	actions: {
		updateIsOwnProfile: ({ commit }, payload) => {
			commit('updateIsOwnProfile', payload);
		},
		updateRating: ({ commit }, payload) => {
			commit('updateRating', payload);
		},
		updateIsDonated: ({ commit }, payload) => {
			commit('updateIsDonated', payload);
		},
		updateplayerIdForCurrentProfile: ({ commit }, payload) => {
			commit('updateplayerIdForCurrentProfile', payload);
		},
		updateCurrentRating: ({ commit }, payload) => {
			commit('updateCurrentRating', payload);
		},
		updateExcludedPlayersFromSearch: ({ commit }, payload) => {
			commit('updateExcludedPlayersFromSearch', payload);
		},
	},
	getters: {
		rating(state) {
			return state.rating;
		},
		currentRating(state) {
			return state.currentRating;
		},
	},
};
