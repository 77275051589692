<template>
	<div class="rin-register-phone rin-register-form">
		<h1>{{ translations['register_PhoneHeading'] }}</h1>

		<div class="rin-register-input-container">
			<label for="phone" class="rin-register-label">{{ translations['register_PhoneNumber'] }}</label>
			<input
				id="phone"
				v-model="userPhone"
				class="rin-register-input"
				type="tel"
				autocomplete="off"
				:placeholder="translations['register_PhoneNumber']"
			/>
		</div>

		<div v-cloak>
			<BaseAlert type="danger" :has-background="false">
				<span v-if="hasError" class="rin-register-error">
					{{ translations['register_2'] }}
				</span>
			</BaseAlert>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue';

export default {
	name: 'RegisterPhone',
	components: {
		BaseAlert,
	},
	props: {
		hasError: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters(['translations', 'layout']),
		...mapGetters('register', ['phone']),
		userPhone: {
			get() {
				return this.phone;
			},
			set(v) {
				this.updatePhone(v);
			},
		},
	},
	methods: {
		...mapMutations('register', ['updatePhone']),
	},
};
</script>
