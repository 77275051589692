<template>
    <div class="rin-base-checkbox" :class="{'disabled':disabled}" @click="click">
        <BaseIcon v-show="isChecked" name="check"></BaseIcon>
    </div>
</template>

<script>
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';

    export default {
        name: 'BaseCheckBox',
        components: {
            BaseIcon
        },
        props: {
            isChecked: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            click() {
                if (!this.disabled) {
                    this.$emit('click');
                }
            }
        }
    };
</script>

<style scoped>
    .disabled{
        opacity: 0.5;
    }
</style>

