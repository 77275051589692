<template>
	<i :class="`flag-icon flag-icon-${country}`"></i>
</template>
<script>
export default {
	name: 'BaseFlag',
	props: {
		country: {
			type: String,
			required: true,
		},
	},
};
</script>