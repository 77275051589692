export default {
    namespaced: true,
    state: {
        isInitialStateLoaded: false,

        isPublished: false,
        isPublishedMsg: false,
        modal: {
            isModalOpen: false,
            isLoading: false,
            header: '',
            text: '',
        }
    },
    mutations: {
        updateIsPublished(state, payload) {
            state.isPublished = payload;
        },
        updateIsPublishedMsg(state, payload) {
            state.isPublishedMsg = payload;
        },
        closePublishModal(state) {
            state.modal.isModalOpen = false;
        },
        setModalState(state, payload) {
            state.modal.isModalOpen = payload.isModalOpen;
            state.modal.isLoading = payload.isLoading;
            state.modal.header = payload.header;
            state.modal.text = payload.text;
        },
        setLoadingPublishState(state, payload) {
            state.isInitialStateLoaded = payload;
        }
    },
    actions: {
        updateIsPublished: ({ commit }, payload) => {
            commit("updateIsPublished", payload);
        },
        updateIsPublishedMsg: ({ commit }, payload) => {
            commit("updateIsPublishedMsg", payload);
        },
        closePublishModal: ({ commit }) => {
            commit('closePublishModal');
        },
        setModalState: ({ commit }, payload) => {
            commit('setModalState', payload);
        },
        setInitialLoadState: ({ commit }, payload) => {
            commit('setLoadingPublishState', payload)
        },
    },
    getters: {
        isPublished(state) {
            return state.isPublished;
        },
        isPublishedMsg(state) {
            return state.isPublishedMsg;
        },
        isInitialStateLoaded(state) {
            return state.isInitialStateLoaded;
        },
        publishModalState(state) {
            return state.modal;
        },
    }
}