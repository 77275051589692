import _ from 'lodash';

export default {
    state: {
        areMatchesLoaded: false,
        isAdmin: false,
        colors: [
            "#e67e22",
            "#2980b9",
            "#664525",
            "#065b53",
            "#875547",
            "#4E5AE3",
            "#3e7c21",
            "#211756",
            "#F03C3C",
            "#660000",
            "#ff00bf",
            "#fa6f9e",
            "#01a2aa",
            "#95af3f",
            "#1660b1",
            "#34d40a",
            "#D2DF4D",
            "#e19dc8",
            "#00ff00",
            "#61e2a5",
            "#e2ff6f",
            "#00FFFF",
            "#5d4d90",
            "#eb4a90",
            "#3355ab",
            "#8f459e",
            "#00837e"
        ],
        tournamentClasses: [],
        table: {
            dates: [],
            noVirtualTableDates: []
        },
        isVisible: true
    },
    mutations: {
        setTournamentClasses(state, payload) {
            state.tournamentClasses = payload;
        },        
        setMatchesLoaded(state, payload) {
            state.areMatchesLoaded = payload;
        },
        setTableDates(state, payload) {
            state.table.dates = payload;
            state.table.noVirtualTableDates = _.filter(payload, function (o) { return !o.isVirtual; });      
        },
        setIsVisible(state, payload) {
            state.isVisible = payload;
        },  
        setIsAdmin(state, payload) {
            state.isAdmin = payload;
        }, 
    },
    actions: {
        setCourtManagerTournamentClasses: ({ commit }, payload) => {
            commit("setTournamentClasses", payload);
        },
        setCourtManagerMatchesLoaded: ({ commit }, payload) => {
            commit("setMatchesLoaded", payload);
        },
        setTableDates: ({ commit }, payload) => {
            commit("setTableDates", payload);
        },
        setIsAdmin: ({ commit }, payload) => {
            commit("setIsAdmin", payload);
        },
    },
    getters: {

    }
}