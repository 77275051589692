<template>
    <div class="rin-matches-placeholder">
        <h5>
            {{translations["playerProfile_PlaceholderYouHaveNoMatches"]}}
        </h5>
        <img src="https://cdn.rankedin.com/images/static/no-events-matches-placeholder.png" />
        <br />
        <span>
            {{translations["playerProfile_PlaceholderPastMatchesAreYourSkill"]}}
        </span>
        <br />
        <span>{{translations["playerProfile_PlaceholderMatchesWillBeHere"]}}</span>
        <br />
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'MatchesPlaceholder',
        components: {
        },
        props: {
        },
        data() {
            return {
            };
        },
        mounted() {
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures'])
        },
        methods: {
            ...mapMutations('ui', ['addBodyModal']),


        }
    }
</script>