<template>
	<div class="topBar col-12">
		<div class="wrap" style="height: 22px">
			<p v-show="isLoaded">
				<span class="back"
					><a onclick="window.history.back()"><span class="icon icon-back"></span></a
				></span>
				<!-- <a href="https://rankedin.ladesk.com/491472-Accept-Online-Payment---Tournament-Fee" target="_blank">
                    <img class="logo-img" src="https://cdn.rankedin.com/images/static/RI_card_icon_1.svg" style="max-height:25px;padding-right:10px;height: 18px;margin-top: -4px;" />
                </a>
                <a href="https://rankedin.ladesk.com/491472-Accept-Online-Payment---Tournament-Fee" target="_blank">
                    <span class="logo-text" style="line-height: 20px;    font-size: 10px;"> {{translations['stripe_AdText']}}</span>
                </a>
                <a href="https://rankedin.ladesk.com/491472-Accept-Online-Payment---Tournament-Fee" target="_blank">
                    <img class="logo-img" src="https://cdn.rankedin.com/images/static/StripeLogo.svg" style="max-height: 25px;padding-left: 5px;height: 18px;margin-top: -4px;" />
                </a>                 -->
				<a href="https://sportcam.app/" target="_blank">
					<img
						class="logo-img"
						src="https://cdn.rankedin.com/images/static/sportcam_logo.svg"
						style="max-height: 25px; padding-right: 6px; height: 11px; margin-top: -3px"
					/>
				</a>
				<span class="logo-text" style="line-height: 20px; font-size: 9px; color: #fff; white-space: nowrap">
					{{ translations['logoTopBar_SportCam_Header'] }}
				</span>
				<a href="https://sportcam.app/" target="_blank">
					<span
						class="logo-text"
						style="line-height: 20px; font-size: 9px; text-decoration: underline; font-weight: bold"
					>
						{{ translations['logoTopBar_SportCam_Link'] }}</span
					>
				</a>
			</p>
		</div>
	</div>
	<!--<div v-if="data.Logo !== null" class="row">
        <div class="topBar col-12">
            <div class="wrap" style="height: 22px;">
                <p v-show="isLoaded">
                    <span class="back"><a onclick="window.history.back()"><span class="icon icon-back"></span></a></span>
                    <a v-bind:href="data.Logo.Url"><img class="logo-img" v-bind:src="data.Logo.Path" style="max-height:25px" /></a>
                    <span class="logo-text"> {{translations['logoTopBar_PlatinumPartner']}} {{data.Logo.SportName.toLowerCase()}} {{translations['logoTopBar_In']}} {{data.Logo.CountryName}}</span>
                </p>
            </div>
        </div>
    </div>
    <div v-else class="row">
        <div class="topBar col-12">
            <div class="wrap" style="height: 22px;">
                <p v-show="isLoaded">
                    <span class="back"><a onclick="window.history.back()"><span class="icon icon-back"></span></a></span>
                    <a v-bind:href="'/' + layout.language + '/logo/partnership?country=' + data.CountryId + '&sport=' + data.SportId" class="btn btn-primary rounded-pill">{{translations['logoTopBar_AddLogo']}}</a>  {{data.SportName.toLowerCase()}} {{translations['logoTopBar_In']}} {{data.CountryName}}
                </p>
            </div>
        </div>
    </div>-->
</template>
<script>
import eventBus from '@/app/eventBus';
import { mapGetters, mapMutations } from 'vuex';

export default {
	data() {
		return {
			isLoaded: true,
			sponsorCountryId: 'get from layout page store',
			sponsorSportId: 'get from layout page store',
			data: {
				Logo: {
					SportName: '',
				},
			},
		};
	},
	mounted() {},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
	},
	methods: {
		loadLogos: function () {
			const _this = this;
			this.$http
				.get('/logo/getlogotopbarjson', {
					params: {
						country: _this.sponsorCountryId,
						sport: _this.sponsorSportId,
					},
				})
				.then(function (res) {
					_this.data = res.data;
					_this.isLoaded = true;
				});
		},
	},
};
</script>

<style scoped>
.stripe-logo {
	margin-right: 10px;
	fill: white;
	height: 21px;
}
</style>
