export default {
    state: {
        model: {}
    },
    mutations: {
        updateOrganistionModel(state, payload) {
            state.model = payload;
        }
    },
    actions: {
        updateOrganistionModel: ({ commit }, payload) => {
            commit("updateOrganistionModel", payload);
        }
    }
}