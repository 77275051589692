<template>
    <portal to="main-base-modal" v-if="isRegisterModalOpen">
        <BaseModalWrapper class="rin-register-modal-wrapper"
                          size="fullv-s"
                          :show="isRegisterModalOpen"
                          @close="closeRegisterModal">
            <template #body>
                <vueper-slides :touchable="false"
                               :bullets="false"
                               :progress="true"
                               :disableArrowsOnEdges="true"
                               :infinite="false">
                    <template #arrow-left>
                        <BaseIcon name="back" />
                    </template>
                    <template #arrow-right>
                        <span ref="arrowRight" style="display: none"></span>
                    </template>
                    <vueper-slide v-for="i in 7" :key="i">
                        <template #content>
                            <div class="rin-register-slide" v-if="i == 1">
                                <RegisterEmailPassword :next-ref="$refs.arrowRight" />
                            </div>
                            <div class="rin-register-slide" v-if="i == 2">
                                <RegisterName :next-ref="$refs.arrowRight" />
                            </div>
                            <div class="rin-register-slide" v-if="i == 3">
                                <RegisterDateOfBirth :next-ref="$refs.arrowRight" />
                            </div>
                            <div class="rin-register-slide" v-if="i == 4">
                                <RegisterGender :next-ref="$refs.arrowRight" />
                            </div>
                            <div class="rin-register-slide" v-if="i == 5">
                                <RegisterCountry :next-ref="$refs.arrowRight" />
                            </div>
                            <div class="rin-register-slide" v-if="i == 6">
                                <RegisterSport :next-ref="$refs.arrowRight" />
                            </div>
                            <div class="rin-register-slide" v-if="i == 7">
                                <RegisterPhone :has-error="$v.phone.$dirty && (!$v.phone.required || !$v.phone.validatePhone)" />
                                <BaseButton v-if="!isLoading" type="success" size="m" @click.native="registerAccount">
                                    {{ translations['register_CreateAccount'] }}
                                </BaseButton>
                                <BaseSpinner v-if="isLoading" />
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </template>
        </BaseModalWrapper>
    </portal>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import { detect } from 'detect-browser';
    import moment from 'moment';

    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner.vue';

    import RegisterEmailPassword from '@/components/rin_new_design/components/register/RegisterEmailPassword';
    import RegisterName from '@/components/rin_new_design/components/register/RegisterName';
    import RegisterDateOfBirth from '@/components/rin_new_design/components/register/RegisterDateOfBirth';
    import RegisterGender from '@/components/rin_new_design/components/register/RegisterGender';
    import RegisterCountry from '@/components/rin_new_design/components/register/RegisterCountry';
    import RegisterSport from '@/components/rin_new_design/components/register/RegisterSport';
    import RegisterPhone from '@/components/rin_new_design/components/register/RegisterPhone';

    import { VueperSlides, VueperSlide } from 'vueperslides';
    import 'vueperslides/dist/vueperslides.css';
    import rinbootbox from '@/app/rin-bootbox';
    import { required } from 'vuelidate/lib/validators';
    import { validatePhone } from '@/app/custom_validations';

    export default {
        name: 'RegisterIndex',
        components: {
            BaseModalWrapper,
            BaseButton,
            BaseIcon,
            BaseSpinner,
            VueperSlides,
            VueperSlide,
            RegisterEmailPassword,
            RegisterName,
            RegisterDateOfBirth,
            RegisterGender,
            RegisterCountry,
            RegisterSport,
            RegisterPhone,
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('register', [
                'email',
                'password',
                'firstName',
                'lastName',
                'dob',
                'gender',
                'country',
                'sport',
                'phone',
                'newsletter',
                'isRegisterModalOpen',
            ]),
            getClientPlatform() {
                const browser = detect();
                const platform = `${browser.name} ${browser.version} / ${browser.os}`;
                return platform;
            },
        },
        data() {
            return {
                isLoading: false,
            };
        },
        methods: {
            ...mapMutations('register', ['clearAll']),
            ...mapActions('register', ['setRegisterModalState']),
            closeRegisterModal(payload) {
                if (payload?.isClickedOutside) {
                    return;
                }
                this.setRegisterModalState(false);
                this.clearAll();
            },
            registerAccount() {
                this.$v.phone.$touch();
                if (!this.$v.phone.required || !this.$v.phone.validatePhone) {
                    return;
                }

                this.isLoading = true;
                const offset = new Date().getTimezoneOffset();
                const payload = {
                    UserName: this.email.trim(),
                    Email: this.email.trim(),
                    BirthDay: moment(this.dob).date(),
                    BirthMonth: moment(this.dob).month() + 1,
                    BirthYear: moment(this.dob).year(),
                    FirstName: this.firstName,
                    LastName: this.lastName,
                    Phone: this.phone,
                    Password: this.password,
                    Gender: Number(this.gender),
                    CountryShort: this.country.short,
                    FavouriteSport: Number(this.sport),
                    ReturnURL: '',
                    Language: this.layout.language,
                    ClientPlatform: this.getClientPlatform,
                    TimezoneOffset: offset,
                    AllowNewsletter: this.newsletter,
                };

                if (this.$windowSize.isMobile) {
                    window.scrollTo(0, 0);
                }

                this.$http
                    .post(`${this.nomenclatures.ApiDomain}/account/RegisterAccountAsync`, payload)
                    .then(() => {
                        this.isLoading = false;
                        const captureMail = this.email.trim(); // :(
                        this.closeRegisterModal();
                        this.clearAll();
                        this.$router.push({
                            name: 'join-us',
                            params: { language: this.language },
                            query: { completed: true, registerEmail: captureMail },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.isLoading = false;
                        this.closeRegisterModal();
                        this.clearAll();
                        this.$router.push({
                            name: 'landing-page',
                            params: { language: this.language },
                        });

                        rinbootbox.alert(this.translations['register_GeneralRegisterError']);
                    });
            },
        },
        watch: {
            isRegisterModalOpen(v) {
                const cb = (e) => {
                    if (e.keyCode == 9 || e.keyCode === 13) {
                        e.preventDefault();
                    }
                };

                if (v) {
                    setTimeout(() => {
                        document.querySelector('.rin-register-modal-wrapper').addEventListener('keydown', cb);
                    }, 0);
                } else {
                    document.querySelector('.rin-register-modal-wrapper').removeEventListener('keydown', cb);
                }
            },
        },
        validations() {
            return {
                phone: { required, validatePhone }
            }
        },
    };
</script>
