import drawData from "../../components/spider/drawData";

import vue from "vue";
import vuex from "vuex";
import _ from 'lodash';

vue.use(vuex);

export default {
    state: {
        isLoading: false,
        template: [],
        templateDynamicValidations: [],
        wizardData: [],
        summary: [],
        currentStepIndex: 0,
        currentWizardOption: 0,
        tournamentClassId: 0        
    },
    mutations: {
        setTournamentClassId(state, payload) {
            state.tournamentClassId = payload;
        },
        selectWizardOption(state, payload) {
            state.currentWizardOption = payload;
        },
        goToStep(state, payload) {
            state.currentStepIndex = payload;
        },
        setTemplate(state, payload) {
            state.template = payload;
        },
        setWizardData(state, payload) {
            state.wizardData[payload.stepIndex] = payload;
        },
        setDynamicValidations(state, payload) {
            state.templateDynamicValidations[payload.stepIndex] = payload.validations;
        },
        clearWizard(state, payload) {
            state.wizardData = [];
            state.summary = [];
            state.templateDynamicValidations = [];
        },
        setSumamry(state, payload) {
            state.summary = payload;
        },
        setLoading(state, payload) {
            state.isLoading = payload;
        },
    },
    actions: {
        goToStep: ({ commit }, payload) => {
            commit("goToStep", payload);
        },
        setWizardData: ({ commit }, payload) => {
            commit("setWizardData", payload);
        },
        clearWizard: ({ commit }, payload) => {
            commit("clearWizard", payload);
        },
        setTemplate: ({ commit }, payload) => {
            commit("setTemplate", payload);
        },
        selectWizardOption: ({ commit }, payload) => {
            commit("selectWizardOption", payload);
        },
        setTournamentClassId: ({ commit }, payload) => {
            commit("setTournamentClassId", payload);
        },
        setSumamry: ({ commit }, payload) => {
            commit("setSumamry", payload);
        },
        setLoading: ({ commit }, payload) => {
            commit("setLoading", payload);
        },
        setDynamicValidations: ({ commit }, payload) => {
            commit("setDynamicValidations", payload);
        },
    },
    getters: {
        getDrawIconSrc: state => (drawType) => {
            return drawData.getIcon(drawType);
        },
        getDrawName: state => (drawType) => {
            return drawData.getName(drawType);
        },
        getCurrentStepIndex: state => () => { return state.currentStepIndex; },
        getStepTemplate: state => () => { return state.template[state.currentStepIndex]; },
        getStepDynamicValidations: state => () => { return state.templateDynamicValidations[state.currentStepIndex]; },
        getStepData: state => () => { return state.wizardData[state.currentStepIndex]; },
        getIsSummaryStep: state => () => { return state.template[state.currentStepIndex].WizardStep == "Summary"; },
        getIsLastStepExceptSummary: state => () => { return state.currentStepIndex == state.template.length - 2; },
        getCurrentWizardOption: state => () => { return state.currentWizardOption; },
        getTournamentClassId: state => () => { return state.tournamentClassId; },
        getSummary: state => () => { return state.summary; },
        getParticipantsDataSteps: state => () => { return _.filter(state.wizardData, function (a) { return typeof (a) !== 'undefined' && a.step === 'DrawSeededParticipants'; }); },
        getIsLoading: state => () => { return state.isLoading; },
    }
}