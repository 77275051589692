export default {
    state: {
        searchData: [],
        resultData: [],
        searchTeamAdminData: [],
        teamAdminData: [],
        eventModel: {
            endPoint: '',
            backUrl: '',
            eventId: 0,
            eventType: '',
            tournamentClassId: 0,
            isDoubles: false
        },
        eventModelForUserPart: {}
    },
    mutations: {
        updateEventModelForUserPart(state, payload) {
            state.eventModelForUserPart = payload;
        },
        updateSearchData(state, payload) {
            state.searchData = payload;
        },
        updateResultData(state, payload) {
            state.resultData = payload;
        },
        updateTeamAdminSearchData(state, payload) {
            state.searchTeamAdminData = payload;
        },
        updateTeamAdminResultData(state, payload) {
            state.teamAdminData = payload;
        },
        updateAutoAddEventModel(state, payload) {
            state.eventModel.endPoint = payload.endPoint;
            state.eventModel.backUrl = payload.backUrl;
            state.eventModel.eventId = payload.eventId;
            state.eventModel.eventType = payload.eventType;
            state.eventModel.tournamentClassId = payload.tournamentClassId;
            state.eventModel.isDoubles = payload.isDoubles;
        },
        moveUpParticipant(state, participantIndex) {
            if (participantIndex > 0) {
                const temp = state.resultData[participantIndex];
                state.resultData.splice(participantIndex, 1);
                state.resultData.splice(participantIndex - 1, 0, temp);

                for (let i = 0; i < state.resultData.length; i++) {
                    state.resultData[i].PlayerOrder = i + 1;
                }
            }
        },
        moveDownParticipant(state, participantIndex) {
            if (participantIndex < state.resultData.length - 1) {
                const temp = state.resultData[participantIndex];
                state.resultData.splice(participantIndex, 1);
                state.resultData.splice(participantIndex + 1, 0, temp);

                for (let i = 0; i < state.resultData.length; i++) {
                    state.resultData[i].PlayerOrder = i + 1;
                }
            }
        },
        changeTeamParticipantType(state, participant) {
            const item = state.resultData[participant.index];
            item.TeamParticipantType = participant.teamParticipantType;
            state.resultData.splice(participant.index, 1, item);
        }
    },
    actions: {
        updateSearchData: ({ commit }, payload) => {
            commit("updateSearchData", payload);
        },
        updateResultData: ({ commit }, payload) => {
            commit("updateResultData", payload);
        },
        updateAutoAddEventModel: ({ commit }, payload) => {
            commit("updateAutoAddEventModel", payload);
        },
        updateEventModelForUserPart: ({ commit }, payload) => {
            commit("updateEventModelForUserPart", payload);
        },
        updateTeamAdminSearchData: ({ commit }, payload) => {
            commit("updateTeamAdminSearchData", payload);
        },
        updateTeamAdminResultData: ({ commit }, payload) => {
            commit("updateTeamAdminResultData", payload);
        },
    }
}