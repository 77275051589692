<template>
    <div class="rin-navmenu-action">
        <router-link v-if="!link" :to="{ name: destinationName, params: params, hash: hash }" @click.native="closeMenu">
            <BaseIcon :name="icon" />
            <span class="rin-action-title">
                {{ title }}
            </span>
            <p class="rin-action-description">
                {{description}}
            </p>
        </router-link>
        <router-link v-if="link" :to="link">
            <BaseIcon :name="icon" />
            <span class="rin-action-title">
                {{ title }}
            </span>
            <p class="rin-action-description">
                {{description}}
            </p>
        </router-link>
    </div>
</template>

<script>
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
    export default {
        name: 'NavMenuAction',
        components: {
            BaseIcon
        },
        props: {
            destinationName: {
                type: String,
                default: null,
            },
            params: {
                type: Object,
                default: () => { },
            },
            icon: {
                type: String,
                requred: true,
            },
            title: {
                type: String,
                requred: true,
            },
            description: {
                type: String,
                required: true
            },
            link: {
                type: String,
                default: null
            },
            hash: {
                type: String,
                requred: false,
                default: ''
            },
        },
        methods: {
            closeMenu() {
                this.$store.commit('topnav/toggleMenu', false);
            }
        }
    };
</script>
