import axios from 'axios';
import bootbox from '@/app/rin-bootbox';
import router from '@/router/router';

export default {
    namespaced: true,
    state: {
        chatList: [],
        selectedChat: null,
        searchResults: [],

        backUrl: null,
        eventName: null,

        isLoading: false
    },
    mutations: {
        updateChatList(state, payload) {
            if (payload.refresh === true) {

                state.chatList = payload.chats;

            }
            else {

                const uniqueChats = payload.chats.filter((c) =>
                    !state.chatList.some((x) => x.chatId === c.chatId)
                );

                state.chatList = state.chatList.concat(uniqueChats);
            }
        },
        addMessages(state, payload) {

            state.selectedChat.chatMessages = [...payload, ...state.selectedChat.chatMessages];
        },
        updateChatBox(state, payload) {

            state.selectedChat = {
                chatMessages: payload.messages,
                chatName: payload.chatName,
                chatId: payload.chatId,
                participantId: payload.participantId,
                rankedinId: payload.rankedinId,
                urlName: payload.urlName
            };
        },
        clear(state) {
            state.selectedChat = null;
            state.searchResults = [];
            state.backUrl = null;
        },
        addMessage(state, payload) {
            state.selectedChat.chatMessages.push(payload);
        },
        updateSearchResults(state, payload) {
            state.searchResults = payload;
        },
        newChat(state, payload) {
            var selectedChat = state.searchResults.find(x => x.participantId == payload.participantId);
            if (selectedChat != null && state.selectedChat?.participantId != selectedChat.participantId)
                state.selectedChat = selectedChat;
        },
        contactAdmin(state, payload) {
            state.backUrl = payload.backUrl;
            state.eventName = payload.eventName;
        },
        clearBackUrl(state) {
            state.backUrl = null;
        },
        updateIsLoading(state, payload) {
            state.isLoading = payload;
        },
        clearState(state) {
            state.chatList = [];
            state.selectedChat = null;
            state.searchResults = [];
            state.backUrl = null;
            state.eventName = null;
            state.isLoading = false;
        }
    },
    actions: {
        async loadChatList({ commit, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            const lang = router.currentRoute.params.language;
            try {
                commit('updateIsLoading', true);
                var res = await axios.get(`${apiDomain}/chat/getchats?skip=${payload.skip}&take=20&language=${lang}`);
                commit('updateChatList', { chats: res.data.chats, refresh: payload.refresh });
                commit('updateIsLoading', false);
                return Promise.resolve(res);
            }
            catch (err) {
                bootbox.alert('Error', 'An error occured. Please try again or contact us on our support chat');
                return Promise.reject(err);
            }

        },
        async loadChatBox({ commit, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;

            try {
                var res = await axios.get(`${apiDomain}/chat/getchatbox?chatId=${payload.chatId}`);
                commit('updateChatBox', res.data);
                return Promise.resolve(res);
            }
            catch (err) {
                bootbox.alert('Error', 'An error occured. Please try again or contact us on our support chat');
                console.error(err);
                return Promise.reject(err);
            }
        },
        async loadMoreMessages({ commit, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;

            axios.get(`${apiDomain}/chat/getmessages?chatId=${payload.chatId}&skip=${payload.skip}`)
                .then(res => {
                    commit('addMessages', res.data);
                    return Promise.resolve();
                })
                .catch(e => {
                    bootbox.alert('Error', 'An error occured. Please try again or contact us on our support chat');
                    return Promise.reject(e);
                });
        },
        clear({ commit }) {
            commit('clear');
        },
        async sendMessage({ commit, dispatch, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            try {

                var res = await axios.post(`${apiDomain}/chat/sendmessage`, {
                    chatId: payload.chatId,
                    message: payload.message
                });

                commit('addMessage', res.data);
                dispatch('loadChatList', { skip: 0, refresh: true });
                return Promise.resolve();
            }
            catch (err) {
                return Promise.reject();
            }
        },
        async searchChats({ commit, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            try {

                var res = await axios.post(`${apiDomain}/chat/searchchats`, {
                    term: payload.term,
                    skip: 0,
                    take: 15
                });

                commit('updateSearchResults', res.data.chats);
                return Promise.resolve();
            }
            catch (err) {
                return Promise.reject();
            }
        },

        newChat({ commit, rootState }, payload) {
            commit('newChat', payload);
        },

        async createNewChat({ dispatch, state, rootState }, payload) {
            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            // contact admins buttons and new chats from search results
            // will allways end up in this action

            // we can distinguish them if the backUrl. 
            // if backUrl is not null, then we are coming from contact admin button
            try {
                let additionalInfo = '';

                if (state.backUrl != null)
                    additionalInfo = `<u><a href="${state.backUrl}">From: ${decodeURIComponent(state.eventName)}<a/></u><br>`

                var res = await axios.post(`${apiDomain}/chat/createchat`, {
                    participantId: payload.participantId,
                    message: payload.message,
                    additionalInfo: additionalInfo
                });

                dispatch('loadChatList', { skip: 0, refresh: true });

                return Promise.resolve(res.data);
            }
            catch (err) {
                return Promise.reject();
            }
        },
        async contactAdmin({ commit, rootState }, payload) {
            commit('contactAdmin', { backUrl: router.currentRoute.fullPath, eventName: payload.eventName });

            const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
            let messages = [];
            var res = await axios.get(`${apiDomain}/chat/GetChatBoxForEventAdminAsync?playerId=${payload.participantId}`);
            if (res && res.data) {
                messages = res.data.messages;
            }
      


            commit('updateChatBox', { chatId: 0, participantId: payload.participantId, chatName: payload.name, messages: messages });
            router.push({ name: 'newchat', params: { id: payload.participantId, language: router.currentRoute.params.language } }).catch(err => { });
        },
        clearBackUrl({ commit }) {
            commit('clearBackUrl');
        }
    },
    getters: {
        getChatList(state) {
            return state.chatList;
        },
        getSearchResults(state) {
            return state.searchResults;
        },
        getChatMessages(state) {
            return state.chatMessages;
        },
        getSelectedChat(state) {
            return state.selectedChat;
        },
        getBackUrl(state) {
            return state.backUrl;
        },
        getIsLoading(state) {
            return state.isLoading;
        }
    }
}