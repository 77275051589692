 <template>
    <div :class="`rin-card rin-card-bg-${type} rin-card-${hasShadow} rin-card-${hasBorder}`">
        <div class="rin-card-title" v-if="$slots.title">
            <slot name="title"></slot>
        </div>
        <div class="rin-card-header" v-if="$slots.header">
            <slot name="header"></slot>
        </div>
        <div class="rin-card-body" v-if="$slots.body">
            <slot name="body"></slot>
        </div>
        <div class="rin-card-footer" v-if="$slots.footer">
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'BaseCard',
        props: {
            type: {
                type: String,
                validator: (v) => ['primary', 'secondary'].indexOf(v) > -1,
                required: true,
            },
            shadow: {
                type: Boolean,
                required: false,
                default: false,
            },
            border: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            hasShadow: function () {
                return this.shadow ? 'shadow' : 'no-shadow';
            },
            hasBorder: function () {
                return this.border ? 'border' : 'no-border'
            }
        },
    };
</script>
