<template>
    <button :role="role" :disabled="disabled" :class="`rin-btn-size-${size} rin-btn-type-${type}`" @click="click">
        <slot />
    </button>
</template>

<script>

    export default {
        name: 'BaseButton',
        props: {
            size: {
                type: String,
                validator: (v) => ['s', 'm', 'l', 'fullwidth'].indexOf(v) > -1,
                default: 's',
            },
            type: {
                type: String,
                validator: (v) => ['primary', 'secondary', 'tertiary', 'success', 'fail', 'warn', 'danger', 'premium', 'pro'].indexOf(v) > -1,
                required: true,
            },
            role: {
                type: String,
                default: 'button',
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            click() {
                if (!this.disabled) {
                    this.$emit('click');
                }
            }
        }
    };
</script>

<style scoped>
    .disabled{
        opacity: 0.5;
    }
</style>

