<template>
	<div class="searchTop col-auto col-lg-3" v-cloak>
		<form action="javascript:void(0);">
			<div class="row">
				<div class="col">
					<input
						type="text"
						@click="showSearch"
						class="search-top-navbar"
						name="searchtop"
						id="searchtop"
						v-model="searchTerm"
						v-on:input="searchTerm = $event.target.value"
						v-on:keyup="search"
						v-bind:placeholder="translations['shared_Search_Global_Placeholder']"
						autocomplete="off"
					/>
				</div>
				<div class="col col-auto">
					<button type="submit" v-on:click="search"><span class="icon icon-search"></span></button>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
    //to be used in spa only
    import spaEventBus from "@/app/eventBus";
    import { mapGetters } from 'vuex';
    import _ from 'lodash'

    export default {
        data() {
            return {                
            }
        },
        mounted: function () {
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),       
            searchTerm: {
                // getter
                get: function () {
                    return this.$store.getters.getGlobalSearchTerm;
                },
                // setter
                set: function (newValue) {
                    this.$store.dispatch("updateGobalSearchTerm", newValue);  
                }
            }
        },
        methods: {
            search: _.debounce(function () {
                var _this = this;                
                if (_this.searchTerm) {
                    this.getSerchResults(this.layout.player.currentPlayerId);
                }
            }, 1200),

		getSerchResults: function (id) {
			this.$store.dispatch('updateGobalSearchTerm', this.searchTerm);
			spaEventBus.$emit('global-search-get-results', {
				id: id,
			});
		},
	},
};
</script>
