<template>
    <div class="rin-search-backdrop">
        <div class="wrap rin-search-wrap">
            <div class="rin-search rin-scroll" v-click-outside="close">
                <div class="rin-search-header">
                    <div class="rin-search-header-title">
                        Search
                    </div>
                    <BaseIcon name="close" @click.native="close" />
                </div>
                <div class="search-input-mobile">
                    <SearchInput name="mobile"></SearchInput>
                </div>
                <SearchFilter v-if="state === 'results'" />
                <BaseSpinner v-if="state === 'loading'" />
                <SearchHistory v-if="state === 'history'" />
                <SearchResults v-if="state === 'results'" />
                <SearchNoRes v-if="state === 'noresults'" />
            </div>
        </div>
          
    </div>
</template>
<script>
    import vClickOutside from 'v-click-outside';

    import SearchHistory from '@/components/rin_new_design/components/search/SearchHistory.vue';
    import SearchResults from '@/components/rin_new_design/components/search/SearchResults.vue';
    import SearchFilter from '@/components/rin_new_design/components/search/SearchFilter.vue';
    import SearchInput from '@/components/rin_new_design/components/search/SearchInput.vue';
    import SearchNoRes from '@/components/rin_new_design/components/search/SearchNoRes.vue';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner.vue';
  

	import { mapGetters } from 'vuex';

    export default {
        name: 'Search',
        components: {
            SearchInput,
            BaseSpinner,
            SearchHistory,
            SearchResults,
            SearchNoRes,
            SearchFilter,
            BaseIcon
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        computed: {
			...mapGetters('ui', ['isMonetizationModalOpen']),
            state() {
                return this.$store.getters['search/state'];
            },
        },
        methods: {
            close(event) {
                if (event.target.className === 'rin-search-input') return;
                this.$store.commit('search/toggle', false);
            },
      
        },
    };
</script>
