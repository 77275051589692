import _ from 'lodash';

export default {
    install(Vue, router) {
        this.router = router;

        Vue.prototype.$routeQuery = this;
    },

    router: null,

    get(key) {
        let lowerCaseQuery = _.transform(this.router.currentRoute.query, function (result, v, k) {
            result[k.toLowerCase()] = v;
        });
        return lowerCaseQuery[key.toLowerCase()];
    },
   
}
