<template>
    <div class="rin-profile-matches">
        <div v-if="!initialContentLoaded" class="d-flex jcc">
            <BaseSpinner size="m" />
        </div>

        <div v-if="!isPlaceholderShown && initialContentLoaded" style="margin-bottom: 1rem">
            <div class="rin-collapse-toggle" @click="upcoming.isOpen = !upcoming.isOpen; loadUpcomingMatches(0, true)">
                <span class="tab-title">{{translations["shared_Events_Upcoming"]}}</span>
                <div class="icon icon-v" v-bind:class="{ 'reversed' : upcoming.isOpen }"></div>
            </div>

            <hr />

            <div v-if="upcoming.isOpen">
                <div class="matches-container" v-if="(upcoming.invitations.length > 0 || upcoming.matches.length > 0) && !(upcoming.matchesLoading || upcoming.invitationsLoading)">
                    <InvitationCard v-for="invitation in upcoming.invitations" :key="invitation.Id" :invitation="invitation" @reload="reloadInvitations" />
                    <MatchesCard v-for="match in upcoming.matches" :key="match.MatchId" :match="match" :showActionButtons="isOwnProfile" />
                </div>
                <div v-else>
                    <BaseAlert v-if="!isLoading" class="matches-alert" type="info">
                        {{translations["playerProfile_ListEmpty"]}}
                    </BaseAlert>
                </div>

                <div v-if="upcoming.hasMore" class="matches-showmore">
                    <BaseButton @click.native="loadUpcomingMatches(upcoming.matches.length)"
                                :disabled="upcoming.matchesLoading || history.loadMore"
                                type="tertiary"
                                size="s">
                        <BaseSpinner v-if="isLoadingMoreUpcoming" size="s" />
                        <span v-if="!isLoadingMoreUpcoming">{{translations['shared_ShowMore']}}</span>
                    </BaseButton>
                </div>
            </div>
        </div>
        
        <AdBox1 />

        <div v-if="!isPlaceholderShown && initialContentLoaded">
            <div class="rin-collapse-toggle" @click="history.isOpen = !history.isOpen; loadFinishedMatches(0, true)">
                <span class="tab-title">{{translations["matchesPartial_History"]}}</span>
                <div class="icon icon-v" v-bind:class="{'reversed' : history.isOpen}"></div>
            </div>
            <hr />
            <div v-if="history.isOpen" class="finished-wrapper">
                <div v-if="history.matches.length > 0 && !history.isLoading" class="matches-container" v-bind:class="{'flash' : isMatchProcessing, 'blurred' : !this.isProPlayer }">
                    <MatchesCard v-for="match in history.matches"
                                 :match="match"
                                 :key="match.MatchId"
                                 :showActionButtons="isOwnProfile" />
                </div>
                <div v-else>
                    <BaseAlert v-if="!isLoading" class="matches-alert" type="info">
                        {{translations["playerProfile_ListEmpty"]}}
                    </BaseAlert>
                </div>
                <UpgradeToProButton v-if="!isProPlayer && !history.isLoading" @toggleSubscriptionModal="openModal">
                    <template #bottom>
                        <p>
                            {{ translations["monetization_Reason_Matches"] }}
                        </p>
                    </template>
                </UpgradeToProButton>
            </div>

            <div v-if="history.isOpen">
                <div v-if="history.hasMore && this.isProPlayer" class="matches-showmore">
                    <div>
                        <BaseButton @click.native="loadFinishedMatches(history.matches.length)" :disabled="history.isLoading || history.loadMore" type="tertiary" size="s">
                            <BaseSpinner v-if="history.loadMore" size="s" />
                            {{translations["shared_ShowMore"]}}
                        </BaseButton>
                    </div>
                </div>
            </div>
        </div>

        <MatchesPlaceholder v-if="isPlaceholderShown && initialContentLoaded">

        </MatchesPlaceholder>

        <BaseModalWrapper size="l" :show="showModal" @close="showModal = false">
            <template #body>
                <ModalProPlayers />
            </template>
        </BaseModalWrapper>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    import spaEventBus from '@/app/eventBus';
    import rinbootbox from '@/app/rin-bootbox';

    import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue'
    import UpgradeToProButton from '@/components/rin_new_design/components/monetization/buttons/UpgradeToProButton.vue'

    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner.vue'
    import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue'

    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import ModalProPlayers from '@/components/rin_new_design/components/monetization/modals/ModalProPlayers';

    import MatchesCard from '@/components/rin_new_design/components/profile/MatchCard';
    import InvitationCard from '@/components/rin_new_design/components/profile/InvitationCard';
    import MatchesPlaceholder from '@/components/rin_new_design/components/profile/MatchesPlaceholder';

    import AdBox1 from '@/components/rin_new_design/components/GoogleAds/mobile/Box1.vue';

    export default {
        name: 'profileMatches',
        components: {
            BaseButton,
            BaseSpinner,
            BaseAlert,
            BaseModalWrapper,
            ModalProPlayers,
            UpgradeToProButton,
            InvitationCard,
            MatchesCard,
            MatchesPlaceholder,
            AdBox1
        },
        props: {
            forceOwnProfileData: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                isEditingResult: false,
                isMatchProcessing: false,
                skip: 0,
                showModal: false,

                isLoadingMoreUpcoming: false,

                upcoming: {
                    matches: [],
                    invitations: [],
                    isOpen: false,
                    hasMore: false,
                    matchesLoading: false,
                    invitationsLoading: false,
                    invitationsInitialContentLoaded: false,
                    matchesInitialContentLoaded: false,
                },

                history: {
                    matches: [],
                    isOpen: false,
                    isLoading: false,
                    hasMore: false,
                    loadMore: false,
                    initialContentLoaded: false,
                }

            }
        },

        mounted() {
            if (this.isOwnProfile)
                this.clearNotifications();

            spaEventBus.$on('reloadMatches', () => {
                this.loadUpcomingMatches(0, true);
                this.loadFinishedMatches(0, true);
            });

            spaEventBus.$on('rin-profile-matches-reload-all-matches', () => {
                this.loadUpcomingMatches(0, true);
                this.loadFinishedMatches(0, true);
            });

            spaEventBus.$on('rin-profile-matches-confirm-result', (payload) => {
                this.confirmResult(payload.challengeId);
            });

            this.upcoming.isOpen = true;
            this.history.isOpen = true;

            this.loadInvitations();
            this.loadUpcomingMatches(0, true);
            this.loadFinishedMatches(0, true);
        },
        computed: {
            ...mapGetters('ui', ['lastOpenedBodyModal']),
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
            ...mapGetters('monetization', ['isProPlayer', 'isTournamentKillSwitchActive']),
            isLoading() {
                return this.upcoming.invitationsLoading || this.upcoming.matchesLoading || this.history.isLoading;
            },
            isOwnProfile() {
                return (this.layout.player.currentPlayerId === this.$store.state.playerProfile.playerIdForCurrentProfile) || this.forceOwnProfileData;
            },
            playerId() {
                if (this.isOwnProfile) {
                    return this.layout.player.currentPlayerId;
                }

                return this.$store.state.playerProfile.playerIdForCurrentProfile;
            },
            isPlaceholderShown() {
                let isProPlayer = this.layout.player.IsProPlayer;
                return isProPlayer &&
                    this.history.matches.length === 0 &&
                    this.upcoming.matches.length === 0 &&
                    this.upcoming.invitations.length === 0;
            },
            initialContentLoaded() {
                return this.history.initialContentLoaded &&
                    this.upcoming.matchesInitialContentLoaded &&
                    this.upcoming.invitationsInitialContentLoaded;
            }
        },
        methods: {

            openModal() {
                if (!this.isProPlayer)
                    this.showModal = true;
            },
            loadFinishedMatches(skip, isRefresh = false) {
                if (this.history.isOpen) {

                    if (this.history.isLoading || this.history.loadMore) return;

                    if (skip == 0) this.history.isLoading = true;
                    if (skip > 0) this.history.loadMore = true;

                    this.$http.get(`${this.nomenclatures.ApiDomain}/player/GetPlayerMatchesAsync?playerid=${this.playerId}&takehistory=true&skip=${skip}&take=10&language=${this.layout.language}`)
                        .then((res) => {
                            if (isRefresh)
                                this.history.matches = res.data.Payload;
                            else
                                this.history.matches = this.history.matches.concat(res.data.Payload);

                            this.history.hasMore = res.data.HasMore;
                            this.history.isLoading = false;
                            this.history.loadMore = false;
                            this.history.initialContentLoaded = true;
                            this.isEditingResult = false;
                        })
                        .catch(err => {
                            this.history.isLoading = false;
                            this.history.loadMore = false;
                            this.isEditingResult = false;
                            this.history.initialContentLoaded = true;
                            console.error(err);
                        });
                }
            },
            loadUpcomingMatches(skip, isRefresh = false) {
                if (this.upcoming.isOpen) {
                    if (this.upcoming.matchesLoading || this.isLoadingMoreUpcoming) return;

                    if (skip == 0) this.upcoming.matchesLoading = true;
                    if (skip > 0) this.isLoadingMoreUpcoming = true;

                    this.$http.get(`${this.nomenclatures.ApiDomain}/player/GetPlayerMatchesAsync?playerid=${this.playerId}&takehistory=false&skip=${skip}&take=10&language=${this.layout.language}`)
                        .then((res) => {

                            if (isRefresh)
                                this.upcoming.matches = res.data.Payload;
                            else
                                this.upcoming.matches = this.upcoming.matches.concat(res.data.Payload);

                            this.upcoming.hasMore = res.data.HasMore;
                            this.upcoming.matchesLoading = false;
                            this.upcoming.matchesInitialContentLoaded = true;
                            this.upcoming.invitationsInitialContentLoaded = true;

                            this.isLoadingMoreUpcoming = false;
                            this.isEditingResult = false;
                        })
                        .catch(err => {
                            this.upcoming.matchesLoading = false;
                            this.isLoadingMoreUpcoming = false;
                            this.isEditingResult = false;
                            this.upcoming.matchesInitialContentLoaded = true;
                            this.upcoming.invitationsInitialContentLoaded = true;
                            console.error(err);
                        })
                }

            },
            reloadInvitations() {
                this.upcoming.invitations = [];
                this.loadInvitations();
            },
            loadInvitations() {
                this.upcoming.invitationsLoading = true;
                this.$http.get(`${this.nomenclatures.ApiDomain}/player/GetEventInvitationsAsync?playerid=${this.playerId}&language=${this.layout.language}`)
                    .then((res) => {
                        this.upcoming.invitations = this.upcoming.invitations.concat(res.data);
                        this.upcoming.invitationsLoading = false;
                    })
            },
            confirmResult(challengeId) {
                this.isEditingResult = true;

                this.$http.post(`/challenges/confirmresult/${challengeId}`)
                    .then(() => {
                        this.loadUpcomingMatches(0, true);
                        this.loadFinishedMatches(0, true);
                    })
                    .catch(() => {
                        rinbootbox.alert(
                            this.translations["error_Text"],
                            this.translations["error_ProblemWithRequest"]);
                    });
            },
            clearNotifications() {
                this.$store.dispatch('updatenotificationsCount', 0);
            },

        }
    }
</script>