<template>
    <div class="modal fade" id="createEventModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h5 class="modal-title">
                        <span>{{ creatingEventName }}</span>
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="icon icon-plus"></span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row aic">
                        <div class="col col-12 col-md">
                            <h4>
                                <span v-if="showSportChange" v-bind:class="[sportIcon]" class="icon"></span>
                                {{ translations['shared_WouldYouCreate'] }}
                                <span v-if="showSportChange">{{ currentSportName }}</span>
                                <span>{{ creatingEventName }}</span>?
                            </h4>
                        </div>
                        <div class="buttons col-auto">
                            <a href="#" class="btn btn-sm btn-close" data-dismiss="modal" aria-label="Close">
                                {{translations['invitePlayerSMPartial_btn_Close']}}
                            </a>
                            <a v-if="eventType != 'tournament' && eventType != 'teamleague'" :href="createEventUrl" v-on:click="closeModal" class="btn btn-sm btn-info createEventBtn">
                                {{ translations['topBar_2'] }}
                            </a>
                            <router-link v-else :to="createEventUrl" @click.native="closeModal" class="btn btn-sm btn-info createEventBtn">
                                {{ translations['topBar_2'] }}
                            </router-link>
                        </div>
                    </div>
                    <div class="row jcb">
                        <div class="change col-auto">
                            <template v-if="showSportChange">
                                <p class="d-inline">{{ translations['change_Sport'] }}:</p>
                                <a class="select"
                                   v-bind:class="{ active: isSportOpen }"
                                   v-on:click="toggleSport"
                                   v-click-outside="closeSport"
                                   href="#">
                                    <i v-bind:class="[sportIcon]" class="flag-icon"></i>
                                    <span>{{ currentSportName }}</span>
                                    <span class="icon icon-v"></span>
                                </a>
                                <ul v-bind:class="{ open: isSportOpen }" class="create-event-ul">
                                    <li v-for="sport in sortedSports"
                                        v-bind:class="{ active: layout.player.sport == sport.id }"
                                        v-bind:data-sport-value="sport.id">
                                        <a v-bind:class="{ change_sport: layout.player.sport != sport.id }"
                                           href="#"
                                           v-on:click="setSport(sport.id)">{{ sport.name }}</a>
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <div class="col-auto createTournamentInfoText createInfoText" v-if="eventType == 'tournament'">
                            <a href="https://rankedin.ladesk.com/507420-2-Tournaments">How does it work?</a>
                        </div>
                        <div class="col-auto createTeamLeagueInfoText createInfoText" v-if="eventType == 'teamleague'">
                            <a href="https://rankedin.ladesk.com/640247-6-Team-League">How does it work?</a>
                        </div>
                        <div class="col-auto createClubLeagueInfoText createInfoText" v-if="eventType == 'clubleague'">
                            <a href="https://rankedin.ladesk.com/774315-5-Club-League">How does it work?</a>
                        </div>
                        <div class="col-auto createOrganisationInfoText createInfoText"
                             v-if="eventType == 'organisation'">
                            <a href="https://rankedin.ladesk.com/141367-7-Club--Organization--Federation--Friends-Group">How does it work?</a>
                        </div>
                        <div class="col-auto createRankingInfoText createInfoText" v-if="eventType == 'ranking'">
                            <a href="https://rankedin.ladesk.com/443991-4-Ranking">How does it work?</a>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div class="modal-footer"></div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import spaEventBus from '@/app/eventBus';
    import vClickOutside from 'v-click-outside';

    export default {
        name: 'layout-create-event-modals',
        directives: {
            clickOutside: vClickOutside.directive,
        },
        components: {},
        data: function () {
            return {
                eventType: '',
                sportId: 0,
                sportIcon: '',
                currentSportName: '',
                rankingId: 0,
                isSportOpen: false,
            };
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            creatingEventName() {
                switch (this.eventType) {
                    case 'tournament':
                        return this.translations['shared_EventType_Tournament'];
                    case 'clubleague':
                        return this.translations['shared_EventType_ClubLeague'];
                    case 'teamleague':
                        return this.translations['shared_EventType_TeamLeague'];
                    case 'ranking':
                        return this.translations['topBar_31'];
                    case 'organisation':
                        return this.translations['topBar_11'];
                    case 'privatematch':
                        return 'Add result';
                }
                return '';
            },
            sortedSports() {
                var sportsArr = Object.keys(this.nomenclatures.Sports).map((key) => {
                    return this.nomenclatures.Sports[key];
                });
                let sports = [];
                sportsArr.forEach((item, index) => {
                    let sport = {
                        id: Number(index) + 1,
                        name: item.description,
                    };
                    sports.push(sport);
                });
                const sortedSports = sports.sort((a, b) => a.name.localeCompare(b.name));
                return sortedSports;
            },
            createEventUrl() {
                switch (this.eventType) {
                    case 'organisation':
                        return `/${this.layout.language}/organisation/createorganisation?sport=${this.sportId}`;
                    case 'ranking':
                        return `/${this.layout.language}/ranking/createranking?sport=${this.sportId}`;
                    case 'teamleague':
                    case 'clubleague':
                        return `/${this.layout.language}/${this.eventType}/create?sport=${this.sportId}`;
                    case 'tournament':
                        return `/${this.layout.language}/${this.eventType}/create?sport=${this.sportId}&rankingId=${this.rankingId}`;
                    case 'privatematch':
                        return `/${this.layout.language}/privatematch/create`;
                    default:
                        return '';
                }
            },
            showSportChange() {
                switch (this.eventType) {
                    case 'organisation':
                        return false;
                    case 'ranking':
                    case 'teamleague':
                    case 'clubleague':
                    case 'tournament':
                    case 'privatematch':
                        return true;
                }
            },
        },
        mounted() {
            this.sportId = this.layout.player.sport;
            //this.sportIcon = this.nomenclatures.SportIconClass[this.layout.player.sport];
            this.setSport(this.layout.player.sport);
            this.currentSportName = this.getSportName();

            spaEventBus.$on('createNewEvent', (payload) => {
                this.eventType = payload.eventType;
                this.rankingId = payload.rankingId;
                this.closeSport();
            });

            if (typeof bootstrapVersion == 'undefined') {
                //not spa page
                this.bindRazorJQueryHandlers();
            }
        },
        methods: {
            closeModal() {
                $('#createEventModal').modal('hide');
            },
            closeSport(event) {
                this.isSportOpen = false;
            },
            bindRazorJQueryHandlers: function () {
                //not spa page if this method executed
                let _this = this;
                $('#createEventModal').off('show.bs.modal');
                $('#createEventModal').on('show.bs.modal', function (event) {
                    var button = $(event.relatedTarget),
                        eventType = button.data('event-type'),
                        firstOrganisationRankingId = $('.ranking-id').data('ranking-id'),
                        modal = $(this);

                    _this.eventType = eventType;
                    _this.rankingId = firstOrganisationRankingId;
                    _this.setSport(Number(rankedin.sport));
                });
            },
            toggleSport: function () {
                this.isSportOpen = !this.isSportOpen;
            },
            setSport: function (index) {
                this.sportId = Number(index);
                this.sportIcon = this.nomenclatures.SportIconClass[this.sportId - 1];
                this.currentSportName = this.getSportName();
                this.closeSport();
            },
            getSportName: function () {
                switch (this.sportId) {
                    case 1:
                        return this.translations['shared_SportType_Squash'];
                    case 2:
                        return this.translations['shared_SportType_Badminton'];
                    case 3:
                        return this.translations['shared_SportType_TableTennis'];
                    case 4:
                        return this.translations['shared_SportType_Tennis'];
                    case 5:
                        return this.translations['shared_SportType_Padel'];
                    case 6:
                        return this.translations['shared_SportType_Racquetball'];
                    case 7:
                        return this.translations['shared_SportType_SoccerSquash'];
                    case 8:
                        return this.translations['shared_SportType_PaletaFrontón'];
                    case 9:
                        return this.translations['shared_SportType_TeqBall'];
                    case 10:
                        return this.translations['shared_SportType_Crossminton'];
                    case 11:
                        return this.translations['shared_SportType_Pickleball'];
                    case 12:
                        return this.translations['shared_SportType_Roundnet'];
                    case 13:
                        return this.translations['shared_SportType_Bowls'];
                    case 14:
                        return this.translations['shared_SportType_FootballTennis'];
                }

                return '';
            },
        },
    };
</script>

<style scoped>

    .create-event-ul {
        min-width: 6rem;
    }
</style>