import vue from 'vue';
import vuex from 'vuex';

vue.use(vuex);

// import createPersistedState from "vuex-persistedstate";

import drawCreatorModule from './modules/draw-creator-module';
import translationsModule from './modules/translations-module';
import nomenclaturesModule from './modules/nomenclatures-module';
import autoAddModule from './modules/auto-add-module';
import courtManagerModule from './modules/court-manager-module';
import tournamentStartModule from './modules/tournamentstart-module';
import wizardModule from './modules/wizard-module';
import tournamentSeedsModule from './modules/tournament-seeds-module';
import teamsMatchesModule from './modules/teams-matches-module';
import teamManagerModule from './modules/team-manager-module';
import teamHomepageModule from './modules/team-homepage-module';
import tournamentClassesModule from './modules/tournament-classes-module';
import tournamentPublishModule from './modules/tournament-publish-module';
import playerSettingsModule from './modules/player-settings-module';
import roundrobinSettingsModule from './modules/roundrobin-settings';
import eventIndexModule from './modules/event-index-module';
import clubLeagueIndexModule from './modules/club-league-index-module';
import teamLeagueIndexModule from './modules/teamleague-index-module';
import chatModule from './modules/chat-module';
import layoutModule from './modules/layout-module';
import organizationModule from './modules/organization-module';
import paymentModule from './modules/payment-module';
import playerProfileModule from './modules/player-profile-module';
import createAmericanoModule from './modules/create-americano-module';
import embedModalModule from './modules/embed-modal-module';
import compareSkillsModule from './modules/compare-skills-module';
import signalrNotificationsModule from './modules/signalr-notifications-module';
import searchHistoryModule from './modules/searchhistory-module';
import searchModule from './modules/search-module';
import topnavModule from './modules/topnav-module';
import uiModule from './modules/ui-module';
import breadcrumbsModule from './modules/breadcrumbs-module';
import monetizationModule from './modules/monetization-module';
import calendarModule from './modules/calendar-module';
import rinBootboxModule from './modules/rinbootbox-module';
import registerModule from './modules/register-module';
import messengerModule from './modules/messenger-module';

import teamChampionshipRRSettingsModule from './modules/teamchampionship-roundrobin-settings-module';



import VuexPersistence from 'vuex-persist';

var vuexLocal = null;
try {
	if (typeof window.localStorage !== 'undefined') {
		vuexLocal = new VuexPersistence({
			storage: window.localStorage,
			modules: ['searchHistory', 'calendar'],
		});
	}
} catch (ex) {
	console.error(ex);
}

export const store = new vuex.Store({
	modules: {
		nomenclatures: nomenclaturesModule,
		translations: translationsModule,
		autoAdd: autoAddModule,
		courtManager: courtManagerModule,
		drawCreator: drawCreatorModule,
		tournamentStart: tournamentStartModule,
		wizard: wizardModule,
		tournamentSeeds: tournamentSeedsModule,
		teamsMatches: teamsMatchesModule,
		teamManager: teamManagerModule,
		teamHomepage: teamHomepageModule,
		tournamentClasses: tournamentClassesModule,
		tournamentPublish: tournamentPublishModule,
		roundRobinSettings: roundrobinSettingsModule,
		settings: playerSettingsModule,
		chat: chatModule,
		layout: layoutModule,
		eventIndex: eventIndexModule,
		clubLeagueIndex: clubLeagueIndexModule,
		teamLeagueIndex: teamLeagueIndexModule,
		organization: organizationModule,
		payment: paymentModule,
		playerProfile: playerProfileModule,
		createAmericano: createAmericanoModule,
		embedModal: embedModalModule,
		compareSkills: compareSkillsModule,
		signalRNotifications: signalrNotificationsModule,
		searchHistory: searchHistoryModule,
		search: searchModule,
		ui: uiModule,
		topnav: topnavModule,
		breadcrumbs: breadcrumbsModule,
		monetization: monetizationModule,
		calendar: calendarModule,
		rinBootbox: rinBootboxModule,
		register: registerModule,
		teamChampionshipRRSettings: teamChampionshipRRSettingsModule,
		messenger: messengerModule
	},
	plugins: vuexLocal != null ? [vuexLocal.plugin] : [],
});
