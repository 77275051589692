import { render, staticRenderFns } from "./top-bar-partial-bs3.vue?vue&type=template&id=352ca66f&scoped=true&"
import script from "./top-bar-partial-bs3.vue?vue&type=script&lang=js&"
export * from "./top-bar-partial-bs3.vue?vue&type=script&lang=js&"
import style0 from "./top-bar-partial-bs3.vue?vue&type=style&index=0&lang=css&"
import style1 from "./top-bar-partial-bs3.vue?vue&type=style&index=1&id=352ca66f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "352ca66f",
  null
  
)

export default component.exports