<template>
        <span :class="`icon icon-star star-indicator star-indicator-${size}`"></span>
</template> 
<script>
    export default {
        name: 'StarIndicator',
        props: {
            size: {
                type: String,
                validator: (v) => ['s', 'm', 'l', 'xl'].indexOf(v) > -1,
                default: 'm',
            }
        },
    }
</script>