<template>
    <div>
        <BaseModalWrapper size="l" :show="showAd" @close="showAd = false">
            <template #body>
                <div class="d-flex flex-row align-items-center pro-campaign-container">
                    <div>
                        <div class="rin-pro-player-modal-image-container">
                            <img src="https://cdn.rankedin.com/images/static/campaign/players-pro-campaign.png"
                                 alt="image player" />
                        </div>
                    </div>
                    <div class="d-flex flex-column campaign-text">
                        <div class="rin-campaign-title">{{translations['campaign_Pro_Header1']}}</div>
                        <div class="rin-campaign-subtitle">{{translations['campaign_Pro_Header2']}} <BaseTypeLabel type="pro" /></div>
                        <span class="not-so-bold p-3">  {{translations['campaign_Pro_HeaderSub']}}</span>

                        <BaseCard type="primary" size="m" :shadow="false" class="pt-2">
                            <template #body>
                                <div class="row">
                                    <div class="col-6 d-flex align-items-start flex-column text-left">

                                        <div class="p-2">
                                            <BaseIcon name="check"></BaseIcon>
                                            {{translations['campaign_Pro_Feature2']}}
                                        </div>
										<div class="p-2">
                                            <BaseIcon name="check"></BaseIcon>
                                            {{translations['campaign_Pro_Feature3']}}
                                        </div>
                                    </div>

                                    <div class="col-6 d-flex align-items-start flex-column text-left">
                                        <div class="p-2">
                                            <BaseIcon name="check"></BaseIcon>
                                            {{translations['campaign_Pro_Feature4']}}
                                        </div>
										<div class="p-2">
                                            <BaseIcon name="check"></BaseIcon>
                                            {{translations['campaign_Pro_Feature1']}}
                                        </div>
                                    </div>


                                    <div class="pt-2 col rin-campaign-feature-others">  {{translations['campaign_Pro_FeatureOthers']}}</div>
                                </div>
                            </template>
                        </BaseCard>

                        <span class="text-pro p-3">{{translations['campaign_Pro_StartPriceEUR']}}</span>


                        <span class="p-2 rin-campaign-footer"> {{translations['campaign_Pro_FooterText']}}</span>

                        <div class="p-3 d-flex justify-content-center">
                            <BaseButton type="primary" size="l" @click.native="seeMore">
                                {{translations['campaign_Pro_ButtonText']}}
                            </BaseButton>
                        </div>
                    </div>

                </div>
            </template>
        </BaseModalWrapper>


        <BaseModalWrapper size="l" :show="showUpgradeModal" @close="showUpgradeModal = false">
            <template #body>
                <ModalProPlayers />
            </template>
        </BaseModalWrapper>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import ModalProPlayers from '@/components/rin_new_design/components/monetization/modals/ModalProPlayers';

    const proPlayerSettings = {
        name: 'ProPlayersCampaign',
        async checkAudienceAsync(params) {
            if (!params.store.getters.layout.isEmbed && !params.store.getters.layout.player.IsProPlayer) return Promise.resolve(true);

            return Promise.resolve(false);
        }
    };
    export { proPlayerSettings };

    export default {
        name: 'ProPlayersCampaign',
        props: {},
        data() {
            return {
                showAd: true,
                showUpgradeModal: false
            }
        },
        components: {            
            BaseButton,
            BaseIcon,
            BaseTypeLabel,
            BaseCard,
            BaseModalWrapper,
            ModalProPlayers
        },
        mounted() {

        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
           
        },
        methods: {
            seeMore() {
                this.showAd = false;

                if (!this.$authClient.isLoggedIn() && this.$route.name !== 'pricing') {
                    this.$router.push({ name: 'pricing', params: { language: this.layout.language }, hash:'#boxPlayers' }).catch(() => { });
                }

                if (this.$authClient.isLoggedIn()) {                
                    this.showUpgradeModal = true;
                }     
            },

        }

    }
</script>