<template>
	<div class="rin-confirm-create-event-container">
		<div class="rin-confirm-create-title">
			<BaseIcon :name="currentSport.name.toLowerCase()" />
			<p>
				{{ translations['shared_WouldYouCreate'] }}
				{{ currentSport.description }}
				{{ eventTypeName }}
			</p>
		</div>
		<div class="rin-confirm-create-body">
			<div class="rin-confirm-create-sport-selection">
				<label for="sport">{{ translations['change_Sport'] }}:</label>
				<BaseDropdown buttonSize="m" buttonType="tertiary" class="sport-dropdown">
					<template #button>
						<span>{{ currentSport.description }}</span>
					</template>
					<template #content>
						<li v-for="sport in sortedSports" :key="sport.id" @click="setSport(sport.id)">
							{{ sport.description }}
						</li>
					</template>
				</BaseDropdown>
			</div>
			<a href="https://rankedin.ladesk.com/507420-2-Tournaments">How does it work?</a>
		</div>
		<div class="rin-confirm-create-footer">
			<BaseButton type="success" size="m">
				<router-link :to="createEventUrl"> {{ translations['topBar_2'] }}</router-link>
			</BaseButton>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
import BaseDropdown from '@/components/rin_new_design/components/base/BaseDropdown';
import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';

export default {
	name: 'ConfirmCreateEvent',
	components: {
		BaseButton,
		BaseDropdown,
		BaseIcon,
	},
	props: {
		type: {
			type: String,
			validator: (v) => ['tournament', 'clubleague', 'teamleague'].indexOf(v) > -1,
			required: true,
		},
	},
	data() {
		return {
			eventType: this.type,
			currentSport: {},
		};
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
		eventTypeName() {
			switch (this.type) {
				case 'tournament':
					return this.translations['shared_EventType_Tournament'];
				case 'clubleague':
					return this.translations['shared_EventType_ClubLeague'];
				case 'teamleague':
					return this.translations['shared_EventType_TeamLeague'];
			}
			return '';
		},
		sortedSports() {
			return Object.entries(this.nomenclatures.Sports)
				.sort((a, b) => a[1].description.localeCompare(b[1].description))
				.map((v) => {
					return { id: v[0], name: v[1].name, description: v[1].description };
				});
		},
		createEventUrl() {
			return `/${this.layout.language}/${this.type}/create?sport=${this.currentSport.id}&rankingId=${this.layout.player.rankingId}`;
		},
	},
	methods: {
		setSport(id) {
			this.currentSport = this.sortedSports.find((v) => id == v.id);
		},
	},
	beforeMount() {
		this.currentSport = this.sortedSports.find((v) => this.layout.player.sport == v.id);
	},
};
</script>

<style scoped>
.sport-dropdown {
	min-width: 10rem;
}
</style>

<style>
.sport-dropdown button {
	width: 100%;
}
</style>