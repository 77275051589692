<template>
	<div class="rin-modal-create-container">
		<div class="rin-modal-header">{{ translations['topBar_2'] }}</div>
		<hr />
		<div class="rin-modal-row first-row">
			<div class="rin-modal-create-entry">
				<BaseCard type="primary" :shadow="true" @click.native="createPrivateMatch()">
					<template #header>
						<div class="rin-modal-create-card-header">
							<span class="icon-plus-private-match"></span>
							<br />
							{{ translations['createEvent_AddResult'] }}
						</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_ImproveRating'] }}
						</div>
					</template>
				</BaseCard>
			</div>
		</div>
		<hr />
		<div class="rin-modal-row">
			<div class="rin-modal-create-entry">
				<BaseCard type="primary" :shadow="true" @click.native="createTournament()">
					<template #title>
						<BaseTypeLabel type="tournament" />
					</template>
					<template #header>
						<div class="rin-modal-create-card-header">{{ translations['topBar_8'] }}</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_TournamentDescription'] }}
						</div>
					</template>
				</BaseCard>

				<a href="https://www.youtube.com/watch?v=z_KwoD8vPAs" class="rin-modal-create-outbound-link"
					>watch video</a
				>
			</div>
			<div class="rin-modal-create-entry">
				<BaseCard
					type="primary"
					:shadow="true"
					@click.native="createGenericEvent('clubleague')"
					data-toggle="modal"
					data-target="#createEventModal"
				>
					<template #title>
						<BaseTypeLabel type="clubleague" />
					</template>
					<template #header>
						<div class="rin-modal-create-card-header">{{ translations['createEvent_LeagueName'] }}</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_ClubLeagueDescription'] }}
						</div>
					</template>
				</BaseCard>

				<a href="https://www.youtube.com/watch?v=-9XrgxW7jjk" class="rin-modal-create-outbound-link"
					>watch video</a
				>
			</div>
			<div class="rin-modal-create-entry">
				<BaseCard
					type="primary"
					:shadow="true"
					@click.native="createGenericEvent('teamleague')"
					data-toggle="modal"
					data-target="#createEventModal"
				>
					<template #title>
						<BaseTypeLabel type="teamleague" />
					</template>
					<template #header>
						<div class="rin-modal-create-card-header">{{ translations['shared_T29'] }}</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_TeamLeagueDescription'] }}
						</div>
					</template>
				</BaseCard>

				<a href="https://www.youtube.com/watch?v=kvDNDCwOuQc" class="rin-modal-create-outbound-link"
					>watch video</a
				>
			</div>

			<div class="rin-modal-create-entry">
				<BaseCard
					type="primary"
					:shadow="true"
					@click.native="createGenericEvent('ranking')"
					data-toggle="modal"
					data-target="#createEventModal"
				>
					<template #title>
						<BaseTypeLabel type="ranking" />
					</template>
					<template #header>
						<div class="rin-modal-create-card-header">{{ translations['topBar_31'] }}</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_RankingDescription'] }}
						</div>
					</template>
				</BaseCard>

				<a href="https://www.youtube.com/watch?v=HxWkiEriTf4" class="rin-modal-create-outbound-link"
					>watch video</a
				>
			</div>
			<div class="rin-modal-create-entry">
				<BaseCard
					type="primary"
					:shadow="true"
					@click.native="createGenericEvent('organisation')"
					data-toggle="modal"
					data-target="#createEventModal"
				>
					<template #title>
						<BaseTypeLabel type="organisation" />
					</template>
					<template #header>
						<div class="rin-modal-create-card-header">{{ translations['topBar_11'] }}</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_OrganizationDescription'] }}
						</div>
					</template>
				</BaseCard>

				<a href="https://www.youtube.com/watch?v=W3hzC_09l4Y" class="rin-modal-create-outbound-link"
					>watch video</a
				>
			</div>
			<div class="rin-modal-create-entry">
				<BaseCard type="primary" :shadow="true" @click.native="createAmericano()">
					<template #title>
						<BaseTypeLabel type="americano" />
					</template>
					<template #header>
						<div class="rin-modal-create-card-header">
							{{ translations['layoutHeader_Americano_Create'] }}
						</div>
					</template>
					<template #body>
						<div class="rin-modal-create-card-body">
							{{ translations['createEvent_AmericanoDescription'] }}
						</div>
					</template>
				</BaseCard>
			</div>
		</div>
		<hr />
		<div class="rin-modal-footer">
			<router-link :to="{ name: 'request-demo' }">Request a free demo</router-link>
			<a href="https://rankedin.ladesk.com/">
				{{ translations['shared_Menu_UserManual'] }}
			</a>
		</div>

		<portal to="main-base-modal" v-if="showModal">
			<BaseModalWrapper size="l" :show="showModal" @close="showModal = false">
				<template #body>
					<ConfirmCreateEvent type="tournament" />
					<PremiumTournament v-if="!isTournamentKillSwitchActive"/>
				</template>
			</BaseModalWrapper>
		</portal>
	</div>
</template>

<script>
import spaEventBus from '@/app/eventBus';

import { mapGetters, mapMutations } from 'vuex';

import BaseCard from '@/components/rin_new_design/components/base/BaseCard';
import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';

import PremiumTournament from '@/components/rin_new_design/components/monetization/modals/PremiumTournament';
import ConfirmCreateEvent from '@/components/rin_new_design/components/common/ConfirmCreateEvent';

export default {
	name: 'ModalView',
	components: {
		BaseCard,
		BaseModalWrapper,
		BaseTypeLabel,
		PremiumTournament,
		ConfirmCreateEvent
	},
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
		...mapGetters('ui', ['lastOpenedBodyModal']),
		...mapGetters('monetization', ['isTournamentKillSwitchActive']),
	},
	methods: {
		...mapMutations('ui', ['removeLastBodyModal']),
		createTournament() {
			this.showModal = true;
		},
		createPrivateMatch() {
			this.$router.push({ name: 'private-match-create' });
		},
		createGenericEvent(eventType) {
			spaEventBus.$emit('createNewEvent', { eventType: eventType });
		},
		createAmericano() {
			this.$store.dispatch('clearAmericanoState');
			this.$router.push({ name: 'americano', params: { language: this.layout.language } }).catch((err) => {
				console.error(`Couldn't navigate to americano`, err);
			});
			this.removeLastBodyModal();
		},
		redirectToAddResult() {
			this.$router.push({ name: 'private-match-create' });
		},
	},
};
</script>
