<template>
    <div class="hide-ads-container">
        <button class="hide-ads-button" @click="toggleModal"> {{ translations['shared_Ads_HideAds'] }}</button>
        <portal to="main-base-modal" v-if="showProPurchaseModal">
            <BaseModalWrapper size="l" :show="showProPurchaseModal" @close="showProPurchaseModal = false">
                <template #body>
                    <ModalProPlayers />
                </template>
            </BaseModalWrapper>
        </portal>
        <portal to="main-base-modal" v-if="showTrialModal">
            <BaseModalWrapper size="m" :show="showTrialModal" @close="closeTrialModal" class="rin-modal-no-padding">
                <template #body>
                    <TrialModal @trialStarted="trialStarted" @close="closeTrialModal" />
                </template>
            </BaseModalWrapper>
        </portal>
    </div>

</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import ModalProPlayers from '@/components/rin_new_design/components/monetization/modals/ModalProPlayers';
    import TrialModal from '@/components/rin_new_design/components/monetization/modals/TrialModal';

    export default {
        name: 'HideAdsButton',
        components: {
            BaseModalWrapper,
            ModalProPlayers,
            TrialModal
        },
        data() {
            return {
                showProPurchaseModal: false,
                showTrialModal: false,
                isTrialStarted: false,
            };
        },
        computed: {
            ...mapGetters('monetization', ['isProPlayer']),
            ...mapGetters(['layout', 'translations'])
        },
        methods: {
            toggleModal() {
                let hasUsedFreeTrial = this.layout.player.hasUsedFreeTrial;

                if (!this.layout.player.isAuthenticated || hasUsedFreeTrial) {
                    this.showProPurchaseModal = true;
                } else {
                    this.showTrialModal = true;
                }
            },
            trialStarted() {
                this.isTrialStarted = true;
            },
            closeTrialModal() {
                if (this.isTrialStarted) {
                    window.location.reload();
                }
                else {
                    this.showTrialModal = false;
                }
            }
        }
    };
</script>

<style scoped>
    .hide-ads-container{
        width: 100%
    }
    .hide-ads-button {
        position: absolute;
        font-size: 10px;
        background: #FBA500;
        color: white;
        font-weight: bold;
        border-top-right-radius: 5px;
    }
</style>
