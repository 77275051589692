export default {
    state: {        
        players: [],
        teams: [],
        isLoading: true,
        tournamentClassId: 0,
        participantType: 1 //1 - player, 2 - team, 4 - double

    },
    mutations: {
        updateParticipantType(state, payload) {
            state.participantType = payload;
        },
        updatePlayers(state, payload) {
            state.players = payload;
        },
        updateIsLoading(state, payload) {
            state.isLoading = payload;
        },
        updateTournamentClassId(state, payload) {
            state.tournamentClassId = payload;
        },
        updateTeamSeeds(state, payload) {
            state.teams = payload;
        },

    },
    actions: {
        updateParticipantType: ({ commit }, payload) => {
            commit("updateParticipantType", payload);
        },
        updatePlayers: ({ commit }, payload) => {
            commit("updatePlayers", payload);
        },
        updateTeamSeeds: ({ commit }, payload) => {
            commit("updateTeamSeeds", payload);
        },
        updateIsLoading: ({ commit }, payload) => {
            commit("updateIsLoading", payload);
        },
        updateTournamentClassId: ({ commit }, payload) => {
            commit("updateTournamentClassId", payload);
        }
    },
    getters: {
        tournamentClassId: state => {
            return state.tournamentClassId;
        },
    }
}