<template>
    <div id="spinner-modal" class="modal" data-backdrop="static">
        <div class="modal-dialog">
            <div class="modal-content background-muted">
                <div class="modal-body" style="text-align: center;">
                    <div class="text-center"><div role="status" class="spinner-border"><span class="sr-only">{{translations["loading"]}}...</span></div></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import spaEventBus from "@/app/eventBus";

    export default {
        name: 'spinner-modal-bs4',
        computed: {
            ...mapGetters(['translations']),
        },
        mounted() {
            spaEventBus.$off('spinnerShow');
            spaEventBus.$on('spinnerShow', () => {
                $('#spinner-modal').modal('show');
            });

            spaEventBus.$off('spinnerHide');
            spaEventBus.$on('spinnerHide', () => {                
                    $("#spinner-modal").modal("hide");                
            });
        }
    }
</script>

<style scoped>
    .spinner-modal {
        max-width: 600px;
    }
</style>