export default {
    state: {
        step: 0,
        steps: {
            review: 1,
            checkout: 2
        },
        request: {
            tournamentId: 0,
            tournamentClassId: 0,
            teamleagueId: 0,
            participantToJoinId: 0,
            eventName: '',
            teamleagueProductId : 0
        },
        review: {
            OrderID: '',
            Description: '',
            Products: []
        },
        checkout: {
            ExternalId: '',
            ClientExternalId: '',
            PublishableKey: ''
        }
    },
    mutations: {

        updateCheckoutStep(state, payload) {
            state.step = payload;
        },

        updateReviewModel(state, payload) {
            state.review = payload;
        },

        updateCheckoutModel(state, payload) {
            state.checkout = payload;
        },

        updateRequestModel(state, payload) {
            state.request = payload;
        }
    },
    actions: {

    },
    getters: {
        payment: state => state,
        isDoubledQuantityEntryFee: state => state.review.Products.length > 0 && state.review.Products[0].Quantity > 1,
        isReviewVisible: state => state.step >= state.steps.review,
        isCheckoutVisible: state => state.step === state.steps.checkout,

    }
}