export default {
    state: {
        chatLog: {},
    },
    mutations: {
        updateChatLog(state, payload) {
            state.chatLog = payload;
        },
    
    },
    actions: {
        updateChatLog: ({ commit }, payload) => {
            commit("updateChatLog", payload);
        },
    
    },
}