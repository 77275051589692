export default {
    state: {
        streamsArray: [],
        liveMatchesInEvent: [],
        bookmarkType: 0,
        eventHeaderInfo: {},
        bookmark: {
            isFollowed: false,
            type: 0,
            eventId: 0,
            isLoaded: false
        }
    },
    mutations: {
        updateEventState(state, payload) {
            state.eventHeaderInfo.EventState = payload;
        },
        updateStreamsArray(state, payload) {
            state.streamsArray = payload;
        },
        updateLiveMatches(state, payload) {
            state.liveMatchesInEvent = payload;
        },
        updateStreamingPlanned(state, payload) {
            state.eventHeaderInfo.IsStreamingPlanned = payload;
        },
        updateEventSport(state, payload) {
            state.eventHeaderInfo.Sport = payload;
        },
        updateEventNameRouting(state, payload) {
            state.eventHeaderInfo.EventNameRouting = payload;
        },
        updateEventHeaderInfo(state, payload) {
            state.eventHeaderInfo = payload;
        },
        updateBookmarkType(state, payload) {
            state.updateBookmarkType = payload;
        },
        updateIsFollowed(state, payload) {
            state.bookmark = payload;
            state.bookmark.isLoaded = true;
        }
    },
    actions: {
        updateEventState: ({ commit }, payload) => {
            commit("updateEventState", payload);
        },
        updateStreamsArray: ({ commit }, payload) => {
            commit("updateStreamsArray", payload);
        },
        updateLiveMatches: ({ commit }, payload) => {
            commit("updateLiveMatches", payload);
        },
        updateStreamingPlanned: ({ commit }, payload) => {
            commit("updateStreamingPlanned", payload);
        },
        updateEventSport: ({ commit }, payload) => {
            commit("updateEventSport", payload);
        },
        updateEventNameRouting: ({ commit }, payload) => {
            commit("updateEventNameRouting", payload);
        },
        updateEventHeaderInfo: ({ commit }, payload) => {
            commit("updateEventHeaderInfo", payload);
        },
        updateBookmarkType: ({ commit }, payload) => {
            commit("updateBookmarkType", payload);
        },
        updateIsFollowed: ({ commit }, payload) => {
            commit("updateIsFollowed", payload);
        },
    },
    getters: {
        eventHeaderEventName(state) {
            return state.eventHeaderInfo.Name;
        },
        eventIndex: (state) => state,
    }
}