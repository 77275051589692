<template>
    <div class="lpDesign" v-bind:class="[landingPageClass]">
        <div class="wrap">
            <div class="burger-wrap row aic jcb">
                <div class="logo col-auto">
                    <router-link v-bind:to="'/?device='+nomenclatures.UserDeviceRin"><img src="https://cdn.rankedin.com/images/static/logoWLpDesign.svg" width="200" height="24" alt="logo"></router-link>
                </div>
                <div class="menuLpDesign col-12 col-lg p-0">
                    <div class="row aic jcc jce-lg">
                        <search-not-logged></search-not-logged>

                        <div class="menuLpDesignWrapper col-12 col-lg-auto">
                            <ul>
                                <li><router-link v-bind:to="'/' + layout.language + '/#features'">{{translations['shared_Menu_Features']}}</router-link></li>
                                <li><router-link v-bind:to="'/' + layout.language + '/calendar'">{{translations['shared_Menu_Events']}}</router-link></li>
                                <li v-if="!isIOSorAndroidUserDeviceRin"><router-link v-bind:to="'/' + layout.language + '/pricing'">{{translations['shared_Menu_Pricing']}}</router-link></li>
                                <li><router-link v-bind:to="'/' + layout.language + '/requestdemo'">Demo</router-link></li>
                                <li><router-link v-bind:to="'/' + layout.language + '#contact'">{{translations['shared_Menu_Contact']}}</router-link></li>
                            </ul>
                        </div>
                        <div class="lang lpDesign col-12 col-lg-auto order-lg-12">
                            <ul>
                                <li class="active"><a href="#"><i v-bind:class="'flag-icon flag-icon-' + countryFlagFromLanguage"></i> <span class="icon icon-v"></span></a></li>
                                <language-switch-items></language-switch-items>
                            </ul>
                        </div>
                        <div class="profile col-12 col-lg-auto">
                            <a class="btn btn-menu btn-ghost pro" style="cursor: pointer">{{translations['shared_Login_ButtonText']}}</a>
                            <div class="profileMenu">
                                <form class="lpDesign" v-on:submit.prevent="login()">
                                    <div class="row">
                                        <div class="col col-12">
                                            <p><strong>{{translations['shared_Login_ButtonText']}}:</strong></p>
                                        </div>
                                        <div class="col col-12">
                                            <input v-on:keyup.enter="login()" type="email" name="UserName" id="email" v-model="username" v-bind:placeholder="translations['shared_Login_EmailAddress']" required>
                                        </div>
                                        <div class="col col-12">
                                            <input v-on:keyup.enter="login()" type="password" name="Password" id="password" v-model="password" v-bind:placeholder="translations['shared_Login_Password']" required>
                                        </div>
                                        <div class="col col-12">
                                            <input type="checkbox" name="RememberMe" id="remember" v-model="rememberMe">
                                            <label for="remember">{{translations['shared_Login_RememberMe']}}</label>
                                        </div>
                                        <div class="col col-12">
                                            <button type="button" v-on:click="login()" id="dropdown-login-button">
                                                <div v-if="isLoading" class="spinner-border-sm spinner-border" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                                {{translations['shared_Login_ButtonText']}}
                                            </button>
                                            <router-link v-bind:to="'/' + layout.language + '/account/cantsignin'" class="forgot">{{translations['shared_Login_ForgotPasswordLink']}}</router-link>
                                        </div>
                                    </div>
                                </form>
                                <div class="new">
                                    <p>{{translations['shared_Login_NewHereQ']}}</p>
                                    <router-link v-bind:to="'/' + layout.language + '/account/joinus'" class="btn btn-info">{{translations['shared_Login_CreateAccount']}}</router-link>
                                </div>
                                <div class="lang lpDesign lpDesignMobile col-12 col-lg-auto order-lg-12">
                                    <ul>
                                        <li class="active"><a href="#"><i v-bind:class="'flag-icon flag-icon-' + countryFlagFromLanguage"></i> <span class="icon icon-v"></span></a></li>
                                        <language-switch-items></language-switch-items>
                                    </ul>
                                </div>
                                <div class="poweredBy text-center">
                                    <p>{{translations['shared_PoweredBy']}}:</p>
                                    <a href="#"><img src="https://cdn.rankedin.com/images/static/logoBook.svg" width="80" height="18" alt="book 24"></a>
                                    <a href="#"><img src="https://cdn.rankedin.com/images/static/logoBlue.svg" width="81" height="11" alt="rankedin logo"></a>
                                </div>
                            </div>
                            <div class="desktop">
                                <a v-bind:href="'/' + layout.language + '#app'" class="btn btn-menu btn-info">{{translations['shared_Menu_GetApp']}}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="burger">
                    <span></span>
                </div>
            </div>
        </div>
    </div>

</template>
<script>    
    import languageSwitchItems from '@/components/layout/top-bar/language-switch-items';
    import search from '@/components/layout/search/search';
    import searchNotLogged from '@/components/layout/search/search-notlogged';
    import { mapGetters } from 'vuex';    

    export default {
        components: {
            'language-switch-items': languageSwitchItems,
            'search': search,
            'searchNotLogged': searchNotLogged
        },
        data() {
            return {
                data: {
                    PlayerTeams: { TeamsCount: 0, Teams: [] },
                    PlayerProfileUrl: ''
                },                                                
                isLoading: false,

                username: '',
                password: '',
                rememberMe: true
            }
        },
        mounted() {            
          
        },
        computed: {
            ...mapGetters(['layout','translations', 'nomenclatures']),
            isIOSorAndroidUserDeviceRin: function () {
                return this.nomenclatures.UserDeviceRin == 'ios' || this.nomenclatures.UserDeviceRin == 'android';
            },
            countryFlagFromLanguage() {
                if (this.layout.language == 'en') return 'gb';

                return this.layout.language;
            },
            landingPageClass() {
                if (this.$route.name == 'landing-page') return 'landing-page-header-class';
                return '';
            }
        },
        methods: {
            showSpinner: function () {
                this.isLoading = true;
            },
            login() {                                
                this.$authClient.login(this.username, this.password, this.rememberMe);
            }
        }
    }
</script>