<template>
    <div class="rin-nav-item">
        <BaseIcon :name="icon" />
        <div class="rin-navlink-description" v-if="description != null">
            {{ description }}
        </div>
        <slot name="indicator-counter"></slot>
    </div>
</template>

<script>
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
    export default {
        name: 'NavLink',
        components: {
            BaseIcon
        },
        props: {
            icon: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                default: null
            }
        }
    };
</script>
