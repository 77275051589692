import axios from 'axios';
import _ from 'lodash';

export default {
	state: {
		translations: [],
		languageLoaded: '',
		isLoaded: false,
		currentTranslationsVersion: 0,
	},
	mutations: {
		updateTranslations(state, payload) {
			let lowerFirstKeys = _.mapKeys(payload, (v, k) => _.lowerFirst(k));
			state.translations = lowerFirstKeys;
			state.isLoaded = true;
		},
		updateLoadedTranslationsFlag(state, payload) {
			state.languageLoaded = payload;
		},
		updateTranslationsVersion(state, payload) {
			state.currentTranslationsVersion = payload;
		},
	},
	actions: {
		updateTranslations: ({ commit }, payload) => {
			commit('updateTranslations', payload);
		},
		getTranslations: ({ state, commit, rootState }, payload) => {
			if (state.languageLoaded != payload) {
				const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
				axios.get(`${apiDomain}/translations/GetTranslationsForLanguageAsync?language=${payload}`).then((response) => {
					commit('updateTranslations', response.data);
					commit('updateLoadedTranslationsFlag', payload);
				});
			}
		},
		async getEnTranslations({ state, commit, rootState }, payload) {
			const apiDomain = rootState.nomenclatures.nomenclatures.ApiDomain;
			return axios.get(`${apiDomain}/translations/GetTranslationsForLanguageAsync?language=en`).then((response) => {
				commit('updateTranslations', response.data);
				commit('updateLoadedTranslationsFlag', 'en');
			});
		},
		async asyncUpdateTranslationsVersion({ commit, getters, dispatch }, payload) {			
			const url = payload.cdn + 'translations/version.json?' + Math.floor(Date.now() / 1000);

			const res = await axios({
				url: url,
				method: 'GET',
				withCredentials: false,
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json',
					'Allow-Headers': 'Content-Type',
					'Access-Control-Allow-Headers': 'Content-Type, Accept',
				},
			});

			if (getters.currentTranslationsVersion !== res.data) {
				commit('updateTranslationsVersion', res.data);
				dispatch('asyncUpdateTranslation', payload.language);
			}			
		},
		async asyncUpdateTranslation({ getters, commit, rootGetters }, payload) {
			const url =
				rootGetters.nomenclatures.BlobDomain +
				'translations/tranlsation-' +
				payload +
				'-' +
				getters.currentTranslationsVersion +
				'.json';
			
				const res = await axios({
					url: url,
					method: 'GET',
					withCredentials: false,
					headers: {
						'Access-Control-Allow-Origin': '*',
						'Content-Type': 'application/json',
						'Allow-Headers': 'Content-Type',
						'Access-Control-Allow-Headers': 'Content-Type, Accept',
					},
				});

				commit('updateTranslations', res.data);
				commit('updateLoadedTranslationsFlag', payload);	
		},
	},
	getters: {
		translations: (state) => state.translations,
		currentTranslationsVersion: (state) => state.currentTranslationsVersion,
	},
};
