<template>
    <div class="trial">
        <div v-if="!showSuccessScreen" class="trial-information-wrapper">
            <BaseCard type="secondary">
                <template #body>
                    <div class="header-intro-text">{{translations['monetization_Player_ModalSupertitle']}}</div>
                    <div class="title-small">
                        Rankedin <BaseTypeLabel type="pro"></BaseTypeLabel> {{translations['monetization_Trial_Plan']}}
                    </div>
                    <div class="title-big">
                        {{translations['monetization_Trial_14Day']}}
                    </div>
                    <div class="trial-description">
                        {{translations['monetization_Trial_Description']}}
                    </div>
                    <div class="trial-body-wrapper">
                        <BaseButton type="warn" @click.native="activateTrial" size="l">{{translations['monetization_Trial_StartTrial']}}</BaseButton>
                        <small class="no-card-needed">({{translations['monetization_Trial_NoCard']}})</small>
                        <div class="trial-modal-subtitle">
                            {{ translations['monetization_Player_ModalSubtitle'] }} <br />
                            <img class="img-responsive" src="https://cdn.rankedin.com/images/static/underline-text.svg" alt="underline text" />
                        </div>
                        <ul class="trial-modal-bulletpoints">
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Player_ModalBulletpoint1'] }}</span>
                            </li>
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Player_ModalBulletpoint2'] }}</span>
                            </li>
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Player_ModalBulletpoint3'] }}</span>
                            </li>
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Player_ModalBulletpoint4'] }}</span>
                            </li>
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Real_Time_Indicator'] }}</span>
                            </li>
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Player_ModalBulletpoint6'] }}</span>
                            </li>
                            <li>
                                <BaseIcon name="check"></BaseIcon>
                                <span>{{ translations['monetization_Player_ModalBulletpoint7'] }}</span>
                            </li>
                        </ul>
                        <div class="choose-plan-wrapper">
                            <div class="trial-modal-pricing-description">
                                {{ translations['monetization_Plans_PricingText'] }}:
                            </div>
                            <div class="trial-modal-plans-container">
                                <BaseCard type="primary"
                                          size="s"
                                          :shadow="false"
                                          v-for="plan in plans"
                                          :key="plan.priceId"
                                          :class="{ 'rin-popular-plan-container': plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly}">
                                    <template #title>
                                        <BaseTypeLabel v-if="plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly"
                                                       type="popular" />
                                    </template>
                                    <template #header v-if="plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly">
                                        <span>-55%</span>
                                    </template>
                                    <template #body>
                                        <div class="rin-pro-player-modal-plan-period">
                                            {{
									translations[
										`monetization_Player_ModalPlanPeriod${
											nomenclatures.PlayerSubscriptionPeriodTypesEnum[plan.type]
										}`
									]
                                            }}
                                        </div>
                                        <div class="rin-pro-player-modal-plan-cost">
                                            {{ parsePrice(plan.price) }} {{ currencySymbol(plan.currency) }}
                                            <span>
                                                /
                                                {{
										translations[
											`monetization_Player_ModalPlanPer${
												nomenclatures.PlayerSubscriptionPeriods[
													nomenclatures.PlayerSubscriptionPeriodTypesEnum[plan.type]
												]
											}`
										]
                                                }}
                                            </span>
                                        </div>
                                        <div class="rin-pro-player-modal-plan-breakdown">
                                            <template v-if="plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly">
                                                12 {{ translations['monetization_Player_ModalPlanMonths'] }} {{ annualPlanBreakdown }} {{ currencySymbol(plan.currency) }} /
                                                {{
										translations[
											`monetization_Player_ModalPlanPer${nomenclatures.PlayerSubscriptionPeriods.Monthly}`
										]
                                                }}
                                            </template>
                                        </div>
                                        <div class="cancel-any-time">
                                            {{ translations['monetization_Player_ModalPlanDescription'] }}
                                        </div>
                                        <BaseButton type="warn" size="m" @click.native="actionButton(plan.priceId, plan.type)">
                                            <template v-if="layout.player.isAuthenticated">
                                                {{ translations['monetization_Player_ModalPlanBuyButton'] }}
                                            </template>
                                            <template v-else>
                                                {{ translations['shared_SeeMore'] }}
                                            </template>
                                        </BaseButton>
                                    </template>
                                </BaseCard>
                            </div>
                        </div>
                        <span class="or">{{translations['monetization_Trial_Or']}}</span>
                        <span @click="close" class="underlined"><u>{{translations['monetization_Trial_StayFree']}}</u></span>
                    </div>
                </template>
            </BaseCard>
        </div>
        <div v-else class="trial-success-wrapper">
            <BaseCard type="secondary">
                <template #title>
                    <div class="title-small">
                        Rankedin <BaseTypeLabel :type="'pro'"></BaseTypeLabel> {{translations['monetization_Trial_Plan']}}
                    </div>
                    <div class="trial-success">
                        <div class="title-big">
                            {{translations['monetization_Trial_Success']}}
                            {{translations['monetization_Trial_StartedTrial']}}
                        </div>
                    </div>
                    <div class="trial-footer">
                        <BaseButton type="primary" @click.native="close" size="m">{{translations['monetization_Trial_GotIt']}}</BaseButton>
                    </div>
                </template>
            </BaseCard>
        </div>
    </div>
</template>
<script>
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard';
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import { mapGetters, mapActions } from 'vuex';
    import { GET_LAYOUT_MODEL_ASYNC } from "@/store/modules/layout-module.js";

    export default {
        name: 'TrialModal',
        components: {
            BaseCard,
            BaseTypeLabel,
            BaseButton,
            BaseIcon
        },
        data() {
            return {
                showSuccessScreen: false,
                plans: [],
            }
        },
        computed: {
            ...mapGetters(['translations', 'nomenclatures', 'layout']),

            annualPlanBreakdown() {
                const annualPlanPrice = this.plans.find(
                    (v) => v.type === this.nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly
                ).price;
                const breakdown = annualPlanPrice / 12;

                if (Number.isInteger(breakdown)) {
                    return breakdown;
                } else {
                    return breakdown.toString().slice(0, breakdown.toString().indexOf('.') + 3);
                }
            },
        },
        methods: {
            ...mapActions({
                getLayoutModel: GET_LAYOUT_MODEL_ASYNC
            }),
            activateTrial() {
                this.$http.post(`${this.nomenclatures.ApiDomain}/monetization/StartFreeTrialSubscriptionAsync`)
                    .then(() => {   
                        this.$emit('trialStarted');
                        this.showSuccessScreen = true;
                    })
            },
            close() {
                this.$emit('close');
            },
            currencySymbol(currencyCode) {
                switch (currencyCode) {
                    case 2:
                        return '€';
                    default:
                        return this.nomenclatures.Currencies[currencyCode].name;
                }
            },
            parsePrice(price) {
                return price.toFixed(2);
            },
            actionButton(priceId, type) {
                if (this.layout.player.isAuthenticated) {
                    const _this = this;

                    this.$http.post(`${_this.nomenclatures.ApiDomain}/Monetization/CreatePlayerSubscriptionAsync`, {
                        priceId: priceId,
                        type: type,
                        language: this.layout.language
                    })
                        .then((response) => {
                            window.location.href = response.data
                        })
                        .catch(() => {
                            window.location.reload();
                        });

                } else {
                    this.$router.push({ name: 'pricing', params: { language: this.layout.language }, hash: '#boxPlayers' });
                }
            },
        },
        mounted() {
            const endpointMethod = 'GetPlayersSubscriptionsAsync';

            this.$http.get(`${this.nomenclatures.ApiDomain}/Monetization/${endpointMethod}`)
                .then((res) => {
                    if (res.data.length > 0)
                        this.plans = res.data;
                    else if (this.layout.player.isAuthenticated && res.data.length === 0)
                        window.location.reload();
                })
                .catch(() => window.location.reload());
        },
    }
</script>