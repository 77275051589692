<template>
    <input :value="term"
           @input="event => term = event.target.value"
           type="text"
           @click="showSearch"
           class="rin-search-input"
           @keyup="search"
           :placeholder="translations['shared_Search_Global_Placeholder']"
           autocomplete="off"
           ref="searchref" />
</template>
<script>
    import { mapGetters } from 'vuex';
    import _ from 'lodash';

    export default {
        name: 'SearchInput',
        props: {
            name: {
                type: String
            }
        },
        mounted() {
            if (this.name === 'mobile') {
                this.$refs.searchref.focus();
            }
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            term: {
                get() {
                    return this.$store.getters['search/term'];
                },
                set(newValue) {
                    this.$store.commit('search/setTerm', newValue.trim());
                },
            },
        },
        methods: {
            showSearch() {
                window.scrollTo(0, 0);
                this.$store.commit('search/toggle', true);
            },
            search: _.debounce(function () {
                if (this.term.trim() === '') return;

                this.$store.dispatch('search/find', {
                    term: this.term,
                    playerid: 0,
                    language: 'en',
                });
            }, 1200),
        },
    };
</script>
