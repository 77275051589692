<template>
    <div v-show="!secondaryFreemiumModalShown">
        <BaseModalWrapper size="l" :show="showFreemiumCampaign" @close="showFreemiumCampaign = false" :backdrop="false">
            <template #body>
                <div class="rin-freemium-campaing-content">
                    <h1>{{ translations['monetization_Freemium_Main_Introduce'] }}</h1>
                    <h3 class="rin-italic">(28.09.2022)</h3>
                    <h6>
                        {{ translations['monetization_Freemium_Main_NoWorries'] }}
                        <span class="rin-fremium-redirect" @click="showNoWorries">{{ translations['monetization_Freemium_Here'] }}.</span>
                    </h6>
                    <h6 class="rin-freemium-price">{{ translations['monetization_Freemium_Main_Price'] }}</h6>
                    <h6 class="rin-freemium-description rin-italic">
                        {{ translations['monetization_Freemium_Main_BodyText'] }}
                    </h6>
                    <h6>
                        {{ translations['monetization_Freemium_Main_FederationText'] }}
                        <span class="rin-fremium-redirect" @click="showWhyFree">{{ translations['monetization_Freemium_Here'] }}.</span>
                    </h6>

                    <div>
                        <p class="rin-freemium-help-text">{{ translations['monetization_Freemium_Main_AnyQuestions'] }}</p>
                        <a class="rin-fremium-redirect rin-freemium-contact" href="mailto:support@rankedin.com">support@rankedin.com</a>
                        <hr />
                        <div class="rin-freemium-checkbox-wrapper">
                            <BaseCheckbox :is-checked="checked" @click.native="checked = !checked" />
                            <span class="rin-freemium-dont-show-again-text">{{ translations['monetization_Freemium_Main_DontShow'] }}</span>
                        </div>
                        <div class="rin-freemium-actions-wrapper">
                            <BaseButton type="primary" size="l" @click.native="okGotIt">
                                {{ translations['monetization_Freemium_Main_Ok'] }}
                            </BaseButton>
                        </div>
                    </div>
                </div>
            </template>
        </BaseModalWrapper>
    </div>
</template>

<script>
    import Cookies from 'js-cookie';
    import { mapGetters } from 'vuex';
    import spaEventBus from "@/app/eventBus";

    import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';
    import BaseCheckbox from '@/components/rin_new_design/components/base/BaseCheckbox.vue';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';

    const freemiumSettings = {
        name: 'FreemiumCampaign',
        async checkAudienceAsync(params) {
            if (!params.store.getters.layout.isEmbed)
                return Promise.resolve(true);

            return Promise.resolve(false);
        },
    };
    export { freemiumSettings };

    export default {
        name: 'FreemiumCampaign',
        components: {
            BaseButton,
            BaseCheckbox,
            BaseModalWrapper,
        },
        data() {
            return {
                showFreemiumCampaign: true,
                checked: false,
                secondaryFreemiumModalShown: false
            };
        },
        mounted() {
            spaEventBus.$on('hideSecondaryFreemiumModal', () => {
                this.secondaryFreemiumModalShown = false;
            });
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
        },
        methods: {
            async okGotIt() {
                if (this.checked === true) {
                    if (this.layout.player.isAuthenticated) {
                        try {
                            await this.$http.post(`${this.nomenclatures.ApiDomain}/Player/DismissFreemiumCampaignAsync`);
                        } catch (error) {
                            console.error(error);
                        } finally {
                            this.showFreemiumCampaign = false;
                        }
                    } else {
                        let modalAdsCookie;
                        try {
                            modalAdsCookie = JSON.parse(Cookies.get('modal-ads'));
                        } catch (error) {
                            modalAdsCookie = {};
                        }
                        const freemiumCookie = modalAdsCookie?._ads?.find(
                            (el) => el._id === this.nomenclatures.CampaignEnum['Freemium']
                        );

                        if (freemiumCookie) {
                            freemiumCookie._lastAdDate = '2022-10-10';
                        } else {
                            //shouldnt happen but jew never know
                            modalAdsCookie.push({
                                _id: this.nomenclatures.CampaignEnum['Freemium'],
                                _lastAdDate: '2022-10-10',
                            });
                        }
                        Cookies.set('modal-ads', JSON.stringify(modalAdsCookie), { expires: 60, path: '/' });
                    }
                }

                this.showFreemiumCampaign = false;
            },
            showNoWorries() {
                spaEventBus.$emit('showNoWorriesModal');
                this.secondaryFreemiumModalShown = true;

            },
            showWhyFree() {
                spaEventBus.$emit('showWhyFreeModal');
                this.secondaryFreemiumModalShown = true;

            }
        },
    };
</script>
