export default {
	install(Vue, { store }) {
		Vue.mixin({
			mounted() {
				if (this.$windowSize.isMobile) {
					window.document
						.querySelectorAll('input[type="text"], input[type="email"], input[type="password"]')
						.forEach((v) => {
							if (v.getAttribute('rinfocus') !== 'true') {
								v.setAttribute('rinfocus', 'true');

								v.addEventListener('focusin', (ev) => {
									const distance = window.pageYOffset + ev.target.getBoundingClientRect().top;
									window.scrollTo({ top: (distance | 0) - 120, behavior: 'smooth' });
									store.commit('setFooter', false);
								});

								v.addEventListener('focusout', () => {
									store.commit('setFooter', true);
								});
							}
						});
				}
			},
			beforeUnmount() {
				if (this.$windowSize.isMobile) {
					window.document
						.querySelectorAll('input[type="text"], input[type="email"], input[type="password"]')
						.forEach((v) => {
							if (v.getAttribute('rinfocus') === 'true') {
								v.removeAttribute('rinfocus');
								v.removeEventListener('focusin');
								v.removeEventListener('focusout');
							}
						});
				}
			},
		});
	},
};
