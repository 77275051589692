<template>
    <nav class="rin-topnav wrap">
        <span class="rin-topnav-back-button back" v-show="$windowSize.isMobile && showBackArrow"><a onclick="window.history.back()"><span class="icon icon-back"></span></a></span>
        <ul>
            <li class="rin-top-nav-logo" :class="{'logo-circle' : isAuthorized}">
                <NavLogo :destinationName="destination"
                         :params="params">
                </NavLogo>
            </li>
            <li class="rin-topnav-search-wrap" v-if="!$windowSize.isMobile">
                <SearchInput></SearchInput>
                <BaseIcon name="search"></BaseIcon>
            </li>
            <li v-if="!isAuthorized && !$windowSize.isMobile" class="rin-topnav-login-or-create">
                <NavLoginOrCreate></NavLoginOrCreate>
            </li>
            <li class="rin-topnav-group" v-if="!isAuthorized">
                <NavButton v-if="!isAuthorized && $windowSize.isMobile"
                           icon="search"
                           @click.native="showSearch" />

                <NavLink destinationName="calendar"
                         :params="{ language: language }"
                         icon="calendar-2"
                         description="" />

                <NavButton icon="burger-menu" @click.native="navMenuOpen" />
            </li>
            <li class="rin-topnav-group rin-topnav-group-m" v-if="isAuthorized && !$windowSize.isMobile">
                <NavButton icon="create"
                           :description="translations['topNavigation_Create']"
                           @click.native="addBodyModal({name: 'event-create', forceClose: true})" />

                <NavButton icon="follow"
                           :description="translations['topNavigation_FollowedPRO']"
                           @click.native="addBodyModal({name: 'bookmarks', forceClose: true})" />

                <NavButton icon="cup-3"
                           :description="translations['topNavigation_Matches']"
                           class="notification-wrapper"
                           @click.native="addBodyModal({name: 'profile-matches', forceClose: true})">
                    <template slot="indicator-counter">
                        <div class="circle" v-if="notificationsCount>0 && layout.player.IsProPlayer">{{notificationsCount}}</div>
                    </template>
                </NavButton>

                <NavButton icon="my-panel"
                           :description="translations['topNavigation_IAdmin']"
                           @click.native="addBodyModal({name: 'i-admin', forceClose: true})" />
            </li>
            <li class="rin-topnav-group" v-if="isAuthorized">
                <NavLink destinationName="calendar"
                         :params="{ language: language }"
                         icon="calendar-2"
                         description="" />

                <NavLink destinationName="messages"
                         :params="{ language: language }"
                         :badge="layout.player.unreadMessages"
                         icon="chat-2"
                         description="" />

                <NavLink destinationName="notifications"
                         :params="{ language: language }"
                         :badge="layout.player.unreadNotifications"
                         icon="notification"
                         description="" />

                <NavButton icon="burger-menu" @click.native="navMenuOpen" />
            </li>
        </ul>

        <!-- modal open declaration -->
        <portal to="main-body-modal" v-if="lastOpenedBodyModal == 'event-create'">
            <BaseBodyModal>
                <template #content>
                    <CreateModal />
                </template>
            </BaseBodyModal>
        </portal>
        <portal to="main-body-modal" v-if="lastOpenedBodyModal == 'profile-matches'">
            <BaseBodyModal>
                <template #content>
                    <ProfileMatches :force-own-profile-data="true">
                        <template #title>
                            <div class="rin-modal-header">
                                <span>Matches</span>
                            </div>
                            <hr />
                        </template>
                    </ProfileMatches>
                </template>
            </BaseBodyModal>
        </portal>
        <portal to="main-body-modal" v-if="lastOpenedBodyModal == 'i-admin'">
            <BaseBodyModal>
                <template #content>
                    <IAdmin>
                        <template #title>
                            <div class="rin-modal-header">
                                <span>You are MANAGER of:</span>
                            </div>
                            <hr />
                        </template>
                    </IAdmin>
                </template>
            </BaseBodyModal>
        </portal>
        <portal to="main-body-modal" v-if="lastOpenedBodyModal == 'bookmarks'">
            <BaseBodyModal>
                <template #content>
                    <ProfileBookmarks>
                        <template #title>
                            <div class="rin-modal-header">
                                <span>Followed <i>PRO</i></span>
                            </div>
                            <hr />
                        </template>
                    </ProfileBookmarks>
                </template>
            </BaseBodyModal>
        </portal>
    </nav>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex';

    import NavLink from '@/components/rin_new_design/components/nav/NavLink';
    import NavButton from '@/components/rin_new_design/components/nav/NavButton';
    import NavLogo from '@/components/rin_new_design/components/nav/NavLogo';
    import SearchInput from '@/components/rin_new_design/components/search/SearchInput';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import NavLoginOrCreate from '@/components/rin_new_design/components/nav/NavLoginOrCreate';

    import BaseBodyModal from '@/components/rin_new_design/components/modal-views/BaseBodyModal';
    import CreateModal from '@/components/rin_new_design/components/modal-views/CreateModal';
    import ProfileMatches from '@/views/player/ProfileMatches';
    import IAdmin from '@/components/rin_new_design/components/modal-views/IAdmin';
    import ProfileBookmarks from '@/views/ProfileBookmarks';


    export default {
        name: 'TopNav',
        components: {
            NavLink,
            NavButton,
            SearchInput,
            BaseIcon,
            NavLogo,
            BaseBodyModal,
            CreateModal,
            ProfileMatches,
            IAdmin,
            ProfileBookmarks,
            NavLoginOrCreate
        },
        props: {
            showBackArrow: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                windowWidth: 0,
            };
        },
        computed: {
            ...mapGetters('ui', ['lastOpenedBodyModal']),
            ...mapGetters(['layout','translations']),
            notificationsCount: function () {
                return this.$store.state.signalRNotifications.notificationsCount;
            },
            isMenuOpen() {
                return this.$store.getters['topnav/isMenuOpen'];
            },
            isAuthorized() {
                return this.$store.getters['topnav/isAuthorized'];
            },
            language() {
                return this.$store.getters.layout.language;
            },
            params() {
                if (this.isAuthorized) {
                    return {
                        id: this.$store.getters['topnav/rankedinId'],
                        language: this.language,
                        name: this.$store.getters['topnav/playerUrlName']
                    };
                }
                else {
                    return {
                        language: this.language
                    }
                }
            },
            destination() {
                return this.isAuthorized ? 'player-info' : 'landing-page';
            },
        },
        methods: {
            ...mapMutations('ui', ['addBodyModal']),
            navMenuOpen() {
                const isOpen = this.$store.getters['topnav/isMenuOpen'];
                if (!isOpen) {
                    this.$store.commit('topnav/toggleMenu', true);
                    window.scrollTo(0, 0);
                } else {
                    this.$store.commit('topnav/toggleMenu', false);
                }
            },
            showSearch() {
                this.$store.commit('search/toggle', true);
            }
        }
    };
</script>
<!--NavLogo param big small
NavProfile
NavSearch
NavLogin-->
