<template>
    <span class="rin-match-participant-name-wrapper" v-bind:class="{ 'is-winner': player.IsWinner }">
        <BaseFlag :country="player.CountryShort.toLowerCase()" />
        <router-link v-if="player.ShortName !== 'Pending'" :to="player.PlayerUrl">
            {{player.ShortName}}
        </router-link>
        <span v-else>{{player.ShortName}}</span>
    </span>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseFlag from '@/components/rin_new_design/components/base/BaseFlag'

    export default {
        name: 'MatchCardPlayerName',
        components: {
            BaseFlag
        },
        props: {
            player: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
            };
        },
        mounted() {
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures'])
        },
        methods: {
        }
    }
</script>