<template>
    <div id="americano-campaign-modal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    &times;
                </button>
                <div class="modal-body">
                    <div class="americano-body">
                        <img src="https://cdn.rankedin.com/images/americano-iphone.png" />
                        <div class="americano-text-wrapper">
                            <div class="americano-best">
                                Best
                            </div>
                            <div>
                                <span class="americano-hero">Americano</span><span class="americano-app">App</span>
                            </div>
                            <div class="americano-referal">
                                - Danish padel federation
                            </div>
                            <div class="btn-wrapper">
                                <router-link v-if="isLoggedIn" :to="createUrl" class="btn btn-success play-americano-btn">Play americano</router-link>
                                <router-link v-if="!isLoggedIn" :to="createUrl" class="btn btn-success play-americano-btn">Play americano</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    const americanoSettings = {
        name: 'americano-campaign',
        async checkAudienceAsync(params) {
            var isLoggedIn = params.store.getters.layout.player.isAuthenticated;
            var sport = 0;

           

            if (isLoggedIn) {
                sport = params.store.getters.layout.player.sport;
            }
            else {
                sport = params.store.getters.layout.page.sport;                
            }

            if (!params.store.getters.layout.isEmbed && sport === params.store.getters.nomenclatures.SportsArray.Padel) return Promise.resolve(true);

            return Promise.resolve(false);
        }
    };
    export { americanoSettings };

    export default {
        name: "americano-campaign",
        data: function () {
            return {
            }
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            createUrl() {
                return `/${this.layout.language}/americano/create`;
            },
            isLoggedIn() {
                return this.layout.player.isAuthenticated;
            }
        },

        mounted() {
            this.showModal();
        },
        methods: {
            playAmericanoClick() {
                $('#americano-campaign-modal').modal('hide');
                this.$router.replace(`/${this.layout.language}/americano/promo`);
            },
            showModal() {
                $('#americano-campaign-modal').modal('show');
            }
        },
        beforeDestroy() {
            if ($('.modal-backdrop.show').length > 0) $('.modal-backdrop.show').remove();
        }
    }
</script>

<style scoped>
    /*shared*/

    .modal .close {
        font-size: 40px;
        position: absolute;
        z-index: 10000000000;
    }

    .americano-body {
        display: flex;
        align-items: center;
    }

    .americano-best {
        font-style: normal;
        font-weight: 800;
        color: #233568;
    }

    .americano-hero {
        font-style: normal;
        font-weight: 800;
        color: #233568;
    }

    .americano-app {
        font-style: italic;
        font-weight: 800;
        color: #233568;
    }

    .americano-referal {
        font-style: italic;
        font-weight: 500;
        color: #233568;
    }

    .play-americano-btn {
        font-weight: bold;
        font-size: 22px;
        line-height: 27px;
        background: #36D076;
        border-radius: 50px;
        padding: 10px 30px;
    }

    .modal .close span {
        font-size: 40px;
        line-height: 15px;
        right: 20px;
        top: 20px;
    }

    /*mobile*/
    @media (max-width: 767px) {
        .americano-body {
            flex-direction: column;
            justify-content: center;
            padding: 20px 0 26px;
        }

        .americano-best {
            font-size: 24px;
            line-height: 29px;
            text-align: center;
        }

        .americano-hero {
            font-size: 36px;
            line-height: 44px;
        }

        .americano-app {
            font-size: 24px;
            line-height: 29px;
            margin-left: 5px;
        }

        .americano-referal {
            font-size: 18px;
            line-height: 22px;
            margin-top: 7px;
            margin-bottom: 27px;
        }

        .btn-wrapper {
            text-align: center;
        }
    }

    /*desktop*/

    @media (min-width: 768px) {
        .modal.show .modal-dialog {
            max-width: 720px;
        }

        .americano-body {
            flex-direction: row;
            padding: 45px 0px 70px;
        }

            .americano-body img {
                max-height: 450px;
            }

        .americano-text-wrapper {
            display: flex;
            flex-direction: column;
        }

        .americano-best {
            font-size: 30px;
            line-height: 37px;
            margin-left: 13px;
        }

        .americano-hero {
            font-size: 60px;
            line-height: 73px;
        }

        .americano-app {
            font-size: 30px;
            line-height: 37px;
            margin-left: 10px;
        }

        .americano-referal {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 50px;
        }
    }
</style>

