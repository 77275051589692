export default {
	namespaced: true,
	state: {
		bodyModals: [],
		isMonetizationModalOpen: false,
		isPremiumModalOpen: false,
		isPageNavBarVisible: false,
		isAlternativeBackgroud: false,
		navigationsCount: 0,
		enabledPwaPopup: false,
		pwaPromptRef: null,
	},
	mutations: {
		addBodyModal(state, payload) {
			if (payload.forceClose) {
				this.commit('ui/resetBodyModalsStack');
			}
			if (state.bodyModals[state.bodyModals.length - 1] !== payload.name) {
				state.bodyModals.push(payload.name);
			}
		},
		removeLastBodyModal(state) {
			state.bodyModals.pop();
		},
		resetBodyModalsStack(state) {
			state.bodyModals = [];
		},
		setMonetizationModalState(state, payload) {
			state.isMonetizationModalOpen = payload;
		},
		setPremiumModalState(state, payload) {
			state.isPremiumModalOpen = payload;
		},
		setPageNavBarState(state, payload) {
			state.isPageNavBarVisible = payload;
		},
		setAlternativeBackgroud(state, payload) {
			state.isAlternativeBackgroud = payload;
		},
		incrementNavigationsCount(state) {
			state.navigationsCount += 1;
		},
		setEnabledPwaPopup(state, payload) {
			state.enabledPwaPopup = payload;
		},
		setPwaPromptRef(state, payload) {
			state.pwaPromptRef = payload;
		},
	},
	actions: {
		setMonetizationModalState({ commit }, payload) {
			setTimeout(() => {
				commit('setMonetizationModalState', payload);
			}, 0);
		},
		setPremiumModalState({ commit }, payload) {
			setTimeout(() => {
				commit('setPremiumModalState', payload);
			}, 0);
		}
	},
	getters: {
		bodyModals(state) {
			return state.bodyModals;
		},
		lastOpenedBodyModal(state) {
			return state.bodyModals[state.bodyModals.length - 1];
		},
		isMonetizationModalOpen(state) {
			return state.isMonetizationModalOpen;
		},
		isPremiumModalOpen(state) {
			return state.isPremiumModalOpen;
		},
		isPageNavBarVisible(state) {
			return state.isPageNavBarVisible;
		},
		isAlternativeBackgroud(state) {
			return state.isAlternativeBackgroud;
		},
		getNavigationsCount(state) {
			return state.navigationsCount;
		},
		isEnabledPwaPopup(state) {
			return state.enabledPwaPopup;
		},
		shouldShowPwaPopup(state, getters) {
			return getters.getPwaPromptRef && getters.enabledPwaPopup;
		},
		getPwaPromptRef(state) {
			return state.pwaPromptRef;
		},
		getHasPwaPrompt(state) {
			return state.pwaPromptRef != null;
		}
	},
};
