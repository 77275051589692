<template>
    <nav class="rin-bottom-nav">
        <ul>
            <li v-if="isAuthorized" :class="{ active: followedProOpen }">
                <NavButton icon="follow"
                           :description="translations['topNavigation_FollowedPRO']"
                           @click.native="addBodyModal({ name: 'bookmarks', forceClose: true })" />
            </li>
            <li v-if="isAuthorized" :class="{ active: matchesOpen }">
                <NavButton icon="cup-3"
                           :description="translations['topNavigation_Matches']"
                           class="notification-wrapper"
                           @click.native="addBodyModal({ name: 'profile-matches', forceClose: true })">
                    <template slot="indicator-counter">
                        <div class="circle" v-if="notificationsCount > 0">{{ notificationsCount }}</div>
                    </template>
                </NavButton>
            </li>
            <li v-if="isAuthorized" :class="{ active: createOpen }">
                <NavButton icon="create"
                           :description="translations['topNavigation_Create']"
                           @click.native="addBodyModal({ name: 'event-create', forceClose: true })" />
            </li>
            <li v-if="isAuthorized" :class="{ active: searchOpen }">
                <NavButton icon="search" 
                           :description="translations['topNavigation_Search']"
                           @click.native="showSearch" />
            </li>
            <li v-if="isAuthorized" :class="{ active: iAdminOpen }">
                <NavButton icon="my-panel"
                           :description="translations['topNavigation_IAdmin']"
                           @click.native="addBodyModal({ name: 'i-admin', forceClose: true })" />
            </li>
            <li v-if="!isAuthorized" class="rin-bottom-nav-login-or-create">
                <NavLoginOrCreate />
            </li>
        </ul>
    </nav>
</template>
<script>
    import { mapGetters, mapMutations } from 'vuex';

    import NavButton from '@/components/rin_new_design/components/nav/NavButton.vue';
    import NavLoginOrCreate from '@/components/rin_new_design/components/nav/NavLoginOrCreate.vue';

    export default {
        name: 'BottomNav',
        components: {
            NavButton,
            NavLoginOrCreate,
        },
        computed: {
            ...mapGetters(['layout', 'translations']),

            isAuthorized() {
                return this.$store.getters['topnav/isAuthorized'];
            },
            notificationsCount: function () {
                return this.$store.state.signalRNotifications.notificationsCount;
            },
            followedProOpen() {
                return 'bookmarks' === this.$store.getters['ui/lastOpenedBodyModal'];
            },
            matchesOpen() {
                return 'profile-matches' === this.$store.getters['ui/lastOpenedBodyModal'];
            },
            createOpen() {
                return 'event-create' === this.$store.getters['ui/lastOpenedBodyModal'];
            },
            searchOpen() {
                return this.$store.getters['search/isShown'];
            },
            iAdminOpen() {
                return 'i-admin' === this.$store.getters['ui/lastOpenedBodyModal'];
            },
        },
        methods: {
            ...mapMutations('ui', ['addBodyModal', 'resetBodyModalsStack']),
            showSearch() {
                this.resetBodyModalsStack();
                this.$store.commit('search/toggle', true);
            },
        },
    };
</script>
