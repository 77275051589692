import { store } from '@/store/store';

export default {
	install(Vue) {
		Vue.mixin({
			created() {
				if (!this.$store) {
					this.$store = store;
				}
			},
		});
	},
};
