export default {
    state: {
        eventState: 0,
        eventName: '',
        isInRole: false,
    },
    mutations: {
        updateClubLeagueEventState(state, payload) {
            state.eventState = payload;
        },
        updateClubLeagueEventName(state, payload) {
            state.eventName = payload;
        },
        updateClubLeagueIsInRole(state, payload) {
            state.isInRole = payload;
        }
    },
    actions: {
        updateClubLeagueEventState: ({ commit }, payload) => {
            commit("updateClubLeagueEventState", payload);
        },
        updateClubLeagueIsInRole: ({ commit }, payload) => {
            commit("updateClubLeagueIsInRole", payload);
        },
        updateClubLeagueEventName: ({ commit }, payload) => {
            commit("updateClubLeagueEventName", payload);
        }
    },
}