export default {

    getIcon(drawType) {
        let result = this.data.find(x => x.drawType === drawType);
        return result.icon;
    },

    getName(drawType) {
        let result = this.data.find(x => x.drawType === drawType);
        return result.name;
    },

    data: [
        {
            drawType: 1,
            selected: false,
            name: "Round robin",
            icon: "https://rankedin.blob.core.windows.net/images/draws/single-2x/RANKEDIN-Icons-02.png"
        },
        {
            drawType: 2,
            selected: false,
            name: "Monrad",
            icon: "https://rankedin.blob.core.windows.net/images/draws/single-2x/RANKEDIN-Icons-08.png"
        },
        {
            drawType: 11,
            selected: false,
            name: "Qualification draw",
            icon: "https://rankedin.blob.core.windows.net/images/draws/single-2x/RANKEDIN-Icons-06.png"
        },
        {
            drawType: 3,
            selected: false,
            name: "Elimination draw",
            icon: "https://rankedin.blob.core.windows.net/images/draws/single-2x/RANKEDIN-Icons_elimination.png"
        },
        {
            drawType: 12,
            selected: false,
            name: "Multi round robin",
            icon: "https://rankedin.blob.core.windows.net/images/draws/single-1x/multi_RR.png"
        }
    ]
};