0<template>
	<div class="text-center">
		<span :class="`rin-spinner-${size}`"></span>
	</div>
</template>
<script>
export default {
		name: 'BaseSpinner',
		props: {
			size: {
				type: String,
				default: 'm',
				validator: (v) => ['s', 'm'].indexOf(v) > -1,
            }
        }
};
</script>
