<template>
	<div class="rin-page-top-bar-container">
		<div class="wrap">
			<div class="rin-old-store-app-not-supported">
					Mobile APP is no longer supported - please use a Chrome Browser.
			</div>
		</div>

	</div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
        name: 'OldStoreAppWarningTopBar',
	components: {		
	},
	data() {
		return {
		
		};
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
	},
	methods: {


	},
};
</script>

<style scoped>
    .rin-page-top-bar-container {
        background-color: #FBC402;
    }

    .rin-old-store-app-not-supported {
        font-weight: 700;
        color: black;
    }
</style>