<template>
    <div>
        <li><a href="#" v-on:click="changeLanguage('hr')"><i class="flag-icon flag-icon-hr"></i><span class="langName">&nbsp;Croatian</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('dk')"><i class="flag-icon flag-icon-dk"></i><span class="langName">&nbsp;Danish</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('nl')"><i class="flag-icon flag-icon-nl"></i><span class="langName">&nbsp;Dutch</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('en')"><i class="flag-icon flag-icon-gb"></i><span class="langName">&nbsp;English</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('fr')"><i class="flag-icon flag-icon-fr"></i><span class="langName">&nbsp;French</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('de')"><i class="flag-icon flag-icon-de"></i><span class="langName">&nbsp;German</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('it')"><i class="flag-icon flag-icon-it"></i><span class="langName">&nbsp;Italian</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('pl')"><i class="flag-icon flag-icon-pl"></i><span class="langName">&nbsp;Polish</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('pt')"><i class="flag-icon flag-icon-pt"></i><span class="langName">&nbsp;Portuguese</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('ru')"><i class="flag-icon flag-icon-ru"></i><span class="langName">&nbsp;Russian</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('si')"><i class="flag-icon flag-icon-si"></i><span class="langName">&nbsp;Slovenian</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('es')"><i class="flag-icon flag-icon-es"></i><span class="langName">&nbsp;Spanish</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('se')"><i class="flag-icon flag-icon-se"></i><span class="langName">&nbsp;Swedish</span></a></li>
        <li><a href="#" v-on:click="changeLanguage('sr')"><i class="flag-icon flag-icon-rs"></i><span class="langName">&nbsp;Serbian</span></a></li>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {      
        mounted() {
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),          
        },
        methods: {
            changeLanguage(code) {                  
                if (this.$route.params.language == code) return;

                this.setLanguageCookie(code);
                
                if (this.layout.player.isAuthenticated) {
                    this.$http.post("/Home/ChangeLanguage/", { code: code }).then(() =>
                        this.reloadWithLanguage(code)
                    );
                }
                else {
                    this.reloadWithLanguage(code);
                }
            },
            reloadWithLanguage(code) {
                let newLanguageRoute = this.$router.resolve({ params: { language: code } });
                location.href = newLanguageRoute.href;
            },
            setLanguageCookie(code) {

                var language_cookie = "language";

                // if the language cookie not exists create one.
                if (!Cookies.get(language_cookie)) {

                    // check if the browser supports cookie
                    var test_cookie = 'test cookie';
                    Cookies.set(test_cookie, true, { path: '/' });

                    // browser supports cookie
                    if (Cookies.get(test_cookie)) {

                        // delete the test cookie
                        Cookies.remove(test_cookie);

                        // create a new cookie 
                        Cookies.set(language_cookie, code, { path: '/' });
                    }
                }
                else {
                    // delete the test cookie
                    Cookies.remove(language_cookie);

                    this.setLanguageCookie(code);
                }
            }
        }
    }
</script>