<template>
    <div class="rin-search-filters">
        <BaseFilterButton :selected="filterSelected == 'all'" @click.native="filterClick('all')">All</BaseFilterButton>
        <div class="rin-search-filter-container" v-on:click="filterClick('tournaments')">
            <BaseFilterButton :selected="filterSelected == 'tournaments'">
                Tournaments
            </BaseFilterButton>
        </div>
        <div class="rin-search-filter-container" v-on:click="filterClick('clubleagues')">
            <BaseFilterButton :selected="filterSelected == 'clubleagues'">
                Club leagues
            </BaseFilterButton>
        </div>
        <div class="rin-search-filter-container" v-on:click="filterClick('teamleagues')">
            <BaseFilterButton :selected="filterSelected == 'teamleagues'">
                Team leagues
            </BaseFilterButton>
        </div>
        <div class="rin-search-filter-container" v-on:click="filterClick('players')">
            <BaseFilterButton :selected="filterSelected == 'players'">
                Players
            </BaseFilterButton>
        </div>
        <div class="rin-search-filter-container" v-on:click="filterClick('rankings')">
            <BaseFilterButton :selected="filterSelected == 'rankings'">
                Rankings
            </BaseFilterButton>
        </div>
        <div class="rin-search-filter-container" v-on:click="filterClick('organisations')">
            <BaseFilterButton :selected="filterSelected == 'organisations'">
                Organisations
            </BaseFilterButton>
        </div>
    </div>
</template>
<script>
    import BaseFilterButton from '@/components/rin_new_design/components/base/BaseFilterButton.vue';
    import { mapGetters } from 'vuex';

    export default {
        name: 'SearchFilter',
        components: {
            BaseFilterButton
        },
        computed: {
            ...mapGetters(['layout', 'nomenclatures']),
            filterSelected() {
                return this.$store.getters['search/filter'];
            },
        },
        methods: {
            filterClick(filter) {
                this.$store.commit('search/setFilter', filter);
            }
        }
    };
</script>
