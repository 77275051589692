<template>
	<div class="rin-register-dob-container rin-register-form">
		<h1>{{ translations['register_DateOfBirthHeading'] }}</h1>

		<div class="rin-register-input-container">
			<label class="rin-register-label">{{ translations['register_DateOfBirth'] }}</label>
			<dropdown-datepicker
				:default-date="defaultDate"
				:default-date-format="format"
				:max-date="maxDate"
				:min-date="minDate"
				@input="updateDob"
				:required="true"
				:day-label="null"
				:month-label="null"
				:year-label="null"
				:day-suffix-values="['','','','']"
			></dropdown-datepicker>
		</div>

		<BaseButton type="success" size="m" @click="goToNext" :disabled="isNextDisabled"> {{ translations['shared_Next'] }} </BaseButton>
	</div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations } from 'vuex';

import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
import DropdownDatepicker from 'vue-dropdown-datepicker';

export default {
	name: 'RegisterDateOfBirth',
	components: {
		BaseButton,
		DropdownDatepicker,
	},
	props: ['nextRef'],
	computed: {
		...mapGetters(['translations', 'layout']),
		...mapGetters('register', ['dob']),
		defaultDate() {
			return this.dob ? this.dob : moment().subtract(20, 'years').format(this.format);
		},
		maxDate() {
			return moment().subtract(1, 'days').format(this.format);
		},
		minDate() {
			return moment('1900-01-01', this.format).format(this.format);
		},
	},
	data() {
		return {
			format: 'YYYY-MM-DD',
			isNextDisabled: false,
		};
	},
	methods: {
		...mapMutations('register', ['updateDob']),
		goToNext() {
			if (this.isNextDisabled) return;

			this.nextRef.click();

			this.isNextDisabled = true;
				setTimeout(() => {
							this.isNextDisabled = false;
						}, 500);
		},
	},
	mounted() {
		if (!this.dob) {
			this.updateDob(moment().subtract(20, 'years').format(this.format));
		}
	},
};
</script>
