import drawData from "../../components/spider/drawData";

import vue from "vue";
import vuex from "vuex";
import _ from 'lodash';
import constants from '@/app/constants.js'

vue.use(vuex);

export default {
    state: {
        draws:
            [
                [
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    }
                ],
                [
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks:[]
                    }
                ],
                [
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    }
                ],
                [
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    },
                    {
                        name: "",
                        size: 0,
                        playoffs: null,
                        plates: null,
                        type: null,
                        hasDraw: false,
                        players: [],
                        output: 0,
                        poolsCount: 0,
                        poolsNames: [],
                        links: [],
                        selectedLinks: []
                    }
                ]
            ],
        stage: 0,
        strength: 0,
        fromStrength: 0,
        eventPlayers: [],
        seedList: [],
        needRegeneration: false,
        isPlayerSummaryModalShown: false,
        isValid: true
    },
    mutations: {
        clearDraws(state) {
            for (let strength = 0; strength < state.draws.length; strength++) {
                for (let stage = 0; stage < state.draws[strength].length; stage++) {
                    state.draws[strength][stage].name = "";
                    state.draws[strength][stage].size = 0;
                    state.draws[strength][stage].playoffs = null;
                    state.draws[strength][stage].plates = null;
                    state.draws[strength][stage].type = null;
                    state.draws[strength][stage].hasDraw = false;
                    state.draws[strength][stage].players = [];
                    state.draws[strength][stage].output = 0;
                    state.draws[strength][stage].poolsCount = 0;
                    state.draws[strength][stage].poolsNames = [];
                    state.draws[strength][stage].links = [];
                    state.draws[strength][stage].selectedLinks = [];
                    state.draws[strength][stage].src = "";
                }
            }
        },
        selectDraw(state, payload) {
            state.stage = payload.stage;
            state.strength = payload.strength;
            state.fromStrength = payload.fromStrength;
        },

        updateDraw(state, payload) {

            if (state.draws[payload.strength][payload.stage].type != payload.type ||
                state.draws[payload.strength][payload.stage].size != payload.size ||
                state.draws[payload.strength][payload.stage].playoffs != payload.playoffs ||
                state.draws[payload.strength][payload.stage].plates != payload.plates ||

                state.draws[payload.strength][payload.stage].output != payload.output ||
                state.draws[payload.strength][payload.stage].poolsCount != payload.poolsCount) {

                state.needRegeneration = true;
            }

            vue.set(state.draws[payload.strength][payload.stage], "name", payload.name);
            vue.set(state.draws[payload.strength][payload.stage], "hasDraw", payload.hasDraw);
            vue.set(state.draws[payload.strength][payload.stage], "src", payload.src);
            vue.set(state.draws[payload.strength][payload.stage], "type", payload.type);
            vue.set(state.draws[payload.strength][payload.stage], "size", payload.size);
            vue.set(state.draws[payload.strength][payload.stage], "playoffs", payload.playoffs);
            vue.set(state.draws[payload.strength][payload.stage], "plates", payload.plates);
            vue.set(state.draws[payload.strength][payload.stage], "output", payload.output);
            vue.set(state.draws[payload.strength][payload.stage], "poolsCount", payload.poolsCount);
            vue.set(state.draws[payload.strength][payload.stage], "poolsNames", payload.poolsNames);     
            vue.set(state.draws[payload.strength][payload.stage], "pools", payload.pools);            

        },

        savePlayers(state, payload) {
            state.draws[state.strength][state.stage].players = payload.selectedPlayers;
            state.eventPlayers = payload.availablePlayers;
            state.needRegeneration = true;
        },

        updateDraws(state, payload) {
            state.eventPlayers = [];
            payload.AvailableParticipants.forEach(x => {
                state.eventPlayers.push({
                    Name: x.Name,
                    ParticipantId: x.ParticipantId,
                    InitialOrder: x.InitialOrder,
                    RankingPosition: x.RankingPosition,
                    LowSeed: x.LowSeed,
                    HighSeed: x.HighSeed,
                    IsSelected: false,
                    DrawStrength: x.DrawStrength,
                    DrawStage: x.DrawStage
                });
            });

            state.seedList = [];
            payload.SeedList.forEach(x => {
                state.seedList.push({
                    LowSeed: x.LowSeed,
                    HighSeed: x.HighSeed
                });
            });

            for (let i = 0; i < payload.Draws.length; i++) {

                let draw = payload.Draws[i];

                state.draws[draw.Strength][draw.Stage].hasDraw = true;
                state.draws[draw.Strength][draw.Stage].name = draw.Name;
                state.draws[draw.Strength][draw.Stage].size = draw.Size;
                state.draws[draw.Strength][draw.Stage].playoffs = draw.Playoffs;
                state.draws[draw.Strength][draw.Stage].plates = draw.Plates;
                state.draws[draw.Strength][draw.Stage].output = draw.Output;
                state.draws[draw.Strength][draw.Stage].type = draw.Type;
                state.draws[draw.Strength][draw.Stage].players = draw.Players;
                state.draws[draw.Strength][draw.Stage].poolsNames = draw.PoolsNames;
                state.draws[draw.Strength][draw.Stage].pools = draw.Pools;
                state.draws[draw.Strength][draw.Stage].drawId = draw.Id;


                state.draws[draw.Strength][draw.Stage].poolsCount = draw.PoolsCount;

                state.draws[draw.Strength][draw.Stage].selectedLinks = draw.SelectedLinks;

                state.draws[draw.Strength][draw.Stage].src = drawData.getIcon(draw.Type);
            }
        },

        updateDrawLinks(state, payload) {
            if (typeof (payload.strength) !== 'undefined') {
                state.draws[payload.strength][payload.stage].links = _.map(payload.links, function (a) {
                    a.Strength = payload.strength;
                    a.Stage = payload.stage;
                    return a;
                });
            }
        },

        updateLinkUsage(state, payload) {

            if (payload.isSelected) {
                if (_.findIndex(state.draws[state.strength][state.stage].selectedLinks, function (a) {return a.WinnerPosition == payload.source.WinnerPosition;}) <0)
                    state.draws[state.strength][state.stage].selectedLinks.push(payload.source);
            }
            else {
                const indexToRemove = _.findIndex(state.draws[state.strength][state.stage].selectedLinks, function (a) { return a.WinnerPosition == payload.source.WinnerPosition; });
                if (indexToRemove >= 0) state.draws[state.strength][state.stage].selectedLinks.splice(indexToRemove, 1);
            }
            state.needRegeneration = true;
        },
        clearInvalidUsage(state, payload) {
            for (var strength = 0; strength < state.draws.length; strength++) {
                for (var stage = 0; stage < state.draws[strength].length; stage++) {
                    
                    _.remove(state.draws[strength][stage].selectedLinks, function (a) {
                        return a.Stage == payload.stage && a.Strength == payload.strength && a.WinnerPosition > payload.links.length;
                    });

                    vue.set(state.draws[strength][stage], "selectedLinks", _.clone(state.draws[strength][stage].selectedLinks));
                }
            }

        },
        togglePlayersSummaryModal(state) {
            state.isPlayerSummaryModalShown = !state.isPlayerSummaryModalShown;
        },
        updateDrawSelectedLinksNames(state, payload) {
            for (var strength = 0; strength < state.draws.length; strength++) {
                for (var stage = 0; stage < state.draws[strength].length; stage++) {
                    _.forEach(state.draws[strength][stage].selectedLinks, function (a) {
                        if (a.Stage == payload.stage && a.Strength == payload.strength) {
                            let link = _.find(state.draws[a.Strength][a.Stage].links, function (l) { return l.WinnerPosition == a.WinnerPosition; });
                            if (typeof (link) != 'undefined')
                                a.Name = link.Name;
                        }
                    });
                }
            }
        },
        validate(state) {
            state.isValid = true;

            //check sizes
            for (let strength = 0; strength < state.draws.length; strength++) {
                for (let stage = 0; stage < state.draws[strength].length; stage++) {
                    let selectedPlayersCount = state.draws[strength][stage].players.length;
                    let selectedLinksCount = state.draws[strength][stage].selectedLinks.length;
                    if (state.draws[strength][stage].size - selectedPlayersCount - selectedLinksCount < 0) {
                        state.isValid = false;
                        return;
                    }
                }
            }
            
        }
    },
    actions: {
        selectDraw: ({ commit }, payload) => {
            commit("selectDraw", payload);
        },

        updateDraw: ({ commit }, payload) => {
            commit("updateDraw", payload);
        },

        savePlayers: ({ commit }, payload) => {
            commit("savePlayers", payload);
        },

        updateDraws: ({ commit }, payload) => {
            commit("clearDraws");
            commit("updateDraws", payload);
        },

        updateDrawLinks: ({ commit }, payload) => {
            commit("updateDrawLinks", payload);
            commit("updateDrawSelectedLinksNames", payload);
            commit("clearInvalidUsage", payload);
        },

        updateLinkUsage: ({ commit }, payload) => {
            commit("updateLinkUsage", payload);
        },
        togglePlayersSummaryModal: ({ commit }) => {
            commit("togglePlayersSummaryModal");
        },
        validate: ({ commit }) => {
            commit("validate");
        },
    },
    getters: {
        currentDraw: state => {
            return state.draws[state.strength][state.stage];
        },
        getDraw: state => (strength, stage) => {
            if (state.draws.length > stage && state.draws[stage].length > strength)
                return state.draws[strength][stage];

            return {};
        },
        getDrawLinks: (state, getters) => (strength, stage) => {
            let draw = getters.getDraw(strength, stage);
            return draw.links;
        },
        seedList: state => {
            return state.seedList;
        },
        fromDraw: state => {
            if (state.stage == 0 || typeof state.fromStrength == "undefined")
                return null;

            return state.draws[state.fromStrength][state.stage - 1];
        },
        getDrawFinalPositions: state => {
            var orderedActiveDraws = [];

            if (state.draws[0][1].hasDraw) {
                orderedActiveDraws.push(state.draws[0][1]);
            }

            if (state.draws[1][2].hasDraw) {
                orderedActiveDraws.push(state.draws[1][2]);
            }

            if (state.draws[1][1].hasDraw) {
                orderedActiveDraws.push(state.draws[1][1]);
            }

            if (state.draws[2][2].hasDraw) {
                orderedActiveDraws.push(state.draws[2][2]);
            }

            if (state.draws[2][1].hasDraw) {
                orderedActiveDraws.push(state.draws[2][1]);
            }

            if (state.draws[3][2].hasDraw) {
                orderedActiveDraws.push(state.draws[3][2]);
            }

            if (state.draws[3][1].hasDraw) {
                orderedActiveDraws.push(state.draws[3][1]);
            }

            if (state.draws[0][0].hasDraw) {
                orderedActiveDraws.push(state.draws[0][0]);
            }

            var finalPositions = [];
            let currentFrom = 1;
            let currentTo = 0;

            for (let i = 0; i < orderedActiveDraws.length; i++) {

                if (orderedActiveDraws[i].players.length === 0 &&
                    orderedActiveDraws[i].selectedLinks.length == 0) {

                    finalPositions.push({
                        stage: orderedActiveDraws[i].stage,
                        strength: orderedActiveDraws[i].strength,
                        from: 0,
                        to: 0
                    });

                } else {

                    let qualifiers = orderedActiveDraws[i].selectedLinks.length;

                    currentTo += orderedActiveDraws[i].players.length + qualifiers;

                    finalPositions.push({
                        stage: orderedActiveDraws[i].stage,
                        strength: orderedActiveDraws[i].strength,
                        from: currentFrom,
                        to: currentTo
                    });

                    currentFrom = currentTo + 1;
                }

            }

            return finalPositions;

        },
        getLinksForPreviousDraw: (state, getters) => () => {
            let previousStage = state.stage - 1;
            let fullLinks = [];
            let sourceStrength = -1;
            let sourceStage = -1;
            for (let strength = state.strength; strength >= 0; strength--) {
                for (let stage = previousStage; stage >= 0; stage--) {

                    fullLinks = _.map(state.draws[strength][stage].links, function (source) {
                        let linkTargetIndex = _.findIndex(getters.getDraw(state.strength, state.stage).selectedLinks, function (o) { return o.WinnerPosition == source.WinnerPosition; });
                        let linkTarget = linkTargetIndex >= 0 ? getters.currentDraw : null;

                        return {
                            source: source,
                            target: linkTarget
                        };
                    });
                    if (fullLinks.length > 0) {
                        sourceStrength = strength;
                        sourceStage = stage;
                        break;
                    }
                }
            }

            //set other draw targets
            if (sourceStrength >= 0 && sourceStage >= 0) {
                for (let strength = sourceStrength; strength < constants.SPIDER_MAX_STRENGTH; strength++) {
                    for (let stage = sourceStage + 1; stage < constants.SPIDER_MAX_STAGE; stage++) {
                        if (typeof (getters.getDraw(strength, stage).selectedLinks) == 'undefined') continue;
                        for (var linkIndex = 0; linkIndex < getters.getDraw(strength, stage).selectedLinks.length; linkIndex++) {
                            let linkTarget = getters.getDraw(strength, stage).selectedLinks[linkIndex];
                            if (linkTarget.Strength != sourceStrength
                                || linkTarget.Stage != sourceStage) {
                                continue;
                            }
                            let targetDraw = getters.getDraw(strength, stage);
                            let linkTargetIndex = _.findIndex(getters.getDraw(sourceStrength, sourceStage).links, function (o) {
                                return o.WinnerPosition == linkTarget.WinnerPosition;
                            });
                            if (linkTargetIndex >= 0) {
                                let link = _.find(fullLinks, function (o) { return o.source.WinnerPosition == linkTarget.WinnerPosition });
                                link.target = targetDraw;
                            }
                        }
                    }
                }
            }

            return _.sortBy(fullLinks, function (o) { return o.source.WinnerPosition; });
        },
        getUsedLinksForCurrentDraw: (state, getters) => () => {
            let allLinks = getters.getLinksForPreviousDraw();
            if (typeof (allLinks) == 'undefined') return [];

            let usedLinks = _.filter(allLinks, function (link) {
                return link.target != null && link.target.strength == state.strength && link.target.stage == state.stage;
            });

            return usedLinks;
        },
        getIflayerSummaryModalShown: state => {
            return state.isPlayerSummaryModalShown;
        },
        getIsValid: state => () => {           
            return state.isValid;
        }



    }
}