<template>
	<footer>
		<div class="wrap">
			<div class="row jcc jcb-lg">
				<div class="col-12 col-md p-0">
					<div class="row">
						<div class="col-12 col-sm-6 col-md-4 col-lg-3">
							<h3>Rankedin</h3>
							<h4>
								<a
									data-toggle="collapse"
									href="#list01"
									role="button"
									aria-expanded="false"
									aria-controls="list01"
									><span class="icon icon-plus"></span> Rankedin</a
								>
							</h4>
							<div class="collapse" id="list01" data-parent="footer">
								<ul>
									<li>
										<router-link v-bind:to="'/' + languageCode + '/content/shownews/about-us'">{{
											translations['footer_1']
										}}</router-link>
									</li>

									<li>
										<router-link
											v-bind:to="'/' + languageCode + '/content/shownews/rankedin-for-players'"
											>{{ translations['footer_2'] }}</router-link
										>
									</li>
									<li>
										<router-link
											v-bind:to="'/' + languageCode + '/content/shownews/rankedin-for-clubs'"
											>{{ translations['footer_3'] }}</router-link
										>
									</li>
									<li>
										<a
											href="https://cdn.rankedin.com/content/RI_presentation_for_CLUB_LEAGUE.pdf"
											>{{ translations['footer_ClubLeague'] }}</a
										>
									</li>
									<li>
										<a href="https://rankedin.ladesk.com/" target="_blank">{{
											translations['footer_8']
										}}</a>
									</li>
									<li>
										<a href="https://rankedin.ladesk.com/774135-13-FAQ" target="_blank">{{
											translations['footer_4']
										}}</a>
									</li>
									<li v-if="!isIOSorAndroidUserDeviceRin">
										<router-link v-bind:to="'/' + languageCode + '/pricing'">{{
											translations['shared_Menu_Pricing']
										}}</router-link>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-4 col-lg-3">
							<h3>General</h3>
							<h4>
								<a
									data-toggle="collapse"
									href="#list02"
									role="button"
									aria-expanded="false"
									aria-controls="list02"
									><span class="icon icon-plus"></span> General</a
								>
							</h4>
							<div class="collapse" id="list02" data-parent="footer">
								<ul>
									<li>
										<router-link v-bind:to="'/' + languageCode + '/termsandconditions'" target="_blank">{{
											translations['footer_5']
										}}</router-link>
									</li>
									<li>
										<router-link v-bind:to="'/' + languageCode + '/dataprotection'">{{
											translations['landingPage_BS4_DataProtection']
										}}</router-link>
									</li>
									<li>
										<router-link v-bind:to="'/' + languageCode + '/privacypolicy'" target="_blank">{{
											translations['footer_6']
										}}</router-link>
									</li>
									<li>
										<router-link v-bind:to="'/' + languageCode + '/home/cookiepolicy'" target="_blank">{{
											translations['landingPage_BS4_CookiePolicy']
										}}</router-link>
									</li>
									<li>
										<router-link v-bind:to="'/' + languageCode + '/content/shownews/federations'">{{
											translations['shared_T18']
										}}</router-link>
									</li>
									<li>
										<a
											href="https://rankedin.ladesk.com/446847-Changelog---Release-Notes---Rankedin-"
											>{{ translations['footer_changelog'] }}</a
										>
									</li>
								</ul>
							</div>
						</div>
						<div class="col-12 col-sm-6 col-md-4 col-lg-3">
							<h3>{{ translations['landingPage_BS4_Office'] }}</h3>
							<h4>
								<a
									data-toggle="collapse"
									href="#list03"
									role="button"
									aria-expanded="false"
									aria-controls="list03"
									><span class="icon icon-plus"></span>
									{{ translations['landingPage_BS4_Office'] }}</a
								>
							</h4>
							<div class="collapse" id="list03" data-parent="footer">
								<p>Rankedin AS <br />Lysaker Torg 8 <br />1366 Lysaker <br />Norway</p>
								<p><a href="mailto:info@rankedin.com">info@rankedin.com</a></p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-12 col-md-4 col-lg-3 order-md-12 text-md-right">
					<h3>{{ translations['landingPage_BS4_SupportCapital'] }}</h3>
					<h4>
						<a data-toggle="collapse" href="#list04" aria-expanded="false" aria-controls="list04"
							><span class="icon icon-plus"></span>
							{{ translations['landingPage_BS4_SupportCapital'] }}</a
						>
					</h4>
					<div class="collapse" id="list04" data-parent="footer">
						<div class="support">
							<a href="mailto:support@rankedin.com">support@rankedin.com</a>
						</div>
					</div>
					<div class="row jcb jce-md">
						<div class="copy col-auto col-md-12 order-md-12 p-0">
							<p>
								copyright {{ yearNow }} rankedin.com <br />
								All rights reserved
							</p>
						</div>
						<div class="social col-auto col-md-12 p-0">
							<a href="https://www.facebook.com/rankedin" target="_blank" title="fb"
								><span class="icon icon-fb"></span
							></a>
							<a href="https://twitter.com/RankedinApp" target="_blank" title="tw"
								><span class="icon icon-tw"></span
							></a>
							<a href="https://www.instagram.com/rankedin.app/" target="_blank" title="in"
								><span class="icon icon-in"></span
							></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
	name: 'DesktopFooter',
	data() {
		return {
			languageCode: 'en',
		};
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
		isIOSorAndroidUserDeviceRin: function () {
			return this.nomenclatures.UserDeviceRin == 'ios' || this.nomenclatures.UserDeviceRin == 'android';
		},
		yearNow() {
			return moment.utc().year();
		},
	},
	mounted() {
		if (typeof this.layout !== 'undefined') this.languageCode = this.layout.language;

		if (this.languageCode.length < 2) {
			try {
				var langCodeFromUrl = window.location.pathname.split('/')[1];
				if (langCodeFromUrl.length > 1) {
					this.languageCode = langCodeFromUrl;
				} else {
					this.languageCode = 'en';
				}
			} catch (e) {
				this.languageCode = 'en';
				console.error('Language error. Language set to default: en');
			}
		}
	},
};
</script>
