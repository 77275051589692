<template>   
    <nav role="navigation" class="navbar navbar-default navbar-logged-in -navbar-fixed-top">
            <div class="container-fluid">
                <div class="navbar-header pull-left">
                    <a class="navbar-brand" v-bind:href="data.PlayerProfileUrl"></a>
                </div>
                <div class="navbar-header pull-right">
                    <ul class="nav navbar-nav navbar-static pull-left navbarList">
                        <li class="visible-xs">
                            <a v-bind:href="'/' + layout.language + '/referee/refereeboard'"><i class="fa fa-fw ti-mobile"></i></a>
                        </li>
                        <li class="visible-xs">
                            <a v-bind:href="'/' + layout.language + '/messages'"><i class="fa fa-fw ti-comment"></i></a>
                            <span v-if="data.MessagesCount > 0" class="badge badge-notification progress-bar-danger">{{data.MessagesCount}}</span>                            
                        </li>
                        <li id="bell">
                            <a v-bind:href="'/' + layout.language + '/notifications'"><i class="fa fa-fw ti-bell"></i></a>                          
                            <span v-if="data.NotificationsCount>0" class="badge badge-notification progress-bar-danger text-center">{{data.NotificationsCount}}</span>                            
                        </li>
                        <li id="my-ranked" class="dropdown hidden-xs">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><i class="fa fa-fw ti-user"></i><span class="caret"></span></a>
                            <ul class="dropdown-menu dropdown-menu-myranked" role="menu" style="right:0; left:auto">
                                <li><a v-bind:href="data.PlayerProfileUrl">{{translations["shared_T22"]}}</a></li>
                                <li role="separator" class="divider"></li>
                                <li><a v-bind:href="'/' + layout.language + '/player/manage'">{{translations["shared_T23"]}}</a></li>
                                <li><a v-bind:href="'/' + layout.language + '/player/organizations'">{{translations["shared_T24"]}}</a></li>

                                <template v-if="isAdminLocal === 'true'">
                                    <li role="separator" class="divider"></li>
                                    <li><a v-bind:href="'/' + layout.language + '/statistics'">{{translations["shared_Events_AdminPanel"]}}</a></li>
                                </template>
                                
                                <li role="separator" class="divider"></li>
                                <li role="presentation" class="dropdown dropdown-nested">
                                    <a id="changeLang7" href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><i v-bind:class="'flag-icon flag-icon-' + countryflagCode"></i>
                                    <p class="hidden-sm navButton">{{translations["shared_ContentEditor_Language"]}}</p><span class="caret"></span></a>
                                    <ul class="dropdown-menu" role="menu" aria-labelledby="changeLang7">
                                        <language-switch-items></language-switch-items>
                                    </ul>
                                </li>
                                <li role="separator" class="divider"></li>
                                <li><a v-bind:href="'/' + layout.language + '/settings'">{{translations["player_Settings"]}}</a></li>
                                <li><a v-bind:href="'/' + layout.language + '/account/logoff'"><i class="fa fa-fw fa-sign-out fa-rotate-180" aria-hidden="true"></i> {{translations["landing_6"]}}</a></li>
                            </ul>
                        </li>
                    </ul>
                    <button data-toggle="collapse" data-target=".navbar-collapse" class="navbar-toggle navbarMobileDropdown"><span class="sr-only">{{translations["shared_T25"]}}</span><i class="fa fa-bars"></i></button>
                </div>               
                <search></search>
                <div class="collapse navbar-collapse navbar-left navbarDropdown">
                    <ul class="nav navbar-nav navbar-iconic pull-right">
                        <li class="dropdown visible-xs"><a v-bind:href="data.PlayerProfileUrl"><i class="fa fa-fw ti-user"></i><p class="navButton">{{translations["shared_T22"]}}</p></a></li>
                        <li class="dropdown visible-xs"><a v-bind:href="'/' + layout.language + '/player/manage'"><i class="fa fa-fw ti-flag-alt-2"></i><p class="navButton">{{translations["shared_T23"]}}</p></a></li>
                        <li class="dropdown visible-xs"><a v-bind:href="'/' + layout.language + '/player/manage?tab=organisations'"><i class="fa fa-fw ti-flag-alt-2"></i><p class="navButton">{{translations["shared_T24"]}}</p></a></li>
                        <li id="create" class="dropdown">
                            <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false"><i class="fa fa-fw ti-plus"></i><span class="caret"></span><p class="hidden-sm navButton" style="margin-left:5px">{{translations["topBar_2"]}}</p></a>
                            <ul class="dropdown-menu dropdown-menu-create" role="menu" style="padding-top: 15px;">
                                <div class="text-right">
                                    <a class="bold btn btn-primary btn-sm" href="https://rankedin.ladesk.com/" target="_blank">{{translations["shared_Menu_UserManual"]}}</a>
                                </div>
                                <li>
                                    <h2 class="h4 text-uppercase">{{translations["topBar_4"]}}<br /> </h2>
                                    <div class="container-controls">
                                        <div class="row">
                                            <div class="col-xs-6">
                                                <button type="button" class="btn btn-orange col-xs-12 ranking-id" data-toggle="modal" data-target="#createEventModal" v-bind:data-event-name="translations['topBar_8']" data-event-type="tournament" >{{translations["topBar_8"]}}</button>
                                            </div>

                                            <div class="col-xs-6">
                                                <button type="button" class="btn btn-info col-xs-12" data-toggle="modal" data-target="#createEventModal" data-event-name="Team League" data-event-type="teamleague">{{translations["shared_EventType_TeamLeague"]}}</button>
                                            </div>
                                        </div>
                                        <hr style="margin:5px 0" />
                                        <div class="row">
                                            <div class="col-xs-6">
                                                <button type="button" class="btn btn-dark col-xs-12" data-toggle="modal" data-target="#createEventModal" v-bind:data-event-name="translations['topBar_7']" data-event-type="clubleague">{{translations["topBar_7"]}}</button>
                                            </div>
                                            <div class="col-xs-6">
                                                <a class="btn btn-info col-xs-12" v-bind:href="'/'+layout.language + '/americano/create'">{{translations["layoutHeader_Americano_Create"]}}</a>
                                            </div>
                                        </div>
                                        <hr style="margin:5px 0" />

                                    </div>
                                    <h2 class="h4 text-uppercase">{{translations["shared_T13"]}}</h2>
                                    <div class="row">
                                        <div class="col-xs-6">
                                            <button type="button" class="btn btn-yellow col-xs-12" data-toggle="modal" data-target="#createEventModal" v-bind:data-event-name="translations['topBar_11']" data-event-type="organisation">{{translations["topBar_11"]}}</button>
                                        </div>
                                        <div class="col-xs-6">
                                            <button type="button" class="btn btn-yellow col-xs-12" data-toggle="modal" data-target="#createEventModal" v-bind:data-event-name="translations['topBar_31']" data-event-type="ranking">{{translations["topBar_31"]}}</button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li id="club" class="dropdown"><a v-bind:href="data.HomeClubUrl"><i class="fa fa-fw ti-flag-alt"></i><p class="hidden-sm navButton">{{translations["clubsViewPartial_Club"]}}</p></a></li>
                        <li id="rankings" class="dropdown"><a v-bind:href="data.HomeRankingUrl"><i class="fa fa-fw ti-cup"></i><p class="hidden-sm navButton">{{translations["shared_Rankings"]}}</p></a></li>
                        <li id="calendar" class="dropdown"><a v-bind:href="'/' + layout.language + '/calendar'"><i class="fa fa-fw ti-calendar"></i><p class="hidden-sm navButton">{{translations["shared_Menu_Calendar"]}}</p></a></li>
                        <li class="dropdown hidden-xs"><a v-bind:href="'/' + layout.language + '/referee/refereeboard'" class="nav-space"><i class="fa fa-fw ti-mobile"></i><p class="hidden-sm navButton">{{translations['shared_Menu_EReferee']}}</p></a></li>
                        <li id="messages" class="dropdown hidden-xs">
                            <a v-bind:href="'/' + layout.language + '/messages'"><i class="fa fa-fw ti-comment"></i><p class="hidden-sm navButton">{{translations["shared_T28"]}}</p></a>
                            <span v-if="data.MessagesCount > 0" class="badge badge-notification progress-bar-danger">{{data.MessagesCount}}</span>                            
                        </li>
                     
                        <template v-if="isAdminLocal === 'true'">
                            <li role="separator" class="divider"></li>
                            <li class="dropdown visible-xs"><a v-bind:href="'/' + layout.language + '/statistics'"><i class="fa fa-fw ti-desktop"></i><p class="navButton">{{translations["shared_Events_AdminPanel"]}}</p></a></li>
                        </template>
                        
                        <li class="dropdown visible-xs" role="presentation">
                            <a href="#" class="dropdown-toggle text-right" data-toggle="dropdown" role="button" aria-expanded="false"><i v-bind:class="'flag-icon flag-icon-' + countryflagCode"></i><span class="caret"></span><p class="hidden-sm navButton" style="margin-left:5px">{{translations["shared_ContentEditor_Language"]}}</p></a>
                            <ul class="dropdown-menu" role="menu">
                                <language-switch-items></language-switch-items>
                            </ul>
                        </li>
                        <li class="dropdown visible-xs"><a v-bind:href="'/' + layout.language + '/settings'"><i class="fa fa-fw ti-settings"></i><p class="navButton">{{translations["player_Settings"]}}</p></a></li>
                        <li class="dropdown visible-xs"><a v-bind:href="'/' + layout.language + '/account/logoff'"><i class="fa fa-fw fa-sign-out fa-rotate-180" aria-hidden="true"></i><p class="hidden-sm navButton">{{translations["landing_6"]}}</p></a></li>
                    </ul>
                </div>
            </div>
        </nav>
    
</template>
<script>
    var rankedin = rankedin || {};

    import { mapGetters } from 'vuex';
    import languageSwitchItems from '@/components/layout/top-bar/language-switch-items';
    import search from '@/components/layout/search/search-bs3';

    export default {
        components: {
            'language-switch-items': languageSwitchItems,
            'search': search,
        },
        data() {
            return {
                data: {
                    PlayerTeams: { TeamsCount: 0, Teams: [] },
                    PlayerProfileUrl: ''
                },                
                isAdminLocal: false,                
                isLoaded: false
            }
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            countryflagCode() {

                switch (this.layout.language) {
                    case 'en':
                        return 'gb';
                    case 'sr':
                        return 'rs';
                    default: return this.layout.language
                }
            }
        },
        mounted() {
            var _this = this;            
            _this.isAdminLocal = isAdmin;            
            this.$http.get('/topnavigation/GetTopNavigationJson')
                .then(res => {
                    _this.data = res.data;
                    _this.isLoaded = true;
                });

            $('#changeLang7').on('click', function (e) {
                $(this).next('ul').toggle();
                e.stopPropagation();
                e.preventDefault();
            });
        },
        methods: {
        }
    }
</script>


<style>
    .dropdown-menu > div > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.42857143;
        color: #00435e;
        white-space: nowrap;
    }
</style>

<style scoped>

    @media (max-width:767px) {

        .navbar-logged-in .navbar-static > li {
            margin-left: 0;
            margin-right: 0;
        }
    }



    .dropdown-nested {
        white-space: nowrap;
    }

        .dropdown-nested .navButton {
            display: inline-block !important;
        }

        .dropdown-nested .dropdown-menu {
            border: none;
            box-shadow: none;
            position: relative;
        }



    [v-cloak] {
        display: none;
    }

</style>
