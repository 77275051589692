import axios from 'axios';
import spaEventBus from "@/app/eventBus";
import * as signalR from '@microsoft/signalr'


export default {
    install(Vue, options) {
        this.store = options.store;

        Vue.prototype.$signalRClient = this;

        spaEventBus.$on('loginOrLogff', () => {
            this.connect();
        });
    },

    store: null,
    connection: '',
    notificationCounter: 0,

    nomenclatures() {
        return this.store.getters.nomenclatures;
    },
    layout() {
        return this.store.getters.layout;
    },

    connect() {        

        if (!this.shouldUseSignalR()) return;

        const apiBaseUrl = this.nomenclatures().SignalRFunctionDomain;
        //const apiBaseUrl = 'http://localhost:7034';
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(apiBaseUrl + '/api/web')
            .configureLogging(signalR.LogLevel.Information)
            .withAutomaticReconnect()
            .build();

        this.connection.on('newMessage', (message) => {
            //console.log(message);
            this.notificationCounter = message.MatchNotificationsCount;
            this.store.dispatch('updatenotificationsCount', this.notificationCounter);
        });

        this.connection.onclose(function () {
            console.log('SignalR disconnected')
        })
        this.connection.onreconnecting(err =>
            console.log('err, reconnecting...', err)
        )

        this.start();
    },

    disconnect() {
        this.connection.stop().then(() => console.log('SignalR disconnected due to logoff.'));
    },

    start() {
        //connect
        this.connection.start()
            .then(() => {
                console.log('SignalR connected!');
                this.getNotifications();
            })
            .catch(error => {
                console.log('SignalR connecting failed!');
                console.log(error);
            })
    },
    getNotifications: function () {
        axios.get(`${this.nomenclatures().ApiDomain}/notifications/GetPlayerMatchNotificationsCountAsync`).then((res) => {
            this.store.dispatch('updatenotificationsCount', res.data.count);
        })
    },
    shouldUseSignalR: function () {
        if (!this.nomenclatures().Domain)
            return false;

        return (this.nomenclatures().Environment == 'test' || this.nomenclatures().Environment == 'staging' || this.nomenclatures().Environment == 'prod' || this.nomenclatures().Environment == 'local')
            && this.layout().player.isAuthenticated
            && (this.store.getters['monetization/isProPlayer'] || this.store.getters['monetization/isJoinedInPremiumEvent']);
    },
   


}
