<template>
    <span :class="`rin-type-label ${type}`">
        {{ text(isShortLabel) }}
        <span v-if="isShortLabel" class="tooltiptext" :class="`rin-type-label ${type}`">
            {{text(false)}}
        </span>

    </span>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
	name: 'BaseTypeLabel',
	props: {
		type: {
			type: String,
			required: true,
			validator: (v) =>
				[
					'tournament',
					'clubleague',
					'teamleague',
					'player',
					'organisation',
					'ranking',
					'team',
					'americano',
					'organization',
					'playerprofile',
					'notapproved',
					'approved',
					'invite',
					'add',
					'fake',
					'pro',
					'pro-invert',
					'notpro',
					'popular',
					'premium-invert',
					'premium',
					'current_plan',
					'license-private',
					'license-active',
					'license-inactive',
					'state-active',
					'state-finished',
					'state-canceled',
					'state-signupclosed',
					'state-upcoming',
					'state-generatingmatches',
					'state-matchesgenerated',
					'privatematch'
				].indexOf(v) > -1,
		},
		isShortLabel: {
            type: Boolean,
            default: false,
        },
	},
	computed: {
		...mapGetters(['translations']),
		},
		methods: {
            text(isShortLabel) {
                switch (this.type) {
                    case 'tournament':
                        if (isShortLabel) return 'TN';
                        return this.translations['shared_EventType_Tournament'].toLowerCase();
                    case 'clubleague':
                        if (isShortLabel) return 'CL';
                        return this.translations['shared_EventType_ClubLeague'].toLowerCase();
                    case 'teamleague':
                        if (isShortLabel) return 'TL';
                        return this.translations['shared_EventType_TeamLeague'].toLowerCase();
                    case 'player':
                        return this.translations['shared_BookmarkType_Player'].toLowerCase();
                    case 'organisation':
                        return this.translations['shared_EventType_Organization'].toLowerCase();
                    case 'organization':
                        return this.translations['shared_BookmarkType_Organization'].toLowerCase();
                    case 'ranking':
                        return this.translations['shared_EventType_Ranking'].toLowerCase();
                    case 'team':
                        return this.translations['shared_BookmarkType_Team'].toLowerCase();
                    case 'americano':
                        return this.translations['shared_FrontEndEventTypeEnum_Americano'].toLowerCase();
                    case 'playerprofile':
                        return this.translations['shared_BookmarkType_Player'].toLowerCase();
                    case 'notapproved':
                        return this.translations[
                            'tournament_CloseSignInLevelSelectingPartial_TableBodyNotApproved'
                        ].toLowerCase();
                    case 'approved':
                        return this.translations[
                            'tournament_CloseSignInLevelSelectingPartial_TableBodyApproved'
                        ].toLowerCase();
                    case 'invite':
                        return this.translations['addPlayers_Invite'].toLowerCase();
                    case 'add':
                        return this.translations['addPlayers_Add'].toLowerCase();
                    case 'fake':
                        return this.translations['addPlayers_Fake'].toLowerCase();
                    case 'pro':
                    case 'pro-invert':
                    case 'notpro':
                        return 'pro'.toUpperCase();
                    case 'premium-invert':
                    case 'premium':
                        return 'premium'.toUpperCase();
                    case 'popular':
                        return this.translations['monetization_Player_ModalPlanPopular'].toLowerCase();
                    case 'current_plan':
                        return this.translations['monetization_Tournament_CurrentPlan'].toLowerCase();
                    case 'license-private':
                        return this.translations['playerProfile_Private'].toLowerCase();
                    case 'license-active':
                        return this.translations['playerProfile_MembershipStatusActive'].toLowerCase();
                    case 'license-inactive':
                        return this.translations['playerProfile_MembershipStatusInactive'].toLowerCase();
                    case 'state-active':
                        return this.translations['shared_Events_Active'].toLowerCase();
                    case 'state-upcoming':
                        return this.translations['shared_Events_Upcoming'].toLowerCase();
                    case 'state-finished':
                        return this.translations['shared_Events_Finished'].toLowerCase();
                    case 'state-canceled':
                        return this.translations['shared_Events_Canceled'].toLowerCase();
                    case 'state-signupclosed':
                        return this.translations['shared_Events_Upcoming'].toLowerCase();
                    case 'state-generatingmatches':
                        return this.translations['shared_Events_Upcoming'].toLowerCase();
                    case 'state-matchesgenerated':
                        return this.translations['shared_Events_Upcoming'].toLowerCase();
                    case 'privatematch':
                        return this.translations['shared_PrivateMatch'].toLowerCase();
                    default:
                        return '';
                }
            },
		}
};
</script>

<style scoped>
    .tooltiptext{
		right: 0;
    }
</style>