<template>
	<div class="rin-register-sport rin-register-form">
		<h1>Pick your sport</h1>

		<BaseDropdown buttonSize="m" buttonType="tertiary" :class="{ 'rin-concrete-selection': sport }">
			<template v-slot:button>
				<span>
					<i v-bind:class="nomenclatures.SportIconClass[sport - 1]"></i>
					{{ selectedSportDescription }}
				</span>
			</template>
			<template v-slot:content>
				<li v-for="sport in sports" :key="sport.Id" @click="updateSport(sport.Id)">
					<i v-bind:class="nomenclatures.SportIconClass[sport.Id - 1]"></i>
					{{ sport.Description }}
				</li>
			</template>
		</BaseDropdown>

		<div v-cloak>
			<BaseAlert type="danger" :has-background="false">
				<span v-if="triggerRequiredError" class="rin-register-error">{{ translations['register_2'] }}</span>
			</BaseAlert>
		</div>

		<BaseButton type="success" size="m" @click="goToNext" :disabled="isNextDisabled"> Next </BaseButton>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
import BaseDropdown from '@/components/rin_new_design/components/base/BaseDropdown';

export default {
	name: 'RegisterSport',
	components: {
		BaseAlert,
		BaseButton,
		BaseDropdown,
	},
	props: ['nextRef'],
	computed: {
		...mapGetters(['translations', 'layout', 'nomenclatures']),
		...mapGetters('register', ['sport']),
		selectedSportDescription() {
			return this.sports.find((item) => item.Id == this.sport).Description;
		},
		sports() {
			const res = [];
			res.push({ Id: 0, Description: this.translations['shared_Select'] });
			for (const item in this.nomenclatures.SportsArray) {
				res.push({
					Id: this.nomenclatures.SportsArray[item],
					Description: item,
				});
			}

			return res;
		},
	},
	data() {
		return {
			countriesList: [],
			filterCountriesInput: '',
			triggerRequiredError: false,
			isNextDisabled: false,
		};
	},
	methods: {
		...mapMutations('register', ['updateSport']),
		goToNext() {
			if (this.isNextDisabled) return;

			if (this.sport > 0) {
				this.triggerRequiredError = false;
				this.nextRef.click();

				this.isNextDisabled = true;
				setTimeout(() => {
							this.isNextDisabled = false;
						}, 500);
			} else {
				this.triggerRequiredError = true;
			}
		},
	},
	mounted() {
		this.countriesList = this.nomenclatures.Countries.filter((c) => c.code > 0);
	},
};
</script>
