<template>
    <div class="google-ad-bottom-banner" v-if="shouldShowAds">

        <template v-if="isGoogleAdsEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info"> {{ translations['shared_Ads_Label'] }}</p>
            <!-- Rankedin Banner Ad Unit -->
            <div v-bind:class="{'ad-border': !isProd}" :key="refreshKey">
                <Adsense data-ad-client="ca-pub-2431348456451065"
                            data-ad-slot="4105067201"
                            ins-style="display:inline-block;width:100%;height:50px"
                            data-ad-format="fluid">
                </Adsense>
            </div>
        </template>

        <template v-if="isPubliftEnabled">
            <HideAdsBtn class="hide-ads" />
            <p class="advertisement-info">{{ translations['shared_Ads_Label'] }}</p>
            <!-- GAM 71161633/RNKDN_rankedin/footer -->
            <div style="min-height: 50px; max-height: 50px;" id="ad-mobile-bottom-banner" data-fuse="23203968655"></div>
        </template>
      
    </div>
</template>

<script>
    import spaEventBus from "@/app/eventBus";

    import { mapGetters } from 'vuex';
    import HideAdsBtn from '@/components/rin_new_design/components/GoogleAds/HideAdsButton.vue';

    export default {
        name: 'BottomBanner',
        components: {
            HideAdsBtn
        },
        data() {
            return {
                refreshKey: 1,
            }
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['shouldShowAds']),

            isProd() {
                return this.nomenclatures.Environment == 'prod';
            },

            isPubliftEnabled() {
                return this.nomenclatures.config.isPubliftEnabled;
            },
            isGoogleAdsEnabled() {
                return this.nomenclatures.config.isGoogleAdsEnabled;
            }
        },
        mounted() {
            if (this.isPubliftEnabled) {
                let fusetag = window.fusetag || (window.fusetag = { que: [] });
                fusetag.que.push(function () {
                    fusetag.registerZone('ad-mobile-bottom-banner');
                });
            }

            spaEventBus.$on('refreshGoogleAd', () => {
                this.refreshKey += 1;
            });
        }
    }
</script>

<style scoped>
    .google-ad-bottom-banner {
        width: 100%;
        background-color: white;
    }

    .advertisement-info {
        margin-bottom: 2px;
        font-size: 12px;
        color: grey;
        text-align: right;
        margin-right: 10px;
    }

    .hide-ads {
        position: absolute;
    }

    #ad-mobile-bottom-banner {
        min-height: 50px;
        max-height: 50px;
        overflow: hidden;
    }

    .adswrapper {
        max-height: 50px !important;
    }

    .ad-border {
        border: 1px solid #e0e0e0;
        border-radius: 5px;
        background-image: url("https://cdn.rankedin.com/images/rin_logo_sm.png");
        background-size: 50px 50px;
    }
</style>