import axios from 'axios';

export default {
    namespaced: true,
    state: {
        term: '',
        state: 'closed',
        filter: 'all',
        tournaments: [],
        clubleagues: [],
        teamleagues: [],
        players: [],
        rankings: [],
        organisations: [],
        isloading: {
            tournaments: false,
            clubleagues: false,
            teamleagues: false,
            participants: false,
            rankings: false,
            organisations: false
        }
    },
    mutations: {
        toggle(state, payload) {
            if (payload === false) {
                state.state = 'closed';
                state.term = '';
                state.tournaments = [];
                state.clubleagues = [];
                state.teamleagues = [];
                state.players = [];
                state.rankings = [];
                state.organisations = [];
            } else if (state.state === 'closed') {
                state.state = 'history';
            }
        },
        clearResults(state) {
            state.tournaments = [];
            state.clubleagues = [];
            state.teamleagues = [];
            state.players = [];
            state.rankings = [];
            state.organisations = [];
        },
        setTerm(state, payload) {
            state.term = payload;
        },
        setState(state, payload) {
            state.state = payload;
        },
        setTournaments(state, payload) {
            state.tournaments = [...state.tournaments, ...payload];
        },
        setClubleagues(state, payload) {
            state.clubleagues = [...state.clubleagues, ...payload];
        },
        setTeamleagues(state, payload) {
            state.teamleagues = [...state.teamleagues, ...payload];
        },
        setParticipants(state, payload) {
            state.players = [...state.players, ...payload];
        },
        setRankings(state, payload) {
            state.rankings = [...state.rankings, ...payload];
        },
        setOrganisations(state, payload) {
            state.organisations = [...state.organisations, ...payload];
        },
        setFilter(state, payload) {
            state.filter = payload;
        },
        setLoading(state, payload) {
            state.isloading[payload.type] = payload.loading;
        }
    },
    actions: {
        find({ commit, rootState }, payload) {
            commit('setState', 'loading');
            commit('setTerm', payload.term);
            const nomenclatures = rootState.nomenclatures.nomenclatures;
            axios(
                `${nomenclatures.ApiDomain}/search/GlobalSearchAsync?term=${payload.term}&language=${payload.language}`
            ).then((res) => {
                commit('clearResults');
                commit('setTournaments', res.data.Tournaments);
                commit('setClubleagues', res.data.ClubLeagues);
                commit('setTeamleagues', res.data.TeamLeagues);
                commit('setParticipants', res.data.Participants);
                commit('setRankings', res.data.Rankings);
                commit('setOrganisations', res.data.Organisation);

                const hasResults =
                    res.data.Tournaments.length > 0 ||
                    res.data.ClubLeagues.length > 0 ||
                    res.data.TeamLeagues.length > 0 ||
                    res.data.Participants.length > 0 ||
                    res.data.Rankings.length > 0 ||
                    res.data.Organisation.length > 0;

                if (hasResults) commit('setState', 'results');
                if (!hasResults) commit('setState', 'noresults');
            });
        },
        loadMore({ commit, state, rootState }, payload) {
            commit('setLoading', { type: payload.type.toLowerCase(), loading: true });
            const nomenclatures = rootState.nomenclatures.nomenclatures;

            var actionName = '';
            switch (payload.type) {
                case 'Tournaments':
                    actionName = 'GetTournamentsAsync';
                    break;
                case 'Clubleagues':
                    actionName = 'GetClubLeaguesAsync';
                    break;
                case 'Teamleagues':
                    actionName = 'GetTeamLeaguesAsync';
                    break;
                case 'Participants':
                    actionName = 'GetParticipantsAsync';
                    break;
                case 'Rankings':
                    actionName = 'GetRankingsAsync';
                    break;
                case 'Organisations':
                    actionName = 'GetOrganisationsAsync';
                    break;
            }            
             

            axios(
                `${nomenclatures.ApiDomain}/search/${actionName}?term=${state.term}&language=${payload.language}&take=${payload.take}&skip=${payload.skip}`
            ).then((res) => {
                const mutation = `set${payload.type}`;
                commit(mutation, res.data);
                commit('setLoading', { type: payload.type.toLowerCase(), loading: false });
            });
        },
    },
    getters: {
        term(state) {
            return state.term;
        },
        state(state) {
            return state.state;
        },
        isShown(state) {
            return state.state != 'closed';
        },
        tournaments(state) {
            return state.tournaments;
        },
        clubleagues(state) {
            return state.clubleagues;
        },
        teamleagues(state) {
            return state.teamleagues;
        },
        players(state) {
            return state.players;
        },
        rankings(state) {
            return state.rankings;
        },
        organisations(state) {
            return state.organisations;
        },
        filter(state) {
            return state.filter;
        },
        isloading(state) {
            return state.isloading;
        }
    },
};
