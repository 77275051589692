<template>
    <div v-if="!isLoading">
        <slot name="title"></slot>
        <div class="iadmin-container">
            <Card v-for="entry in model" :key="entry.$id" :type="'secondary'">
                <template v-slot:title>
                    <div class="event-card-header">
                        <div>
                            <Label :type="entry.label"></Label>

                            <br />
                            <span class="header-text">
                                {{entry.title}}
                            </span>
                        </div>
                    </div>
                </template>
                <template v-if="entry.Payload.length > 0" v-slot:body>
                    <hr />
                    <div class="event-wrapper" v-for="event in entry.Payload" :key="entry.label+event.Id">
                        <template v-if="entry.label === 'americano'">
                            <div class="collapse-toggle">
                                <router-link class="americano-link" v-bind:to="event.PublicUrl"><span>{{event.Description}}</span></router-link>
                            </div>
                            <hr />
                        </template>
                        <template v-else>
                            <div @click="toggleCollapse(event)" class="collapse-toggle">
                                <span>{{event.Description}}<StarIndicator size="m" v-if="!isTournamentKillSwitchActive && event.IsPremium"></StarIndicator></span><span v-bind:class="{'icon icon-v' : !event.isCollapsed, 'icon icon-v collapsed' : event.isCollapsed}"></span>
                            </div>
                            <div v-bind:class="{'collapse-body-collapsed' : event.isCollapsed, 'collapse-body' : !event.isCollapsed}">
                                <router-link class="event-link-public" v-bind:to="event.PublicUrl">{{translations['iAdmin_PublicPage']}}</router-link>
                                <br />
                                <router-link class="event-link-admin" v-bind:to="event.AdminUrl">{{translations['iAdmin_AdminPage']}}&nbsp;<span class="icon icon-pencil"></span></router-link>
                            </div>
                            <hr />
                        </template>
                    </div>
                </template>
                <template v-if="entry.Payload.length === 0" v-slot:header>
                    <Alert :type="'info'">
                        {{translations['iAdmin_ListEmpty']}}
                    </Alert>
                </template>
                <template v-if="entry.Payload.length > 0" v-slot:footer>
                    <div v-if="entry.label === 'americano'" class="button-wrapper">
                        <router-link class="rin-btn-size-s rin-btn-type-tertiary" :to="`/${layout.language}/player/${rankedinId}/${urlName}/americano/0`">{{translations['iAdmin_ShowAll']}}</router-link>
                    </div>
                    <div v-else-if="entry.label === 'organisation'" class="button-wrapper">
                        <router-link class="rin-btn-size-s rin-btn-type-tertiary" :to="`/${layout.language}/player/manage?tab=organisations`">{{translations['iAdmin_ShowAll']}}</router-link>
                    </div>
                    <div v-else class="button-wrapper">
                        <router-link class="rin-btn-size-s rin-btn-type-tertiary" :to="`/${layout.language}/player/manage`">{{translations['iAdmin_ShowAll']}}</router-link>
                    </div>
                </template>
            </Card>
        </div>
    </div>
    <Spinner v-else :size="'m'"></Spinner>
</template>
<script>
    import Card from '@/components/rin_new_design/components/base/BaseCard.vue'    
    import Spinner from '@/components/rin_new_design/components/base/BaseSpinner.vue'
    import Label from '@/components/rin_new_design/components/base/BaseTypeLabel.vue'
    import Alert from '@/components/rin_new_design/components/base/BaseAlert.vue'
    import StarIndicator from '@/components/rin_new_design/components/monetization/labels/StarIndicator.vue'


    import { mapGetters, mapMutations } from 'vuex';

    export default {
        name: 'IAdmin',
        data() {
            return {
                model: [],
                isLoading: true
            }
        },
        components: {
            Card,            
            Label,
            Spinner,
            Alert,
            StarIndicator
        },
        mounted() {
            this.loadEvents();
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
			...mapGetters('monetization', ['isTournamentKillSwitchActive']),
            ...mapMutations('ui', ['removeLastBodyModal']),
            rankedinId: function () {
                return this.$route.params.id;
            },
            urlName: function () {
                return this.$store.state.topnav.urlName;
            }
        },
        methods: {
            loadEvents: function () {
                this.$http.get(`${this.nomenclatures.ApiDomain}/player/GetPlayerOrganizedEventsAsync?language=` + this.layout.language).then((res) => {
                    this.mapToLocalModel(res.data);
                    this.isLoading = false;
                })
            },
            toggleCollapse: function (event) {
                event.isCollapsed = !event.isCollapsed;
            },
            isCollapsed: function (event) {
                return event.isCollapsed ? true : false;
            },
            mapToLocalModel: function (response) {
                response.Tournaments.Payload.forEach(x => x.isCollapsed = false);
                response.ClubLeagues.Payload.forEach(x => x.isCollapsed = false);
                response.TeamLeagues.Payload.forEach(x => x.isCollapsed = false);
                response.Organizations.Payload.forEach(x => x.isCollapsed = false);
                response.Americano.Payload.forEach(x => x.isCollapsed = false);
                response.Tournaments.title = this.translations['iAdmin_MyTournaments'];
                response.ClubLeagues.title = this.translations['iAdmin_MyClubLeagues'];
                response.TeamLeagues.title = this.translations['iAdmin_MyTeamLeagues'];
                response.Organizations.title = this.translations['iAdmin_MyOrganizations'];
                response.Americano.title = this.translations['iAdmin_MyAmericano'];
                response.Tournaments.label = 'tournament';
                response.ClubLeagues.label = 'clubleague';
                response.TeamLeagues.label = 'teamleague';
                response.Organizations.label = 'organisation';
                response.Americano.label = 'americano';
                this.model.push(response.Organizations);
                this.model.push(response.ClubLeagues);
                this.model.push(response.Tournaments);
                this.model.push(response.Americano);
                this.model.push(response.TeamLeagues);
            },
            rerouteToAmericano() {
                //this.$store.dispatch('clearAmericanoState');
                this.$router.push({ name: 'americano', params: { language: this.layout.language } }).catch((err) => {
                    console.error(`Couldn't navigate to americano`, err);
                });
                this.removeLastBodyModal();
            }
        }

    }
</script>