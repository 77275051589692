<template>
	<BaseModalWrapper :show="showModal" @close="showModal = false">
		<template #body>
			<div class="rin-free-trial-end-content">
				<h4 class="rin-free-trial-end-title">
					Rankedin <BaseTypeLabel :type="'pro'"></BaseTypeLabel> {{ translations['monetization_Trial_Plan'] }}
				</h4>
				<h1>{{ translations['monetization_TrialEnd_Title1'] }}</h1>
				<h1 class="underline">{{ translations['monetization_TrialEnd_Title2'] }}</h1>
				<div class="rin-free-trial-end-body">
					<p>{{ translations['monetization_TrialEnd_BodyText1'] }}</p>
					<UpgradeToProButton :is-overlayed="false" />
					<p>{{ translations['monetization_TrialEnd_BodyText2'] }}</p>
					<h4 class="underline" @click="showModal = false">
						{{ translations['monetization_TrialEnd_ChooseFree'] }}
					</h4>
					<p>
						{{ translations['monetization_TrialEnd_Info'] }}
					</p>
				</div>
			</div>
		</template>
	</BaseModalWrapper>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
import UpgradeToProButton from '@/components/rin_new_design/components/monetization/buttons/UpgradeToProButton.vue';

const freeTrialEndSettings = {
	name: 'FreeTrialEndCampaign',
	async checkAudienceAsync(params) {
		if (!params.store.getters.layout.isEmbed && !params.store.getters['monetization/isProPlayer'] && params.store.getters.layout.player.hasUsedFreeTrial)
			return Promise.resolve(true);

		return Promise.resolve(false);
	},
};

export { freeTrialEndSettings };
export default {
	name: 'FreeTrialEndCampaign',
	components: {
		BaseModalWrapper,
		BaseTypeLabel,
		UpgradeToProButton,
	},
	data() {
		return {
			showModal: true,
		};
	},
	computed: {
		...mapGetters(['translations', 'layout']),
	},
};
</script>
