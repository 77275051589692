export const INIT = 'init';

export const SET_TOURNAMENT_MODEL = 'setTournamentModel';
export const SET_TEAM_MODEL = 'setTeamModel';
export const SET_TOURNAMENT_CLASS_ID = 'setTournamentClassId';

export const SET_IS_LOADING_MODEL = 'setIsLoadingModel';
export const SET_IS_LOADED_MODEL = 'setIsLoadedModel';

export const SET_TEAM_NAME = 'setTeamName';
export const SET_HOME_CLUB_ID = 'setHomeClubId';

export const SET_IS_SAVING_TEAM = 'setIsSavingTeam';

export const GET_NEW_MODEL_ASYNC = 'getNewModel';
export const CREATE_TEAM_ASYNC = 'createTeamAsync';
export const GET_MODEL_ASYNC = 'getModelAsync';
export const UPDATE_TEAM_ASYNC = 'updateTeamAsync';

export default {
    namespaced: true,
    state() {
        return {
            team: {
                id: 0,
                name: '',
                tournamentId: 0,
                tournamentClassId: 0,
                homeClubId: 0,
                teamUrl: null
            },

            initiator: {},

            tournament: {},
            tournament_classes: [],
            home_club: null,
            page_referer: '',

            is_loading_model: false,
            is_loaded_model: false,
            is_saving_team: false,
            repositories: null,
            language_code: 'en'
        };
    },
    getters: {
        team: state =>
            state.team,

        tournament: state =>
            state.tournament,

        tournamentClasses: state =>
            state.tournament_classes,

        homeClub: state =>
            state.home_club,

        pageReferer: state =>
            state.page_referer,

        isLoadingModel: state =>
            state.is_loading_model,

        isLoadedModel: state =>
            state.is_loaded_model,

        isSavingTeam: state =>
            state.is_saving_team
    },
    mutations: {
        [INIT]: (state, config) => {
            state.team.id = config.teamId;
            state.team.tournamentId = config.tournamentId;
            state.team.tournamentClassId = config.tournamentClassId;

            state.page_referer = config.pageReferer;

            state.repositories = config.repositories;
            state.language_code = config.languageCode;
            state.initiator = config.initiator;
        },
        [SET_TOURNAMENT_MODEL]: (state, payload) => {
            state.tournament = payload.Tournament;
            state.tournament_classes = payload.TournamentClasses;            
        },
        [SET_TEAM_MODEL]: (state, payload) => {
            state.home_club = payload.HomeClub;
            state.team.id = payload.Id;
            state.team.name = payload.Name;
            state.team.teamUrl = payload.TeamUrl;
            if (payload.HomeClub) 
                state.team.homeClubId = payload.HomeClub.Id;
        },
        [SET_IS_LOADING_MODEL]: (state, payload) => {
            state.is_loading_model = payload;
        },
        [SET_IS_LOADED_MODEL]: (state, payload) => {
            state.is_loaded_model = payload;
        },
        [SET_IS_SAVING_TEAM]: (state, payload) => {
            state.is_saving_team = payload;
        },
        [SET_TOURNAMENT_CLASS_ID]: (state, payload) => {
            state.team.tournamentClassId = payload;
        },
        [SET_TEAM_NAME]: (state, payload) => {
            state.team.name = payload;
        },
        [SET_HOME_CLUB_ID]: (state, payload) => {
            state.team.homeClubId = payload;
        }
    },
    actions: {
        [GET_NEW_MODEL_ASYNC]: ({ state, commit }) => {
            commit(SET_IS_LOADED_MODEL, false);
            commit(SET_IS_LOADING_MODEL, true);

            return state.repositories.teams.getTournamentTeamManagerAsync(state.team.tournamentId, state.team.tournamentClassId, state.language_code)
                .then(response => {
                    commit(SET_TOURNAMENT_MODEL, response);
                    commit(SET_TEAM_MODEL, {});//clear state
                    commit(SET_HOME_CLUB_ID, 0);//clear state
                    commit(SET_IS_LOADED_MODEL, true);
                    commit('updateTeamAdminResultData', [state.initiator], { root: true });
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_MODEL, false);
                });
        },
        [CREATE_TEAM_ASYNC]: ({ state, commit, rootState }) => {
            commit(SET_IS_SAVING_TEAM, true);

            const players = rootState.autoAdd.resultData;
            const teamAdmins = rootState.autoAdd.teamAdminData;
            const fakePlayers = players.filter(p => p.Fake);

            return createFakePlayersAsync(state, fakePlayers)
                .then(() => {
                    return createTeamAsync(state, players, teamAdmins);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_SAVING_TEAM, false);
                });
        },
        [GET_MODEL_ASYNC]: ({ state, commit }, payload) => {
            commit(SET_IS_LOADED_MODEL, false);
            commit(SET_IS_LOADING_MODEL, true);

            const teamPromise = state.repositories.teams.getTeamAsync(state.team.id, state.team.tournamentId, payload)
                .then(response => {
                    commit(SET_TEAM_MODEL, response);
                    const players = response.Players.map(p => {
                        p.IsTeamParticipant = true;
                        return p;
                    });
                    const admins = response.Admins.map(p => {
                        p.IsTeamParticipant = true;
                        return p;
                    });
                    commit('updateResultData', players, { root: true });
                    commit('updateTeamAdminResultData', admins, { root: true });
                });

            const tournamentPromise = state.repositories.teams.getTournamentTeamManagerAsync(state.team.tournamentId, state.team.tournamentClassId, state.language_code)
                .then(response => {
                    commit(SET_TOURNAMENT_MODEL, response);
                });

            return Promise.all([teamPromise, tournamentPromise])
                .then(responses => {
                    commit(SET_IS_LOADED_MODEL, true);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_MODEL, false);
                });
        },
        [UPDATE_TEAM_ASYNC]: ({ state, commit, rootState }) => {
            commit(SET_IS_SAVING_TEAM, true);

            const players = rootState.autoAdd.resultData;
            const teamAdmins = rootState.autoAdd.teamAdminData;
            const fakePlayers = players.filter(p => p.Fake);

            return createFakePlayersAsync(state, fakePlayers)
                .then(() => {
                    return updateTeamAsync(state, players, teamAdmins);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_SAVING_TEAM, false);
                });
        }
    }
};

function createFakePlayersAsync(state, fakePlayers) {
    if (fakePlayers.length > 0) {
        const model = {
            EventId: state.team.tournamentClassId,
            EventType: 4,
            FakePlayers: fakePlayers
        };

        return state.repositories.teams.createFakePlayersAsync(model)
            .then(function (response) {
                if (response.length > 0) {
                    response.forEach(function (x) {
                        const fakePlayer = fakePlayers.find(fp => fp.Index === x.Index);
                        fakePlayer.added = true;
                        fakePlayer.Id = x.Id;
                    });
                }
            });
    }

    return Promise.resolve();
}

function createTeamAsync(state, players, teamAdmins) {
    const participants = mapPlayersToParticipants(players);
    const invitedPlayerIds = players.filter(p => p.invited).map(p => p.Id);
    const teamAdminIds = teamAdmins.map(p => p.Id);

    const model = {
        name: state.team.name,
        homeClubId: state.team.homeClubId,
        tournamentClassId: state.team.tournamentClassId,
        participants: participants,
        invitedIds: invitedPlayerIds,
        teamAdminIds: teamAdminIds
    };

    return state.repositories.teams.createTeamAsync(model);
}

function updateTeamAsync(state, players, teamAdmins) {
    const participants = mapPlayersToParticipants(players);
    const invitedPlayerIds = players.filter(p => p.invited).map(p => p.Id);
    const teamAdminIds = teamAdmins.map(p => p.Id);

    const model = {
        id: state.team.id,
        name: state.team.name,
        homeClubId: state.team.homeClubId,
        tournamentClassId: state.team.tournamentClassId,
        participants: participants,
        invitedIds: invitedPlayerIds,
        teamAdminIds: teamAdminIds
    };

    return state.repositories.teams.updateTeamAsync(model);
}

function mapPlayersToParticipants(players) {
    return players
        .filter(p => p.added || p.IsTeamParticipant)
        .map(p => {
            return {
                id: p.Id,
                playerOrder: p.PlayerOrder,
                teamParticipantType: p.TeamParticipantType
            };
        });
}