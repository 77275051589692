<template>
	<div class="rin-page-top-bar-container">
		<div class="wrap">
			<div class="rin-page-top-bar-back-btn" @click="goBack" v-if="$windowSize.isMobile">
				<BaseIcon name="back" />
			</div>
			<div v-on:click="showProPlayersModal" class="rin-page-top-bar-monetization-description">
				<div class="rin-page-top-bar-upgrade-description">
					{{ translations['monetization_Player_TopbarUpgradeDescription1'] }}
					<BaseTypeLabel type="pro" />
					{{ translations['monetization_Player_TopbarUpgradeDescription2'] }}
				</div>
				<div class="rin-page-top-bar-check-btn">
					<span class="check-it">
						{{ translations['monetization_Player_TopbarCheckIt'] }}
					</span>
				</div>
			</div>
		
		</div>
		<portal to="main-base-modal" v-if="showModal">
			<BaseModalWrapper size="l" :show="showModal" @close="showModal = false">
				<template #body>
					<ModalProPlayers />
				</template>
			</BaseModalWrapper>
		</portal>
	</div>
</template>

<script>
import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
import ModalProPlayers from '@/components/rin_new_design/components/monetization/modals/ModalProPlayers';

import { mapGetters } from 'vuex';

export default {
	name: 'PageTopBar',
	components: {		
		BaseIcon,
		BaseModalWrapper,
		BaseTypeLabel,
		ModalProPlayers,
	},
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		showProPlayersModal() {
			this.showModal = true;
		},
	},
};
</script>
