<template>
    <div class="modal fade" id="h2hmodal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <button type="" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" class="icon icon-plus"></span>
                    </button>
                </div>
                <div class="modal-body" id="h2hmodal-body">                    
                    <div class="row nowrap">
                        <div class="col img"><img width="88" v-bind:src="model.ChallengerImageUrl"></div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col img text-right"><img width="88" v-bind:src="model.ChallengedImageUrl"></div>
                    </div>
                    <div class="row nowrap">
                        <div class="col name"><a href="#"><i class="flag-icon" v-bind:class="'flag-icon-'+model.ChallengerCountry"></i> {{model.Challenger1FirstName | firstChar }}. {{model.Challenger1LastName}}</a></div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col name text-right"><a href="#">{{model.Challenged1FirstName | firstChar}}. {{model.Challenged1LastName}}<i class="flag-icon" v-bind:class="'flag-icon-'+model.ChallengedCountry"></i></a></div>
                    </div>

                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col name"><a href="#"><i v-bind:class="'flag-icon-'+model.Challenger1Country"></i> {{model.Challenger2FirstName | firstChar}}. {{model.Challenger2LastName}}</a></div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col name text-right"><a href="#">{{model.Challenged2FirstName | firstChar}}. {{model.Challenged2LastName}}<i v-bind:class="'flag-icon-'+model.Challenged1Country" class="flag-icon"></i></a></div>
                    </div>
                    
                    <div class="row nowrap">
                        <div class="col score">{{model.ChallengerWinsH2H}} <span class="small">{{model.ChallengerH2HPercents}}% {{translations["player_Wins"]}}</span></div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col score text-right"><span class="small">{{model.ChallengedH2HPercents}}% {{translations["player_Wins"]}}</span> {{model.ChallengedWinsH2H}}</div>
                    </div>
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.Challenger1Age}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_Age"]}}</div>
                        <div class="col box text-center">{{model.Challenged1Age}}</div>
                    </div>
                  
                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col box text-center">{{model.Challenger2Age}}</div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col box text-center">{{model.Challenged2Age}}</div>
                    </div>
                    
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.Challenger1BirthPlace}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_BirthPlace"]}}</div>
                        <div class="col box text-center">{{model.Challenged1BirthPlace}}</div>
                    </div>
                  
                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col box text-center">{{model.Challenger2BirthPlace}}</div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col box text-center">{{model.Challenged2BirthPlace}}</div>
                    </div>
                    
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.Challenger1Hand}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_StrongHand"]}}</div>
                        <div class="col box text-center">{{model.Challenged1Hand}}</div>
                    </div>
                  
                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col box text-center">{{model.Challenger2Hand}}</div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col box text-center">{{model.Challenged2Hand}}</div>
                    </div>
                    
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.Challenger1Backhand}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_Backhand"]}}</div>
                        <div class="col box text-center">{{model.Challenged1Backhand}}</div>
                    </div>
             
                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col box text-center">{{model.Challenger2Backhand}}</div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col box text-center">{{model.Challenged2Backhand}}</div>
                    </div>
                    
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.ChallengerWinsAll}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_CareerWins"]}}</div>
                        <div class="col box text-center">{{model.ChallengedWinsAll}}</div>
                    </div>
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.Challenger1Racket}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_Racket"]}}</div>
                        <div class="col box text-center">{{model.Challenger1Shoes}}</div>
                    </div>
                 
                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col box text-center">{{model.Challenger2Racket}}</div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col box text-center">{{model.Challenged2Racket}}</div>
                    </div>
                    
                    <div class="row nowrap">
                        <div class="col box text-center">{{model.Challenger1Shoes}}</div>
                        <div class="col-3 text-center m-auto">{{translations["player_Shoes"]}}</div>
                        <div class="col box text-center">{{model.Challenged1Shoes}}</div>
                    </div>
                   
                    <div v-if="model.IsDouble" class="row nowrap">
                        <div class="col box text-center">{{model.Challenger2Shoes}}</div>
                        <div class="col-3 text-center m-auto"></div>
                        <div class="col box text-center">{{model.Challenged2Shoes}}</div>
                    </div>
                    
                    <div class="row">
                        <div class="col-12 box text-center">{{translations["match_Date"]}}: <strong>{{dateString}}</strong></div>
                        <div class="col-12 box text-center m-auto">{{translations["match_Place"]}}: <strong>{{model.Place}}</strong></div>
                        <div class="col-12 box text-center">{{translations["clubLeague_Index_Ranking"]}}: <strong>{{model.Ranking}}</strong></div>
                    </div>
                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import spaEventBus from "@/app/eventBus";
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        name: 'head2head-modal-bs4',
        data() {
            return {
                isLoading: false,
                challengeId: 0,
                model: {}
            }
        },
        filters: {
            firstChar: function (value) {
                if (typeof (value) === 'undefined' || value == '') return '';
                return value.charAt(0);
            }
        },
        computed: {
            ...mapGetters(['translations', 'layout','nomenclatures']),

            dateString() {
                if (typeof this.model.Date == "undefined" || this.model.Date == null) return "Not Set";
                return moment.utc(this.model.Date).format(this.nomenclatures.DateFormat_Moment);
            }
        },
        mounted() {

            spaEventBus.$off('head2headModalShow');
            spaEventBus.$on('head2headModalShow', (payload) => {
                this.challengeId = payload.challengeId;
                if (this.challengeId == 0) return;

                if (payload.isForTeams) {
                    let route = `/${this.layout.language}/team/tournamentMatchResults/${this.challengeId}`;
                    this.$router.replace(route);
                }
                else {
                    this.loadH2H();
                }
            });
        },

        methods: {
            loadH2H: function () {
                this.isLoading = true;

                this.$http.get('/api/challengesapi/headtohead?challengeId=' + this.challengeId)
                    .then(response => {
                        this.model = response.data;
                        this.isLoading = false;
                        this.showModal();
                    })
                    .catch(e => {
                        this.isLoading = false;
                        console.log(e);
                    });
            },
            showModal: function () {
                $('#h2hmodal').modal('show');
            },
           
        }
    }
</script>