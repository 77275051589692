<template>
    <div class="rin-search-history">
        <div class="rin-search-history-header">
            <h4>Last visited</h4>
            <span @click="removeAll">clear all</span>
        </div>
        <ul class="rin-search-history-list">
            <li v-for="(history, index) in searchHistory" :key="history.url">
                <div>
                    <router-link @click.native.prevent="close({ name: history.route.name, params: history.route.params }, index)"
                                 event
                                 :to="{ name: history.route.name, params: history.route.params }">
                        <BaseTypeLabel @click.native="close" type="pro" v-if="!layout.player.IsProPlayer && index > 0" />
                        {{ history.name }}
                    </router-link>
                    <StarIndicator v-if="!isTournamentKillSwitchActive && history.isPremium" size="s"></StarIndicator>
                </div>
                <BaseIcon name="close" @click.native="remove(history)" />
            </li>
        </ul>

    
    </div>
</template>
<script>
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
 
    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import StarIndicator from '@/components/rin_new_design/components/monetization/labels/StarIndicator';

    import { mapGetters, mapActions, mapMutations } from 'vuex';

    export default {
        name: 'SearchHistory',
        components: {
            BaseIcon,
            BaseTypeLabel,
            StarIndicator
        },
        computed: {
            ...mapGetters(['layout', 'nomenclatures']),
			...mapGetters('monetization', ['isTournamentKillSwitchActive']),

            searchHistory() {
                return this.$store.getters['searchHistory/history'];
            },
        },
        methods: {
            ...mapActions('ui', ['setMonetizationModalState']),
			...mapMutations('monetization', ['updateShowFreeTrialModal']),
            removeAll() {
                this.$store.commit('searchHistory/removeAll');
            },
            remove(history) {
                this.$store.commit('searchHistory/removeHistory', history);
            },
            close(routeObject, idx) {
                this.$store.commit('search/toggle', false);
                if (this.layout.player.IsProPlayer || idx === 0) {                    
                    this.$router.push(routeObject);
                } else {
					if(!this.layout.player.isAuthenticated || this.layout.player.hasUsedFreeTrial) {
						this.setMonetizationModalState(true);
					} else {
						this.updateShowFreeTrialModal(true);
					}
                }
            },
     
        },
    };
</script>
