<template>
    <div class="modal-pro-player-wrapper" v-if="!isLoading">
        <div class="rin-pro-player-modal-body-container">
            <div>
                <div class="rin-pro-player-modal-titles-container">
                    <div class="rin-pro-player-modal-supertitle">
                        {{ translations['monetization_Player_ModalSupertitle'] }}
                    </div>
                    <div class="rin-pro-player-modal-title">
                        Rankedin <BaseTypeLabel type="pro" /> {{ translations['monetization_Player_ModalTitle'] }}
                    </div>
                    <div class="rin-pro-player-modal-subtitle">{{ translations['monetization_Player_ModalSubtitle'] }}</div>
                </div>
                <ul class="rin-pro-player-modal-bulletpoints">
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Player_ModalBulletpoint1'] }}</span>
                    </li>
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Player_ModalBulletpoint2'] }}</span>
                    </li>
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Player_ModalBulletpoint3'] }}</span>
                    </li>
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Player_ModalBulletpoint4'] }}</span>
                    </li>
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Real_Time_Indicator'] }}</span>
                    </li>
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Player_ModalBulletpoint6'] }}</span>
                    </li>
                    <li>
                        <BaseIcon name="check"></BaseIcon>
                        <span>{{ translations['monetization_Player_ModalBulletpoint7'] }}</span>
                    </li>
                </ul>

            </div>
            <div v-if="!$windowSize.isMobile">
                <div class="rin-pro-player-modal-image-container">
                    <img src="https://cdn.rankedin.com/images/static/players_pro_plan_modal_image.svg" alt="image player" />
                </div>
            </div>
        </div>

        <div class="choose-plan-wrapper">
            <div class="rin-pro-player-modal-description">
                {{ translations['monetization_Player_ModalDescription'] }}
            </div>
            <div class="rin-pro-player-modal-plans-container">
                <BaseCard type="primary"
                          size="s"
                          :shadow="false"
                          v-for="plan in plans"
                          :key="plan.priceId"
                          :class="{
							'rin-popular-plan-container':
								plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly,
						}">
                    <template #title>
                        <BaseTypeLabel v-if="plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly"
                                       type="popular" />
                    </template>
                    <template #header v-if="plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly">
                        <span>-55%</span>
                    </template>
                    <template #body>
                        <div class="rin-pro-player-modal-plan-period">
                            {{
									translations[
										`monetization_Player_ModalPlanPeriod${
											nomenclatures.PlayerSubscriptionPeriodTypesEnum[plan.type]
										}`
									]
                            }}
                        </div>
                        <div class="rin-pro-player-modal-plan-cost">
                            {{ parsePrice(plan.price) }} {{ currencySymbol(plan.currency) }}
                            <span>
                                /
                                {{
										translations[
											`monetization_Player_ModalPlanPer${
												nomenclatures.PlayerSubscriptionPeriods[
													nomenclatures.PlayerSubscriptionPeriodTypesEnum[plan.type]
												]
											}`
										]
                                }}
                            </span>
                        </div>
                        <div class="rin-pro-player-modal-plan-breakdown">
                            <template v-if="plan.type === nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly">
                                12 {{ translations['monetization_Player_ModalPlanMonths'] }} {{ annualPlanBreakdown }} {{ currencySymbol(plan.currency) }} /
                                {{
										translations[
											`monetization_Player_ModalPlanPer${nomenclatures.PlayerSubscriptionPeriods.Monthly}`
										]
                                }}
                            </template>
                        </div>
                        <div class="cancel-any-time">
                            {{ translations['monetization_Player_ModalPlanDescription'] }}
                        </div>
                        <BaseButton type="warn" size="m" @click.native="actionButton(plan.priceId, plan.type)">
                            <template v-if="layout.player.isAuthenticated">
                                {{ translations['monetization_Player_ModalPlanBuyButton'] }}
                            </template>
                            <template v-else>
                                {{ translations['shared_SeeMore'] }}
                            </template>
                        </BaseButton>
                    </template>
                </BaseCard>
            </div>
        </div>
    </div>

    <BaseSpinner v-else></BaseSpinner>

</template>

<script>
    import { GET_LAYOUT_MODEL_ASYNC } from '@/store/modules/layout-module.js';

    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner';

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: 'ModalProPlayes',
        components: {
            BaseButton,
            BaseCard,
            BaseIcon,
            BaseTypeLabel,
            BaseSpinner
        },
        data() {
            return {
                plans: [],
                isLoading: false
            };
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('monetization', ['isProPlayer']),
            annualPlanBreakdown() {
                const annualPlanPrice = this.plans.find(
                    (v) => v.type === this.nomenclatures.PlayerSubscriptionPeriodTypesEnum.Yearly
                ).price;
                const breakdown = annualPlanPrice / 12;

                if (Number.isInteger(breakdown)) {
                    return breakdown;
                } else {
                    return breakdown.toString().slice(0, breakdown.toString().indexOf('.') + 3);
                }
            },
        },
        methods: {
            ...mapActions({
                getLayoutModel: GET_LAYOUT_MODEL_ASYNC,
            }),

            actionButton(priceId, type) {
                if (this.layout.player.isAuthenticated) {
                    const _this = this;

                    this.$http.post(`${_this.nomenclatures.ApiDomain}/Monetization/CreatePlayerSubscriptionAsync`, {
                        priceId: priceId,
                        type: type,
                        language: this.layout.language
                    })
                        .then((response) => {
                            window.location.href = response.data
                        })
                        .catch(() => {
                            window.location.reload();
                        });

                } else {
                    this.$router.push({ name: 'pricing', params: { language: this.layout.language }, hash: '#boxPlayers' });
                }
            },
            currencySymbol(currencyCode) {
                switch (currencyCode) {
                    case 2:
                        return '€';
                    default:
                        return this.nomenclatures.Currencies[currencyCode].name;
                }
            },
            parsePrice(price) {
                return price.toFixed(2);
            },
        },
        mounted() {
            const endpointMethod = this.layout.player.isAuthenticated
                ? 'GetPlayerAvailableSubscriptionsAsync'
                : 'GetPlayersSubscriptionsAsync';

            this.$http.get(`${this.nomenclatures.ApiDomain}/Monetization/${endpointMethod}`)
                .then((res) => {
                    if (res.data.length > 0)
                        this.plans = res.data;
                    else if (this.layout.player.isAuthenticated && res.data.length === 0)
                        window.location.reload();
                })
                .catch(() => window.location.reload());
        },
    };
</script>
