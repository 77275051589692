export default {
    state: {
        name: '',
        points: 0,
        clubId: null,
        clubName: '',

        clubCity: '',
        clubCountry: '',
        clubFlag: '',

        isAdmin: false,
        selectedPlayers: [],

        isNameStepVisible: true,
        isPointsStepVisible: false,
        isPlayersStepVisible: false,
        
    },
    mutations: {
        updateName(state, payload) {
            state.name = payload;
        },

        updatePoints(state, payload) {
            state.points = payload;
        },

        updateHomeClub(state, payload) {
            state.clubId = payload.id;
            state.clubName = payload.name;
            state.isAdmin = payload.isAdmin;
            state.clubCountry = payload.country;
            state.clubCity = payload.city;
            state.clubFlag = payload.countryShort;
        },

        setNameStepVisible(state) {
            state.isNameStepVisible = true;
            state.isPointsStepVisible = false;
            state.isPlayersStepVisible = false;
        },

        setPointsStepVisible(state) {
            state.isNameStepVisible = false;
            state.isPointsStepVisible = true;
            state.isPlayersStepVisible = false;
        },

        setPlayersStepVisible(state) {
            state.isNameStepVisible = false;
            state.isPointsStepVisible = false;
            state.isPlayersStepVisible = true;
        },

        addPlayer(state, payload) {
            state.selectedPlayers.push(payload);
        },

        removePlayer(state, paylaod) {
            const index = state.selectedPlayers.indexOf(paylaod);
            if (index > -1) {
                state.selectedPlayers.splice(index, 1);
            }
        },

        clear(state) {
            state.selectedPlayers = [];
            state.name = '';
            state.clubId = null;
            state.clubName = '';
            state.points = 0;
            state.isAdmin = false;

            state.isNameStepVisible = true;
            state.isPointsStepVisible = false;
            state.isPlayersStepVisible = false;
        }
    },
    actions: {
        setAmericanoName: ({ commit }, payload) => {
            commit("updateName", payload);
        },

        setAmericanoPoints: ({ commit }, payload) => {
            commit("updatePoints", payload);
        },

        setAmericanoHomeClub: ({ commit }, payload) => {
            commit("updateHomeClub", payload);
        },

        setAmericanoNameStepVisible: ({ commit }) => {
            commit("setNameStepVisible");
        },

        setAmericanoPointsStepVisible: ({ commit }) => {
            commit("setPointsStepVisible");
        },

        setAmericanoPlayersStepVisible: ({ commit }) => {
            commit("setPlayersStepVisible");
        },

        addAmericanoPlayer: ({ commit }, payload) => {
            commit("addPlayer", payload);
        },

        removeAmericanoPlayer: ({ commit }, payload) => {
            commit("removePlayer", payload);
        },

        clearAmericanoState: ({ commit }) => {
            commit("clear");
        },
    }
}