export const INIT_TEAM_LEAGUE_TEAMS_MATCHES = 'initTeamLeagueTeamsMatches';
export const INIT_TOURNAMENT_TEAMS_MATCHES = 'initTournamentTeamsMatches';
export const SET_MATCHES_MODEL = 'setMatchesModel';
export const SET_LINEUPS_MODEL = 'setLineupsModel';
export const SET_TOURNAMENT_MODEL = 'setTournamentModel';
export const SET_UPDATING_MATCH_TYPE_LINEUPS = 'setUpdatingMatchTypeLineups';
export const SET_TEAM_MATCH_STANDINGS = 'setTeamMatchStandings';
export const SET_STANDINGS_SETTINGS = 'setStandingsSettings';
export const SET_IS_LOADING_MODEL = 'setIsLoadingModel';
export const SET_IS_LOADING_TEAM_MATCH_STANDINGS = 'setIsLoadingTeamMatchStandings';
export const SET_IS_LOADING_STANDINGS_SETTINGS = 'setIsLoadingStandingsSettings';
export const SET_IS_LOADED_MODEL = 'setIsLoadedModel';
export const SET_IS_LOADED_TEAM_MATCH_STANDINGS = 'setIsLoadedTeamMatchStandings';
export const SET_IS_LOADED_STANDINGS_SETTINGS = 'setIsLoadedStandingsSettings';

export const GET_MODEL_ASYNC = 'getModelAsync';
export const GET_TEAM_MATCH_STANDINGS_ASYNC = 'getTeamMatchStandingsAsync';
export const GET_STANDINGS_SETTINGS_ASYNC = 'getStandingsSettingsAsync';
export const POST_MODIFIED_LINEUPS_ASYNC = 'postModifiedLineupsAsync';
export const UPDATE_LINEUP = 'updateLineup';
export const TRIGGER_TEAM_POWER = 'triggerTeamPower';


export default {
    namespaced: true,
    state() {
        return {
            model: {},

            team1TotalPower: null,
            team2TotalPower: null,

            modified_lineups: {},
            team_match_standings: {},
            standings_standings: {},
            is_loading_model: false,
            is_loading_team_match_standings: false,
            is_loading_standings_settings: false,
            is_loaded_model: false,
            is_loaded_team_match_standings: false,
            is_loaded_standings_settings: false,
            updating_match_type_lineups: 0,
            repositories: null,
            team_league: {
                id: 0,
                teamMatchId: 0,
                canEnterResultBeforeLineup: false,
                isTeamLeagueAdmin: false,
                teamMatchState: 0
            },
            tournament: {
                id: 0,
                classId: 0,
                challengeId: 0,
                model: {}
            },
            language_code: 'en',
            event_type: null
        };
    },
    getters: {
        teamLeagueModel: state =>
            state.team_league,

        model: state =>
            state.model,

        tournamentModel: state =>
            state.tournament.model,

        modifiedLineups: state =>
            state.modified_lineups,

        teamMatchStandings: state =>
            state.team_match_standings,

        standingsSettings: state =>
            state.standings_standings,

        isLoadingModel: state =>
            state.is_loading_model,

        isLoadingTeamMatchStandings: state =>
            state.is_loading_team_match_standings,

        isLoadingStandingsSettings: state =>
            state.is_loading_standings_settings,

        isLoadedModel: state =>
            state.is_loaded_model,

        isLoadedTeamMatchStandings: state =>
            state.is_loaded_team_match_standings,

        isLoadedStandingsSettings: state =>
            state.is_loaded_standings_settings,

        updatingMatchTypeLineups: state =>
            state.updating_match_type_lineups,

        eventType: state =>
            state.event_type,

        team1TotalPower: state =>
            state.team1TotalPower,

        team2TotalPower: state =>
            state.team2TotalPower,
    },
    mutations: {
        [INIT_TEAM_LEAGUE_TEAMS_MATCHES]: (state, config) => {
            state.repositories = config.repositories;
            state.team_league.id = config.teamLeagueId;
            state.team_league.teamMatchId = config.teamMatchId;
            state.team_league.eventState = config.eventState;
            state.team_league.canEnterResultBeforeLineup = config.canEnterResultBeforeLineup;
            state.team_league.teamMatchState = config.teamMatchState;
            state.team_league.isTeamLeagueAdmin = config.isTeamLeagueAdmin;
            state.language_code = config.languageCode;
            state.event_type = config.eventType;
        },
        [INIT_TOURNAMENT_TEAMS_MATCHES]: (state, config) => {
            state.repositories = config.repositories;
            state.tournament.id = config.tournamentId;
            state.tournament.classId = config.tournamentClassId;
            state.tournament.challengeId = config.challengeId;
            state.language_code = config.languageCode;
            state.event_type = config.eventType;
        },
        [SET_MATCHES_MODEL]: (state, payload) => {
            state.model.TeamMatches = payload;
        },
        [SET_LINEUPS_MODEL]: (state, payload) => {
            state.model.FirstTeam = payload.FirstTeam;
            state.model.SecondTeam = payload.SecondTeam;
            state.model.IsLicenseRequired = payload.IsLicenseRequired;
            state.model.IsLineupPublished = payload.IsLineupPublished;
            state.model.IsAdmin = payload.IsAdmin;
            state.model.HasRating = payload.HasRating;

        },
        [SET_TOURNAMENT_MODEL]: (state, payload) => {
            state.tournament.model = payload;
        },
        [SET_UPDATING_MATCH_TYPE_LINEUPS]: (state, payload) => {
            state.updating_match_type_lineups = payload;
        },
        [SET_TEAM_MATCH_STANDINGS]: (state, payload) => {
            state.team_match_standings = payload;
        },
        [SET_IS_LOADING_MODEL]: (state, payload) => {
            state.is_loading_model = payload;
        },
        [SET_IS_LOADING_TEAM_MATCH_STANDINGS]: (state, payload) => {
            state.is_loading_team_match_standings = payload;
        },
        [SET_IS_LOADING_STANDINGS_SETTINGS]: (state, payload) => {
            state.is_loading_standings_settings = payload;
        },
        [SET_STANDINGS_SETTINGS]: (state, payload) => {
            state.standings_standings = payload;
        },
        [SET_IS_LOADED_MODEL]: (state, payload) => {
            state.is_loaded_model = payload;
        },
        [SET_IS_LOADED_TEAM_MATCH_STANDINGS]: (state, payload) => {
            state.is_loaded_team_match_standings = payload;
        },
        [SET_IS_LOADED_STANDINGS_SETTINGS]: (state, payload) => {
            state.is_loaded_standings_settings = payload;
        }
    },
    actions: {
        [GET_MODEL_ASYNC]: ({ state, commit, dispatch, rootState }, language) => {
            commit(SET_IS_LOADED_MODEL, false);
            commit(SET_IS_LOADING_MODEL, true);

            const teamsRepository = state.repositories.teams;
            let loaderAsync;
            if (state.event_type === rootState.nomenclatures.nomenclatures.EventTypes.TeamLeague) {
                const matchesPromise = teamsRepository.getTeamLeagueTeamsMatchesAsync(state.team_league.teamMatchId, language)
                    .then(response => {
                        commit(SET_MATCHES_MODEL, response);
                    });
                const lineupsPromise = teamsRepository.getTeamLeagueTeamsLineupsAsync(state.team_league.teamMatchId, language)
                    .then(response => {
                        commit(SET_LINEUPS_MODEL, response);
                    });
                loaderAsync = Promise.all([matchesPromise, lineupsPromise])
                    .then(response => {
                        commit(SET_IS_LOADED_MODEL, true);
                    });
            } else {
                const matchesPromise = teamsRepository.getTournamentTeamsMatchesAsync(state.tournament.id, state.tournament.challengeId, language)
                    .then(response => {
                        commit(SET_MATCHES_MODEL, response);
                    });
                const lineupsPromise = teamsRepository.getTournamentTeamsLineupsAsync(state.tournament.id, state.tournament.challengeId, language)
                    .then(response => {
                        commit(SET_LINEUPS_MODEL, response);
                    });
                const tournamentPromise = teamsRepository.getTeamParticipatedTournamentAsync(state.tournament.id, state.tournament.classId, language)
                    .then(response => {
                        commit(SET_TOURNAMENT_MODEL, response);
                    });

                loaderAsync = Promise.all([matchesPromise, lineupsPromise, tournamentPromise])
                    .then(response => {
                        commit(SET_IS_LOADED_MODEL, true);
                    });
            }

            return loaderAsync
                .then(response => {
                    commit(SET_IS_LOADED_MODEL, true);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_MODEL, false);
                    dispatch('triggerTeamPower');
                });
        },
        [GET_TEAM_MATCH_STANDINGS_ASYNC]: ({ state, commit, rootState }, language) => {
            commit(SET_IS_LOADED_TEAM_MATCH_STANDINGS, false);
            commit(SET_IS_LOADING_TEAM_MATCH_STANDINGS, true);

            const teamsRepository = state.repositories.teams;
            let getStandingsAsync;
            if (state.event_type === rootState.nomenclatures.nomenclatures.EventTypes.TeamLeague) {
                getStandingsAsync = teamsRepository.getTeamLeagueTeamMatchStandingsAsync(state.team_league.teamMatchId);
            } else {
                getStandingsAsync = teamsRepository.getTournamentTeamMatchStandingsAsync(state.tournament.id, state.tournament.challengeId, language);
            }

            return getStandingsAsync
                .then(response => {
                    commit(SET_IS_LOADED_TEAM_MATCH_STANDINGS, true);
                    commit(SET_TEAM_MATCH_STANDINGS, response);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_TEAM_MATCH_STANDINGS, false);
                });
        },
        [GET_STANDINGS_SETTINGS_ASYNC]: ({ state, commit, rootState }) => {
            commit(SET_IS_LOADED_STANDINGS_SETTINGS, false);
            commit(SET_IS_LOADING_STANDINGS_SETTINGS, true);

            const teamsRepository = state.repositories.teams;
            let getSettingsAsync;
            if (state.event_type === rootState.nomenclatures.nomenclatures.EventTypes.TeamLeague) {
                getSettingsAsync = teamsRepository.getTeamLeagueStandingsSettingsAsync(state.team_league.id);
            } else {
                getSettingsAsync = teamsRepository.getTournamentStandingsSettingsAsync(state.tournament.id);
            }
            return getSettingsAsync
                .then(response => {
                    commit(SET_IS_LOADED_STANDINGS_SETTINGS, true);
                    commit(SET_STANDINGS_SETTINGS, response);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_STANDINGS_SETTINGS, false);
                });
        },
        [POST_MODIFIED_LINEUPS_ASYNC]: ({ state, dispatch, commit, rootState }, matchType) => {

            const divisionLineups = state.modified_lineups[matchType];
            const hasError = validateLineups(divisionLineups);
            if (hasError) {
                return Promise.reject(new Error('Lineups are invalid! ' + hasError));
            }
            const matchesLineups = mapToMatchesLineups(divisionLineups);

            commit(SET_UPDATING_MATCH_TYPE_LINEUPS, matchType);

            const teamsRepository = state.repositories.teams;
            let posterAsync;
            if (state.event_type === rootState.nomenclatures.nomenclatures.EventTypes.TeamLeague) {
                const lineups = {
                    TeamMatchId: state.team_league.teamMatchId,
                    MatchesLineups: matchesLineups
                };
                posterAsync = teamsRepository.postTeamLeagueTeamsLineupsAsync(lineups);
            } else {
                const lineups = {
                    TournamentId: state.tournament.id,
                    ChallengeId: state.tournament.challengeId,
                    MatchesLineups: matchesLineups
                };
                posterAsync = teamsRepository.postTournamentTeamsLineupsAsync(lineups);
            }

            return posterAsync
                .then(response => {
                    const divisionMatches = state.model.TeamMatches
                        .find(dm => dm.Settings.MatchType === matchType);
                    updateMatchesParticipants(divisionMatches, response, divisionLineups);

                    Vue.delete(state.modified_lineups, matchType);

                    if (state.event_type === rootState.nomenclatures.nomenclatures.EventTypes.TeamLeague) dispatch('getModelAsync');
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_UPDATING_MATCH_TYPE_LINEUPS, 0);
                });
        },
        [UPDATE_LINEUP]: ({ state, dispatch, rootState }, newLineup) => {

            let divisionLineups = state.modified_lineups[newLineup.settings.MatchType];
            if (divisionLineups === undefined) {
                divisionLineups = {};
            }

            let matchLineup = divisionLineups[newLineup.match.Id];
            if (matchLineup === undefined) {
                matchLineup = createMatchLineup(newLineup);
                divisionLineups[newLineup.match.Id] = matchLineup;
            }

            var lineupForValidation = {};
            lineupForValidation[newLineup.match.Id] = createMatchLinupForValidation(newLineup);

            const hasError = validateLineups(lineupForValidation);

            if (hasError) {
                return Promise.reject('Lineups are invalid! ' + hasError);
            }

            const shouldCheckLicense = !state.model.IsAdmin;

            if (shouldCheckLicense) {

                const requiresLicense = state.model.IsLicenseRequired;
                let hasLicense = true;

                var newlySelectedPlayerId = newLineup.player.id;

                if (newLineup.isFirstTeam) {
                    var playerInfo = state.model.FirstTeam.Players.find(x => x.PlayerId == newlySelectedPlayerId);
                    if (typeof (playerInfo) != 'undefined') hasLicense = playerInfo.HasLicense;
                }
                else {
                    var playerInfo = state.model.SecondTeam.Players.find(x => x.PlayerId == newlySelectedPlayerId);
                    if (typeof (playerInfo) != 'undefined') hasLicense = playerInfo.HasLicense;
                }

                if (requiresLicense && !hasLicense) {
                    return Promise.reject('This player cannot be selected because he does not have a valid license - Contact Team League Admin');
                }
            }

            updateMatchLineupPlayer(matchLineup, newLineup);

            Vue.set(state.modified_lineups, newLineup.settings.MatchType, divisionLineups);
            
            dispatch('triggerTeamPower');

            return Promise.resolve();
        },

        [TRIGGER_TEAM_POWER]: ({ state }) => {
            var allRatings = state.model.TeamMatches.flatMap(match => match.Matches.Matches.map(x => getMatchRating(x)));
            var firstTeamMatches = allRatings.flatMap(matchGroup => matchGroup.firstTeam);
            var secondTeamMatches = allRatings.flatMap(matchGroup => matchGroup.secondTeam);

            for (var keyNames in state.modified_lineups)
                for (var challengeId in state.modified_lineups[keyNames]) {
                    var newRating = state.modified_lineups[keyNames][challengeId];
                    if (typeof (newRating) == 'undefined') continue;

                    var challenger1 = newRating.FirstTeamPlayers[0];
                    var challenger2 = newRating.FirstTeamPlayers[1];

                    if (typeof (challenger1) != 'undefined' && challenger1.isModified === true) {
                        var modifiedPlayer = firstTeamMatches.find(x => x.matchId == challengeId && x.order === 1);
                        modifiedPlayer.playerId = challenger1.id;
                        modifiedPlayer.playerRating = challenger1.rating;
                    }

                    if (typeof (challenger2) != 'undefined' && challenger2.isModified === true) {
                        var modifiedPlayer = firstTeamMatches.find(x => x.matchId == challengeId && x.order === 2);
                        modifiedPlayer.playerId = challenger2.id;
                        modifiedPlayer.playerRating = challenger2.rating;
                    }

                    var challenged1 = newRating.SecondTeamPlayers[0];
                    var challenged2 = newRating.SecondTeamPlayers[1];

                    if (typeof (challenged1) != 'undefined' && challenged1.isModified === true) {
                        var modifiedPlayer = secondTeamMatches.find(x => x.matchId == challengeId && x.order === 1);
                        modifiedPlayer.playerId = challenged1.id;
                        modifiedPlayer.playerRating = challenged1.rating;
                    }

                    if (typeof (challenged2) != 'undefined' && challenged2.isModified === true) {
                        var modifiedPlayer = secondTeamMatches.find(x => x.matchId == challengeId && x.order === 2);
                        modifiedPlayer.playerId = challenged2.id;
                        modifiedPlayer.playerRating = challenged2.rating;
                    }
                }

            state.team1TotalPower = calcTotalTeamPower(firstTeamMatches);
            state.team2TotalPower = calcTotalTeamPower(secondTeamMatches);
        }
    }
};

function getMatchRating(match) {

    var firstTeam = [{
        matchId: match.Id,
        playerId: match.Challenger.Player1Id,
        playerRating: match.Challenger.Player1Id == 0 ? null : match.Challenger.Player1RatingBegin,
        order: 1,
    },
    {
        matchId: match.Id,
        playerId: match.Challenger.Player2Id,
        playerRating: match.Challenger.Player2Id == 0 ? null : match.Challenger.Player2RatingBegin,
        order: 2,
    }];

    var secondTeam = [{
        matchId: match.Id,
        playerId: match.Challenged.Player1Id,
        playerRating: match.Challenged.Player1Id == 0 ? null : match.Challenged.Player1RatingBegin,
        order: 1
    },
    {
        matchId: match.Id,
        playerId: match.Challenged.Player2Id,
        playerRating: match.Challenged.Player2Id == 0 ? null : match.Challenged.Player2RatingBegin,
        order: 2
    }];

    return {
        firstTeam: firstTeam,
        secondTeam: secondTeam
    };
}

function calcTotalTeamPower(playersWithRatings) {
    var teamRating = [];

    for (var i = 0; i < playersWithRatings.length; i++) {
        var player = playersWithRatings[i];

        if (isValidRatingNumber(player.playerRating)) {
            teamRating.push(player.playerRating);
        }
    }

    var teamPow = teamRating.length > 0 ? sum(teamRating) / teamRating.length : null;
    return teamPow;
}

function isValidRatingNumber(num) {
    return !isNaN(num) && num != null && num > 0;
}

function sum(arr) {
    return arr.reduce((accumulator, a) => accumulator + a, 0);
}

const updateMatchesParticipants = function (divisionMatches, updatedMatchesParticipants, divisionLineups) {
    const isSingle = isSingleMatchType(divisionMatches.Settings.MatchType);
    for (let i = 0; i < updatedMatchesParticipants.length; i++) {
        const updatedMatchParticipants = updatedMatchesParticipants[i];
        const match = divisionMatches.Matches.Matches.find(m => m.Id === updatedMatchParticipants.ChallengeId);
        const lineup = divisionLineups[updatedMatchParticipants.ChallengeId];
        const firstTeamPlayers = lineup.FirstTeamPlayers;
        const secondTeamPlayers = lineup.SecondTeamPlayers;

        match.Challenger.Id = updatedMatchParticipants.FirstParticipantId;
        match.Challenged.Id = updatedMatchParticipants.SecondParticipantId;

        if (isSingle) {
            match.Challenger.Name = stripRatingFronName(firstTeamPlayers[0].name);
            match.Challenged.Name = stripRatingFronName(secondTeamPlayers[0].name);
            match.Challenger.Player1Id = firstTeamPlayers[0].id;
            match.Challenged.Player1Id = secondTeamPlayers[0].id;

            if (firstTeamPlayers[0].isModified === true) {
                match.Challenger.Player1RatingBegin = firstTeamPlayers[0].rating;
            }

            if (secondTeamPlayers[0].isModified === true) {
                match.Challenged.Player1RatingBegin = secondTeamPlayers[0].rating;
            }

        } else {
            let challengerFirstName = firstTeamPlayers[0].name;
            let challengerSecondName = firstTeamPlayers[1].name;

            match.Challenger.Player1Id = firstTeamPlayers[0].id;
            match.Challenger.Name = stripRatingFronName(challengerFirstName);
            match.Challenger.Player2Id = firstTeamPlayers[1].id;
            match.Challenger.Player2Name = stripRatingFronName(challengerSecondName);

            if (firstTeamPlayers[0].isModified === true) match.Challenger.Player1RatingBegin = firstTeamPlayers[0].rating;
            if (firstTeamPlayers[1].isModified === true) match.Challenger.Player2RatingBegin = firstTeamPlayers[1].rating;

            let challengedFirstName = secondTeamPlayers[0].name;
            let challengedSecondName = secondTeamPlayers[1].name;

            match.Challenged.Player1Id = secondTeamPlayers[0].id;
            match.Challenged.Name = stripRatingFronName(challengedFirstName);
            match.Challenged.Player2Id = secondTeamPlayers[1].id;
            match.Challenged.Player2Name = stripRatingFronName(challengedSecondName);

            if (secondTeamPlayers[0].isModified === true) match.Challenged.Player1RatingBegin = secondTeamPlayers[0].rating;
            if (secondTeamPlayers[1].isModified === true) match.Challenged.Player2RatingBegin = secondTeamPlayers[1].rating;
        }
    }
};

function stripRatingFronName(playerName) {
    if (playerName == null) return null;
    var d = document.createElement('DIV');
    d.innerHTML = playerName;
    let name = d.removeChild(d.childNodes[0]);
    return name.textContent;
}

function createMatchLinupForValidation(lineup) {

    var newLineup = {
        MatchType: lineup.settings.MatchType,
        FirstTeamPlayers: mapParticipantToPlayers(lineup.match.Challenger, lineup.settings.MatchType),
        SecondTeamPlayers: mapParticipantToPlayers(lineup.match.Challenged, lineup.settings.MatchType)
    };

    var index = lineup.isFirstPlayer ? 0 : 1;

    if (lineup.isFirstTeam) {
        newLineup.FirstTeamPlayers[index].id = lineup.player.id;
        newLineup.FirstTeamPlayers[index].name = lineup.player.name;
    }
    else {
        newLineup.SecondTeamPlayers[index].id = lineup.player.id;
        newLineup.SecondTeamPlayers[index].name = lineup.player.name;
    }

    return newLineup;
}

const validateLineups = function (divisionLineups) {
    for (let key in divisionLineups) {
        const firstTeamPlayers = divisionLineups[key].FirstTeamPlayers;
        const secondTeamPlayers = divisionLineups[key].SecondTeamPlayers;

        if (isSingleMatchType(divisionLineups[key].MatchType)) {
            if (firstTeamPlayers[0].id === secondTeamPlayers[0].id && firstTeamPlayers[0].id > 0) {
                return `${firstTeamPlayers[0].name} is used more than once.`;
            };
        } else {
            if (firstTeamPlayers[0].id <= 0 && firstTeamPlayers[1].id > 0) {
                return `${firstTeamPlayers[1].name} has no partner selected`;
            }

            if (secondTeamPlayers[0].id <= 0 && secondTeamPlayers[1].id > 0) {
                return `${secondTeamPlayers[1].name} has no partner selected`;
            }

            const participants = [firstTeamPlayers[0], firstTeamPlayers[1], secondTeamPlayers[0], secondTeamPlayers[1]];
            for (let i = 0; i < participants.length - 1; i++) {
                if (participants[i].id === 0)
                    continue;

                const found = participants.filter(p => p.id === participants[i].id).length;
                if (found > 1)
                    return `${participants[i].name} is used more than once.`;
            }
        }
    }

    return null;
};

const mapToMatchesLineups = function (divisionLineups) {
    const matchLineups = [];

    for (let key in divisionLineups) {
        const firstTeamPlayerIds = divisionLineups[key].FirstTeamPlayers.map(p => p.id);
        const secondTeamPlayerIds = divisionLineups[key].SecondTeamPlayers.map(p => p.id);

        matchLineups.push({
            ChallengeId: key,
            FirstTeamPlayerIds: firstTeamPlayerIds,
            SecondTeamPlayerIds: secondTeamPlayerIds
        });
    }

    return matchLineups;
};

const createMatchLineup = function (lineup) {
    return {
        MatchType: lineup.settings.MatchType,
        FirstTeamPlayers: mapParticipantToPlayers(lineup.match.Challenger, lineup.settings.MatchType),
        SecondTeamPlayers: mapParticipantToPlayers(lineup.match.Challenged, lineup.settings.MatchType)
    };
};

const updateMatchLineupPlayer = function (matchLineup, newLineup) {
    const players = newLineup.isFirstTeam ? matchLineup.FirstTeamPlayers : matchLineup.SecondTeamPlayers;
    const index = newLineup.isFirstPlayer ? 0 : 1;

    players[index].id = newLineup.player.id;
    players[index].name = newLineup.player.name;
    players[index].rating = newLineup.rating;
    players[index].isModified = true;
};

const mapParticipantToPlayers = function (participant, matchType) {
    if (isSingleMatchType(matchType)) {
        return [
            {
                id: participant.Player1Id,
                name: participant.Name
            }];
    }

    return [
        {
            id: participant.Player1Id,
            name: participant.Name
        },
        {
            id: participant.Player2Id,
            name: participant.Player2Name
        }];
};


const isSingleMatchType = function (matchType) {
    return matchType === 1 || matchType === 2;
};