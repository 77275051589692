export default {
    state: {
        eventName: ''
    },
    mutations: {
        updateTeamLeagueEventName(state, payload) {
            state.eventName = payload;
        },
    },
    actions: {
        updateTeamLeagueEventName: ({ commit }, payload) => {
            commit("updateTeamLeagueEventName", payload);
        }
    },
}