<template>

    <BaseCard type="secondary" :border="true" class="rin-matches-card">
        <template #title>
            <div class="match-title-container">
                <div class="matches-header-left-side">
                    <BaseLabel :type="getEventTypeAsString(invitation.EventType)"></BaseLabel>
                    <div class="rin-matches-name-wrapper">
                        <span v-bind:class="invitation.SportIcon" class="icon-sport icon-custom"></span>&nbsp;
                        <router-link class="rin-matches-title" :to="invitation.EventUrl">{{invitation.EventName}}</router-link>
                    </div>
                </div>
            </div>
        </template>
        <template #header>
            <div class="match-header-content" v-if="invitation.Date !== null">
                <div class="header-item">
                    <BaseIcon name="calendar" />
                    <div class="rin-matches-max-width">
                        {{getDate(invitation.StartDate)}}
                    </div>
                </div>
            </div>
            <div class="match-header-content" v-if="invitation.Location">
                <div class="header-item">
                    <BaseIcon name="pin2" />
                    <div class="rin-matches-max-width">
                        {{invitation.Location}}
                    </div>
                </div>
            </div>
            <div class="match-header-content" v-if="invitation.Ranking !== null">
                <div class="header-item">
                    <BaseIcon name="cup-2" />
                    <div class="rin-matches-max-width">
                        {{invitation.Ranking}}
                    </div>
                </div>
            </div>
            <hr class="upper-row" />
        </template>
        <template #body>
            <div class="invitation-text">{{invitation.Text}}</div>
        </template>
        <template #footer>
            <hr class="lower-row" />
            <div class="matches-buttons-container">
                <div class="left-buttons has-action-buttons">
                    <a class="rin-btn-size-s rin-btn-type-success" href="#"
                        v-on:click="acceptInvitation()"
                       v-bind:class="{ 'disabled': isInAsyncRequest }"
                       v-if="invitation.Buttons.Accept.IsActive">
                        {{translations['player_RequestedMessagesPart_Accept']}}
                    </a>
                    <a class="rin-btn-size-s rin-btn-type-warn"
                       v-on:click="rejectInvitation()" href="#"
                       v-bind:class="{ 'disabled': isInAsyncRequest }"
                       v-if="invitation.Buttons.Reject.IsActive">
                        {{translations['player_RequestedMessagesPart_Reject']}}
                    </a>
                </div>
                <div class="right-buttons">
                    <router-link class="rin-btn-size-s rin-btn-type-tertiary" :to="invitation.Buttons.VisitEvent.Url">Visit Event</router-link>
                </div>
            </div>
        </template>
    </BaseCard>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard.vue'
    import BaseLabel from '@/components/rin_new_design/components/base/BaseTypeLabel'
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';

    export default {
        name: 'InvitationCard',
        components: {
            BaseCard,
            BaseLabel,
            BaseIcon
        },
        props: {
            invitation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                isInAsyncRequest: false
            };
        },
        mounted() {
        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures'])
        },
        methods: {
            getEventTypeAsString(type) {
                switch (type) {
                    case this.nomenclatures.EventTypes.Tournament:
                        return 'tournament';
                    case this.nomenclatures.EventTypes.ClubLeague:
                        return 'clubleague';
                    case this.nomenclatures.EventTypes.TeamLeague:
                        return 'teamleague';
                    default:
                        return '';
                }
            },
            getDate(date) {
                return date.split(' ')[0];
            },
            acceptInvitation() {
                this.isInAsyncRequest = true;
                if (this.invitation.EventType != 4) window.location.assign(this.invitation.Buttons.Accept.Url);
                else this.$http.post(`${this.nomenclatures.ApiDomain}/tournament/AcceptInvitationAsync`, {
                    invitationId: this.invitation.Id
                })
                    .then((response) => {
                        if (response.data.status == this.nomenclatures.TournamentJoinResponseStatus.RedirectToJoinPageAndAskToJoin) {
                            if (response.data.isAskToJoinInSignlesClass) this.$router.push(`/${this.layout.language}/join/tournament/${response.data.tournamentId}?askToJoinAsSingleInClass=true`).catch(() => { });
                            if (response.data.isAskToJoinAsDoubleInClass) this.$router.push(`/${this.layout.language}/join/tournament/${response.data.tournamentId}?askToJoinAsDoubleInClass=${response.data.isAskToJoinAsDoubleInClass}&isAcceptInvitation=${response.data.isAcceptInvitation}&invitationId=${response.data.invitationId}`).catch(() => { });
                            if (response.data.isAskToJoinInTeamClass) this.$router.push(`/${this.layout.language}/join/tournament/${response.data.tournamentId}?askToJoinInTeamClass=${response.data.isAskToJoinInTeamClass}&isAcceptInvitation=${response.data.isAcceptInvitation}`).catch(() => { });
                        }
                        this.isInAsyncRequest = false;
                    }).catch(() => {
                        this.isInAsyncRequest = false;
                    });
            },
            rejectInvitation() {
                this.isInAsyncRequest = true;
                if (this.invitation.EventType != 4) window.location.assign(this.invitation.Buttons.Reject.Url);
                else
                    this.$http.post(`${this.nomenclatures.ApiDomain}/tournament/RejectInvitationAsync`, {
                        invitationId: this.invitation.Id
                    })
                        .then(() => {
                            this.$emit('reload');
                            this.isInAsyncRequest = false;
                        }).catch(() => {
                            this.isInAsyncRequest = false;
                        });
            },
        }
    }
</script>