<template>

    <div>
        <div id="search-app" class="navbar-header navbar-search mainSearch" v-cloak>
            <ul class="nav navbar-nav navbar-iconic-search pull-right">
                <li>
                    <div class="navbar-search-wrapper" v-bind:class="{active : isMobileSearchOpen}">
                        <form class="navbar-search-form search-form-mobile" onsubmit="return false;" role="search">
                            <div class="input-group">
                                <span class="input-group-addon">
                                    <a href="#" v-on:click="openMobileSearch" v-if="!(isSearchOpen)"><i class="fa ti-search"></i></a>
                                    <a href="#" v-on:click="closeResults" v-if="isSearchOpen"><i class="fa ti-close"></i></a>
                                </span>
                                <input type="text" class="form-control" ref="searchinput" v-model="searchTerm" v-on:keyup="search" v-on:input="searchTerm=$event.target.value" v-bind:placeholder="translations['shared_T26'] + '...'" autocomplete="off">
                            </div>
                        </form>
                    </div>
                    <a href="#" v-on:click="openMobileSearch" class="hidden-lg btn-search-mobile" style="margin-right: 40px"><i class="fa ti-search"></i><p class="hidden-xs hidden-sm">Search</p></a>
                </li>
            </ul>



        </div>
        <div id="search-results-app">
            <div v-if="resultsOpen" class="dropdown-menu dropdown-menu-search-results" v-cloak>
                <a href="#" class="btn btn-default" v-on:click="closeResults" style="float: right; position: static; margin-top: 15px;"><i class="fa ti-close"></i></a>
                <div class="text-center alert-warning" style="font-size:20px;" v-if="noResults">{{translations["shared_T27"]}}.</div>
                <div v-if="tournaments.length > 0">
                    <div class="header searchHeader"><h2>{{translations["topBar_18"]}}</h2></div>
                    <div v-for="tournament in tournaments">
                        <a v-bind:href="tournament.EventUrl">
                            <div class="search-result clearfix">
                                <div class="sr-desc">
                                    <div class="flag-and-name">
                                        <i class="flag-icon" v-bind:class="'flag-icon-' + tournament.CountryFlag"></i>
                                        <h4 class="organisation-name">{{ tournament.Name }}</h4>
                                    </div>
                                    <p class="searchResultInfo">
                                        <span class="label label-info">Start date:</span>
                                        <span class="startdate" style="vertical-align: text-top;">{{ tournament.StartDate | dateAndTime }}</span>
                                        <i class="fa" v-bind:class="nomenclatures.SportIconClass[tournament.Sport]"></i>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="footer">
                        <div class="showmore showmoreTournaments col-sm-offset-4 col-sm-4">
                            <button v-on:click="tournamentsLoad" class="btn btn-lg btn-block btn-primary btn-primary-outlined"><i class="fa fa-ellipsis-h"></i>&nbsp;{{translations["shared_ShowMore"]}}</button>
                        </div>
                        <div class="showmore clearfix">
                        </div>
                    </div>
                </div>
                <div v-if="participants.length > 0">
                    <div class="header searchHeader"><h2>{{translations["topBar_19"]}}</h2></div>
                    <div v-for="participant in participants">
                        <div class="search-result sr-type-player clearfix">
                            <div class="sr-desc">
                                <div class="flag-and-name">
                                    <h4 class="participant-name">
                                        <i class="flag-icon" v-bind:class="'flag-icon-' + participant.Flag"></i>
                                        <span>{{ participant.Name }}</span>
                                    </h4>
                                </div>
                                <p class="result-details">{{ participant.Details }}</p><small>ID: </small>
                                <small>{{ participant.RankedinId}}</small>
                                <div class="sr-player-actions hidden">
                                    <div class="btn-group" role="group">
                                        <a v-bind:href="'/' + layout.language + '/player/' + participant.RankedinId" class="btn btn-default btn-sm"><i class="fa fa-fw fa-user"></i><span class="hidden-xs"> {{translations["shared_Matches_Player_Profile"]}}</span></a>                                        
                                        <button type="button" class="btn btn-primary btn-sm close-sr-player-actions"><i class="fa fa-fw fa-times"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="showmore showmoreParticipants col-sm-offset-4 col-sm-4">
                            <button v-on:click="participantsLoad" class="btn btn-lg btn-block btn-primary btn-primary-outlined"><i class="fa fa-ellipsis-h"></i>&nbsp;{{translations["shared_ShowMore"]}}</button>
                        </div>
                        <div class="showmore clearfix">
                        </div>
                    </div>
                </div>
                <div v-if="rankings.length > 0">
                    <div class="header searchHeader"><h2>{{translations["shared_Rankings"]}}</h2></div>
                    <div v-for="ranking in rankings">
                        <div class="search-result clearfix">
                            <div class="sr-desc">
                                <div class="flag-and-name">
                                    <i class="flag-icon" v-bind:class="'flag-icon-' + ranking.Flag"></i> <h4 class="ranking-name">{{ ranking.Name }}</h4>
                                    <div class="result-details">
                                        <span>{{ ranking.OrganisationName }}</span> <i class="fa text-active" v-bind:class="ranking.SportClass"></i>
                                    </div>
                                </div>
                                <div class="sr-player-actions hidden">
                                    <div class="btn-group" role="group">
                                        <a v-bind:href="ranking.Url" class="btn btn-default btn-sm"><i class="fa fa-fw fa-trophy"></i><span class="hidden-xs">{{translations["topBar_31"]}}</span></a>
                                        <button type="button" class="btn btn-primary btn-sm close-sr-player-actions"><i class="fa fa-fw fa-times"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="showmore showmoreRankings col-sm-offset-4 col-sm-4">
                            <button v-on:click="rankingsLoad" class="btn btn-lg btn-block btn-primary btn-primary-outlined"><i class="fa fa-ellipsis-h"></i>&nbsp;{{translations["shared_ShowMore"]}}</button>
                        </div>
                        <div class="showmore clearfix">
                        </div>
                    </div>
                </div>
                <div v-if="organisations.length > 0">
                    <div class="header searchHeader"><h2>{{translations["topBar_22"]}}</h2></div>
                    <div v-for="organisation in organisations">
                        <div class="search-result clearfix">
                            <div class="sr-desc">
                                <div class="flag-and-name">
                                    <i class="flag-icon" v-bind:class="'flag-icon-' + organisation.Flag"></i>
                                    <h4 class="organisation-name">{{ organisation.Name }}</h4>
                                </div>
                                <p class="searchResultInfo"><span class="result-details" style="vertical-align: middle;">{{ organisation.Location }}</span></p>
                                <div class="sr-player-actions hidden">
                                    <div class="btn-group" role="group">
                                        <a v-bind:href="organisation.Url" class="btn btn-default btn-sm"><i class="fa fa-fw fa-home"></i><span class="hidden-xs"> {{translations["tournament_Shared_Organization"]}}</span></a>
                                        <button type="button" class="btn btn-primary btn-sm close-sr-player-actions"><i class="fa fa-fw fa-times"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer">
                        <div class="showmore showmoreOrganisations col-sm-offset-4 col-sm-4">
                            <button v-on:click="organisationsLoad" class="btn btn-lg btn-block btn-primary btn-primary-outlined"><i class="fa fa-ellipsis-h"></i>{{translations["shared_ShowMore"]}}</button>
                        </div>
                        <div class="showmore clearfix">
                        </div>
                    </div>
                </div>
                <div v-if="clubleagues.length > 0">
                    <div class="header searchHeader"><h2>{{translations["topBar_20"]}}</h2></div>
                    <div v-for="clubleague in clubleagues">
                        <a v-bind:href="clubleague.EventUrl">
                            <div class="search-result clearfix">
                                <div class="sr-desc">
                                    <i class="flag-icon" v-bind:class="'flag-icon-' + clubleague.CountryFlag"></i>
                                    <h4 class="organisation-name">{{ clubleague.Name }}</h4>
                                    <p class="searchResultInfo">
                                        <span class="label label-info">{{translations["topBar_26"]}}:</span>
                                        <span class="startdate" style="vertical-align: text-top">{{ clubleague.StartDate | dateAndTime }}</span>
                                        <i class="fa" v-bind:class="nomenclatures.SportIconClass[clubleague.Sport]"></i>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="footer">
                        <div class="showmore showmoreClubLeagues col-sm-offset-4 col-sm-4">
                            <button v-on:click="clubLeaguesLoad" class="btn btn-lg btn-block btn-primary btn-primary-outlined"><i class="fa fa-ellipsis-h"></i>&nbsp;{{translations["shared_ShowMore"]}}</button>
                        </div>
                        <div class="showmore clearfix">
                        </div>
                    </div>
                </div>
                <div v-if="teamleagues.length > 0">
                    <div class="header searchHeader"><h2>{{translations["teamLeaguesTitle"]}}</h2></div>
                    <div v-for="teamleague in teamleagues">
                        <a v-bind:href="teamleague.EventUrl">
                            <div class="search-result clearfix">
                                <div class="sr-desc">
                                    <i class="flag-icon" v-bind:class="'flag-icon-' + teamleague.CountryFlag"></i>
                                    <h4 class="organisation-name">{{ teamleague.Name }}</h4>
                                    <p class="searchResultInfo">
                                        <span class="label label-info">{{translations["topBar_26"]}}:</span>
                                        <span class="startdate" style="vertical-align: text-top;">{{ teamleague.StartDate | dateAndTime }}</span>
                                        <i class="fa" v-bind:class="nomenclatures.SportIconClass[teamleague.Sport]"></i>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div class="footer">
                        <div class="showmore showmoreTeamLeagues col-sm-offset-4 col-sm-4">
                            <button v-on:click="teamLeaguesLoad" class="btn btn-lg btn-block btn-primary btn-primary-outlined"><i class="fa fa-ellipsis-h"></i>&nbsp;{{translations["shared_ShowMore"]}}</button>
                        </div>
                        <div class="showmore clearfix">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
    import moment from 'moment';

    import { MOMENT_DATETIME_FORMAT } from '@/app/constants';
    //to be used in spa only
    import spaEventBus from "@/app/eventBus";
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                // state
                resultsOpen: false,
                isMobileSearchOpen: false,
                searchTerm: '',
                visibilityCss: 'visible',


                rankings: [],
                organisations: [],
                participants: [],
                tournaments: [],
                clubleagues: [],
                teamleagues: [],

                rankingContextButtonsIndex: -1,
                organisationsContextButtonsIndex: -1,
                participantsContextButtonsIndex: -1,


                isRankingShown: true,
                isPlayersShown: true,
                isOrganisationsShown: true,
                isTournamentsShown: true,
                isClubLeaguesShown: true,
                isTeamLeaguesShown: true
            }
        },
        mounted: function () {
  
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            isSearchOpen: function () {
                return this.resultsOpen || this.isMobileSearchOpen;
            },
            noResults: function () {
                return this.rankings.length +
                    this.organisations.length +
                    this.participants.length +
                    this.tournaments.length +
                    this.clubleagues.length +
                    this.teamleagues.length === 0;
            }
        },
        filters: {
            dateAndTime: function (value) {
                return moment.utc(value).format(MOMENT_DATETIME_FORMAT);
            }
        },
        methods: {

            openMobileSearch: function () {
                this.$refs.searchinput.focus();
                this.isMobileSearchOpen = true;
            },






            
        hideAll: function () {
            this.isRankingShown = false;
            this.isPlayersShown = false;
            this.isOrganisationsShown = false;
            this.isTournamentsShown = false;
            this.isClubLeaguesShown = false;
            this.isTeamLeaguesShown = false;
        },
        showAll: function () {
            this.isRankingShown = true;
            this.isPlayersShown = true;
            this.isOrganisationsShown = true;
            this.isTournamentsShown = true;
            this.isClubLeaguesShown = true;
            this.isTeamLeaguesShown = true;
        },
        showRankings: function () {
            this.hideAll();
            this.isRankingShown = true;
        },
        showPlayers: function () {
            this.hideAll();
            this.isPlayersShown = true;
        },
        showOrganisations: function() {
            this.hideAll();
            this.isOrganisationsShown = true;
        },
        showTournaments: function() {
            this.hideAll();
            this.isTournamentsShown = true;
        },
        showClubLeagues: function () {
            this.hideAll();
            this.isClubLeaguesShown = true;
        },
        showTeamLeagues: function () {
            this.hideAll();
            this.isTeamLeaguesShown = true;
        },
        hideOrganisationContextButtons: function () {
            this.organisationsContextButtonsIndex = -1;
        },
        showOrganisationContextButtons: function (index) {
            this.organisationsContextButtonsIndex = index;
        },
        isOrganisationContextButtonsShown: function (index) {
            return this.organisationsContextButtonsIndex == index;
        },
        hideParticipantContextButtons: function () {
            this.participantsContextButtonsIndex = -1;
        },
        showParticipantContextButtons: function (index) {
            this.participantsContextButtonsIndex = index;
        },
        isParticipantContextButtonsShown: function (index) {
            return this.participantsContextButtonsIndex == index;
        },
        hideRankingContextButtons: function () {
            this.rankingContextButtonsIndex = -1;
        },
        showRankingContextButtons: function (index) {
            this.rankingContextButtonsIndex = index;
        },
        isRankingContextButtonsShown: function (index) {
            return this.rankingContextButtonsIndex == index;
        },
        openResults: function () {
            $("#search-results-app").modal("show");

            //turnoff android/ios keyboard 
            document.activeElement.blur();

            //searchApp.visibilityCss = 'invisible';
            this.resultsOpen = true;
        },

        closeResults: function () {
            this.resultsOpen = false;
            this.isMobileSearchOpen = false;
            this.searchTerm = '';
            //searchApp.visibilityCss = 'visible';
            
            $('#search-results-app').modal('toggle');
            $('.mobileSearch').click();
        },


        rankingsLoad: function () {
            var _this = this,
                skip = _this.rankings.length,
                take = 5,
                term = _this.searchTerm;

            $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GetRankingsAsync?language=${this.layout.language}&term=` + term + '&take=' + take + "&skip=" + skip, function (response) {
                _this.rankings = _this.rankings.concat(response);   
            });
        },
        participantsLoad: function () {
            var _this = this,
                skip = _this.participants.length,
                take = 5,
                term = _this.searchTerm;

            $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GetParticipantsAsync?language=${this.layout.language}&term=` + term + '&take=' + take + "&skip=" + skip, function (response) {
                _this.participants = _this.participants.concat(response);
            });
        },
        organisationsLoad: function () {
            var _this = this,
                skip = _this.organisations.length,
                take = 5,
                term = _this.searchTerm;

            $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GetOrganisationsAsync?language=${this.layout.language}&term=` + term + '&take=' + take + "&skip=" + skip, function (response) {
                _this.organisations = _this.organisations.concat(response);
            });
        },
        tournamentsLoad: function () {
            var _this = this,
                skip = _this.tournaments.length,
                take = 5,
                term = _this.searchTerm;


            this.$http.get(`${this.nomenclatures.ApiDomain}/Search/GetTournamentsAsync`, {
                params: {
                    term: term,
                    skip: skip,
                    take: take,
                    language: _this.layout.language
                }
            })
                .then(function (response) {
                    _this.tournaments = _this.tournaments.concat(response.data);
                });
        },
        teamLeaguesLoad: function () {
            var _this = this,
                skip = _this.teamleagues.length,
                take = 5,
                term = _this.searchTerm;

            this.$http.get(`${this.nomenclatures.ApiDomain}/Search/GetTeamLeaguesAsync`, {
                params: {
                    term: term,
                    skip: skip,
                    take: take,
                    language: _this.layout.language
                }
            })
                .then(function (response) {
                    _this.teamleagues = _this.teamleagues.concat(response.data);
                });
        },
        clubLeaguesLoad: function () {
            var _this = this,
                skip = _this.clubleagues.length,
                take = 5,
                term = _this.searchTerm;

            this.$http.get(`${this.nomenclatures.ApiDomain}/Search/GetClubLeaguesAsync`, {
                params: {
                    term: term,
                    skip: skip,
                    take: take,
                    language: _this.layout.language
                }
            })
                .then(function (response) {
                    _this.clubleagues = _this.clubleagues.concat(response.data);
                });
        },

        getSerchResults: function (term, id) {

            var _this = this;


            rankedin.spinner.show(0);
            $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GlobalSearchAsync`, "term=" + term  + "&language=" + _this.layout.language, function (response) {

                _this.participants = _this.participants = response.Participants;
                _this.clubleagues = _this.clubleagues = response.ClubLeagues;
                _this.teamleagues = _this.teamleagues = response.TeamLeagues;
                _this.tournaments = _this.tournaments = response.Tournaments;
                _this.organisations = _this.organisations = response.Organisation;
                _this.rankings = _this.rankings = response.Rankings;

                _this.rankingContextButtonsIndex = -1;
                _this.organisationsContextButtonsIndex = -1;
                _this.participantsContextButtonsIndex = -1;

            })
                .done(function () {
                    setTimeout(function () {
                        _this.openResults();
                    }, 50);
                })
                .fail(function (e) {
                    console.log("error");
                    console.log("term: " + term);
                })
                .always(function () {
                    rankedin.spinner.hide(0);
                    console.log("complete");
                });
            },
        search: _.debounce(function () {
            var _this = this;
            var id = rankedin.currentPlayerId || 0;
            if (_this.searchTerm) {
                this.getSerchResults(_this.searchTerm, id);
            }
        }, 1200)
    
        }
    };
</script>

<style>

    .container-fluid > .navbar-search .navbar-iconic-search {
        margin-left: 0;
    }


    .dropdown-menu-search-results {
        display: block;
    }

    .search-result > .sr-img {
        height: 55px;
    }

    .search-result:hover {
        background-color: #d8d8d8;
    }

    .sr-social {
        height: 55px;
    }

    @media(max-width:320px) {
        .navbarList {
            margin-right: 0 !important;
        }

        .navbarMobileDropdown {
            margin-right: 0;
        }
    }

    p.result-details {
        margin: 0;
    }

    @media (max-width:1200px) {
        .navbar-search-wrapper {
            display: none;
        }

            .navbar-search-wrapper.active {
                display: block;
            }
    }
</style>