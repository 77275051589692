<template>
    <div>
        <BaseModalWrapper size="l" :show="showAd"  @close="showAd = false">
            <template #body>
                <div class="d-flex align-items-center newsletter-campaign-container rin-newsletter-campaing-wrapper">
                    <div class="p-4">
                        <img class="img-fluid" src="https://cdn.rankedin.com/images/static/campaign/newsletter-campaign.png" alt="newsletter campaign image" />
                    </div>
                    <div class="d-flex flex-column text-center">
                        <div class="h1 p-3">{{translations['campaign_Newsletter_Line1']}}</div>
                        <div class=" p-3">
                            <span class="font-weight-bold">  {{translations['campaign_Newsletter_Line2']}}</span>
                            <ul class="text-left pt-2">
                                <li>{{translations['campaign_Newsletter_Line2_1']}}</li>
                                <li>{{translations['campaign_Newsletter_Line2_2']}}</li>
                                <li>{{translations['campaign_Newsletter_Line2_3']}}</li>
                                <li>{{translations['campaign_Newsletter_Line2_4']}}</li>
                                <li>{{translations['campaign_Newsletter_Line2_5']}}</li>
                            </ul>
                        </div>
                        <div class="font-weight-bold p-3"><span>{{translations['campaign_Newsletter_Line3']}}</span></div>
                        <div class="p-3 d-flex justify-content-center">
                            <BaseButton type="success" size="m" @click.native="subscribe">
                                <span v-if="!isSubscribing">{{translations['campaign_Newsletter_ButtonText']}}</span>
                                <BaseSpinner size="s" v-if="isSubscribing"></BaseSpinner>
                            </BaseButton>
                        </div>
                    </div>

                </div>
            </template>
        </BaseModalWrapper>

        <BaseModalWrapper size="s" :show="showConfirmation" @close="showConfirmation = false">
            <template #body>
                <div class="p-3 d-flex flex-column text-center newsletter-campaign-container">
                    <div class="p-2"><span class="h1">Thank you!</span></div>
                    <div class="p-2">You have been subscribed successfully.</div>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex w-100 justify-content-center">
                    <BaseButton type="tertiary" size="m" @click.native="dismissConfirmation">
                        {{translations['shared_CloseButton']}}
                    </BaseButton>
                </div>
            </template>
        </BaseModalWrapper>

    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';
    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner.vue';

    const newsletterCampaignSettings = {
        name: 'NewsletterCampaign',
        async checkAudienceAsync(params) {
            if (params.store.getters.layout.isEmbed) return Promise.resolve(false);
            
            if (params.store.getters.layout.player.isAuthenticated) {

                let checkIsNewsetterSubscribedAsync = params.http.get(`${params.store.getters.nomenclatures.ApiDomain}/settings/CheckIsNewsetterSubscribedAsync`)
                    .then((res) => res.data );

                var isSubscribed = await Promise.resolve(checkIsNewsetterSubscribedAsync);
                return !isSubscribed;
            }

            return Promise.resolve(false);
        }
    };
    export { newsletterCampaignSettings };

    export default {
        name: 'NewsletterCampaign',
        props: {},
        data() {
            return {
                showAd: true,
                showConfirmation: false,
                isSubscribing:false
            }
        },
        components: {
            BaseModalWrapper,
            BaseButton,
            BaseSpinner
        },
        mounted() {

        },
        computed: {
            ...mapGetters(['translations', 'layout', 'nomenclatures']),
           
        },
        methods: {
            subscribe() {
                if (this.isSubscribing) return;
                this.isSubscribing = true;
                this.$http.post(`${this.nomenclatures.ApiDomain}/settings/SubscribeForNewsletterAsync`)
                    .then(() => {
                        this.isSubscribing = false;
                        this.showAd = false;
                        this.showConfirmation = true;
                    });
            },
            dismissConfirmation() {
                this.showAd = false;
                this.showConfirmation = false;         
            }
        }
    }
</script>