<template>
	<div class="rin-register-email-password-container rin-register-form">
		<h1>
			<span>Welcome to</span>
			<img src="https://cdn.rankedin.com/images/static/logoBlue.svg" alt="logo" height="20" />
		</h1>
		<span class="rin-register-email-password-subtitle">{{ translations['register_CreateFreeAccount'] }}</span>

		<div class="rin-register-form-container">
			<div class="rin-register-input-container">
				<label for="email" class="rin-register-label">{{ translations['email'] }}</label>
				<input id="email" v-model.trim="useremail" class="rin-register-input" type="email" autocomplete="off"
					:placeholder="translations['email']" inputmode="search" v-bind:class="{
						'has-error':
							$v.useremail.$dirty &&
							(!$v.useremail.required || !$v.useremail.isValidEmail || existingAccount || !$v.useremail.minLength || !$v.useremail.maxLength),
					}" @blur="$v.useremail.$touch()" />
			</div>
			<div v-cloak>
				<BaseAlert type="danger" :has-background="false">
					<span v-if="$v.useremail.$dirty && (!$v.useremail.required || !$v.useremail.isValidEmail)"
						class="rin-register-error">
						{{ translations['register_InvalidEmail'] }}
					</span>
					<span v-if="$v.useremail.$dirty && !$v.useremail.minLength" class="rin-register-error">
						{{ interpolateString(translations['register_FieldMinLength'], [6]) }}
					</span>
					<span v-if="$v.useremail.$dirty && !$v.useremail.maxLength" class="rin-register-error">
						{{ interpolateString(translations['register_FieldMaxLength'], [150]) }}
					</span>
					<span class="rin-register-error" v-if="existingAccount">
						{{ translations['register_UserExistsValidationMessage'] }}
					</span>
				</BaseAlert>
			</div>

			<div class="rin-register-input-container">
				<label for="password" class="rin-register-label">{{ translations['register_Password'] }}</label>
				<input id="password" v-model="userpassword" class="rin-register-input" type="password" inputmode="search"
					:placeholder="translations['register_Password']" autocomplete="new-password" v-bind:class="{
						'has-error':
							$v.userpassword.$dirty && (!$v.userpassword.required || !$v.userpassword.minLength),
					}" @blur="$v.userpassword.$touch()" />
			</div>

			<div v-cloak>
				<BaseAlert type="danger" :has-background="false">
					<span v-if="$v.userpassword.$dirty && !$v.userpassword.required" class="rin-register-error">
						{{ translations['register_PasswordRequiredValidation'] }}
					</span>
					<span v-if="$v.userpassword.$dirty && !$v.userpassword.minLength" class="rin-register-error">
						{{ translations['register_PasswordMinLengthValidation'] }}
					</span>
				</BaseAlert>
			</div>

			<div class="rin-register-checkbox-container mt-2">
				<BaseCheckbox :is-checked="userNewsletter" @click.native="userNewsletter = !userNewsletter">
				</BaseCheckbox>
				<span>{{ translations['register_NewsletterLabel'] }}</span>
			</div>

			<div class="rin-register-checkbox-container">
				<BaseCheckbox :is-checked="userTerms" @click.native="toggleTerms"> </BaseCheckbox>
				<span v-html="termsTranslation"></span>
			</div>

			<div v-cloak>
				<BaseAlert type="danger" :has-background="false">
					<span v-if="$v.userTerms.$dirty && !$v.userTerms.sameAs" class="rin-register-error">
						{{ translations['register_YouHaveToAcceptTermsFirst'] }}
					</span>
				</BaseAlert>
			</div>

			<BaseButton type="success" size="m" @click="goToNext" :disabled="isChecking">
				{{ translations['register_CreateAccount'] }}
			</BaseButton>

			<h6>Already a member? <span @click="goToLogin">Log in</span></h6>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { required, minLength, maxLength, sameAs } from 'vuelidate/lib/validators';
import utils from '@/mixins/utils.js';

import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
import BaseCheckbox from '@/components/rin_new_design/components/base/BaseCheckbox.vue';

export default {
	name: 'RegisterEmailPassword',
	components: {
		BaseAlert,
		BaseButton,
		BaseCheckbox,
	},
	props: ['nextRef'],
	mixins: [utils],
	computed: {
		...mapGetters(['translations', 'layout', 'nomenclatures']),
		...mapGetters('register', ['email', 'password', 'terms', 'newsletter']),
		useremail: {
			get() {
				return this.email;
			},
			set(v) {
				this.existingAccount = false;
				this.updateEmail(v);
			},
		},
		userpassword: {
			get() {
				return this.password;
			},
			set(v) {
				this.updatePassword(v);
			},
		},
		userNewsletter: {
			get() {
				return this.newsletter;
			},
			set(v) {
				this.updateNewsletter(v);
			},
		},
		userTerms: {
			get() {
				return this.terms;
			},
			set(v) {
				this.updateTerms(v);
			},
		},
		passwordValidationText: function () {
			if (this.$v.userpassword.$dirty && !this.$v.userpassword.required) {
				return this.translations['register_PasswordRequiredValidation'];
			}

			if (this.$v.userpassword.$dirty && !this.$v.userpassword.minLength) {
				return this.translations['register_PasswordMinLengthValidation'];
			}

			return null;
		},

		termsTranslation: function () {
			const register_ReadPolicyAndTermsConfirmationMessage_TermsAndConditions = this.interpolateString(
				`<a href="/${this.layout.language}/termsandconditions" target="_blank">{0}</a>`,
				[this.translations['register_ReadPolicyAndTermsConfirmationMessage_TermsAndConditions']]
			);
			const register_ReadPolicyAndTermsConfirmationMessage_PrivacyPolicy = this.interpolateString(
				`<a href="/${this.layout.language}/privacypolicy" target="_blank">{0}</a>`,
				[this.translations['register_ReadPolicyAndTermsConfirmationMessage_PrivacyPolicy']]
			);
			return this.interpolateString(this.translations['register_ReadPolicyAndTermsConfirmationMessage'], [
				register_ReadPolicyAndTermsConfirmationMessage_TermsAndConditions,
				register_ReadPolicyAndTermsConfirmationMessage_PrivacyPolicy,
			]);
		},
	},
	data() {
		return {
			existingAccount: false,
			isChecking: false,
		};
	},
	methods: {
		...mapMutations('register', ['updateEmail', 'updatePassword', 'updateTerms', 'updateNewsletter', 'clearAll']),
		...mapActions('register', ['setRegisterModalState']),
		toggleTerms() {
			this.userTerms = !this.userTerms;
			this.$v.userTerms.$touch();
		},
		goToLogin() {
			if (this.$route.name !== 'login') {
				this.$router.push({ name: 'login', params: { language: this.layout.language } });
			}

			this.setRegisterModalState(false);
			this.clearAll();
		},
		goToNext() {
			if (this.isChecking) return;
			
			this.$v.useremail.$touch();
			this.$v.userpassword.$touch();
			this.$v.userTerms.$touch();
			if (!this.$v.useremail.$error && !this.$v.userpassword.$error && !this.$v.userTerms.$error) {

				this.isChecking = true;
				this.$http
					.post(`${this.nomenclatures.ApiDomain}/account/CheckAccountExistsAsync`, {
						EMail: this.useremail.trim(),
					})
					.then((response) => {
						if (response.data) {
							this.existingAccount = true;
						} else {
							this.nextRef.click();
						}

						setTimeout(() => {
							this.isChecking = false;
						}, 500);
					})
					.catch(function (e) {
						console.log(e);
					});
			}
		},
	},
	validations: {
		useremail: {
			required: required,
			minLength: minLength(6),
			maxLength: maxLength(150),
			isValidEmail: function isValidEmail(value) {
				value = value.trim();
				var re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
				return re.test(value);
			},
		},
		userpassword: {
			required: required,
			minLength: minLength(8),
		},
		userTerms: {
			sameAs: sameAs(() => true),
		},
	},
	mounted() {
		this.isChecking = false;
	},
};
</script>
