import spaEventBus from "@/app/eventBus";

export const INIT_TOURNAMENT_MATCHES = 'initTournamentMatches';
export const INIT_CLUBLEAGUE_MATCHES = 'initClubLeagueMatches';

export const GET_RR_STANDINGS_SETTINGS_ASYNC = 'getRRStandingsSettingsAsync';

export const SET_IS_LOADING_STANDINGS_SETTINGS = 'setIsLoadingStandingsSettings';
export const SET_IS_LOADED_STANDINGS_SETTINGS = 'setIsLoadedStandingsSettings';
export const SET_STANDINGS_SETTINGS = 'setStandingsSettings';

export default {
    namespaced: true,
    state: {
        isSavingChangesCount: 0,
        clubLeagueId: null,        
        teamLeagueId: null,
        tournamentId: null,
        repositories: null,

        tournament: {
            id: 0,
            classId: 0,
            model: {}
        },
        club_league: {
            id: 0,
        },
        language_code: 'en',
        event_type: null,
        is_loading_standings_settings: false,
        is_loaded_standings_settings: false,
        standings_standings: {},
    },
    getters: {
        isSavingChanges(state) {
            return state.isSavingChangesCount > 0;
        },

        standingsSettings: state =>
            state.standings_standings,

        isLoadingStandingsSettings: state =>
            state.is_loading_standings_settings,

        isLoadedStandingsSettings: state =>
            state.is_loaded_standings_settings,
    },
    mutations: {
        setTeamLeagueId(state, payload) {
            state.teamLeagueId = payload;
        },
        setClubLeagueId(state, payload) {
            state.clubLeagueId = payload;
        },
        setTournamentId(state, payload) {
            state.tournamentId = payload;
        },
        saveChangesBegin(state) {
            state.isSavingChangesCount++;
        },
        saveChangesFinished(state) {
            if (state.isSavingChangesCount > 0) {
                state.isSavingChangesCount--;
                if (state.isSavingChangesCount === 0) {
                    spaEventBus.$emit('saveChangesFinished');
                }
            }
        },
        [INIT_TOURNAMENT_MATCHES]: (state, config) => {
            state.repositories = config.repositories;
            state.tournament.id = config.tournamentId;
            state.tournament.classId = config.tournamentClassId;
            state.language_code = config.languageCode;
            state.event_type = config.eventType;
        },
        [INIT_CLUBLEAGUE_MATCHES]: (state, config) => {
            state.repositories = config.repositories;
            state.club_league.id = config.clubLeagueId;
            state.language_code = config.languageCode;
            state.event_type = config.eventType;
        },       
        [SET_IS_LOADING_STANDINGS_SETTINGS]: (state, payload) => {
            state.is_loading_standings_settings = payload;
        },
        [SET_STANDINGS_SETTINGS]: (state, payload) => {
            state.standings_standings = payload;
        },
        [SET_IS_LOADED_STANDINGS_SETTINGS]: (state, payload) => {
            state.is_loaded_standings_settings = payload;
        }
    },
    actions: {
        [GET_RR_STANDINGS_SETTINGS_ASYNC]: ({ state, commit, rootState }) => {
            commit(SET_IS_LOADED_STANDINGS_SETTINGS, false);
            commit(SET_IS_LOADING_STANDINGS_SETTINGS, true);

            const repository = state.repositories.roundrobin;
            let getSettingsAsync;
            if (state.event_type === rootState.nomenclatures.nomenclatures.EventTypes.ClubLeague) {
                getSettingsAsync = repository.getClubLeagueStandingsSettingsAsync(state.club_league.id);
            } else {
                getSettingsAsync = repository.getTournamentStandingsSettingsAsync(state.tournament.id, state.tournament.classId);
            }

            return getSettingsAsync
                .then(response => {
                    commit(SET_IS_LOADED_STANDINGS_SETTINGS, true);
                    commit(SET_STANDINGS_SETTINGS, response);
                })
                .catch(error => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    commit(SET_IS_LOADING_STANDINGS_SETTINGS, false);
                });
        }
    }
};