import { store } from "@/store/store";

export default {
    alert(title, body) {
        store.dispatch('rinBootbox/show', { title, body, alert: true });
    },
    alerthtml(title, bodyhtml) {
        store.dispatch('rinBootbox/show', { title, bodyhtml, alert: true });
    },
    close() {
        var closeButtonCallback = store.getters['rinBootbox/closeCb'];

        if (closeButtonCallback)
            closeButtonCallback();

        store.dispatch('rinBootbox/close');
    },
    success() {
        var successButtonCallback = store.getters['rinBootbox/successCb'];

        if (successButtonCallback)
            successButtonCallback();

        store.dispatch('rinBootbox/close');
    },
    confirm({ title, body, successButtonCallback, cancelButtonCallback, successButtonText, bodyhtml }) {
        store.dispatch('rinBootbox/show', {
            title,
            body,
            alert: false,
            confirm: true,
            successCb: successButtonCallback,
            cancelCb: cancelButtonCallback,
            successText: successButtonText,
            bodyhtml
        });
    }
};