import _ from 'lodash'

export default {
    methods: {
        isFunction: function (functionToCheck) {
            return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
        },
        interpolateString: function (string, params) {
            let interpolatedString = string;
            for (var i = 0; i < params.length; i++) {
                interpolatedString = _.replace(interpolatedString, '{' + i + '}', params[i]);
            }
            return interpolatedString;
        },
        hasValue: function (value) {
            if (typeof (value) == 'undefined'
                || value == '' || value == null) return false;

            return true;
        },
    }
}