export default {
    state: {
        classToEdit: {},
        classes: [],
        tournamentDates: [],
        playDates: [],
        classesToMerge: [],
        tournamentId: 0,
        inEdit: false,
        inMerge: false,
        teamSettings: [],
        teamSettingsForEdit: [],
        ageGroups: [],
        ageGroupsPerMatchType: []
    },
    mutations: {
        initTeamSettings(state, payload) {
            Vue.set(state, 'teamSettings', payload);
        },
        updateTeamSetting(state, payload) {
            const index = state.teamSettings.findIndex(ts => ts.MatchType === payload.MatchType);
            state.teamSettings.splice(index, 1, payload);
        },
        updateTeamSettingForEdit(state, payload) {
            const index = state.teamSettingsForEdit.findIndex(ts => ts.MatchType === payload.MatchType);
            state.teamSettingsForEdit.splice(index, 1, payload);
        },
        updateTeamSettingsForEdit(state, payload) {
            state.teamSettingsForEdit = payload;
        },
        updateClass(state, payload) {
            state.classToEdit = payload;
        },
        updateClasses(state, payload) {
            state.classes = payload;
        },
        updateInEdit(state, payload) {
            state.inEdit = payload;
        },
        updateInMerge(state, payload) {
            state.inMerge = payload;
        },
        updateTournamentDates(state, payload) {
            state.tournamentDates = payload;
        },
        updatePlayDates(state, payload) {
            state.playDates = payload;
        },
        updateTournamentId(state, payload) {
            state.tournamentId = payload;
        },
        updateStoreClassesToMerge(state, payload) {
            state.classesToMerge = payload;
        },
        updateAgeGroups(state, payload) {
            state.ageGroups = payload.ageGroups;
            state.ageGroupsPerMatchType = payload.ageGroupsPerMatchType;
        },
    },
    actions: {
        updateClass: ({ commit }, payload) => {
            commit("updateClass", payload);
        },
        updateClasses: ({ commit }, payload) => {
            commit("updateClasses", payload);
        },
        updateInEdit: ({ commit }, payload) => {
            commit("updateInEdit", payload);
        },
        updateInMerge: ({ commit }, payload) => {
            commit("updateInMerge", payload);
        },
        updateTournamentDates: ({ commit }, payload) => {
            commit("updateTournamentDates", payload);
        },
        updatePlayDates: ({ commit }, payload) => {
            commit("updatePlayDates", payload);
        },
        updateTournamentId: ({ commit }, payload) => {
            commit("updateTournamentId", payload);
        },
        updateStoreClassesToMerge: ({ commit }, payload) => {
            commit("updateStoreClassesToMerge", payload);
        },
        updateAgeGroups: ({ commit }, payload) => {
            commit("updateAgeGroups", payload);
        },

    },
}