<template>
    <div class="modal" id="search-results-app" role="dialog">
        <div class="modal-dialog" v-show="resultsOpen">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="secondary-search fixed-search col-12 px-0">
                        <div class="searchTop-secondary py-2 px-1  col-auto" v-cloak>
                            <form action="javascript:void(0);">
                                <div class="row">
                                    <div class="col px-0">
                                        <input type="text" class="search-input-field" name="searchtop" v-on:input="searchTerm=$event.target.value" v-model="searchTerm" v-on:keyup="search" placeholder="Search Players, Events, Clubs" autocomplete="off">
                                    </div>
                                    <div class="col px-0 col-auto">
                                        <button type="submit" class="search-magnifying-glass" v-on:click="search"><span class="icon icon-search"></span></button>
                                    </div>
                                    <button class="col-1 pl-3 pb-3 pr-1 bg-transparent close-modal" type="button" data-dismiss="modal" v-on:click="closeResults()" aria-label="Close"><span class="text-dark icon icon-plus"></span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="submenu submenu-search m-auto">
                        <div class="">
                            <div class="row jcc jcb-lg aic">
                                <div class="col col-auto">
                                    <ul>
                                        <li><a v-on:click="showAll()" href="javascript:;">{{translations['refereeCodesPartial_All']}}</a></li>
                                        <li><a v-bind:class="{'active': isTournamentsSelected }" v-on:click="showTournaments()" href="javascript:;">{{translations['topBar_18']}}</a></li>
                                        <li><a v-bind:class="{'active': isPlayersSelected }" v-on:click="showPlayers()" href="javascript:;">{{translations['topBar_19']}}</a></li>
                                        <li><a v-bind:class="{'active': isRankingSelected }" v-on:click="showRankings()" href="javascript:;">{{translations['topBar_31']}}</a></li>
                                        <li><a v-bind:class="{'active': isOrganisationsSelected }" v-on:click="showOrganisations()" href="javascript:;">{{translations['topBar_16']}}</a></li>
                                        <li><a v-bind:class="{'active': isClubLeaguesSelected }" v-on:click="showClubLeagues()" href="javascript:;">{{translations['topBar_20']}}</a></li>
                                        <li><a v-bind:class="{'active': isTeamLeaguesSelected }" v-on:click="showTeamLeagues()" href="javascript:;">{{translations['teamLeaguesTitle']}}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="submenuNav">
                                <div class="prev icon icon-v"></div>
                                <div class="next icon icon-v"></div>
                            </div>
                        </div>
                    </div>
                    <div class="submenuFixSearch col-12"></div>
                    <section class="clear">
                        <div class="">
                            <div class="row ais">
                                <div class="content col-12">
                                    <div class="searchResults">
                                        <div class="row">
                                            <div class="col-12 text-center" style="font-size:20px;" v-show="noResults">{{translations['shared_T27']}}.</div>
                                            <div class="col-12" v-if="isTournamentsShown && tournaments.length > 0">
                                                <p><strong>{{translations['topBar_18']}}</strong></p>
                                            </div>
                                            <div v-show="isTournamentsShown" class="col-12 searchResultsSingle" v-for="(tournament,index) in tournaments">
                                                <div class="row nowrap">
                                                    <div class="cont col">
                                                        <div class="row">
                                                            <div class="flag col-auto"><i class="flag-icon" v-bind:class="'flag-icon-' + tournament.CountryFlag"></i></div>
                                                            <div class="name col"><router-link v-bind:to="tournament.EventUrl" @click.native="closeResults"><strong>{{ tournament.Name }}</strong></router-link></div>
                                                            <div class="info col-12"><p>{{translations['topBar_26']}}: {{ tournament.StartDate | dateAndTime }} <i class="fa" v-bind:class="nomenclatures.SportIconClass[tournament.Sport]"></i></p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center my-3" v-if="isTournamentsShown && tournaments.length > 0">
                                                <a href="javascript:;" class="btn btn-success" v-on:click="tournamentsLoad">{{translations['shared_T57']}}</a>
                                            </div>
                                            <div class="col-12" v-if="isPlayersShown && participants.length > 0">
                                                <p><strong>{{translations['topBar_19']}}</strong></p>
                                            </div>
                                            <div v-show="isPlayersShown" v-for="(participant,index) in participants" class="col-12 searchResultsSingle">
                                                <div class="row nowrap">
                                                    <div class="cont col">
                                                        <div class="row">
                                                            <div class="flag col-auto"><i class="flag-icon" v-bind:class="'flag-icon-' + participant.Flag"></i></div>
                                                            <div class="name col"><a v-on:click="showParticipantContextButtons(index)" href="javascript:;"><strong>{{ participant.Name }}</strong></a></div>
                                                            <div class="info col-12"><p>{{ participant.Details }} <br><strong>ID: {{ participant.RankedinId}}</strong></p></div>
                                                            <div class="buttons col-12" v-show="isParticipantContextButtonsShown(index)">
                                                                <router-link v-bind:to="'/' + layout.language + '/player/' + participant.RankedinId" class="btn btn-sm btn-success"  @click.native="closeResults">{{translations['shared_Matches_Player_Profile']}}</router-link>
                                                                <router-link v-bind:playerid="participant.Id" v-bind:to="'/' + layout.language + '/messages/beginchat?playerId='+ participant.Id" class="btn btn-sm btn-primary">{{translations['matchesPartial_Message']}}</router-link>
                                                                <a v-on:click="hideParticipantContextButtons()" href="#" class="close"><span class="icon icon-plus"></span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center my-3" v-if="isPlayersShown && participants.length > 0">
                                                <a href="javascript:;" class="btn btn-success" v-on:click="participantsLoad">{{translations['shared_T57']}}</a>
                                            </div>
                                            <div class="col-12" v-if="isRankingShown && rankings.length > 0">
                                                <p><strong>{{translations['shared_Rankings']}}</strong></p>
                                            </div>
                                            <div v-show="isRankingShown" v-for="(ranking,index) in rankings" class="col-12 searchResultsSingle">
                                                <div class="row nowrap">
                                                    <div class="cont col">
                                                        <div class="row">
                                                            <div class="flag col-auto"> <i class="flag-icon" v-bind:class="'flag-icon-' + ranking.Flag"></i> </div>
                                                            <div class="name col"><strong><a href="javascript:;" v-on:click="showRankingContextButtons(index)">{{ ranking.Name }}</a></strong></div>
                                                            <div class="info col-12"><p>{{ranking.OrganisationName }}<i class="fa text-active" v-bind:class="ranking.SportClass"></i></p></div>
                                                            <div class="buttons col-12" v-show="isRankingContextButtonsShown(index)">
                                                                <router-link v-bind:to="'/' + layout.language + '/organisation/' + ranking.RankingOrganisationId" class="btn btn-sm btn-success"  @click.native="closeResults">{{translations['tournament_Shared_Organization']}}</router-link>
                                                                <router-link v-bind:to="'/' + layout.language + '/ranking/' + ranking.Id" class="btn btn-sm btn-primary"  @click.native="closeResults">{{translations['topBar_31']}}</router-link>
                                                                <router-link v-bind:to="'/' + layout.language + '/ranking/settings/' + ranking.Id" class="btn btn-sm">{{translations['player_Settings']}} <span class="icon icon-settings"></span></router-link>
                                                                <a v-on:click="hideRankingContextButtons()" href="#" class="close"><span class="icon icon-plus"></span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center my-3" v-if="isRankingShown && rankings.length > 0">
                                                <a href="#" v-on:click="rankingsLoad" class="btn btn-success">{{translations['shared_T57']}}</a>
                                            </div>
                                            <div class="col-12" v-if=" isOrganisationsShown && organisations.length > 0">
                                                <p><strong>{{translations['topBar_16']}}</strong></p>
                                            </div>
                                            <div v-show="isOrganisationsShown" v-for="(organisation,index) in organisations" class="col-12 searchResultsSingle">
                                                <div class="row nowrap">
                                                    <div class="cont col">
                                                        <div class="row">
                                                            <div class="flag col-auto"><i class="flag-icon" v-bind:class="'flag-icon-' + organisation.Flag"></i></div>
                                                            <div class="name col"><router-link v-bind:to="'/' + layout.language + '/organisation/' + organisation.Id"  @click.native="closeResults"><strong>{{ organisation.Name }}</strong></router-link></div>
                                                            <div class="info col-12"><p>{{ organisation.Location }}</p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center my-3" v-if="isOrganisationsShown && organisations.length > 0">
                                                <a href="javascript:;" class="btn btn-success" v-on:click="organisationsLoad">{{translations['shared_T57']}}</a>
                                            </div>
                                            <div class="col-12" v-if="isClubLeaguesShown && clubleagues.length > 0">
                                                <p><strong>{{translations['topBar_20']}}</strong></p>
                                            </div>
                                            <div v-show="isClubLeaguesShown" class="col-12 searchResultsSingle" v-for="clubleague in clubleagues">
                                                <div class="row nowrap">
                                                    <div class="cont col">
                                                        <div class="row">
                                                            <div class="flag col-auto"><i class="flag-icon" v-bind:class="'flag-icon-' + clubleague.CountryFlag"></i></div>
                                                            <div class="name col"><router-link v-bind:to="clubleague.EventUrl"  @click.native="closeResults"><strong>{{ clubleague.Name }}</strong></router-link></div>
                                                            <div class="info col-12"><p>{{translations['topBar_26']}}: {{ clubleague.StartDate | dateAndTime}} <i class="fa" v-bind:class="nomenclatures.SportIconClass[clubleague.Sport]"></i></p></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center my-3" v-if="isClubLeaguesShown && clubleagues.length > 0">
                                                <a href="javascript:;" class="btn btn-success" v-on:click="clubLeaguesLoad">{{translations['shared_T57']}}</a>
                                            </div>
                                            <div class="col-12" v-if="isTeamLeaguesShown && teamleagues.length > 0">
                                                <p><strong>{{translations['teamLeaguesTitle']}}</strong></p>
                                            </div>
                                            <div v-show="isTeamLeaguesShown" class="col-12 searchResultsSingle" v-for="teamleague in teamleagues">
                                                <div class="row nowrap">
                                                    <div class="cont col">
                                                        <div class="row">
                                                            <div class="flag col-auto"><i class="flag-icon" v-bind:class="'flag-icon-' + teamleague.CountryFlag"></i></div>
                                                            <div class="name col"><router-link v-bind:to="teamleague.EventUrl"  @click.native="closeResults"><strong>{{ teamleague.Name }}</strong></router-link></div>
                                                            <div class="info col-12"><p>{{translations['topBar_26']}}: {{ teamleague.StartDate | dateAndTime }}  <i class="fa" v-bind:class="nomenclatures.SportIconClass[teamleague.Sport]"></i></p></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 text-center my-3" v-if="isTeamLeaguesShown && teamleagues.length > 0">
                                                <a href="javascript:;" class="btn btn-success" v-on:click="teamLeaguesLoad">{{translations['shared_T57']}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from 'moment';

    import { MOMENT_DATETIME_FORMAT } from '@/app/constants';
    //to be used in spa only
    import spaEventBus from "@/app/eventBus";
    import { mapGetters } from 'vuex';
    import _ from 'lodash';

    export default {
        data() {
            return {
                rankings: [],
                organisations: [],
                participants: [],
                tournaments: [],
                clubleagues: [],
                teamleagues: [],

                rankingContextButtonsIndex: -1,
                organisationsContextButtonsIndex: -1,
                participantsContextButtonsIndex: -1,

                // state
                resultsOpen: false,                        

                isRankingShown: true,
                isPlayersShown: true,
                isOrganisationsShown: true,
                isTournamentsShown: true,
                isClubLeaguesShown: true,
                isTeamLeaguesShown: true,

                isRankingSelected: false,
                isPlayersSelected: false,
                isOrganisationsSelected: false,
                isTournamentsSelected: false,
                isClubLeaguesSelected: false,
                isTeamLeaguesSelected: false,

            }
        },
        filters: {
            dateAndTime: function (value) {
                return moment.utc(value).format(MOMENT_DATETIME_FORMAT);
            }
        },
        mounted: function () {
            var _this = this;            
            spaEventBus.$on('global-search-get-results', (arg) => {
                _this.getSerchResults(arg.id);
            });
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            searchTerm: {
                get: function () {
                    return this.$store.getters.getGlobalSearchTerm;
                },
                set: function (newValue) {
                    this.$store.dispatch("updateGobalSearchTerm", newValue);
                }
            },
            noResults: function () {
                return this.rankings.length +
                    this.organisations.length +
                    this.participants.length +
                    this.tournaments.length +
                    this.clubleagues.length +
                    this.teamleagues.length === 0;
            },
            isSearchOpen: function () {
                return this.resultsOpen;
            },
        },
        methods: {
            hideAll: function () {
                this.isRankingShown = false;
                this.isPlayersShown = false;
                this.isOrganisationsShown = false;
                this.isTournamentsShown = false;
                this.isClubLeaguesShown = false;
                this.isTeamLeaguesShown = false;
                this.isRankingSelected = false;
                this.isPlayersSelected = false;
                this.isOrganisationsSelected = false;
                this.isTournamentsSelected = false;
                this.isClubLeaguesSelected = false;
                this.isTeamLeaguesSelected = false;
            },
            showAll: function () {
                this.isRankingShown = true;
                this.isPlayersShown = true;
                this.isOrganisationsShown = true;
                this.isTournamentsShown = true;
                this.isClubLeaguesShown = true;
                this.isTeamLeaguesShown = true;
                this.isRankingSelected = false;
                this.isPlayersSelected = false;
                this.isOrganisationsSelected = false;
                this.isTournamentsSelected = false;
                this.isClubLeaguesSelected = false;
                this.isTeamLeaguesSelected = false;
            },
            showRankings: function () {
                this.hideAll();
                this.isRankingShown = true;
                this.isRankingSelected = true;
            },
            showPlayers: function () {
                this.hideAll();
                this.isPlayersShown = true;
                this.isPlayersSelected = true;
            },
            showOrganisations: function () {
                this.hideAll();
                this.isOrganisationsShown = true;
                this.isOrganisationsSelected = true;
            },
            showTournaments: function () {
                this.hideAll();
                this.isTournamentsShown = true;
                this.isTournamentsSelected = true;
            },
            showClubLeagues: function () {
                this.hideAll();
                this.isClubLeaguesShown = true;
                this.isClubLeaguesSelected = true;
            },
            showTeamLeagues: function () {
                this.hideAll();
                this.isTeamLeaguesShown = true;
                this.isTeamLeaguesSelected = true;
            },
            hideParticipantContextButtons: function () {
                this.participantsContextButtonsIndex = -1;
            },
            showParticipantContextButtons: function (index) {
                this.participantsContextButtonsIndex = index;
            },
            isParticipantContextButtonsShown: function (index) {
                return this.participantsContextButtonsIndex == index;
            },
            hideRankingContextButtons: function () {
                this.rankingContextButtonsIndex = -1;
            },
            showRankingContextButtons: function (index) {
                this.rankingContextButtonsIndex = index;
            },
            isRankingContextButtonsShown: function (index) {
                return this.rankingContextButtonsIndex == index;
            },
            openResults: function () {
                $("#search-results-app").modal("show");

                //turnoff android/ios keyboard 
                document.activeElement.blur();          
                this.resultsOpen = true;
            },

            closeResults: function () {
                this.resultsOpen = false;                                                    
                $('#search-results-app').modal('toggle');
                this.searchTerm = '';
                this.showAll();
            },

            rankingsLoad: function () {
                var _this = this,
                    skip = _this.rankings.length,
                    take = 5,
                    term = _this.searchTerm;

                $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GetRankingsAsync?language=${this.layout.language}&term=` + term + '&take=' + take + "&skip=" + skip, function (response) {
                    _this.rankings = response;
                });
            },
            participantsLoad: function () {
                var _this = this,
                    skip = _this.participants.length,
                    take = 5,
                    term = _this.searchTerm;

                $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GetParticipantsAsync?language=${this.layout.language}&term=` + term + '&take=' + take + "&skip=" + skip, function (response) {
                    _this.participants = response;
                });
            },
            organisationsLoad: function () {
                var _this = this,
                    skip = _this.organisations.length,
                    take = 5,
                    term = _this.searchTerm;

                $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GetOrganisationsAsync?language=${this.layout.language}&term=` + term + '&take=' + take + "&skip=" + skip, function (response) {
                    _this.organisations = response;
                });
            },
            tournamentsLoad: function () {
                var _this = this,
                    skip = _this.tournaments.length,
                    take = 5,
                    term = _this.searchTerm;


                this.$http.get(`${this.nomenclatures.ApiDomain}/Search/GetTournamentsAsync`, {
                    params: {
                        term: term,
                        skip: skip,
                        take: take,
                        language: _this.layout.language
                    }
                })
                    .then(function (response) {
                        _this.tournaments = _this.tournaments.concat(response.data);
                    });
            },
            teamLeaguesLoad: function () {
                var _this = this,
                    skip = _this.teamleagues.length,
                    take = 5,
                    term = _this.searchTerm;

                this.$http.get(`${this.nomenclatures.ApiDomain}/Search/GetTeamLeaguesAsync`, {
                    params: {
                        term: term,
                        skip: skip,
                        take: take,
                        language: _this.layout.language
                    }
                })
                    .then(function (response) {
                        _this.teamleagues = _this.teamleagues.concat(response.data);
                    });

            },
            clubLeaguesLoad: function () {
                var _this = this,
                    skip = _this.clubleagues.length,
                    take = 5,
                    term = _this.searchTerm;

                this.$http.get(`${this.nomenclatures.ApiDomain}/Search/GetClubLeaguesAsync`, {
                    params: {
                        term: term,
                        skip: skip,
                        take: take,
                        language: _this.layout.language
                    }
                })
                    .then(function (response) {
                        _this.clubleagues = _this.clubleagues.concat(response.data);
                    });
   
            },

            getSerchResults: function (id) {

                var _this = this;
                let term = _this.searchTerm;
                
                spaEventBus.$emit('spinnerShow');
                $.getJSON(`${this.nomenclatures.ApiDomain}/Search/GlobalSearchAsync`, "term=" + term + "&language=" + _this.layout.language, function (response) {

                    _this.participants = _this.participants = response.Participants;
                    _this.clubleagues = _this.clubleagues = response.ClubLeagues;
                    _this.teamleagues = _this.teamleagues = response.TeamLeagues;
                    _this.tournaments = _this.tournaments = response.Tournaments;
                    _this.organisations = _this.organisations = response.Organisation;
                    _this.rankings = _this.rankings = response.Rankings;

                    _this.rankingContextButtonsIndex = -1;
                    _this.participantsContextButtonsIndex = -1;

                })
                    .done(function () {
                        setTimeout(function () {
                            _this.openResults();
                        }, 50);
                    })
                    .fail(function (e) {
                        console.log("error");
                        console.log("term: " + term);
                    })
                    .always(function () {
                        spaEventBus.$emit('spinnerHide');                                           
                    });
            },
            search: _.debounce(function () {
                var _this = this;
                var id = this.layout.player.currentPlayerId;
                if (_this.searchTerm) {                    
                    this.getSerchResults(id);
                }
            }, 1200)
        }        
    }
</script>

<style>
 /* styles in _search.scss */
</style>