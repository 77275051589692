<template>
	<button role="filter" class="rin-filter-button" :class="{selected : selected}">
		<slot />
	</button>
</template>
<script>
export default {
	name: 'BaseFilterButton',
	props: {
		selected: {
			type: Boolean,
			default: false
		}
	}
};
</script>
