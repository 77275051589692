<template>
    <div class="rin-main-body-modal-container" v-bind:class="{'rin-body-modal-ads-offset': isAdVisible}">
        <div>
            <slot name="close">
                <img class="rin-main-body-modal-close-btn"
                     src="https://cdn.rankedin.com/images/static/close-icon-big.svg"
                     @click="removeLastBodyModal"
                     alt="close icon" />
            </slot>

            <slot name="content"> example contetnt </slot>
        </div>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside';
    import { mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'BaseBodyModal',
        directives: {
            clickOutside: vClickOutside.directive,
        },
        props: {
            closeOutside: {
                type: Boolean,
                default: true,
            },
        },
        methods: {
            ...mapMutations('ui', ['removeLastBodyModal']),
        },
        created() {
            //Cookie check after each route change - should be temporary solution as it is constatly calling server
            this.$authClient.checkServerAuthCookieStatus();
        },
        computed: {
            ...mapGetters('monetization', ['shouldShowAds', 'isTournamentKillSwitchActive']),
            isAdVisible() {
                return this.shouldShowAds && this.$windowSize.isMobile && !this.isTournamentKillSwitchActive;
            }
        },
        mounted() {
            // @TODO: maybe export that as common function and add it with native modifier to the click event of the open modal?
            window.scrollTo(0, 0);
        },
    };
</script>
