import { GET_LAYOUT_MODEL_ASYNC } from "@/store/modules/layout-module.js";
import axios from 'axios';
import _ from 'lodash';
import spaEventBus from "@/app/eventBus";
import { detect } from 'detect-browser';


export default {
    install(Vue, options) {
        this.store = options.store;
        this.router = options.router;
        this.logger = options.Logger;

        Vue.prototype.$authClient = this;
    },

    store: null,
    router: null,
    logger: null,

    nomenclatures() {
        return this.store.getters.nomenclatures;
    },
    layout() {
        return this.store.getters.layout;
    },
    isLoggedIn() {
        return this.store.getters.layout.player.isAuthenticated;
    },

    login(username, password, rememberMe) {
        let _this = this;

        let offset = new Date().getTimezoneOffset();
        offset = -offset;

        spaEventBus.$emit('spinnerShow');
        axios.post(`${this.nomenclatures().ApiDomain}/Account/LogInAsync`,
            {
                username: username,
                password: password,
                rememberMe: rememberMe,
                TimezoneOffset: offset,
                ClientPlatform: this.getClientPlatform()
            },
            {
                withCredentials: true,
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Allow-Headers': 'Content-Type',
                    'Access-Control-Allow-Headers': 'Content-Type, Accept'

                }
            })
            .then(response => {                             
                this.redirectAfterLogin();                                   
            })
            .catch((e) => {
                console.log(e);                
                if (e.response && e.response.data.Code == _this.nomenclatures().ExceptionsEnum.AccountIsNotConfirmed) {
                    _this.router.push({ name: 'confirm-email', params: { language: _this.layout().language }, query: { email: username } }).catch(err => { });
                }
                else {
                    _this.router.push({ name: 'login', params: { language: _this.layout().language }, query: { incorectCredentials: true } }).catch(err => { });
                }
                spaEventBus.$emit('spinnerHide');
            });


    },
    logoff() {        
        spaEventBus.$emit('spinnerShow');
        axios.post(`${this.nomenclatures().ApiDomain}/account/LogOutAsync`, {}, { withCredentials: true })
            .then(response => {
                this.redirectAfterLogin();
            })
            .catch((e)=> {
                console.log(e);
            }).finally(() => {
                spaEventBus.$emit('spinnerHide');
            });
    },
    async confirmRegistration(token) {
        let _this = this;
        return axios.post(`${this.nomenclatures().ApiDomain}/account/ConfirmAccountAsync`,
            {
                token: token
            })
            .then(response => {
                _this.redirectAfterLogin({ isAccountConfirmed: true });
                spaEventBus.$emit('loginOrLogff');
            })
            .catch((e) => {
                console.log(e);
                spaEventBus.$emit('spinnerHide');
                throw e;
            });
    },
    redirectAfterLogin(queryParams) {
        let _this = this;
        this.store.dispatch(GET_LAYOUT_MODEL_ASYNC)
            .then(() => {

                spaEventBus.$emit('loginOrLogff');

                if (_this.layout().player.isAuthenticated) {                    
                    if (_this.router.currentRoute.query.returnUrl || _this.router.currentRoute.query.ReturnUrl) {
                        let returnUrl = _this.router.currentRoute.query.returnUrl || _this.router.currentRoute.query.ReturnUrl;
                        let noHttpReturnUrl = returnUrl;
                        if (noHttpReturnUrl.startsWith('https://')) {
                            let noHttpReturnUrlObj = new URL(noHttpReturnUrl);
                            noHttpReturnUrl = noHttpReturnUrlObj.pathname + noHttpReturnUrlObj.searchParams;
                            }
                        let newReturnUrl = _this.router.resolve({ path: noHttpReturnUrl, query: queryParams }).href;
                        location.href = newReturnUrl;
                    }
                    else {
                        _this.redirectToProfile(queryParams);
                    }
                }
                else {
                    _this.router.push({ name: 'landing-page', params: { language: _this.layout().language } }).catch(err => { });
                }

                spaEventBus.$emit('spinnerHide');
            });
    },
    redirectToProfile(queryParams) {
        let route = {
            name: 'player-info', params: { id: this.layout().player.rankedinId, language: this.layout().language }, query: {}
        };
        _.assign(route.query, queryParams);
        this.router.push(route).catch(err => { });
    },

    checkAuthRequirements(to, next) {        
        let language = to.params?.language || this.layout().language || 'en';

        let browser = detect();
        let platform = `${browser.name} / ${browser.os}`;
        if (to.query.device || (platform.toLowerCase().includes('android') && platform.toLowerCase().includes('chromium-webview'))) 
            {
                this.layout().isOldStoreApp = true;
            }

        if (to.meta.requireAuth && !this.isLoggedIn()) {            
            next({ path: `/${language}/account/login`, query: { returnUrl: to.fullPath }, replace: true});
        }
        else if (to.meta.requireAnonymous && this.isLoggedIn()) {
            if (this.layout().player.rankedinId) {
                next({ path: `/${language}/player/${this.layout().player.rankedinId}`, replace: true });
            }
        }
        else next();
    },
    getClientPlatform() {
        let browser = detect();
        let platform = `${browser.name} ${browser.version} / ${browser.os}`;
        console.log(platform);
        return platform;
    },
    handleForbidden(e) {
        let isForbidden = false;
        if (e.response.status == 403) isForbidden = true;
        if (e.response && (e.response.data.Code == this.nomenclatures().ExceptionsEnum.InvalidPermissions || e.response.data.code == this.nomenclatures().ExceptionsEnum.InvalidPermissions)) isForbidden = true;

        if (isForbidden) {
            if (this.router.currentRoute.meta.isBS3) window.location = '/';
            else this.redirectAfterLogin();
        }

        return isForbidden;
    },
    checkServerAuthCookieStatus() {
        if (!this.isLoggedIn()) return;
        if (!this.nomenclatures().ApiDomain) return;

        return axios.get(`${this.nomenclatures().ApiDomain}/account/IsCookieValid`)
            .catch(() => {
                window.location = '/';
            });
    },

}
