export default {
	namespaced: true,
	state: {
		email: '',
		password: '',
		firstName: '',
		lastName: '',
		dob: '',
		gender: '',
		country: { code: 0 },
		sport: 0,
		phone: '',
		newsletter: false,
		terms: false,

		isRegisterModalOpen: false,
	},
	mutations: {
		updateEmail(state, payload) {
			state.email = payload;
		},
		updatePassword(state, payload) {
			state.password = payload;
		},
		updateFirstName(state, payload) {
			state.firstName = payload;
		},
		updateLastName(state, payload) {
			state.lastName = payload;
		},
		updateDob(state, payload) {
			state.dob = payload;
		},
		updateGender(state, payload) {
			state.gender = payload;
		},
		updateCountry(state, payload) {
			state.country = payload;
		},
		updateSport(state, payload) {
			state.sport = payload;
		},
		updatePhone(state, payload) {
			state.phone = payload;
		},
		updateNewsletter(state, payload) {
			state.newsletter = payload;
		},
		updateTerms(state, payload) {
			state.terms = payload;
		},
		clearAll(state) {
			state.email = '';
			state.password = '';
			state.firstName = '';
			state.lastName = '';
			state.dob = '';
			state.gender = '';
			state.country = { code: 0 };
			state.sport = 0;
			state.phone = '';
			state.newsletter = false;
			state.terms = false;
		},
		setRegisterModalStateInternal(state, payload) {
			state.isRegisterModalOpen = payload;
		},
	},
	actions: {
		setRegisterModalState({ commit }, payload) {
			commit('setRegisterModalStateInternal', payload);
			commit('setFooter', !payload, { root: true });
		},
	},
	getters: {
		email(state) {
			return state.email;
		},
		password(state) {
			return state.password;
		},
		firstName(state) {
			return state.firstName;
		},
		lastName(state) {
			return state.lastName;
		},
		dob(state) {
			return state.dob;
		},
		gender(state) {
			return state.gender;
		},
		country(state, getters, rootState, rootGetters) {
			if (state.country?.code == 0) {
				return { ...state.country, name: rootGetters.translations['shared_Select'] };
			} else {
				return state.country;
			}
		},
		sport(state) {
			return state.sport;
		},
		phone(state) {
			return state.phone;
		},
		newsletter(state) {
			return state.newsletter;
		},
		terms(state) {
			return state.terms;
		},
		isRegisterModalOpen(state) {
			return state.isRegisterModalOpen;
		},
	},
};
