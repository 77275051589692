<template>
	<div class="rin-register-gender rin-register-form">
		<h1>{{ translations['register_GenderHeading'] }}</h1>

		<div class="rin-register-form-container">
			<div>
				<input id="sexm" v-model="userGender" class="rin-register-input" type="radio" value="1" />
				<label for="sexm" class="rin-register-label">{{ translations['register_GenderMale'] }}</label>
			</div>
			<div>
				<input id="sexf" v-model="userGender" class="rin-register-input" type="radio" value="2" />
				<label for="sexf" class="rin-register-label">{{ translations['register_GenderFemale'] }}</label>
			</div>

			<div v-cloak>
				<BaseAlert type="danger" :has-background="false">
					<span v-if="$v.userGender.$dirty && !$v.userGender.required" class="rin-register-error">
						{{ translations['register_2'] }}
					</span>
				</BaseAlert>
			</div>

			<BaseButton type="success" size="m" @click="goToNext" :disabled="isNextDisabled">
				{{ translations['shared_Next'] }}
			</BaseButton>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { required } from 'vuelidate/lib/validators';

import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton';

export default {
	name: 'RegisterGender',
	components: {
		BaseAlert,
		BaseButton,
	},
	props: ['nextRef'],
	computed: {
		...mapGetters(['translations', 'layout']),
		...mapGetters('register', ['gender']),
		userGender: {
			get() {
				return this.gender;
			},
			set(v) {
				this.updateGender(v);
			},
		},
	},
	data() {
		return {
			isNextDisabled: false,
		};
	},
	methods: {
		...mapMutations('register', ['updateGender']),
		goToNext() {
			if (this.isNextDisabled) return;
			
			this.$v.userGender.$touch();
			if (!this.$v.userGender.$error) {
				this.nextRef.click();

				this.isNextDisabled = true;
				setTimeout(() => {
							this.isNextDisabled = false;
						}, 500);
			}
		},
	},
	validations: {
		userGender: {
			required: required,
		},
	},
};
</script>
