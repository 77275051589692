<template>
	<span aria-hidden="true" :class="`icon icon-${name}`"></span>
</template>
<script>
	export default {
		name: 'BaseIcon',
		props: {
			name: {
				type: String,
				required: true,
			}
		}
	};
</script>
