<template>
	<div class="rin-nav-login-or-create-wrap">
		<router-link :to="{ name: 'login', params: { language: language } }" @click.native="close">
			<BaseButton size="m" type="success"> Login </BaseButton>
		</router-link>
		<!-- <BaseButton size="m" type="success" @click.native="login"> Login </BaseButton> -->
		or
		<!-- <router-link :to="{ name: 'join-us', params:{ language:language } }" @click.native="close"> -->
		<BaseButton size="m" type="tertiary" @click.native="openRegisterModal"> create account </BaseButton>
		<!-- </router-link> -->
		<!-- <BaseButton size="m" type="tertiary" @click.native="register"> create account </BaseButton> -->
		<span class="italic"> for free! </span>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';

export default {
	name: 'NavLoginOrCreate',
	components: {
		BaseButton,
	},
	computed: {
		language() {
			return this.$store.getters.layout.language;
		},
	},
	methods: {
		...mapActions('register', ['setRegisterModalState']),
		// register() {
		// 	if (this.$route.name !== 'join-us') {
		// 		this.$router.push({ name: 'join-us', params: { language: this.language } });
		// 		this.$store.commit('topnav/toggleMenu', false);
		// 	}
		// },
		// login() {
		// 	if (this.$route.name !== 'login') {
		// 		this.$router.push({ name: 'login', params: { language: this.language } });
		// 		this.$store.commit('topnav/toggleMenu', false);
		// 	}
		// },
		close() {
			this.$store.commit('topnav/toggleMenu', false);
		},
		openRegisterModal() {
			this.setRegisterModalState(true);
		},
	},
};
</script>
