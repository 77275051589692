<template>
    <div class="rin-dropdown-container" v-click-outside="hide">
        <BaseButton :size="buttonSize"
                    :type="buttonType"
                    :role="buttonRole"
                    :disabled="disabled"
                    @click.native="state = !state"
                    class="rin-dropdown-toggle">
            <slot name="button"></slot>
            <span :class="{ 'rin-dropdown-toggle-icon-open': state }" class="rin-dropdown-toggle-icon"> </span>
        </BaseButton>
        <ul class="rin-dropdown-list" v-show="state" @click="hide" v-bind:class="direction">
            <slot name="content"></slot>
        </ul>
    </div>
</template>

<script>
    import vClickOutside from 'v-click-outside';
    import BaseButton from '@/components/rin_new_design/components/base/BaseButton';

    export default {
        name: 'BaseDropdown',
        // inheritAttrs: false,
        directives: {
            clickOutside: vClickOutside.directive,
        },
        components: {
            BaseButton,
        },
        props: {
            isOpen: {
                type: Boolean,
                default: false
            },
            buttonSize: String,
            buttonType: String,
            buttonRole: String,
            disabled: {
                type: Boolean,
                default: false
            },
            direction: {
                type: String,
                default: 'down'
            }
        },
        data() {
            return {
                state: this.isOpen,
            };
        },
        methods: {
            hide(ev) {
                const inputInDropdownPressed = ev &&
                    ev.target.tagName === 'INPUT' &&
                    ev.target
                        .parentElement
                        .parentElement
                        .parentElement
                        .classList.contains('rin-dropdown-container');

                if (inputInDropdownPressed) return;
                this.state = false;
            },
        },
    };
</script>
