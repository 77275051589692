<template>
	<div class="rin-page-top-bar-container">
		<div class="wrap">
			<div class="rin-page-top-bar-back-btn" @click="goBack" v-if="$windowSize.isMobile">
				<BaseIcon name="back" />
			</div>
			<div class="rin-no-worries-bar-text">
				<div class="rin-page-top-bar-upgrade-description">
					{{translations["freemium_Top_Bar_Cant_Find_Something"]}}
					<span class="rin-top-bar-click-here" @click="showNoWorriesModal">
						{{translations["freemium_Top_Bar_Click_Here"]}}
					</span>
				</div>
			</div>
		
		</div>
		<portal to="main-base-modal" v-if="showModal">
			<BaseModalWrapper size="l" :show="showModal" @close="showModal = false">
				<template #body>
					<FreemiumNoWorries />
				</template>
			</BaseModalWrapper>
		</portal>
	</div>
</template>

<script>
import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
import BaseModalWrapper from '@/components/rin_new_design/components/base/modals/BaseModalWrapper';
import FreemiumNoWorries from '@/components/rin_new_design/components/monetization/freemium/FreemiumNoWorries';

import { mapGetters } from 'vuex';

export default {
	name: 'NoWorriesTopBar',
	components: {		
		BaseIcon,
		BaseModalWrapper,
        FreemiumNoWorries,
	},
	data() {
		return {
			showModal: false,
		};
	},
	computed: {
		...mapGetters(['layout', 'translations', 'nomenclatures']),
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
        showNoWorriesModal() {
			this.showModal = true;
		},
	},
};
</script>
