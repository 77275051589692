export default {
	install(Vue) {
		const _vm = new Vue({
			data() {
				return {
					isMobile: null,
				};
			},
			created() {
				this.isMobile = window.innerWidth < 1024;
				// @TODO: add debouncer
				window.addEventListener('resize', () => {
					this.isMobile = window.innerWidth < 1024;
				});
			},
		});

		Vue.prototype.$windowSize = _vm;
	},
};