
export default {
    install(Vue, router, store, spaEventBus, http) {

        this.router = router;
        this.store = store;
        this.spaEventBus = spaEventBus;
        this.http = http;

        Vue.prototype.$googleAds = this;
    },
    router: null,
    store: null,
    http: null,
    spaEventBus: null,


    // Ads manager start

    async generateAd() {
        const hideAds = await this.shouldHideAllAdsAsync();

        if (hideAds) {
            this.spaEventBus.$emit('showGoogleAd', false);
        }
        else {
            this.spaEventBus.$emit('showGoogleAd', true);
        }
    },

    emitRefreshGoogleAd() {
        if (!this.store.getters.nomenclatures.config.isGoogleAdsEnabled) return;
        this.spaEventBus.$emit('refreshGoogleAd');
    },

    // Ads manager end

    async shouldHideAllAdsAsync() {
        if (!this.store.getters.nomenclatures.config.isGoogleAdsEnabled) return true;

        const isEmbed = this.store.getters.layout.isEmbed;
        const isPro = this.store.getters.layout.player.IsProPlayer;
        const isRouteForbidden = this.isRouteForbidden();


        if (isEmbed || isPro || isRouteForbidden) return true;

        if (this.store.getters.nomenclatures.IsPremiumAllowed && this.isInTournamentFeature()) {
            const isPremiumTournament = await this.isPremiumTournamentAsync();
            return isPremiumTournament;
        }

        if (this.store.getters.nomenclatures.IsPremiumAllowed && this.isInOrganizationFeature()) {
            let isPremiumOrganization = await this.isPremiumOrganisationAsync();
            if (isPremiumOrganization) return true;

            let isBook247Organization = await this.isBook247OrganisationAsync();
            return isBook247Organization;
        }

        return false;
    },

    isInTournamentFeature() {
        var feature = this.router.currentRoute.meta?.feature;
        if (!feature) return false;
        return 'Tournament' === feature;
    },

    isInOrganizationFeature() {
        var feature = this.router.currentRoute.meta?.feature;
        if (!feature) return false;
        return 'Organisation' === feature;
    },

    async isPremiumTournamentAsync() {        
        return this.http.get(`${this.store.getters.nomenclatures.ApiDomain}/Monetization/GetIsPremiumTournamentAsync`,
            {
                params: { tournamentId: this.store.getters.layout.page.id }
            })
            .then(res => res.data)
            .catch(e => {
                if (e.response && e.response.data.code == this.nomenclatures.PremiumPlanIsNotAllowed) {
                    return false;
                }

                return true;
            });
    },

    async isPremiumOrganisationAsync() {

        const organisationId = this.router.currentRoute.params.id;

        return this.http.get(`${this.store.getters.nomenclatures.ApiDomain}/Monetization/GetIsPremiumOrganisationAsync`,
            {
                params: { organisationId: organisationId }
            })
            .then(res => res.data)
            .catch(() => false);
    },

    async isBook247OrganisationAsync() {

        const organisationId = this.router.currentRoute.params.id;

        return this.http.get(`${this.store.getters.nomenclatures.ApiDomain}/Organization/GetIsBook247ConnectedOrganisationAsync`,
            {
                params: { organisationId: organisationId }
            })
            .then(res => res.data)
            .catch(() => false);
    },

    isRouteForbidden() {
        return !!this.router.currentRoute?.meta?.ads?.isForbidden;
    },




}