<template>
    <div id="betsson-campaign-modal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    &times;
                </button>
                <div class="modal-body text-center">
                    <div class="betsson-body mt-5 mb-3">
                        <a href="https://betssonpadeltour.se" target="_blank" class="betsson-mobile"><img class="betsson-img" src="https://cdn.rankedin.com/images/static/campaign/betsson-sm.jpeg" /></a>
                        <a href="https://betssonpadeltour.se" target="_blank" class="betsson-desktop"><img class="betsson-img" src="https://cdn.rankedin.com/images/static/campaign/betsson-lg.jpeg" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters } from 'vuex';

    const bettsonSettings = {
        name: 'betsson-campaign',
        async checkAudienceAsync(params) {
            if (params.store.getters.layout.isEmbed) return Promise.resolve(false);

            var isLoggedIn = params.store.getters.layout.player.isAuthenticated;
            var country = 0;
            var sweden = 152;

            if (isLoggedIn) {
                country = params.store.getters.layout.player.country;
                if (country === sweden) return Promise.resolve(true);
            }

            country = params.store.getters.layout.page.country;
            if (navigator.language == 'sv') country = sweden;

            if (country === sweden) return Promise.resolve(true);

            return Promise.resolve(false);
        }
    };
    export { bettsonSettings };

    export default {
        name: "betsson-campaign",
        data: function () {
            return {
            }
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            createUrl() {
                return `/${this.layout.language}/americano/create`;
            },
            isLoggedIn() {
                return this.layout.player.isAuthenticated;
            }
        },

        mounted() {
            this.showModal();            
        },
        methods: {
            playAmericanoClick() {
                $('#betsson-campaign-modal').modal('hide');
                this.$router.replace(`/${this.layout.language}/americano/promo`);
            },
            showModal() {
                $('#betsson-campaign-modal').modal('show');
            }
        },
        beforeDestroy() {
            if ($('.modal-backdrop.show').length > 0) $('.modal-backdrop.show').remove();
        }
    }
</script>

<style scoped>
    /*shared*/

    .betsson-img {
        width: 80%
    }

    .modal .close {
        font-size: 40px;
        position: absolute;
        z-index: 10000000000;
    }

   

    .modal .close span {
        font-size: 40px;
        line-height: 15px;
        right: 20px;
        top: 20px;
    }

    /*mobile*/
    @media (max-width: 767px) {
        .betsson-mobile{
            display:initial;
        }
        .betsson-desktop {
            display: none;
        }
    }

    /*desktop*/

    @media (min-width: 768px) {
        .modal.show .modal-dialog {
            max-width: 720px;
        }

        .betsson-mobile {
            display: none;
        }

        .betsson-desktop {
            display: initial;
        }

     
    }
</style>

