<template>
    <div class="freemium-no-worries">
        <h4 class="title">
            {{translations['monetization_Freemium_NoWorries']}}
        </h4>
        <div class="first-card">
            <BaseCard type="secondary" :border="true">
                <template #header>
                    {{translations['monetization_Freemium_Basic']}}
                </template>
                <template #body>
                    <ul class="list-wrapper">
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_PersonalProfile']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Join']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Create']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Play']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_TournamentList']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Calendar']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Chat']}}</span>
                        </li>
                    </ul>
                </template>
            </BaseCard>
        </div>
        <div class="second-card">
            <BaseCard type="secondary" :border="true">
                <template #header>
                    Rankedin <BaseTypeLabel :type="'pro'"></BaseTypeLabel> {{translations['monetization_Freemium_PlayerPlan']}}
                </template>
                <template #body>
                    <ul class="list-wrapper">
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Unlock']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_KeepTrack']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Follow']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_Upcoming']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_SkillRating']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_LastVisited']}}</span>
                        </li>
                        <li class="freemium-item">
                            <div><span><BaseIcon name="check" /></span></div><span class="item-text">{{translations['monetization_Freemium_NoAds']}}</span>
                        </li>
                    </ul>
                </template>
            </BaseCard>
        </div>
    </div>
</template>

<script>
    import BaseCard from '@/components/rin_new_design/components/base/BaseCard';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon';
    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';
    import { mapGetters } from 'vuex';

    export default {
        name: 'FreemiumNoWorries',
        components: {
            BaseCard,
            BaseIcon,
            BaseTypeLabel
        },
        computed: {
            ...mapGetters(['translations'])
        }
    };
</script>
