<template>
    <div class="hidden-print">
        <footer class="container-fluid container-footer">
            <div class="row footer-search">
                <div class="col-xs-12">
                </div>
            </div>
            <div class="row colophon">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 col-lg-offset-1 text-center">
                    <h4>Rankedin</h4>
                    <ul class="list-unstyled">
                        <li><a v-bind:href="'/'+ layout.language + '/content/shownews/about-us'">{{translations["footer_1"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/content/shownews/rankedin-for-players'">{{translations["footer_2"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/content/shownews/rankedin-for-clubs'">{{translations["footer_3"]}}</a></li>
                        <li><a href="https://cdn.rankedin.com/content/RI_presentation_for_CLUB_LEAGUE.pdf">{{translations["footer_ClubLeague"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/content/shownews/faq'">{{translations["footer_4"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/home/features'">{{translations["shared_Menu_Features"]}}</a></li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center">
                    <h4>General</h4>
                    <ul class="list-unstyled">
                        <li><a v-bind:href="'/'+ layout.language + '/termsandconditions'" target="_blank">{{translations["footer_5"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/privacypolicy'" target="_blank">{{translations["footer_6"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/home/cookiepolicy'" target="_blank">{{translations["landingPage_BS4_CookiePolicy"]}}</a></li>
                        <li><a href="https://rankedin.ladesk.com/">{{translations["footer_8"]}}</a></li>
                        <li><a v-bind:href="'/'+ layout.language + '/content/shownews/federations'">{{translations["shared_T18"]}}</a></li>
                        <li><a href="https://rankedin.ladesk.com/446847-Changelog---Release-Notes---Rankedin-">{{translations["footer_changelog"]}}</a></li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center">
                    <h4>Social</h4>
                    <ul class="list-unstyled">
                        <li><a href="https://www.facebook.com/rankedin" target="_blank"><i class="fa fa-facebook fa-fw"></i>&nbsp;Facebook</a></li>
                        <li><a href="https://www.youtube.com/channel/UC-60J-i89RdsRxLeNKv4FKQ" target="_blank"><i class="fa fa-youtube-square fa-fw"></i>&nbsp;YouTube</a></li>
                        <li><a href="https://twitter.com/RankedinApp" target="_blank"><i class="fa fa-twitter fa-fw"></i>&nbsp;Twitter</a></li>
                    </ul>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center">
                    <h4>{{translations["home_COntactForm_Contacts"]}}</h4>
                    <address>
                        Rankedin AS<br>
                        Lysaker Torg 8<br>
                        1366 Lysaker<br>
                        Norway
                    </address>
                    <ul class="list-unstyled">
                        <li><a href="mailto:info@rankedin.com">info@rankedin.com</a></li>
                    </ul>
                </div>

                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-2 text-center">
                    <h4>Certified software</h4>
                    <a href="http://www.worldsquash.org/calendar/wsf-certified-software/" target="_blank">
                        <img src="https://cdn.rankedin.com/images/static/fed/logo_fed_wsf_color.jpg" style="height:100px" alt="WSF" />
                    </a>
                </div>
            </div>
            <div class="row attribution">
                <p>&copy; {{yearNow}} Rankedin. All rights reserved</p>
            </div>
        </footer>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import moment from 'moment';

    export default {
        name: "layout-footer",
        components:
        {
        },
        data: function () {
            return {
                languageCode: LANGUAGE,
                showCookieAlert: false,
                weUseCookies: "rankedin-first",
            }
        },
        computed: {
            ...mapGetters(['layout','translations', 'nomenclatures']),

            isIOSorAndroidUserDeviceRin: function () {
                return this.nomenclatures.UserDeviceRin == 'ios' || this.nomenclatures.UserDeviceRin == 'android';
            },
            yearNow: function () {
                return moment.utc().year();
            }
        },
        mounted() {

        },
        methods: {
        }
    }
</script>

<style scoped>
    .container-footer .colophon {
        background-color: #00435e;
    }
</style>