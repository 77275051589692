<template>
    <router-link class="rin-nav-item" :to="{ name: destinationName, params: params, query: query }">
        <BaseIcon :name="icon" />
        <div class="rin-navlink-description" v-if="description != null">
            {{ description }}
        </div>
        <span class="rin-nav-badge" v-if="badge > 0">
            {{ badge }}
        </span>
    </router-link>
</template>

<script>
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
    export default {
        name: 'NavLink',
        components: {
            BaseIcon
        },
        props: {
            destinationName: {
                type: String,
                required: true,
            },
            params: {
                type: Object,
                default: () => { },
            },
            query: {
                type: Object,
                default: () => { },
            },
            icon: {
                type: String,
                required: true,
            },
            description: {
                type: String,
                default: null
            },
            badge: {
                type: Number,
                default: 0
            }
        }
    };
</script>
