<template>
    <div>
        <SearchResultGroup v-if="tournaments.length > 0 && (filterSelected == 'tournaments' || filterSelected == 'all')"
                           :events="tournaments"
                           title="Tournaments"
                           type="Tournaments">
            <template #description="{ item }">
                Start date: {{ item.StartDate }}
                <BaseIcon :name="item.Sport" />
            </template>
        </SearchResultGroup>
        <SearchResultGroup v-if="clubleagues.length > 0 && (filterSelected == 'clubleagues' || filterSelected == 'all')"
                           :events="clubleagues"
                           title="Club Leagues"
                           type="Clubleagues">
            <template #description="{ item }">
                Start date: {{ item.StartDate }}
                <BaseIcon :name="item.Sport" />
            </template>
        </SearchResultGroup>
        <SearchResultGroup v-if="teamleagues.length > 0 && (filterSelected == 'teamleagues' || filterSelected == 'all')"
                           :events="teamleagues"
                           title="Team Leagues"
                           type="Teamleagues">
            <template #description="{ item }">
                Start date: {{ item.StartDate }}
                <BaseIcon :name="item.Sport" />
            </template>
        </SearchResultGroup>
        <SearchResultGroup v-if="players.length > 0 && (filterSelected == 'players' || filterSelected == 'all')"
                           :events="players"
                           title="Players"
                           type="Participants">
            <template #description="{ item }">
                <div>
                    {{ item.PlayerInfo }}
                </div>
                <div>ID: {{ item.RankedinId }}</div>
            </template>
        </SearchResultGroup>
        <SearchResultGroup v-if="rankings.length > 0 && (filterSelected == 'rankings' || filterSelected == 'all')"
                           :events="rankings"
                           title="Rankings"
                           type="Rankings">
            <template #description="{ item }">
                {{ item.OrganisationName }}
            </template>
        </SearchResultGroup>
        <SearchResultGroup v-if="organisations.length > 0 && (filterSelected == 'organisations' || filterSelected == 'all')"
                           :events="organisations"
                           title="Organisations"
                           type="Organisations">
            <template #description="{ item }">
                {{ item.Address }}
            </template>
        </SearchResultGroup>
    </div>
</template>
<script>
    import SearchResultGroup from '@/components/rin_new_design/components/search/SearchResultsGroup.vue';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
    export default {
        name: 'SearchResults',
        components: {
            SearchResultGroup,
            BaseIcon
        },
        computed: {
            state() {
                return this.$store.getters['search/state'];
            },
            tournaments() {
                return this.$store.getters['search/tournaments'];
            },
            clubleagues() {
                return this.$store.getters['search/clubleagues'];
            },
            teamleagues() {
                return this.$store.getters['search/teamleagues'];
            },
            players() {
                return this.$store.getters['search/players'];
            },
            rankings() {
                return this.$store.getters['search/rankings'];
            },
            organisations() {
                return this.$store.getters['search/organisations'];
            },
            filterSelected() {
                return this.$store.getters['search/filter'];
            }
        },
    };
</script>
