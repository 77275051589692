export default {
    state: {
        modalSettings: {},
        passwordChangeModel: {},
        settingsToggleModel: {},
        settingsInputModel: {}
    },
    mutations: {
        updateModalSettings(state, payload) {
            state.modalSettings = payload;
        },
        updatePasswordModel(state, payload) {
            state.passwordChangeModel = payload;
        },
        updateSettingsToggleModel(state, payload) {
            state.settingsToggleModel = payload;
        },
        updateSettingsInputModel(state, payload) {
            state.settingsInputModel = payload;
        },
        clearPasswordModel(state) {
            state.passwordChangeModel = {};
        }
    },
    actions: {
        updateModalSettings: ({ commit }, payload) => {
            commit("updateModalSettings", payload);
        },
        updatePasswordModel: ({ commit }, payload) => {
            commit("updatePasswordModel", payload);
        },
        updateSettingsToggleModel: ({ commit }, payload) => {
            commit("updateSettingsToggleModel", payload);
        },
        updateSettingsInputModel: ({ commit }, payload) => {
            commit("updateSettingsInputModel", payload);
        },
    },
}