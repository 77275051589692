import _ from 'lodash';
import Cookies from 'js-cookie'

import americanoCampaign, { americanoSettings } from '@/components/shared/ads/americano-campaign';
import betssonCampaign, { bettsonSettings } from '@/components/shared/ads/betsson-campaign';
import PrivateMatchCampaign, { privateMatchSettings } from '@/views/Ads/PrivateMatchCampaign.vue';
import NewsletterCampaign, { newsletterCampaignSettings } from '@/views/Ads/NewsletterCampaign.vue';
import ProPlayersCampaign, { proPlayerSettings } from '@/views/Ads/ProPlayersCampaign.vue';
import FreemiumCampaign, { freemiumSettings } from '@/views/Ads/FreemiumCampaign.vue';
import FreeTrialEndCampaign, { freeTrialEndSettings } from '@/views/Ads/FreeTrialEndCampaign.vue';
import PwaCampaign, { pwaSettings } from '@/views/Ads/PwaCampaign.vue';


import moment from 'moment';


class PlayerCampaign {
    constructor(id, lastAdDate) {
        this._id = id;

        if (lastAdDate) this._lastAdDate = lastAdDate;
        else this._lastAdDate = '0001-01-01';
    }

    get id() { return this._id; }
    get lastAdDate() { return this._lastAdDate; }

    setShownNow() {
        this._lastAdDate = moment().utc().format('YYYY-MM-DD');
    }

    getDaysFromAd() {
        return moment().utc().diff(moment(this._lastAdDate), 'days');
    }
}

class CampaignStatus {
    constructor(playerId, ads) {
        this._playerId = playerId;
        this._ads = ads;
    }

    get playerId() { return this._playerId; }
    get ads() { return this._ads; }

    get lastShownAdDate() {
        const maxDateAd = _.maxBy(this.ads, (a) => a.lastAdDate);

        if (maxDateAd) return maxDateAd.lastAdDate;
        else return '0001-01-01';
    }

    setPlayer(playerId) {
        this._playerId = playerId;
    }

    getDaysFromAd() {
        return moment().utc().diff(moment(this.lastShownAdDate), 'days');
    }
}

class Campaign {
    constructor(campaign, priority, beginDate, endDate, periodicType, periodicQuantity, adType, settings) {
        this._id = campaign;
        this._priority = priority;
        this._startDate = moment(beginDate).utc().format('YYYY-MM-DD');
        this._periodicType = periodicType;
        this._periodicQuantity = periodicQuantity;
		this._adType = adType;
        this._name = settings.name;
        this._checkAudienceAsync = settings.checkAudienceAsync;

        if (endDate == null) this._endDate = '9999-01-01'
        else this._endDate = moment(endDate).utc().format('YYYY-MM-DD');
    }

    get id() { return this._id; }
    get priority() { return this._priority; }
    get name() { return this._name; }    
    get sheduleInDays() {
        let multiplier = 1;
        switch (this._periodicType) {
            case 2: multiplier = 1; break
            case 3: multiplier = 7; break
            case 4: multiplier = 30; break
        }

        return multiplier * this._periodicQuantity;
    }

    isValidCampaignPeriod() {
        return moment.utc() >= moment(this._startDate) && moment.utc() < moment(this._endDate)
    }

    async checkAudienceAsync(params) {
        return await this._checkAudienceAsync(params);
    }

}

class AdsSettings {
    constructor(campaigns, adsImpressionFrequencyInDays, isForLoggedinPlayer, playerId) {
        this._campaigns = _.map(campaigns, (d) => new Campaign(d.campaign, d.priority, d.beginDate, d.endDate, d.periodicType, d.periodicQuantity, d.adType, this.mapCapmaignIdToSettings(d.campaign)));
        this._isForLoggedinPlayer = isForLoggedinPlayer;
        this._adsImpressionFrequencyInDays = adsImpressionFrequencyInDays;
        this._playerId = playerId;
    }

    get adsImpressionFrequencyInDays() { return this._adsImpressionFrequencyInDays; }
    get isForLoggedinPlayer() { return this._isForLoggedinPlayer; }
    get campaigns() { return this._campaigns; }

    mapCapmaignIdToSettings(id) {
        switch (id) {
            case 1: return bettsonSettings;
            case 2: return privateMatchSettings;
            case 3: return newsletterCampaignSettings;
            case 4: return americanoSettings;
            case 6: return proPlayerSettings;
			case 7: return freemiumSettings;
			case 8: return freeTrialEndSettings;
			case 9: return pwaSettings;
			case 10: return pwaSettings;
            default: return { name: '', async checkAudienceAsync(params) { return Promise.resolve(false) } }
        }
    }

    hasVaildSettings(playerId) {        
        if (this._campaigns.length > 0 && !this._isForLoggedinPlayer && playerId == null) return true;
        if (this._campaigns.length > 0 && this._isForLoggedinPlayer && playerId != null) return true;

        return false;
    }

    getSortedCampaigns() {
        let sortedCampaigns = _.sortBy(this._campaigns, (settings) => settings.priority);
        return sortedCampaigns;
    }
}


class Plugin {
    componentName = '';
    playerId = null;

    settings = new AdsSettings([], 0, false);
    adStatuses = new CampaignStatus(null, []);


    adsSecondsTimeout = 4;
    adsCookieExpirationInDays = 60;
    cookieName = 'modal-ads';
    audienceParameters = {};

    constructor(options) {
        this.cookies = options.cookies;
        this.$http = options.http;
        this.store = options.store;
        this.router = options.router;
        this.spaEventBus = options.eventBus;
        this.audienceParameters.http = options.http;
        this.audienceParameters.store = options.store;

        this.layout = () => this.store.getters.layout;
        this.nomenclatures = () => this.store.getters.nomenclatures;
    }

    get adStatuses() { return this.adStatuses; }

    generateAd() {
        setTimeout(() => this.generateAdInternal(), this.adsSecondsTimeout * 1000)      
    }

    async generateAdInternal() {
        this.initModuleState();
        if (this.playerId == null && navigator && !navigator.cookieEnabled) return;
        if (!this.nomenclatures().ApiDomain) return;
        if (this.hasAnyModalOpen()) return;
        if (this.isRouteForbidden()) return;
		
        await this.getSettingsAsync();
        await this.readAdsStatusAsync();
        await this.convertLegacyAdCookiesAsync();

        const daysFromLastAd = this.adStatuses.getDaysFromAd();
        if (daysFromLastAd <= this.settings.adsImpressionFrequencyInDays) return;
        let sortedCampaignSettings = this.settings.getSortedCampaigns();

        for (const settings of sortedCampaignSettings) {
            var status = _.find(this.adStatuses.ads, (st) => st.id == settings.id);
            if (!status) {
                status = new PlayerCampaign(settings.id);
                this.adStatuses.ads.push(status);
            } else {
                if (settings.periodicType == this.nomenclatures().CampaignPeriodTypeEnum.OneTime) continue;
			}
			
            const daysFromAd = status.getDaysFromAd();

            if (daysFromAd <= settings.sheduleInDays) continue;
            if (!settings.isValidCampaignPeriod()) continue;

			if(settings?.adType === this.nomenclatures().CampaignTypeEnum.Advert) {
				if(this.store.getters['monetization/isProPlayer']) continue;
				if(await this.isPremiumTournamentAsync()) continue;
				if(await this.isPremiumOrganisationAsync()) continue;
			}

			var isValidAudience = await settings.checkAudienceAsync(this.audienceParameters);
			if (!isValidAudience) continue;

            status.setShownNow();
			await this.updateStatusesAsync(settings.id);
			
            this.setComponent(settings.name);         

            return;
        }

    }

    setComponent(name) {
        this.componentName = name;        
        this.spaEventBus.$emit('showModalAd', this.componentName);
    }

    async convertLegacyAdCookiesAsync() {
        const betssonCookieName = 'betsson-campaing';
        const betssonCookie = this.cookies.get(betssonCookieName);
        if (betssonCookie) {
            this.adStatuses.setPlayer(this.playerId);
            const status = _.find(this.adStatuses.ads, (st) => st.id == this.nomenclatures().CampaignEnum.Betsson);
            if (status) { status.setShownNow(); }
            else {
                this.adStatuses.ads.push(new PlayerCampaign(this.nomenclatures().CampaignEnum.Betsson, moment().utc().format('YYYY-MM-DD')));
            }
            await this.updateStatusesAsync(this.nomenclatures().CampaignEnum.Betsson);
            this.cookies.remove(betssonCookieName);
        }

        const americanoCookieName = 'americano-campaing';
        const americanoCookie = this.cookies.get(americanoCookieName);
        if (americanoCookie) {
            this.adStatuses.setPlayer(this.playerId);
            const status = _.find(this.adStatuses.ads, (st) => st.id == this.nomenclatures().CampaignEnum.Americano);
            if (status) { status.setShownNow(); }
            else {
                this.adStatuses.ads.push(new PlayerCampaign(this.nomenclatures().CampaignEnum.Americano, moment().utc().format('YYYY-MM-DD')));
            }

            await this.updateStatusesAsync(this.nomenclatures().CampaignEnum.Americano);
            this.cookies.remove(americanoCookieName);
        }
    }

    getSettingsAsync() {
        if (this.settings.hasVaildSettings(this.playerId)) return Promise.resolve();
        if (this.playerId == null) {
            return this.$http.get(`${this.nomenclatures().ApiDomain}/campaign/GetCampaignsForNotLoggedUsersAsync`)
                .then((res) => {
                    this.settings = new AdsSettings(res.data.campaigns, res.data.frequencyInDays, false);
                });
        }
        else {
            return this.$http.get(`${this.nomenclatures().ApiDomain}/campaign/GetPlayerCampaignsAsync`)
                .then((res) => {
                    this.settings = new AdsSettings(res.data.campaigns, res.data.frequencyInDays, true);
                    this.buildAdStatuses(res.data.playerCampaigns);                    
                });
        }
    }


    initModuleState() {
        this.playerId = this.layout() && this.layout().player.currentPlayerId;        
    }

    async readAdsStatusAsync() {
        if (this.playerId == null && (this.adStatuses.ads.length == 0 || this.adStatuses.playerId != this.playerId)) { this.getCookies(); return Promise.resolve() }
        if (this.playerId != null && (this.adStatuses.ads.length == 0 || this.adStatuses.playerId != this.playerId)) return this.getAdStatusesFromServerAsync();
    }

    getCookies() {
        
        const jsonCookie = this.cookies.get(this.cookieName);
        if (jsonCookie) {
            const jsonStatus = JSON.parse(jsonCookie);            
            this.adStatuses = new CampaignStatus(jsonStatus._playerId, _.map(jsonStatus._ads
                , (a) => new PlayerCampaign(a._id, a._lastAdDate)));
        }
        else {
            this.adStatuses = new CampaignStatus(null, []);            
        }
        if (this.store._mutations['ui/setEnabledPwaPopup'])
            this.store._mutations['ui/setEnabledPwaPopup'][0](this.adStatuses.ads.findIndex(v => v.id == this.nomenclatures().CampaignEnum.PwaInstallAdvertUntilIgnored) < 0);
    }

    setCookies() {
        const jsonCookie = JSON.stringify(this.adStatuses);
        this.cookies.set(this.cookieName, jsonCookie, { expires: this.adsCookieExpirationInDays, path: '/' });
    }

    buildAdStatuses(data) {
        let ads = _.map(data, (s) => new PlayerCampaign(s.campaign, moment(s.dateShown).utc().format('YYYY-MM-DD')));
        this.adStatuses = new CampaignStatus(this.playerId, ads);
        if (this.store._mutations['ui/setEnabledPwaPopup'])
            this.store._mutations['ui/setEnabledPwaPopup'][0](this.adStatuses.ads.findIndex(v => v.id == this.nomenclatures().CampaignEnum.PwaInstallAdvertUntilIgnored) < 0);
    }

    getAdStatusesFromServerAsync() {        
        return this.$http.get(`${this.nomenclatures().ApiDomain}/campaign/GetPlayerCampaignsAsync`).
            then((res) => {
                this.buildAdStatuses(res.data.playerCampaigns);      
            });
    }

    async updateStatusesAsync(campaignId) {
        if (this.playerId != null) {
            return this.$http.post(`${this.nomenclatures().ApiDomain}/campaign/UpdatePlayerCampaignAsync`, { Campaign: campaignId });
        }
        else {
            this.adStatuses.setPlayer(null);
            this.setCookies();
            return Promise.resolve();
        }
    } 
    
    hasAnyModalOpen() {
        let result = false;

        if (document.body.classList.contains('rin-body-modal-open')
            || document.body.classList.contains('modal-open')) {
            result = true;
        }

        return result;
    }

    isRouteForbidden() {
        return !!this.router.currentRoute?.meta?.ads?.isForbidden;
    }


    async isPremiumTournamentAsync() {
        if (!this.nomenclatures().IsPremiumAllowed || !this.isRouteForPremiumTournamentCheck()) return false;

        return this.$http.get(`${this.nomenclatures().ApiDomain}/Monetization/GetIsPremiumTournamentAsync`,
            {
                params: { tournamentId: this.layout().page.id }
            })
            .then(res => res.data)
            .catch(e => {
                if (e.response && e.response.data.code == this.nomenclatures.PremiumPlanIsNotAllowed) {
                    return false;
                }

                return true;
            });
    }

    isRouteForPremiumTournamentCheck() {
        return !!this.router.currentRoute?.meta?.ads?.checkPremiumTournament;
    }

    async isPremiumOrganisationAsync() {
        if (!this.nomenclatures().IsPremiumAllowed || !this.isRouteForPremiumOrganisationCheck()) return false;

        const organisationId = this.router.currentRoute.params.id;
      
        return this.$http.get(`${this.nomenclatures().ApiDomain}/Monetization/GetIsPremiumOrganisationAsync`,
            {
                params: { organisationId: organisationId }
            })
            .then(res => res.data)
            .catch(() => false);
    }

    isRouteForPremiumOrganisationCheck() {
        return !!this.router.currentRoute?.meta?.ads?.checkPremiumOrganisation;
    }
}

export { Plugin }

export default {
    install(Vue, options) {
        Vue.component('americano-campaign', americanoCampaign);
        Vue.component('betsson-campaign', betssonCampaign);
        Vue.component('PrivateMatchCampaign', PrivateMatchCampaign);
        Vue.component('NewsletterCampaign', NewsletterCampaign);
        Vue.component('ProPlayersCampaign', ProPlayersCampaign);
		Vue.component('FreemiumCampaign', FreemiumCampaign);
		Vue.component('FreeTrialEndCampaign', FreeTrialEndCampaign);
		Vue.component('PwaCampaign', PwaCampaign);

        options.cookies = Cookies;
        this.plugin = new Plugin(options);        

        Vue.prototype.$modalAds = this.plugin;
    },
    
    generateAd() { this.plugin.generateAd(); }
   
}