<template>
	<router-link :to="{ name: destinationName, params: params }">
		<img v-show="!isAuthorized" src="https://cdn.rankedin.com/images/email/emailLogo.png" alt="logo" height="25" />
		<ProfileImage v-show="isAuthorized"></ProfileImage>
	</router-link>
</template>

<script>
import ProfileImage from '@/components/rin_new_design/components/nav/ProfileImage.vue';

export default {
	name: 'NavLink',
	components: {
		ProfileImage,
	},
	props: {
		destinationName: {
			type: String,
			required: true,
		},
		params: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		isAuthorized() {
			return this.$store.getters['topnav/isAuthorized'];
		},
	},
};
</script>
