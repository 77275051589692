export default {
    state: {
        notificationsCount: {}
    },
    mutations: {
        updatenotificationsCount(state, payload) {
            state.notificationsCount = payload;
        }
    },
    actions: {
        updatenotificationsCount: ({ commit }, payload) => {
            commit("updatenotificationsCount", payload);
        }
    },
}