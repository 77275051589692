<template>
    <div class="rin-navmenu-backdrop">
        <div class="wrap rin-navmenu-wrap">
            <div class="rin-navmenu" v-click-outside="close">
                <div class="rin-navmenu-close">
                    <div class="rin-navmenu-menu">Menu</div>
                    <div class="rin-navmenu-close-icon" @click="close"><BaseIcon name="close"></BaseIcon></div>
                </div>
                <div v-if="isAuthorized" class="rin-navmenu-user-card">
                    <router-link @click.native="close" :to="{ name: 'player-info', params: {language: language, id: rankedinId, name: urlName} }">
                        <ProfileImage></ProfileImage>
                        <div class="rin-player-info">
                            <BaseTypeLabel v-bind:type="layout.player.IsProPlayer ? 'pro' : 'notpro'" class="ml-0" />
                            <p class="rin-player-name">{{playerName}}</p>
                            <p class="rin-player-link">
                                {{ translations['topNavigation_Menu_SeeProfile'] }}
                            </p>
                        </div>
                    </router-link>
                </div>
                <div class="rin-navmenu-actions-wrap">
                    <NavMenuAction v-if="isAuthorized"
                                   icon="club"
                                   :title="translations['topNavigation_Menu_Club']"
                                   :destinationName="homeClubDestination"
                                   :params="{ language: language, id: homeClubId }"
                                   :description="translations['topNavigation_Menu_ClubDescription']" />

                    <NavMenuAction v-if="isAuthorized"
                                   icon="ranking"
                                   :title="translations['topNavigation_Menu_Rankings']"
                                   :destinationName="rankingDestination"
                                   :params="{ language: language, id: rankingId }"
                                   :class="'custom-increase-font-size'"
                                   :description="translations['topNavigation_Menu_RankingsDescription']" />

                    <NavMenuAction v-if="isAuthorized"
                                   icon="ereferee"
                                   :title="translations['topNavigation_Menu_EReferee']"
                                   destinationName="refereeBoard"
                                   :params="{ language: language }"
                                   :description="translations['topNavigation_Menu_ERefereeDescription']" />

                    <NavMenuAction v-if="isAuthorized"
                                   class="admin"
                                   icon="my-panel"
                                   :title="translations['topNavigation_Menu_AdminPanel']"
                                   destinationName="player-manage"
                                   :params="{ language: language }"
                                   :description="translations['topNavigation_Menu_AdminPanelDescription']" />

                    <NavMenuAction v-if="!isAuthorized"
                                   icon="features"
                                   :title="translations['topNavigation_Menu_Features']"
                                   destinationName="landing-page"
                                   :params="{ language: language }"
                                   :class="'not-logged-in-menu-icon'"
                                   :hash="'#features'"
                                   description="" />

                    <NavMenuAction v-if="!isAuthorized"
                                   icon="pricing"
                                   :title="translations['topNavigation_Menu_Pricing']"
                                   destinationName="pricing"
                                   :params="{ language: language }"
                                   :class="'not-logged-in-menu-icon'"
                                   description="" />

                    <NavMenuAction v-if="!isAuthorized"
                                   icon="demo"
                                   :title="translations['topNavigation_Menu_Demo']"
                                   destinationName="request-demo"
                                   :params="{ language: language }"
                                   :class="'not-logged-in-menu-icon'"
                                   :description="translations['topNavigation_Menu_DemoDescription']" />

                    <NavMenuAction v-if="!isAuthorized"
                                   icon="contact"
                                   :title="translations['topNavigation_Menu_Contact']"
                                   destinationName="landing-page"
                                   :params="{ language: language }"
                                   :class="'not-logged-in-menu-icon'"
                                   :hash="'#contact'"
                                   description="" />

                    <NavLoginOrCreate v-if="!isAuthorized"></NavLoginOrCreate>
                </div>
                <div class="rin-navmenu-list" :class="{'logged-out-list': !isAuthorized}">
                    <ul>
                        <li v-if="isAdmin" class="rin-admin-panel">
                            <a :href="'/' + language + '/statistics'"><div><BaseIcon name="profile" /> Super admin</div> </a><BaseIcon class="rin-menu-right" name="v" />
                        </li>
                        <li v-if="isAuthorized && layout.player.IsProPlayer" v-on:click="manageSubscription">
                            <div>
                                <BaseTypeLabel type="pro" /> {{ translations['topNavigation_Menu_ManageSubscription'] }}
                            </div>
                        </li>
                        <li v-if="isAuthorized && !layout.player.IsProPlayer" v-on:click="updgradeToProPlan">
                            <div class="pro-color">
                                <BaseTypeLabel type="pro" />
                                {{ translations['monetization_Player_TopbarUpgradeDescription1'] }}
                                <span class="font-italic">PRO</span>
                            </div>
                        </li>
                        <li :class="{'open' : languageMenuOpen}" @click="openLanguageMenu">
                            <div><BaseFlag :country="languageFlag" /> {{ translations['topNavigation_Menu_Language'] }}  </div> <BaseIcon class="rin-menu-right" name="v" />
                        </li>
                        <li class="rin-nav-language-select" :class="{'open' : languageMenuOpen}">
                            <div @click="changeLanguage('hr')">
                                <BaseFlag country="hr" /> Croatian
                            </div>
                            <div @click="changeLanguage('dk')">
                                <BaseFlag country="dk" /> Danish
                            </div>
                            <div @click="changeLanguage('nl')">
                                <BaseFlag country="nl" /> Dutch
                            </div>
                            <div @click="changeLanguage('en')">
                                <BaseFlag country="gb" /> English
                            </div>
                            <div @click="changeLanguage('fr')">
                                <BaseFlag country="fr" /> French
                            </div>
                            <div @click="changeLanguage('de')">
                                <BaseFlag country="de" /> German
                            </div>
                            <div @click="changeLanguage('it')">
                                <BaseFlag country="it" /> Italian
                            </div>
                            <div @click="changeLanguage('pl')">
                                <BaseFlag country="pl" /> Polish
                            </div>
                            <div @click="changeLanguage('pt')">
                                <BaseFlag country="pt" /> Portuguese
                            </div>
                            <div @click="changeLanguage('ru')">
                                <BaseFlag country="ru" /> Russian
                            </div>
                            <div @click="changeLanguage('si')">
                                <BaseFlag country="si" /> Slovenian
                            </div>
                            <div @click="changeLanguage('es')">
                                <BaseFlag country="es" /> Spanish
                            </div>
                            <div @click="changeLanguage('se')">
                                <BaseFlag country="se" /> Swedish
                            </div>
                            <div @click="changeLanguage('rs')">
                                <BaseFlag country="rs" /> Serbian
                            </div>
                        </li>
                        <li v-if="isAuthorized" @click="settingsClick">
                            <div><BaseIcon name="settings" /> {{ translations['topNavigation_Menu_Settings'] }}</div> <BaseIcon class="rin-menu-right" name="v" />
                        </li>
                        <li v-if="isAuthorized"><router-link :to="'/' + language + '/account/logoff'"><div><BaseIcon name="log_out" /> {{ translations['topNavigation_Menu_LogOut'] }}</div> </router-link></li>
                    </ul>


                    <div v-if="showInstallButton" class="rin-navmenu-pwa-option">
                        <div class="rin-pwa-install-menu-option">
                            <img src="https://cdn.rankedin.com/images/static/rankedin_app_incon.svg?1" alt="Rankedin">
                            <div>
                                <h4>Rankedin</h4>
                                <div>{{ translations['campaign_Pwa_Title'] }}</div>
                            </div>
                            <BaseButton  type="success" size="s" @click.native="installClicked"> {{ translations['campaign_Pwa_Install'] }} </BaseButton>
                        </div>
                    </div>
                    <template v-if="isModalVisible && !shouldInstallPwaNow">
                        <PwaCampaign @close-pwa-modal="isModalVisible = false"></PwaCampaign>
                    </template>
                </div>
                </div>

        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import vClickOutside from 'v-click-outside';
    import BaseIcon from '@/components/rin_new_design/components/base/BaseIcon.vue';
    import BaseFlag from '@/components/rin_new_design/components/base/BaseFlag.vue';
    import NavLoginOrCreate from '@/components/rin_new_design/components/nav/NavLoginOrCreate.vue';
    import ProfileImage from '@/components/rin_new_design/components/nav/ProfileImage.vue';
    import NavMenuAction from '@/components/rin_new_design/components/nav/NavMenuAction.vue';
	import BaseButton from '@/components/rin_new_design/components/base/BaseButton.vue';
	import PwaCampaign from '@/views/Ads/PwaCampaign';

    import BaseTypeLabel from '@/components/rin_new_design/components/base/BaseTypeLabel';

    import rinbootbox from '@/app/rin-bootbox';
	import { detect } from 'detect-browser';

    export default {
        name: 'NavMenu',
        components: {
            BaseIcon,
            BaseFlag,
            NavMenuAction,
            NavLoginOrCreate,
            ProfileImage,
            BaseTypeLabel,
			BaseButton,
			PwaCampaign
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        data() {
            return {
				userBrowser: detect().name,
				userPlatform: detect().os,
                languageMenuOpen: false,
                isModalVisible: false,
            }
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),
            ...mapGetters('ui', ['isMonetizationModalOpen', 'getPwaPromptRef', 'getHasPwaPrompt']),
            showInstallButton() {
                return !this.isWindowsOrMac && (this.getHasPwaPrompt || this.isAndroidOrIOS)
            },
            shouldInstallPwaNow() {
                return this.userPlatform?.match(/android/i)?.index >= 0 && this.userBrowser?.match(/chrome/i)?.index >= 0;
            },
            isAndroidOrIOS() {
                return this.userPlatform?.match(/android/i)?.index >= 0 || this.userPlatform?.match(/ios/i)?.index == 0;
            },
            isIOSSafari() {
                return this.userPlatform?.match(/ios/i)?.index == 0 && (this.userBrowser?.match(/ios/i)?.index == 0 || this.userBrowser?.match(/safari/i)?.index >= 0);
            },
            isWindowsOrMac() {
                return this.userPlatform?.match(/windows/i)?.index >= 0 || this.userPlatform?.match(/mac/i)?.index >= 0;
            },
            playerImage() {
                return this.$store.getters['topnav/playerImage'];
            },
            rankingId() {
                return this.$store.getters['topnav/playerRankingId'];
            },
            homeClubId() {
                return this.$store.getters['topnav/playerHomeClubId'];
            },
            homeClubDestination() {
                if (this.homeClubId === null) return 'chose-your-organisation';
                return 'organisation-index-info';
            },
            rankingDestination() {
                if (this.rankingId === null) return 'chose-your-organisation';
                return 'ranking';
            },
            language() {
                return this.$store.getters.layout.language;
            },
            isAdmin() {
                return this.$store.getters['topnav/isSuperAdmin'];
            },
            playerName() {
                return this.$store.getters['topnav/playerName'];
            },
            rankedinId() {
                return this.$store.getters['topnav/rankedinId'];
            },
            urlName() {
                return this.$store.getters['topnav/playerUrlName'];
            },
            isAuthorized() {
                return this.$store.getters['topnav/isAuthorized'];
            },
            languageFlag() {
                const lang = this.$store.getters.layout.language;
                if (lang === 'en') return 'gb';
                return lang;
            },			
        },        
        mounted() {
        },
        methods: {
            ...mapActions('ui', ['setMonetizationModalState']),
			...mapMutations('ui', ['setPwaPromptRef']),
            close() {
                this.$store.commit('topnav/toggleMenu', false);
            },
            settingsClick() {
                this.$router.push({ name: 'player-settings', params: { language: this.language } });
                this.close();
            },
            openLanguageMenu() {
                this.languageMenuOpen = !this.languageMenuOpen;
            },
            changeLanguage(code) {
                if (this.$route.params.language == code) return;

                this.setLanguageCookie(code);

                if (this.layout.player.isAuthenticated) {
                    this.$http.post("/home/changelanguage/", { code: code }).then(() =>
                        this.reloadWithLanguage(code)
                    );
                }
                else {
                    this.reloadWithLanguage(code);
                }
            },
            reloadWithLanguage(code) {
                let newLanguageRoute = this.$router.resolve({ params: { language: code } });
                location.href = newLanguageRoute.href;
            },
            setLanguageCookie(code) {

                var language_cookie = "language";

                // if the language cookie not exists create one.
                if (!this.$cookies.get(language_cookie)) {

                    // check if the browser supports cookie
                    var test_cookie = 'test cookie';
                    this.$cookies.set(test_cookie, true, { path: '/' });

                    // browser supports cookie
                    if (this.$cookies.get(test_cookie)) {

                        // delete the test cookie
                        this.$cookies.remove(test_cookie);

                        // create a new cookie
                        this.$cookies.set(language_cookie, code, { path: '/' });
                    }
                }
                else {
                    // delete the test cookie
                    this.$cookies.remove(language_cookie);

                    this.setLanguageCookie(code);
                }
            },
            manageSubscription() {
                this.$http.post(`${this.nomenclatures.ApiDomain}/monetization/CreateCustomerPortalSessionAsync`, {
                    language: this.layout.language
                })
                .then((response) => window.location.href = response.data)
                .catch(e => {
                    if (e.response && e.response.data.Code == this.nomenclatures.ExceptionsEnum.NoStripeAccount) {
                        rinbootbox.alert(this.translations['monetization_ManageSubscriptionErrorHeader'], this.translations['monetization_ManageSubscriptionErrorBody']);
                    }                    
                });                           
            },
            updgradeToProPlan() {
                this.setMonetizationModalState(true);
                this.close();
            },
			installClicked() {
				if(this.shouldInstallPwaNow) {
					this.isModalVisible = false;
					this.installPwa();
				} else {
					this.isModalVisible = true;
				}
			},
			async installPwa() {
				const prompt = this.getPwaPromptRef;
				if (prompt !== null) {
					prompt.prompt();
					const { outcome } = await prompt.userChoice;
					this.setPwaPromptRef(null);

					if (outcome === 'accepted') {
						this.$gtag.event('pwa_accept', { account: this.layout.player.rankedinId });
					} else if (outcome === 'dismissed') {
						this.$gtag.event('pwa_decline', { account: this.layout.player.rankedinId });
					}
				}
			},
        }
    };
</script>
