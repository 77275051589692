import Vue from 'vue';

import Router from 'vue-router';
import VueMeta from 'vue-meta';

Vue.use(Router);
Vue.use(VueMeta);
export default new Router({
	mode: 'history',
	base: '',
	routes: [
		{
			path: `/home/index`,
			name: 'android-app-landing-page',
			component: () => import('@/components/landing-page/landing-page.vue'),
			meta: {
				requireAnonymous: true, ads: { isForbidden: true }, feature: 'LandingPage'
			}
        },
        {
			path: `/:language([a-z]{2})?`,
			alias: [`/:language([a-z]{2})?/home/index`],
            name: 'landing-page',
            component: () => import('@/components/landing-page/landing-page.vue'),
            meta: {
                requireAnonymous: true, ads: { isForbidden: true }, feature: 'LandingPage'
			}
        },
		{
			path: '/:language([a-z]{2})?/pricing',
			alias: '/pricing',
			name: 'pricing',
			meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPagePricing', ads: { isForbidden: true } },
			component: () => import('@/views/Pricing.vue'),
		},
		{
			path: '/:language([a-z]{2})?/requestdemo',
			alias: '/requestdemo',
			name: 'request-demo',
			meta: { feature: 'LandingPage' },
			component: () => import('@/components/landing-page/request-demo.vue'),
		},
		{
			path: '/:language([a-z]{2})?/logo/partnership',			
			alias: '/logo/partnership',			
			name: 'partnership',			
			component: () => import('@/components/logo/partnership.vue'),
			meta: { feature: 'LandingPage' }
		},
		{
			path: '/:language([a-z]{2})?/account/login',
			alias: `/account/login`,
			name: 'login',
			component: () => import('@/components/account/login.vue'),
			meta: { requireAnonymous: true, ads: { isForbidden: true }, feature: 'LandingPage' },
		},
		{
			path: '/:language([a-z]{2})?/account/logoff',
			alias: '/account/logoff',
			name: 'logoff',
			component: () => import('@/components/account/logoff.vue'),
			meta: { requireAuth: true, ads: { isForbidden: true } },
		},
		{
			path: '/:language([a-z]{2})?/account/confirmemail',
			alias: '/account/confirmemail',
			name: 'confirm-email',
			component: () => import('@/components/account/confirm-email.vue'),
			meta: { requireAnonymous: true, ads: { isForbidden: true }, feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' },
		},
		{
			path: '/:language([a-z]{2})?/account/confirmregistration',
			alias: '/account/confirmregistration',
			name: 'confirm-registration',
			component: () => import('@/components/account/confirm-registration.vue'),
			meta: { ads: { isForbidden: true }, feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' },
		},
		{
			path: '/:language([a-z]{2})?/account/cantsignin',
			alias: '/account/cantsignin',
			name: 'cant-sign-in',
			component: () => import('@/components/account/cant-sign-in.vue'),
			meta: { requireAnonymous: true, ads: { isForbidden: true }, feature: 'LandingPage' },
		},
		{
			path: '/:language([a-z]{2})?/account/resetpassword',
			alias: '/account/resetpassword',
			name: 'reset-password',
			component: () => import('@/components/account/reset-password.vue'),
			meta: { requireAnonymous: true, ads: { isForbidden: true }, feature: 'PlayerProfile' },
		},
		{
			path: '/:language([a-z]{2})?/account/joinus',
			alias: '/account/joinus',
			name: 'join-us',
			component: () => import('@/components/account/join-us.vue'),
			meta: { requireAnonymous: true, ads: { isForbidden: true }, feature: 'LandingPage' },
		},		
		{
			path: '/:language([a-z]{2})?/unsubscribenewsletter/:token',
			name: 'player-newsletter-unsubscribe',
			component: () => import('@/components/player-settings/player-newsletter-unsubscribe.vue'),		
			meta: { feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' }
		},
		{
			path: '/:language([a-z]{2})?/settings',
			alias: '/:language([a-z]{2})?/settings/index',
			name: 'player-settings',
			component: () => import('@/components/rin_new_design/components/settings/SettingsIndex.vue'),
			meta: { requireAuth: true, feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' }
		},
		{
			path: '/:language([a-z]{2})?/player/manage',
			name: 'player-manage',
			component: () => import('@/views/player/ProfileManageEventsAndOrganisations.vue'),
			meta: { requireAuth: true, feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' }
		},
		{
			path: `/:language([a-z]{2})?/player/:id([D-R]\\w{9,14})/:name?`,
			component: () => import('@/components/player/playerProfile.vue'),
			meta: { searchHistory: true, name: 'player-info', breadcrumbRecord: { mapTo: 'PlayerProfileBreadcrumb', value: '', shouldTriggerSignalR: true }, feature: 'PlayerProfile' },
			children: [
				{
					path: `info`,
					alias: '',
					component: () => import('@/components/player/profile-info.vue'),
					name: 'player-info',
					props: true,
					meta: { breadcrumbRecord: { value: 'Info', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `skill`,
					component: () => import('@/components/player/profile-skill.vue'),
					name: 'player-skill',
					props: true,
					meta: { breadcrumbRecord: { value: 'Skill', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `rankings`,
					component: () => import('@/components/player/profile-rankings.vue'),
					name: 'player-rankings',
					props: true,
					meta: { breadcrumbRecord: { value: 'Ranking', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `americano/:skip(\\d+)`,
					component: () => import('@/views/player/ProfileAmericano.vue'),
					name: 'player-americano',
					props: true,
					meta: { breadcrumbRecord: { value: 'Americano', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `matches`,
					component: () => import('@/views/player/ProfileMatches.vue'),
					name: 'player-matches',
					props: true,
					meta: { breadcrumbRecord: { value: 'Matches', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `events`,
					component: () => import('@/components/player/events/profile-events-tab.vue'),
					name: 'player-events',
					props: true,
					meta: { breadcrumbRecord: { value: 'Events', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `memberships`,
					component: () => import('@/components/player/profile-membership.vue'),
					name: 'player-memberships',
					props: true,
					meta: { breadcrumbRecord: { value: 'Membership', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
				{
					path: `opponents`,
					component: () => import('@/components/player/player-opponents.vue'),
					name: 'player-opponents',
					props: true,
					meta: { breadcrumbRecord: { value: 'Opponents', shouldTriggerSignalR: true }, feature: 'PlayerProfile' }
				},
			],
		},
		{
			path: `/:language([a-z]{2})?/notifications`,
			alias: '/:language([a-z]{2})?/notifications/index',
			name: 'notifications',
			component: () => import('@/components/notification/notificationAppSpaComponent.vue'),
			meta: { feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' }
		},
		{
			path: `/:language([a-z]{2})?/messages`,
			alias: `/:language([a-z]{2})?/messages/index`,
			component: () => import('@/views/Messenger.vue'),
			name: 'messages',
			meta: { feature: 'PlayerProfile', ads: { isForbidden: true }, getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' },
			children: [
				{
					path: ':id(\\d+)',
					component: () => import('@/views/Messenger.vue'), //import('@/components/messages/messagesChatBox.vue'),
					name: 'chatbox',
					props: true
				},
				{
					path: 'new/:id(\\d+)',
					component: () => import('@/views/Messenger.vue'), //import('@/components/messages/messagesChatBox.vue'),
					name: 'newchat',
					props: true
				},
			],
		},
		{
			path: `/:language([a-z]{2})?/calendar`,
			component: () => import('@/views/calendar.vue'),
			name: 'calendar',
			meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageCalendar' },
		},
		{
			path: `/:language([a-z]{2})?/americano/promo`,
			component: () => import('@/components/americano/americano-feature-promo.vue'),
			name: 'americano-promo',
			meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageAmericano' }
		},
		{
			path: `/:language([a-z]{2})?/americano/create`,
			component: () => import('@/components/americano/create.vue'),
			name: 'americano',
			meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageAmericano', ads: { isForbidden: true } }
		},
		{
			path: `/:language([a-z]{2})?/americano/copy/:id(\\d+)`,
			component: () => import('@/components/americano/create.vue'),
			name: 'americano-copy',
			meta: { feature: 'Americano', ads: { isForbidden: true } }
		},
		{
			path: `/:language([a-z]{2})?/americano/:id(\\d+)/:name?`,
			component: () => import('@/components/americano/index.vue'),
			name: 'americano-index',
			meta: { feature: 'Americano' }
		},
		{
			path: `/:language([a-z]{2})?/ranking/createranking`,			
			component: () => import('@/views/adminpart/ranking/createranking.vue'),
			name: 'create-ranking',
			meta: { requireAuth: true, isAdminPart: true, feature: 'LandingPage', getByRelatedEntity: 'LandingPageCreateRanking' }
		},
		{
			path: `/:language([a-z]{2})?/ranking/settings/:id(\\d+)?`,
			component: () => import('@/views/adminpart/ranking/RankingSettings.vue'),
			name: 'ranking-settings',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Ranking' }
		},
		{
			path: `/:language([a-z]{2})?/ranking/:id(\\d+)?/:name?`,
			alias: `/:language([a-z]{2})?/promote/:color/ranking/index/:id/:name?`,
			component: () => import('@/components/ranking/ranking-index.vue'),
			name: 'ranking',
			meta: { searchHistory: true, name: 'ranking', feature: 'Ranking', hasAliternativeBackground: true },
		},
		{
			path: `/:language([a-z]{2})?/ranking/points/:id(\\d+)/:agegroup(\\d+)/:weeksfromnow(\\d+)`,
			component: () => import('@/components/ranking/ranking-points-details.vue'),
			name: 'ranking-points',
			meta: { feature: 'Ranking', getByRelatedEntity: 'GetRankingByRankingParticipantId' },
		},
		{
			path: `/:language([a-z]{2})?/referee/refereeboard/:code?`,
			component: () => import('@/components/refereeCodes/referee-board.vue'),
			name: 'refereeBoard',
			meta: { feature: 'LandingPage', ads: { isForbidden: true }, getByRelatedEntity: 'LandingPageRefereeboard' }
		},
		{
			path: `/:language([a-z]{2})?/referee/courtview/:id(\\d+)?`,
			component: () => import('@/views/userpart/referee/courtview.vue'),
			name: 'referee-courtview',
			meta: { feature: 'LandingPage', ads: { isForbidden: true }, getByRelatedEntity: 'LandingPageRefereeboard' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/create/:id(\\d+)?`,
			alias: `/:language([a-z]{2})?/tournament/edit/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentCreateEdit.vue'),
			name: 'tournament-create-edit',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/EnterResult/:id(\\d+)?`,
			alias: `/:language([a-z]{2})?/tournament/EnterResult/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentEnterResult.vue'),
			name: 'tournament-enter-result',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/images/add/:id(\\d+)`,
			component: () => import('@/views/AdminPart/LogoImages/LogoImages.vue'),
			name: 'LogoImages',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/playersettings/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentPlayerSettings.vue'),
			name: 'tournament-player-settings',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/moveplayers/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentPlayersMove.vue'),
			name: 'tournament-move-players',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/autoadd/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Shared/AutoAdd.vue'),
			name: 'tournament-auto-add',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/team/autoadd/:id(\\d+)`,
			component: () => import('../components/team/team-manager-container-bs4.vue'),
			name: 'tn-team-create',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/team/teamManager/:id(\\d+)`,
			component: () => import('../components/team/team-manager-container-bs4.vue'),
			name: 'tn-team-manager',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament', getByRelatedEntity: 'GetTournamentByParticipantId' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/timetable/:id(\\d+)`,
			component: () => import('@/views/userpart/tournament/TimetableNoMenu.vue'),
			name: 'tournament-timetable-nomenu',			
			meta: { ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/closesignin/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentAdminCloseSignin.vue'),
			name: 'tournament-close-sign-in',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/timesettings/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentTimes.vue'),
			name: 'tournament-times',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/starttournament/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentStart.vue'),
			name: 'tournament-start',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/drawssettings/:id(\\d+)/:classId(\\d+)?`,
			component: () => import('@/views/adminpart/tournament/DrawSettings.vue'),
			name: 'tournament-draws-settings',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/drawpreview/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentDrawPreview.vue'),
			name: 'tournament-draws-preview',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/drawcreator/:id(\\d+)/:classId(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentDrawCreator.vue'),
			name: 'tournament-draws-creator',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/classes/:id(\\d+)`,
			component: () => import('@/views/adminpart/tournament/TournamentClasses.vue'),
			name: 'tournament-classes-step',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/matchessettings/:id(\\d+)`,
			component: () => import('@/views/adminpart/tournament/MatchesSettings.vue'),
			name: 'tournament-matches-settings',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament'}
		},
		{
			path: `/:language([a-z]{2})?/tournament/AllPlayersAndPayments/:id(\\d+)`,
			component: () => import('@/components/tournament/admin-spa/all-players-and-payments.vue'),
			name: 'all-players-and-payments',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
        {
            path: `/:language([a-z]{2})?/tournament/sendemail/:id(\\d+)`,
			component: () => import('@/views/adminpart/Tournament/TournamentSendEmails.vue'),
            name: 'tournament-admin-sendemails',
			meta: { ads: { isForbidden: true }, feature: 'Tournament' },
        },
		{
			path: `/:language([a-z]{2})?/tournament/printdraw/:id(\\d+)?`,
			component: () => import('@/components/tournament/user-part/print-draw.vue'),
			name: 'print-tournament-draw',
			meta: { ads: { isForbidden: true }, feature: 'Tournament' }
		},		
        {
            path: `/:language([a-z]{2})?/tournament/premiumpaymentsuccess/:id(\\d+)`,
			component: () => import('@/views/UserPart/Tournament/PremiumPaymentSuccess.vue'),
            name: 'tournament-premium-payment-success',
			meta: { ads: { isForbidden: true }, feature: 'Tournament' },
		},
		{
			path: `/:language([a-z]{2})?/tournament/managestreams/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentManageStreams.vue'),
			name: 'tournament-manage-streams',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/substitutedouble/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentSubstituteDouble.vue'),
			name: 'tournament-substitute-double',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/substitute/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentSubstitute.vue'),
			name: 'tournament-substitute',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/admins/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentAdmins.vue'),
			name: 'tournament-admins',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/activity/:id(\\d+)`,
			component: () => import('@/views/AdminPart/Tournament/TournamentActivity.vue'),
			name: 'tournament-activity',
			meta: { requireAuth: true, isAdminPart: true, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
		},
		{
			path: `/:language([a-z]{2})?/tournament/:id(\\d+)/:name?`,
			alias: `/:language([a-z]{2})?/promote/:color/tournament/index/:id/:name?`,
			component: () => import('@/components/tournament/user-part/tournament.vue'),
			meta: { searchHistory: true, name: 'tournament-info', breadcrumbRecord: { mapTo: 'TournamentBreadcrumbs', value: '' }, ads: { checkPremiumTournament: true }, feature: 'Tournament' },
			children: [
				{
					path: `info`,
					alias: '',
					component: () => import('@/components/tournament/user-part/tournament-info.vue'),
					name: 'tournament-info',
					props: true,
					meta: { breadcrumbRecord: { value: 'Info' }, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
				},
				{
					path: 'draws',
					component: () => import('@/components/tournament/user-part/tournament-draws.vue'),
					name: 'tournament-draws',
					props: true,
					meta: { breadcrumbRecord: { value: 'Draws' }, ads: { checkPremiumTournament: true }, feature: 'Tournament'},
					children: [
						{
							path: `:classid(\\d+)`,
							component: () => import('@/components/tournament/user-part/tournament-draws.vue'),
							props: true,
							name: 'specific-class',
							meta: { ignoreBreadcrumbMissmatch: true, ads: { checkPremiumTournament: true }, feature: 'Tournament'},
							children: [
								{
									path: `:stage(\\d+)/:strength(\\d+)`,
									component: () => import('@/components/tournament/user-part/tournament-draws.vue'),
									props: true,
									name: 'specific-stage-strength',
									meta: { ignoreBreadcrumbMissmatch: true, ads: { checkPremiumTournament: true }, feature: 'Tournament'},
								},
							],
						},
					],
				},
				{
					path: 'scoreboard',
					component: () => import('@/components/tournament/user-part/tournament-scoreboard.vue'),
					name: 'tournament-scoreboard',
					meta: { breadcrumbRecord: { value: 'Scoreboard' }, ads: { checkPremiumTournament: true }, feature: 'Tournament'}
				},
				{
					path: 'timetable',
					component: () => import('@/components/tournament/user-part/tournament-timetable.vue'),
					name: 'tournament-timetable',
					meta: { breadcrumbRecord: { value: 'Timetable' }, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
				},
				{
					path: 'matches',
					component: () => import('@/components/tournament/user-part/tournament-matches.vue'),
					name: 'tournament-matches',
					meta: { breadcrumbRecord: { value: 'Matches' }, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
				},
				{
					path: 'players',
					component: () => import('@/components/tournament/user-part/tournament-players.vue'),
					name: 'tournament-players',
					meta: { breadcrumbRecord: { value: 'Players' }, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
				},
				{
					path: 'classes',
					component: () => import('@/components/tournament/user-part/tournament-classes.vue'),
					name: 'tournament-classes',
					meta: { breadcrumbRecord: { value: 'Classes' }, ads: { checkPremiumTournament: true }, feature: 'Tournament' }
				},
				{
					path: 'results',
					component: () => import('@/components/tournament/tournament-results-table-bs4.vue'),
					name: 'tournament-results',
					meta: { breadcrumbRecord: { value: 'Results' }, ads: { checkPremiumTournament: true }, feature: 'Tournament'}
				},
				{
					path: 'video',
					component: () => import('@/components/shared/video-tab.vue'),
					name: 'tournament-video',
					meta: { breadcrumbRecord: { value: 'Video' }, ads: { checkPremiumTournament: true }, feature: 'Tournament'}
				},
			],
		},
		{
			path: '/:language([a-z]{2})?/join/tournament/:id(\\d+)',
			name: 'tournament-join',
			component: () => import('@/components/tournament/tournament-join.vue'),
			meta: { ads: { checkPremiumTournament: true }, feature: 'Tournament' }

		},
		{
			path: `/:language([a-z]{2})?/organisation/choseyourorganisation`,
			component: () => import('@/components/organisation/chose-your-organisation.vue'),
			name: 'chose-your-organisation',
			meta: { requireAuth: true }
		},
		{
			path: `/:language([a-z]{2})?/organisation/sendemailtoclubmembers/:id`,
			component: () => import('@/views/AdminPart/Organization/SendEmailsToClubMembers.vue'),
			name: 'send-emails-to-club-members',
			meta: { requireAuth: true, feature: 'Organisation' }
		},
		{
			path: `/:language([a-z]{2})?/organisation/createorganisation`,
			component: () => import('@/components/organisation/admin-spa/create-organisation.vue'),
			name: 'create-organisation',
			meta: { requireAuth: true, isAdminPart: true, feature: 'LandingPage', getByRelatedEntity: 'LandingPageCreateOrganisation' }
		},
		{
			path: '/:language([a-z]{2})?/organisation/embedevents/:id(\\d+)/:name?',
			component: () => import('@/views/userpart/organisation/OrganisationIndexCalendarEmbeded.vue'),
			name: 'organizationCalendar-embedevents',
			meta: { ads: { checkPremiumOrganisation: true }, feature: 'Organisation' },
		},
		{
			path: `/:language([a-z]{2})?/organisation/:id(\\d+)?/:name?`,
			alias: `/:language([a-z]{2})?/promote/:color/organisation/index/:id/:name?`,
			component: () => import('@/views/userpart/organisation/OrganisationIndex.vue'),
			meta: { searchHistory: true, name: 'organisation-index-info', ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { mapTo: 'OrganisationBreadcrumbs', value: '' }, feature: 'Organisation'},
			children: [
				{
					path: '/',
					component: () => import('@/views/userpart/organisation/OrganisationIndexInfo.vue'),
					name: 'organisation-index-info',
					meta: { ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { value: 'Info' }, feature: 'Organisation', hasAliternativeBackground: true },
				},
				{
					path: '/:language([a-z]{2})?/organisation/calendar/:id(\\d+)/:name?',
					component: () => import('@/views/userpart/organisation/OrganisationIndexCalendar.vue'),
					name: 'organisation-index-calendar',
					props: true,
					meta: { ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { value: 'Calendar' }, feature: 'Organisation', hasAliternativeBackground: true },
				},
				{
					path: '/:language([a-z]{2})?/organisation/ranking/:id(\\d+)/:name?',
					component: () => import('@/views/userpart/organisation/OrganisationIndexRanking.vue'),
					name: 'organisation-index-ranking',
					props: true,
					meta: { ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { value: 'Ranking' }, feature: 'Organisation', hasAliternativeBackground: true },
				},
				{
					path: '/:language([a-z]{2})?/organisation/memberclubs/:id(\\d+)/:name?',
					component: () => import('@/views/userpart/organisation/OrganisationIndexClubs.vue'),
					name: 'organisation-index-clubs',
					props: true,
					meta: { ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { value: 'Clubs' }, feature: 'Organisation', hasAliternativeBackground: true },
				},
				{
					path: '/:language([a-z]{2})?/organisation/members/:id(\\d+)/:name?',
					component: () => import('@/views/userpart/organisation/OrganisationIndexMembers.vue'),
					name: 'organisation-index-members',
					props: true,
					meta: { ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { value: 'Members' }, feature: 'Organisation', hasAliternativeBackground: true },
				},
				{
					path: '/:language([a-z]{2})?/organisation/teams/:id(\\d+)/:name?',
					component: () => import('@/views/userpart/organisation/OrganisationIndexTeams.vue'),
					name: 'organisation-index-teams',
					props: true,
					meta: { ads: { checkPremiumOrganisation: true }, breadcrumbRecord: { value: 'Teams' }, feature: 'Organisation', hasAliternativeBackground: true },
				},
				{
					path: '/:language([a-z]{2})?/organisation/settings/:id(\\d+)/:name?',
					beforeEnter: (to) => {
						window.location.href = to.fullPath;
					},
				},
				{
					path: '*',
					component: () => import('@/views/userpart/organisation/OrganisationIndex.vue'),
					name: 'organisation-catch',
					meta: { ads: { checkPremiumOrganisation: true }, feature: 'Organisation' },
				},
			]
		},
		{
			path: `/:language([a-z]{2})?/clubleague/playerssettings/:id(\\d+)?`,
			component: () =>
				import('@/components/clubleague/admin-spa/clubleague-player-settings.vue'),
			name: 'clubLeague-player-settings',
			meta: { isBS3: true, requireAuth: true, isAdminPart: true }
		},
		{
			path: `/:language([a-z]{2})?/clubleague/managestreams/:id(\\d+)`,
			component: () => import('@/views/AdminPart/ClubLeague/ClubLeagueManageStreams.vue'),
			name: 'clubleague-manage-streams',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Clubleague' }
		},
		{
			path: `/:language([a-z]{2})?/clubleague/activity/:id(\\d+)`,
			component: () => import('@/views/AdminPart/ClubLeague/ClubLeagueActivity.vue'),
			name: 'clubleague-activity',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Clubleague' }
		},
		{
			path: `/:language([a-z]{2})?/clubleague/:id(\\d+)?/:name?`,
			alias: `/:language([a-z]{2})?/promote/:color/clubleague/index/:id/:name?`,
			component: () => import('@/components/clubleague/user-part/club-league.vue'),
			meta: { searchHistory: true, name: 'clubleague-info', breadcrumbRecord: { mapTo: 'ClubLeagueBreadcrumbs', value: '' }, feature: 'Clubleague' },
			children: [
				{
					path: `info`,
					alias: '',
					component: () => import('@/components/clubleague/user-part/club-league-info.vue'),
					name: 'clubleague-info',
					props: true,
					meta: { breadcrumbRecord: { value: 'Info' }, feature: 'Clubleague' }
				},
				{
					path: 'pools/:rangeIndex(\\d+)?',
					component: () => import('@/components/clubleague/club-league-pools-panel-bs4.vue'),
					name: 'clubleague-pools',
					props: true,
					meta: { breadcrumbRecord: { value: 'Pools' }, feature: 'Clubleague' }
				},
				{
					path: 'video',
					component: () => import('@/components/shared/video-tab.vue'),
					name: 'clubleague-video',
					props: true,
					meta: { breadcrumbRecord: { value: 'Video' }, feature: 'Clubleague' }
				},
				{
					path: 'players',
					component: () => import('@/components/clubleague/user-part/club-league-players.vue'),
					name: 'clubleague-players',
					props: true,
					meta: { breadcrumbRecord: { value: 'Players' }, feature: 'Clubleague' }
				},
				{
					path: 'results',
					component: () => import('@/components/clubleague/user-part/club-league-results-tab.vue'),
					name: 'clubleague-results',
					meta: { breadcrumbRecord: { value: 'Results' }, feature: 'Clubleague' },
					children: [
						{
							path: 'results/:rangeIndex(\\d+)?',
							component: () =>
								import('@/components/clubleague/user-part/club-league-results-tab-results.vue'),
							name: 'clubleague-results-results',
							props: true,
							meta: { ignoreBreadcrumbMissmatch: true, feature: 'Clubleague' },
						},
						{
							path: 'standings',
							component: () =>
								import('@/components/clubleague/user-part/club-league-results-tab-standings.vue'),
							name: 'clubleague-results-standings',
							props: true,
							meta: { ignoreBreadcrumbMissmatch: true, feature: 'Clubleague' },
						},
						{
							path: 'ratings',
							component: () =>
								import('@/components/clubleague/user-part/club-league-results-tab-ratings.vue'),
							name: 'clubleague-results-ratings',
							props: true,
							meta: { ignoreBreadcrumbMissmatch: true, feature: 'Clubleague' },
						},
						{
							path: 'newest',
							component: () =>
								import('@/components/clubleague/user-part/club-league-results-tab-newest.vue'),
							name: 'clubleague-results-newest',
							props: true,
							meta: { ignoreBreadcrumbMissmatch: true, feature: 'Clubleague' },
						},
						{
							path: '*',
							component: () => import('@/components/clubleague/user-part/club-league-results-tab-standings.vue'),
							name: 'clubleague-catch',
							props: true,
							meta: { ignoreBreadcrumbMissmatch: true, feature: 'Clubleague' },
						},
					],
				},
			],
		},
		{
			path: `/:language([a-z]{2})?/payment/order/:id(\\d+)`,
			component: () => import('@/components/payment/order.vue'),
			children: [
				{
					path: '',
					component: () => import('@/components/payment/order-review.vue'),
					name: 'order-review',
					props: true,
				},
				{
					path: 'order-payment',
					component: () => import('@/components/payment/order-checkout.vue'),
					name: 'order-checkout',
					props: true,
				},
			],
		},
		{
			path: '/:language([a-z]{2})?/payment/success/:id(\\d+)?',
			name: 'payment-success',
			component: () => import('@/components/payment/payment-success.vue'),
			meta: { ads: { isForbidden: true } },
		},
		{
			path: '/:language([a-z]{2})?/payment/fail/:id(\\d+)?',
			name: 'payment-fail',
			component: () => import('@/components/payment/payment-fail.vue'),
			meta: { ads: { isForbidden: true } },
		},
		{
			path: '/:language([a-z]{2})?/payment/summary/tournament/:eventId(\\d+)/:participantId(\\d+)',
			name: 'tournament-payment-summary',
			component: () => import('@/components/payment/summary/tournament-payment-summary.vue'),
			meta: { ads: { checkPremiumTournament: true }, feature: 'Tournament'}
		},
		{
			path: '/:language([a-z]{2})?/payment/history/tournament/:eventId(\\d+)/:participantId(\\d+)/:orderid(\\d+)',
			name: 'tournament-payment-history',
			props: true,
			component: () => import('@/components/payment/summary/tournament-payment-history.vue'),
			meta: { ads: { checkPremiumTournament: true }, feature: 'Tournament'}
		},
		{
			path: '/:language([a-z]{2})?/payment/summary/teamleague/:eventId(\\d+)/:participantId(\\d+)',
			name: 'teamleague-payment-summary',
			component: () => import('@/components/payment/summary/teamleague-payment-summary.vue'),
			meta: {  }
		},
		{
			path: '/:language([a-z]{2})?/payment/history/teamleague/:eventId(\\d+)/:participantId(\\d+)/:orderid(\\d+)',
			name: 'teamleague-payment-history',
			props: true,
			component: () => import('@/components/payment/summary/teamleague-payment-history.vue'),
			meta: { }
		},
		{
			path: `/:language([a-z]{2})?/teamleague/pool`,
			component: () => import('@/components/teamleague/user-part/teamleague-pool.vue'),
			name: 'teamleague-pool',			
			meta: { feature: 'Teamleague', getByRelatedEntity: 'GetTeamleagueByPoolId' },
		},
		{
			path: `/:language([a-z]{2})?/teamleague/managestreams/:id(\\d+)`,
			component: () => import('@/views/AdminPart/teamleague/TeamLeagueManageStreams.vue'),
			name: 'teamleague-manage-streams',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague' }
		},
		{
			path: `/:language([a-z]{2})?/teamleague/activity/:id(\\d+)`,
			component: () => import('@/views/AdminPart/teamleague/TeamLeagueActivity.vue'),
			name: 'teamleague-activity',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague' }
		},
		{
			path: `/:language([a-z]{2})?/teamleague/:id(\\d+)/:name?`,
			alias: `/:language([a-z]{2})?/promote/:color/teamleague/index/:id/:name?`,
			component: () => import('@/components/teamleague/user-part/teamleague.vue'),
			meta: { searchHistory: true, name: 'teamleague-info', breadcrumbRecord: { mapTo: 'TeamLeagueBreadcrumbs', value: '' }, feature: 'Teamleague' },
			children: [
				{
					path: `info`,
					alias: '',
					component: () => import('@/components/teamleague/user-part/teamleague-info.vue'),
					name: 'teamleague-info',
					props: true,
					meta: { breadcrumbRecord: { value: 'Info' }, feature: 'Teamleague' },
				},
				{
					path: 'video',
					component: () => import('@/components/shared/video-tab.vue'),
					name: 'teamleague-video',
					props: true,
					meta: { breadcrumbRecord: { value: 'Video' }, feature: 'Teamleague' },
				},
				{
					path: 'teams',
					component: () => import('@/components/teamleague/user-part/teamleague-teams.vue'),
					name: 'teamleague-teams',
					meta: { breadcrumbRecord: { value: 'Teams' }, feature: 'Teamleague' },
				},
				{
					path: 'matches',
					component: () => import('@/components/teamleague/user-part/teamleague-matches.vue'),
					name: 'teamleague-matches',
					meta: { breadcrumbRecord: { value: 'Matches' }, feature: 'Teamleague' },
				},
				{
					path: 'players',
					component: () => import('../components/teamleague/user-part/teamleague-players.vue'),
					name: 'teamleague-players',
					meta: { breadcrumbRecord: { value: 'Players' }, feature: 'Teamleague' },
				},
				{
					path: 'standings',
					component: () => import('@/components/teamleague/user-part/teamleague-standings.vue'),
					name: 'teamleague-standings',
					meta: { breadcrumbRecord: { value: 'Standings' }, feature: 'Teamleague' },
				},
				{
					path: 'clubs',
					component: () => import('@/components/teamleague/user-part/teamleague-clubs.vue'),
					name: 'teamleague-clubs',
					meta: { breadcrumbRecord: { value: 'Clubs' }, feature: 'Teamleague' },
				},
			],
		},
		{
			path: `/:language([a-z]{2})?/teamleague/create/:id(\\d+)?`,
			alias: `/:language([a-z]{2})?/teamleague/edit/:id(\\d+)`,
			component: () => import('@/views/AdminPart/TeamLeague/TeamLeagueCreateEdit.vue'),
			name: 'tl-create-edit',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague' }
		},
		{
			path: `/:language([a-z]{2})?/teamleague/validation/:id(\\d+)?`,
			component: () => import('@/views/AdminPart/TeamLeague/TeamLeagueTeams.vue'),
			name: 'tl-admin-teams',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague' }
		},
		{
			path: `/:language([a-z]{2})?/teamleague/sendemail/:id(\\d+)?`,
			component: () => import('@/views/AdminPart/TeamLeague/TeamLeagueSendEmails.vue'),
			name: 'tl-admin-sendemails',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague' }
		},
		{
			path: `/:language([a-z]{2})?/team/create`,
			component: () => import('@/components/team/admin-spa/team-manager.vue'),
			name: 'tl-team-create',
			meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague' }
		},
		{
			path: `/:language([a-z]{2})?/team/manager/:id(\\d+)`,
			component: () => import('@/components/team/admin-spa/team-manager.vue'),
			name: 'tl-team-manager',
            meta: { requireAuth: true, isAdminPart: true, feature: 'Teamleague', getByRelatedEntity: 'GetTeamleagueByTeamId' }
		},
		{
			path: `/:language([a-z]{2})?/team/homepage/:id(\\d+)/:name?`,
			component: () => import('@/components/team/tl-team-homepage-container.vue'),
			name: 'tl-team-homepage',
			meta: { searchHistory: true, name: 'tl-team-homepage', ignoreBreadcrumbMissmatch: true, feature: 'Teamleague', getByRelatedEntity: 'GetTeamleagueByTeamId' },
		},
		{
			path: `/:language([a-z]{2})?/team/matchresults/:id(\\d+)`,
			component: () => import('@/components/teamleague/user-part/teamleague-match-results.vue'),
			name: 'team-matchresults',
			meta: { ignoreBreadcrumbMissmatch: true, feature: 'Teamleague', getByRelatedEntity: 'GetTeamleagueByTeamMatchId' }
		},
		{
			path: `/:language([a-z]{2})?/team/tournamentmatchresults/:id(\\d+)`,
			component: () => import('@/views/userpart/tournament/TeamMatchLineUp.vue'),
			name: 'tournament-matchresults',
			meta: { ignoreBreadcrumbMissmatch: true, ads: { checkPremiumTournament: true }, feature: 'Tournament', getByRelatedEntity: 'GetTournamentByChallengeId' }
		},
		{
			path: `/:language([a-z]{2})?/team/:id(T\\w{9})/:name?`,
			alias: `/:language([a-z]{2})?/team/index/:id(T\\w{9})?`,
			component: () => import('@/components/team/team-homepage-container.vue'),
			name: 'team-homepage',
			meta: { searchHistory: true, name: 'team-homepage', ignoreBreadcrumbMissmatch: true, feature: 'Tournament', getByRelatedEntity: 'GetTournamentByParticipantRankedinId' },

		},
		{
			path: `/:language([a-z]{2})?/content/poster/:id(\\d+)`,
			component: () => import('@/components/content/poster.vue'),
			name: 'poster',
			meta: { ads: { isForbidden: true } },
		},
		{
			path: `/:language([a-z]{2})?/content/shownews`,
			alias: `/content/shownews`,
			component: () => import('@/components/content/show-news.vue'),	
			meta: { feature: 'LandingPage' },
			children: [
				{
					path: 'about-us',
					component: () => import('@/components/content/articles/about-us.vue'),
					name: 'about-us',
					meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageAboutUs' }
				},
				{
					path: 'federations',
					component: () => import('@/components/content/articles/federations.vue'),
					name: 'federations',
					meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageFederations' }
				},
				{
					path: 'rankedin-for-clubs',
					component: () => import('@/components/content/articles/rankedin-for-clubs.vue'),
					name: 'rankedin-for-clubs',
					meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageForClubs' }
				},
				{
					path: 'rankedin-for-players',
					component: () => import('@/components/content/articles/rankedin-for-players.vue'),
					name: 'rankedin-for-players',
					meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageForPlayers' }
				},
				{
					path: 'enable-embedded-livestream',
					component: () => import('@/components/content/articles/enable-embedded-livestream.vue'),
					name: 'enable-embedded-livestream',
					meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageEnableEmbedded' }
				}
			],
		},
		{
			path: `/:language([a-z]{2})?/live-scoring`,
			alias: `/live-scoring`,
			component: () => import('@/components/content/live-scoring.vue'),
			name: 'live-scoring',
			meta: { feature: 'LandingPage', getByRelatedEntity: 'LandingPageLiveScoring'}
		},
		{
			path: `/:language([a-z]{2})?/dataprotection`,
			component: () => import('@/components/content/data-protection.vue'),
			name: 'data-protection',
			meta: { feature: 'LandingPage' }
		},
		{
			path: `/:language([a-z]{2})?/match/edit/:id(\\d+)`,
			alias: `/match/edit/:id`,
			component: () => import('@/components/shared/user-part/edit-match.vue'),
			name: 'edit-match',
			meta: { feature: 'PlayerProfile'}
		},
		{
			path: `/:language/privatematch/create`,			
			component: () => import('@/views/PrivateMatch/PrivateMatchCreate.vue'),
			name: 'private-match-create',
			meta: { feature: 'LandingPage', getByRelatedEntity:'LandingPageCreatePrivateMatch' }			
		},
		{
			path: `/:language([a-z]{2})?/subscription/success`,
			component: () => import('@/views/SubscriptionSuccess.vue'),
			name: 'subscription-success',
			meta: { ads: { isForbidden: true }, feature: 'PlayerProfile', getByRelatedEntity: 'GetPlayerByCurrentSessionPlayerId' },			
		},
		{
			path: `/:language([a-z]{2})?/freemium/noworries`,
			component: () => import('@/views/Ads/Freemium/NoWorries.vue'),
			name: 'freemium-noworries',
			meta: { ads: { isForbidden: true } },
		},
		{
			path: `/:language([a-z]{2})?/freemium/whyfree`,
			component: () => import('@/views/Ads/Freemium/WhyFree.vue'),
			name: 'freemium-whyfree',
			meta: { ads: { isForbidden: true } },
		},
		{
			path: `/:language([a-z]{2})?/account/updatecardinformation`,
			component: () => import('@/views/UpdateCardInfo.vue'),
			name: 'update-card-info',
			meta: { ads: { isForbidden: true } },
        },
		{
			path: '/:pathMatch(.*)*',
			beforeEnter: (to) => {
				window.location.href = to.fullPath;
			},
		}
	],
});
