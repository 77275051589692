<template>
    <div class="searchTop lpDesign col-12 col-lg-auto" v-cloak>
        <a href="#"><span class="icon icon-search"></span></a>
        <form class="lpDesign" action="javascript:void(0);">
            <div class="row">
                <div class="col">
                    <SearchInput></SearchInput>

                    <!--<input type="text" name="searchtop" id="searchtop" v-model="searchTerm" v-on:input="searchTerm=$event.target.value" v-on:keyup="search" v-bind:placeholder="translations['shared_Search_Global_Placeholder']" autocomplete="off">-->
                </div>
                <!--<div class="col col-auto">
                    <button type="submit" v-on:click="search"><span class="icon icon-search"></span></button>
                </div>-->
            </div>
        </form>
    </div>
</template>
<script>
    import SearchInput from '@/components/rin_new_design/components/search/SearchInput.vue';
    //to be used in spa only
    import spaEventBus from "@/app/eventBus";
    import { mapGetters } from 'vuex';

    export default {
        name: 'SearchNotLogged',
        components: {
            SearchInput
        },
        computed: {
            ...mapGetters(['layout', 'translations', 'nomenclatures']),

            isSearchOpen: function () {
                return this.resultsOpen || this.isMobileSearchOpen;
            },
            visibilityCss: function () {
                return this.$store.getters.getSearchInputVisibility;
            },
            searchTerm: {
                // getter
                get: function () {
                    return this.$store.getters.getGlobalSearchTerm;
                },
                // setter
                set: function (newValue) {
                    this.$store.dispatch("updateGobalSearchTerm", newValue);
                }
            }
        },
        methods: {
            search: _.debounce(function () {
                var _this = this;
                var id = 0;//not logged in
                if (_this.searchTerm) {
                    this.getSerchResults(id);
                }
            }, 1200),

            getSerchResults: function (id) {
                this.$store.dispatch("updateGobalSearchTerm", this.searchTerm);
                spaEventBus.$emit('global-search-get-results', {
                    id: id
                });
            }

        }
    };
</script>