export default {
	namespaced: true,
	state: {
		playerProfileBreadcrumbs: {},
		tournamentBreadcrumbs: {},
		clubLeagueBreadcrumbs: {},
		teamLeagueBreadcrumbs: {},
		organisationBreadcrumbs: {},
	},
	mutations: {
		resetAllBreadcrumbs(state) {
			state.playerProfileBreadcrumbs = {};
			state.tournamentBreadcrumbs = {};
			state.clubLeagueBreadcrumbs = {};
			state.teamLeagueBreadcrumbs = {};
			state.organisationBreadcrumbs = {};
		},
		setBreadcrumb(state, payload) {
			if (payload?.bc[0]?.mapTo) {
				//the first record is going to be the abstract parent which will have to be renamed via another mutation
				this.commit(`breadcrumbs/set${payload.bc[0].mapTo}`, payload);
			}
		},
		setPlayerProfileBreadcrumb(state, payload) {
			state.playerProfileBreadcrumbs = payload;
		},
		setTournamentBreadcrumbs(state, payload) {
			state.tournamentBreadcrumbs = payload;
		},
		setClubLeagueBreadcrumbs(state, payload) {
			state.clubLeagueBreadcrumbs = payload;
		},
		setTeamLeagueBreadcrumbs(state, payload) {
			state.teamLeagueBreadcrumbs = payload;
		},
		setOrganisationBreadcrumbs(state, payload) {
			state.organisationBreadcrumbs = payload;
		},
		setFeatureBreadcrumb(state, payload) {
			if (state.tournamentBreadcrumbs.bc && payload.feature === 'Tournament') {
				state.tournamentBreadcrumbs.bc[0].value = payload.value;
			} else if (state.clubLeagueBreadcrumbs.bc && payload.feature === 'Clubleague') {
				state.clubLeagueBreadcrumbs.bc[0].value = payload.value;
			} else if (state.teamLeagueBreadcrumbs.bc && payload.feature === 'Teamleague') {
				state.teamLeagueBreadcrumbs.bc[0].value = payload.value;
				state.teamLeagueBreadcrumbs.bc[0].params.id = payload.id;
			} else if (state.playerProfileBreadcrumbs.bc && payload.feature === 'PlayerProfile') {
				state.playerProfileBreadcrumbs.bc[0].value = payload.value;
			} else if (state.organisationBreadcrumbs.bc && payload.feature === 'Organisation') {
				state.organisationBreadcrumbs.bc[0].value = payload.value;
			}
		},
	},
	getters: {
		getPlayerProfileBreadcrumbs(state) {
			const bc = state.playerProfileBreadcrumbs.bc ?? [];

			return bc.map(v => {
				return {
					description: v.value,
					name: v.name,
					params: v.params,
					query: v.query
				}
			});
		},
		getTournamentBreadcrumbs(state) {
			const bc = state.tournamentBreadcrumbs.bc ?? [];

			return bc.map(v => {
				return {
					description: v.value,
					name: v.name,
					params: v.params,
					query: v.query
				}
			});
		},
		getClubLeagueBreadcrumbs(state) {
			const bc = state.clubLeagueBreadcrumbs.bc ?? [];

			return bc.map(v => {
				return {
					description: v.value,
					name: v.name,
					params: v.params,
					query: v.query
				}
			});
		},
		getTeamLeagueBreadcrumbs(state) {
			const bc = state.teamLeagueBreadcrumbs.bc ?? [];

			return bc.map(v => {
				return {
					description: v.value,
					name: v.name,
					params: v.params,
					query: v.query
				}
			});
		},
		getOrganisationBreadcrumbs(state) {
			const bc = state.organisationBreadcrumbs.bc ?? [];

			return bc.map(v => {
				return {
					description: v.value,
					name: v.name,
					params: v.params,
					query: v.query
				}
			});
		},
	},
};
