<template>
	<div class="rin-register-country rin-register-form">
		<h1>{{ translations['register_CountryHeading'] }}</h1>

		<BaseDropdown
			buttonSize="m"
			buttonType="tertiary"
			:class="{ 'rin-concrete-selection': country && country.code > 0 }"
		>
			<template v-slot:button>
				<span>
					<i v-if="country != null" :class="['flag-icon flag-icon-' + country.short]"></i>
					<span>
						{{ country != null ? country.name : translations['calendarIndex_CountryFilter'] }}
					</span>
				</span>
			</template>
			<template v-slot:content>
				<li class="no-hover">
					<input
						class="countries-input rounded-pill form-control"
						v-model="filterCountriesInput"
						@input="updateFilter"
						inputmode="search"
					/>
				</li>
				<li v-for="c in countriesList" :key="c.code" @click="updateUserCountry(c)">
					<i :class="['flag-icon flag-icon-' + c.short]"></i>
					{{ c.name }}
				</li>
			</template>
		</BaseDropdown>

		<div v-cloak>
			<BaseAlert type="danger" :has-background="false">
				<span v-if="triggerRequiredError" class="rin-register-error">{{ translations['shared_T12'] }}</span>
			</BaseAlert>
		</div>

		<BaseButton type="success" size="m" @click="goToNext" :disabled="isNextDisabled"> {{ translations['shared_Next'] }} </BaseButton>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton';
import BaseDropdown from '@/components/rin_new_design/components/base/BaseDropdown';

export default {
	name: 'RegisterCountry',
	components: {
		BaseAlert,
		BaseButton,
		BaseDropdown,
	},
	props: ['nextRef'],
	computed: {
		...mapGetters(['translations', 'layout', 'nomenclatures']),
		...mapGetters('register', ['country']),
	},
	data() {
		return {
			countriesList: [],
			filterCountriesInput: '',
			triggerRequiredError: false,
			isNextDisabled: false,
		};
	},
	methods: {
		...mapMutations('register', ['updateCountry']),
		updateFilter() {
			const ctr = this.nomenclatures.Countries.filter((c) => c.code > 0);
			if (this.filterCountriesInput == '') this.countriesList = ctr;

			this.countriesList = ctr.filter(
				(c) => c.name.toLowerCase().indexOf(this.filterCountriesInput.toLowerCase()) !== -1
			);
		},
		updateUserCountry(c) {
			this.updateCountry(c);
			this.filterCountriesInput = '';
			this.updateFilter();
			this.triggerRequiredError = false;
		},
		goToNext() {
			if (this.isNextDisabled) return;

			if (this.country && this.country.code > 0) {
				this.triggerRequiredError = false;
				this.nextRef.click();

				this.isNextDisabled = true;
				setTimeout(() => {
							this.isNextDisabled = false;
						}, 500);
			} else {
				this.triggerRequiredError = true;
			}
		},
	},
	mounted() {
		this.countriesList = this.nomenclatures.Countries.filter((c) => c.code > 0);
	},
};
</script>
