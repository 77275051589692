<template>
	<div class="rin-register-usernames rin-register-form">
		<h1>{{ translations['register_NamesHeading'] }}</h1>

		<div class="rin-register-input-container">
			<label for="firstname" class="rin-register-label">{{ translations['register_FirstName'] }}</label>
			<input
				id="firstname"
				v-model.trim="userFirstName"
				class="rin-register-input"
				type="text"
				inputmode="search"
				autocomplete="off"
				:placeholder="translations['register_FirstName']"
				v-bind:class="{
					'has-error':
						$v.userFirstName.$dirty &&
						(!$v.userFirstName.required || !$v.userFirstName.minLength || !$v.userFirstName.maxLength),
				}"
			/>
		</div>

		<div v-cloak>
			<BaseAlert type="danger" :has-background="false">
				<span v-if="$v.userFirstName.$dirty && !$v.userFirstName.required" class="rin-register-error">
					{{ translations['register_2'] }}
				</span>
				<span v-if="$v.userFirstName.$dirty && !$v.userFirstName.minLength" class="rin-register-error">
					{{ interpolateString(translations['register_FieldMinLength'], [2]) }}
				</span>
				<span v-if="$v.userFirstName.$dirty && !$v.userFirstName.maxLength" class="rin-register-error">
					{{ interpolateString(translations['register_FieldMaxLength'], [150]) }}
				</span>
			</BaseAlert>
		</div>

		<div class="rin-register-input-container">
			<label for="lastname" class="rin-register-label">{{ translations['register_LastName'] }}</label>
			<input
				id="lastname"
				v-model.trim="userLastName"
				class="rin-register-input"
				type="text"
				inputmode="search"
				:placeholder="translations['register_LastName']"
				v-bind:class="{
					'has-error':
						$v.userLastName.$dirty &&
						(!$v.userLastName.required || !$v.userLastName.minLength || !$v.userLastName.maxLength),
				}"
			/>
		</div>

		<div v-cloak>
			<BaseAlert type="danger" :has-background="false">
				<span v-if="$v.userLastName.$dirty && !$v.userLastName.required" class="rin-register-error">
					{{ translations['register_2'] }}
				</span>
				<span v-if="$v.userLastName.$dirty && !$v.userLastName.minLength" class="rin-register-error">
					{{ interpolateString(translations['register_FieldMinLength'], [2]) }}
				</span>
				<span v-if="$v.userLastName.$dirty && !$v.userLastName.maxLength" class="rin-register-error">
					{{ interpolateString(translations['register_FieldMaxLength'], [150]) }}
				</span>
			</BaseAlert>
		</div>

		<BaseButton type="success" size="m" @click="goToNext" :disabled="isNextDisabled"> {{ translations['shared_Next'] }} </BaseButton>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import utils from '@/mixins/utils.js';

import BaseAlert from '@/components/rin_new_design/components/base/BaseAlert.vue';
import BaseButton from '@/components/rin_new_design/components/base/BaseButton';

export default {
	name: 'RegisterName',
	components: {
		BaseAlert,
		BaseButton,
	},
	props: ['nextRef'],
	mixins: [utils],
	computed: {
		...mapGetters(['translations', 'layout']),
		...mapGetters('register', ['firstName', 'lastName']),
		userFirstName: {
			get() {
				return this.firstName;
			},
			set(v) {
				this.updateFirstName(v);
			},
		},
		userLastName: {
			get() {
				return this.lastName;
			},
			set(v) {
				this.updateLastName(v);
			},
		},
	},
	data() {
		return {
			isNextDisabled: false,
		};
	},
	methods: {
		...mapMutations('register', ['updateFirstName', 'updateLastName']),
		goToNext() {
			if (this.isNextDisabled) return;
			this.$v.userFirstName.$touch();
			this.$v.userLastName.$touch();
			if (!this.$v.userFirstName.$error && !this.$v.userLastName.$error) {
				this.nextRef.click();

				this.isNextDisabled = true;
				setTimeout(() => {
							this.isNextDisabled = false;
						}, 500);
			}
		},
	},
	validations: {
		userFirstName: {
			required: required,
			minLength: minLength(2),
			maxLength: maxLength(150),
		},
		userLastName: {
			required: required,
			minLength: minLength(2),
			maxLength: maxLength(150),
		},
	},
};
</script>
