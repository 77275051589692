<template>
    <div>
        <BaseSpinner v-if="!isLoaded" class="mt-5"></BaseSpinner>
        <div v-if="!isLoaded" class="progress m-5">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" v-bind:style="{'width': progress + '%'}"></div>
        </div>

        <mainAppBs3 v-if="isLoaded && isBS3"></mainAppBs3>
        <mainAppBs4 v-if="isLoaded && !isBS3"></mainAppBs4>
    </div>
</template>

<script>
    import mainAppBs4 from "@/components/shared/mainApp";
    import mainAppBs3 from "@/components/shared/mainApp-bs3";

    import BaseSpinner from '@/components/rin_new_design/components/base/BaseSpinner.vue'

    export default {
        name: 'MainAppLoader',        
        computed: {
            progress() {
                let calls = 0;
                if (this.$store.state.translations.isLoaded) { calls++; }
                if (this.$store.state.nomenclatures.isLoaded) { calls++; }
                if (this.$store.state.layout.player.isLoaded) { calls++; }
                if (calls == 0) return 0;
                return calls /3 * 100;
            }
        },
        data: function () {
            return {
                isLoaded: false,
                isBS3: false
            };
        },
        components: {
            mainAppBs3,
            mainAppBs4,
            BaseSpinner
        },
        watch: {
            progress() {
                if (this.progress == 100) this.isLoaded = true;
            }
        },
        mounted() {
            /*eslint-disable */
            if (bootstrapVersion && bootstrapVersion == 'BS3') this.isBS3 = true;   
            /*eslint-enable */
        }
    }
</script>