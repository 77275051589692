export default {
	namespaced: true,
	state: {
		history: [],
	},
	mutations: {
		setHistory(state, payload) {
			const clean = state.history.filter(x => x.route.name != payload.route.name || x.route.params.id != payload.route.params.id);
			clean.unshift(payload);
			state.history = clean.slice(0, 10);
		},
		removeAll(state){
			state.history = [];
		},
		removeHistory(state, payload){
			const index = state.history.findIndex(x => x.route.name == payload.route.name && x.route.params.id == payload.route.params.id);
			if(index >= 0)
				state.history.splice(index, 1);
		}
	},
	actions: {
		setHistory: ({ commit }, payload) => {
			commit('setHistory', payload);
		}
	},
	getters: {
		history(state) {
			return state.history;
		},
	},
};