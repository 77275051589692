<template>
    <div v-click-outside="hideOnClickOutside" class="popover-wrapper">
        <div v-bind:id="`button${id}`" v-bind:aria-describedby="`tooltip${id}`">
            <slot name="trigger">
            </slot>
        </div>
        <div v-bind:id="`tooltip${id}`" role="tooltip">
            <slot name="tooltip">
            </slot>
            <div id="arrow" data-popper-arrow></div>
        </div>
    </div>
</template>

<script>
    import { createPopper } from '@popperjs/core';
    import vClickOutside from 'v-click-outside';

    export default {
        name: 'BasePopover',
        directives: {
            clickOutside: vClickOutside.directive
        },
        data() {
            return {
                button: {},
                tooltip: {},
                popper: {},
                isOpen: false
            }
        },
        computed: {
            id: function () {
                return this._uid;
            }
        },
        mounted() {
            this.button = document.querySelector('#button' + this.id);
            this.tooltip = document.querySelector('#tooltip' + this.id);
            this.popper = createPopper(this.button, this.tooltip, {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            });
            this.button.addEventListener('click', () => {

                this.isOpen ? this.hide() : this.show();
            })
            this.tooltip.addEventListener('click', () => {
                this.hide();
            })
        },
        methods: {
            show: function () {
                this.tooltip.setAttribute('data-show', '');
                this.isOpen = true;
                this.popper.update();
            },
            hide: function () {
                this.isOpen = false;
                this.tooltip.removeAttribute('data-show');
            },
            hideOnClickOutside: function () {
                this.hide();
            }
        }
    };
</script>

