export default {
    namespaced: true,
    state: {
        eventState: 0,
        standings: [],
        areStandingsGenerated: false
    },
    mutations: {
        updateEventState(state, payload) {
            state.eventState = payload;
        },
        updateStandings(state, payload) {
            state.standings = payload;
        },
        setStandingsGenerated(state, payload) {
            state.areStandingsGenerated = payload;
        },
    },
    actions: {
        updateEventState: ({ commit }, payload) => {
            commit("updateEventState", payload);
        },
        updateStandings: ({ commit }, payload) => {
            commit("updateStandings", payload);
        },
        setStandingsGenerated: ({ commit }, payload) => {
            commit("setStandingsGenerated", payload);
        }
    },
    getters: {
        getStandings(state) {
            return state.standings;
        },
        areStandingsGenerated(state) {
            return state.areStandingsGenerated;
        },
        eventState(state) {
            return state.eventState;
        }
    }
}