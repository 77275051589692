<template>
    <div class="flex-body-div">
        <use-google-chrome-bar></use-google-chrome-bar>
        <logos-top-bar v-if="layout.player.isAuthenticated"></logos-top-bar>
        <header v-if="showHeader">
            <top-bar v-if="layout.player.isAuthenticated"></top-bar>
        </header>

        <div id="landingPageContainer" class="container-fluid container-search">

            <div v-show="isTopNotificationShown" class="alert alert-warning text-center margin-top-sm">
                {{topNotificationMessage}}
                <button type="button" class="close" aria-label="Close" v-on:click="closeTopNotification">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <router-view></router-view>
        </div>
        <layoutFooter v-if="showFooter"></layoutFooter>
    </div>
</template>

<script>
    import spaEventBus from "@/app/eventBus";
    import { mapGetters, mapMutations } from 'vuex';

    import { GET_LAYOUT_MODEL_ASYNC } from "@/store/modules/layout-module.js";

    import useGoogleChromeBar from "@/components/shared/use-google-chrome-bar.vue";
    import logosTopBar from '@/components/layout/top-bar/logos-top-bar-bs3';
    import topBar from '@/components/layout/top-bar/top-bar-partial-bs3';    
    import layoutFooter from '@/components/layout/footer/layout-footer-bs3';

    export default {
        name: "mainApp-bs3",
        metaInfo() {
            return {
                colorLocal: '',
                title: this.layout.meta.title,
                titleTemplate: '%s | Rankedin',
                link: this.layout.hreflangsAndCanonicalLinks,
                meta: [
                    { charset: 'utf-8' },
                    { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
                    { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge, chrome=1' },
                    { name: 'format-detection', content: 'telephone=yes' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0' },
                    { name: 'author', content: 'rankedin.com' },
                    { property: 'fb:app_id', content: '575210812498503' },
                    { property: 'og:site_name', content: 'Rankedin' },

                    { name: 'description', content: this.layout.meta.description },

                    { property: 'og:type', content: 'article' },
                    { property: 'og:title', content: this.layout.meta.socialMediaTitle },
                    { property: 'og:url', content: this.layout.meta.socialMediaUrl },
                    { property: 'og:description', content: this.layout.meta.socialMediaDescription },
                    { property: 'og:image', content: this.layout.meta.socialMediaImage },
                ]
            }
        },
        components:
        {
            'use-google-chrome-bar': useGoogleChromeBar,
            'logos-top-bar': logosTopBar,
            'top-bar': topBar,                        
            'layoutFooter': layoutFooter,
        },
        beforeMount() {
            this.$store.dispatch("updateLayoutDomain", window.location.origin);
            this.displaySupportChat();
        },
        data: function () {
            return {                                                                
             
            }
        },
        created() {
            window.addEventListener('scroll', this.handleScroll);
        },
        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        computed: {
            ...mapGetters(['layout', 'translations']),

            isTopNotificationShown: function () {
                return this.layout.topNotification.isShown;
            },
            topNotificationMessage: function () {
                return this.layout.topNotification.message;
            },
            showHeader() { return this.layout.showHeader; },
            showFooter() { return this.layout.showFooter; },

        },
        mounted() {                        
            const urlParams = new URLSearchParams(window.location.search.toLowerCase());

            if (urlParams.get('isAccountConfirmed'.toLowerCase()) != null) {
                this.$store.dispatch("layout_updateTopNotification", { isShown: true, message: this.translations['register_AccountActivatedNotification'] });
            }

            if (urlParams.get('notificationMessage'.toLowerCase()) != null) {
                switch (urlParams.get('notificationMessage'.toLowerCase())) {
                    case 'americano_successfulconfirmation':
                        this.$store.dispatch("layout_updateTopNotification", { isShown: true, message: this.translations['americano_SuccessfulConfirmation'] });
                        break;
                    case 'americano_notsuccessfulconfirmation':
                        this.$store.dispatch("layout_updateTopNotification", { isShown: true, message: this.translations['americano_NotSuccessfulConfirmation'] });
                        break;
                    default:
                        console.log(`Sorry, we are out of translations for ${urlParams.get('notificationMessage'.toLowerCase())}.`);
                }


            }

            spaEventBus.$on('refreshLayoutModel', () => {
                this.$store.dispatch(GET_LAYOUT_MODEL_ASYNC);
            });

        },
        methods: {
            handleScroll(event) {
                this.closeTopNotification();
            },
            closeTopNotification: function () {
                this.$store.dispatch("layout_updateTopNotification", { isShown: false, message: '' });
            },
            displaySupportChat: function () {
                if (!this.layout.player.isEventOrganizer) return;

                var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
                (function () {
                    var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
                    s1.async = true;
                    s1.src = 'https://embed.tawk.to/5db2db1778ab74187a5b80f8/default';
                    s1.charset = 'UTF-8';
                    s1.setAttribute('crossorigin', '*');
                    s0.parentNode.insertBefore(s1, s0);
                })();
            },
            hideBurger: function () {
                const menu = document.getElementsByClassName('profileMenu')[0];
                const menuWrapper = document.getElementsByClassName('user')[0];
                const burger = document.getElementsByClassName('burger')[0];
                const mobileMenu = document.getElementsByClassName('menu')[0];
                burger?.classList.remove('open');
                mobileMenu?.removeAttribute('style');
                menu?.classList.remove('open');
                menu?.removeAttribute('style');
                menuWrapper?.classList.remove('open');
            }
        }
    }
</script>
<style>
    .embed-padding {
        padding-top: 20px !important;
    }

    .margin-fix-afterheader {
        margin-top: -12px;
    }

    .alert-top-notification {
        margin-top: 10px;
        color: #2e3e78;
        background-color: #d7f6e5;
        font-weight: 600;
        position: fixed;
        z-index: 100;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
    }

    @media (max-width: 1006px) {
        .alert-top-notification {
            margin-top: 40px;
        }
    }

    main.notauthenticated .submenu.fixed {
        top: 0 !important;
    }

    .flex-body-div {
        min-height: 100%;
        display: flex;
        flex-direction: column;
    }
</style>