export default {
    state: {
        controller: '',
        action: '',
        id: '',
        classId: '',
        stage: '',
        strength: '',
        embedType: '',
       
        colorHex: '',
    },
    mutations: {

        updateParameters(state, payload) {
            state.controller = payload.controller;
            state.action = payload.action;
            state.id = payload.id;
            state.classId = payload.classId;
            state.stage = payload.stage;
            state.strength = payload.strength;
            state.embedType = payload.embedType;

            // for setting default color
            state.colorHex = payload.colorHex;
        },

        updateColorHex(state, payload) {
            state.colorHex = payload;
        }
    },
    actions: {

        updateEmbedModalParameters: ({ commit }, payload) => {
            commit("updateParameters", payload);
        },

        updateEmbedModalColorHex: ({ commit }, payload) => {
            commit("updateColorHex", payload);
        },
    },
}